import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetDashBoardStatusQuery,
  useGetStageQuery,
} from "../../features/status/statusApiSlice";
import { getUserFromLocal, removeAuthFromLocal } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { authActions } from "../../features/auth/authSlice";
import CurrentRating from "../../components/ratingStatusBoard/currentRating/CurrentRating";
import EmisDeclarationStatus from "../../components/ratingStatusBoard/emisDeclarationStatus/EmisDeclarationStatus";
import GlobalGHGRating from "../../components/globalGHG/GlobalGHGRating";

import "./style.css";

import TopBg from "../../assets/icons/dashTopIcon.svg";
import RightBg from "../../assets/icons/dashRightIcon.svg";
import EmissionDecLogo from "../../assets/logo/EmissionDeclLogo.svg";
import Location from "../../assets/icons/location.svg";
import BellIcon from "../../assets/icons/bell.svg";
import ProfileIcon from "../../assets/icons/profileImg.svg";
import UserProfileImg from "../../assets/images/userImg.png";
import AddConsultantAndAuditor from "../../components/dashboardComponents/AddConsultantAndAuditor";
import OrganizationDashboard from "../../components/dashboardComponents/OrganizationDashboard";
import AuditorDashboard from "../../components/dashboardComponents/AuditorDashboard";
import ConsultantDashboard from "../../components/dashboardComponents/ConsultantDashboard";
import OrganizationDashboard2 from "../../components/dashboardComponents/OrganizationDashboard2";
import ConsultantDashboard2 from "../../components/dashboardComponents/ConsultantDashboard2";
import AuditorDashboard2 from "../../components/dashboardComponents/AuditorDashboard2";
import OrganizationCurrentRating from "../../components/dashboardComponents/OrganizationCurrentRating";
import {
  useGetMyRatingQuery,
  useMyRatingQuery,
  useUserAnalyticsQuery,
} from "../../features/steps/declerationApiSlice";
import PageLoader from "../../components/Loader/PageLoader";
import Notifications from "../../components/indicationMessage/Notifications";
import MyRatingHeader from "../../components/ratingComponents/myRatingHeader/MyRatingHeader";
import { Margin } from "@mui/icons-material";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [logOutPopUp, setLogOutPopUp] = useState(false);
  const userDetails = getUserFromLocal();
  const [isTeam, setIsTeam] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [isAuditor, setIsAuditor] = useState(false);
  const [isConsultantAccepted, setIsConsultantAccepted] = useState(false);
  const [isAuditorAccepted, setIsAuditorAccepted] = useState(false);
  const [invite, setInvite] = useState();
  // const [isScrolled, setIsScrolled] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications((prevState) => !prevState);
  };

  const toggleLogOut = () => {
    setLogOutPopUp(!logOutPopUp);
  };

  const {
    data: dashBoardStatusData,
    isLoading: dashBoardStatusIsLoading,
    isSuccess: dashBoardStatusIsSuccess,
    error: dashBoardStatusError,
    isError: dashBoardStatusIsError,
    isFetching: dashboardStatusIsFetching,
  } = useGetDashBoardStatusQuery({}, { refetchOnMountOrArgChange: true });

  const {
    data: myRatingData,
    isLoading: myRatingIsLoading,
    isSuccess: myRatingIsSuccess,
    error: myRatingError,
    isError: myRatingIsError,
  } = useMyRatingQuery({}, { refetchOnMountOrArgChange: true });

  const {
    data: stageData,
    isLoading: stageIsLoading,
    isSuccess: stageIsSuccess,
    error: stageError,
    isError: stageIsError,
    isFetching: stageIsFetching,
  } = useGetStageQuery({}, { refetchOnMountOrArgChange: true });

  const { data: userAnalytics, isLoading: userAnalyticsIsLoading } =
    useUserAnalyticsQuery();

  console.log("dashBoardStatusData", dashBoardStatusData);
  console.log(
    "organizationStatus",
    dashBoardStatusData?.data?.organization?.organizationStatus
  );
  console.log(`myRatingData`, myRatingData);
  const handleNavigateToRating = () => {
    navigate("/rating");
  };

  const handleNavigateToFeedback = () => {
    navigate("/userFeedBack");
  };

  return (
    <div className="container dashboardSection">
      {(stageIsLoading ||
        dashBoardStatusIsLoading ||
        dashboardStatusIsFetching ||
        userAnalyticsIsLoading ||
        stageIsFetching) && <PageLoader />}
      {/* <div className="dashtitleDiv">
        <div className=" logoSec wd-50">
          <img src={EmissionDecLogo} alt="" />
          <ul className="dashUl">
            <li>Dashboard</li>
            <li onClick={handleNavigateToRating}>My Rating</li>
            <li onClick={handleNavigateToFeedback}>FeedBack</li>
            <li>Help & Support</li>
          </ul>
        </div>
        <div className="dashProfil wd-50">
          <div className="city">
            <p>Dubai</p>
            <img src={Location} />
          </div>
          <div className="rightBorder"></div>
          <div
            className="city"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={toggleNotifications}
          >
            <img src={BellIcon} />
          </div>
          {showNotifications && <Notifications />}

          <div className="rightBorder"></div>
          <div className="profileImg" onClick={toggleLogOut}>
            <img src={UserProfileImg} />
          </div>
        </div>
      </div> */}
      <MyRatingHeader width="98%" />
      <div className="dashboardInner dashInnerSec">
        <div className="welcomeDiv">
          <p className="welcome">Welcome,</p>
          <p className="userName">{userDetails?.userName}</p>
        </div>
        <img src={TopBg} className="topbg" />
        <img src={RightBg} className="rightbg" />
        {userDetails?.userType === "organization" && (
          // <AddConsultantAndAuditor
          //   status={dashBoardStatusData}
          //   stageData={stageData}
          // />
          <OrganizationDashboard2
            status={dashBoardStatusData}
            stageData={stageData}
          />
        )}
        {userDetails?.userType === "auditor" && (
          <AuditorDashboard2
            status={dashBoardStatusData}
            stageData={stageData}
          />
        )}
        {userDetails?.userType === "consultant" && (
          <ConsultantDashboard2
            status={dashBoardStatusData}
            stageData={stageData}
          />
        )}
        <div className="dashCurrentStatus">
          {/* <CurrentRating /> */}
          <OrganizationCurrentRating myRatingData={myRatingData} />
          <EmisDeclarationStatus userAnalytics={userAnalytics} />
          <GlobalGHGRating userAnalytics={userAnalytics} />
        </div>
      </div>
      <div className="dashboardCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>

      {logOutPopUp && (
        <div className="LogOutContainer cursor-pointer">
          <div
            className="cursor-pointer"
            onClick={() => {
              removeAuthFromLocal();
              dispatch(authActions.logout());
              navigate("/home", { replace: true });
            }}
          >
            <h4 className="cursor-pointer colorWhite"> Log Out </h4>
          </div>
          <div>{/* <h4 className="">Sign In </h4> */}</div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
