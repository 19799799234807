import React from "react";

import "./style.css";

import RemoveIcon from "../../../assets/icons/removeBtn.svg";

function RemoveButton({ btnTitle, remove, index }) {
  return (
    <div
      className="removeButtonContainer cursorPointer"
      onClick={() => remove({ deleteIndex: index })}
    >
      <img src={RemoveIcon} alt="AddIcon" />
      <p>{btnTitle}</p>
    </div>
  );
}

export default RemoveButton;
