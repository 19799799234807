import React from 'react';

import "./style.css";

import CodewaveLogo from "../../../assets/logo/CodeWaveLogo.svg";

function MyProfileOrgDetails() {

  return (
    <div className='profileOrgaInfoCon'>
        <h1>Organization Details</h1>
        <div className='profileCodeLogo'>
            <img src={CodewaveLogo} alt='CodewaveLogo'/>
        </div>
        <div className='proOrgInfoSection'>
            <div className='orgaInfoSecData'>
                <span>Name of Organization</span>
                <p>Codewave Technologies</p>
            </div>
            <div className='orgaInfoSecData'>
                <span>Website link</span>
                <p>www.codewave.com</p>
            </div>
            <div className='orgaInfoSecData'>
                <span>Position in the organization</span>
                <p>VP Sustainability</p>
            </div>

            <div className='myProOrgHeadquarter'>
                <h1 className='myProHeadQ'>Headquarters</h1>
                <div className='myProOrgHeadqInner'>
                    <div className='orgaInfoSecData'>
                        <span>Address</span>
                        <p>123, ABC Street, XYZ Colony</p>
                    </div>
                    <div className='orgaInfoSecData'>
                        <span>City / Town / Suburb</span>
                        <p>Dubai</p>
                    </div>
                    <div className='orgaInfoSecData'>
                        <span>Country</span>
                        <p>United Arab Emirates</p>
                    </div>
                </div>
            </div>

            <div className='orgaInfoSecData'>
                <span>Number of Employees</span>
                <p>500 to 1000</p>
            </div>
            <div className='orgaInfoSecData'>
                <span>Sector</span>
                <p>Energy</p>
            </div>
            <div className='orgaInfoSecData'>
                <span>Sub - Category</span>
                <p>Energy Equipment & Services​</p>
            </div>
        </div>

    </div>
  )
}

export default MyProfileOrgDetails;