import React, { useEffect, useRef, useState } from "react";

import "./style.css";
import DonutChart from "../../charts/DonutChart";
import RatingSelectProfile from "../../ratingComponents/ratingSelectProfile/RatingSelectProfile";

const locationData = [
  { name: "All Location", value: "" },
  { name: "Rassia", value: 1 },
  { name: "India", value: 2 },
  { name: "UK", value: 3 },
  { name: "Nepal", value: 4 },
];

function DashboardRating({ ratingsData }) {
  const [locationName, setLocationName] = useState();

  const handleChange = (event) => {
    setLocationName(event.target.value);
  };

  return (
    <div className="userProgressCard">
      <div className="totalEmissDeclareRow">
        <div className="totalEmissDeclareCol">
          <span>Ratings</span>
          <h1>9,432</h1>
        </div>
        <RatingSelectProfile
          data={locationData}
          value={locationName}
          onChange={handleChange}
          defaultValue={locationData[0].value}
        />
      </div>
      <div className="" style={{ width: "30vw" }}>
        <DonutChart ratingsData={ratingsData} />
      </div>
    </div>
  );
}

export default DashboardRating;
