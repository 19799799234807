import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import "./style.css"

function OrgRateDetailsBread() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" className="orgsRatingText">
      Orgs & Ratings
    </Link>,
    <Link underline="hover" key="1" color="inherit" className="orgsRatingText">
      Organizations
    </Link>,
    <Typography key="3" color="text.primary" className="adminOrgsUser">
      Rating Details
    </Typography>,
  ];

  return (
    <Stack spacing={2} className="orgsRatingBread">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default OrgRateDetailsBread;
