import React, { useEffect } from 'react';
import './style.css';

function RegCancelButton({ buttonName }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <button type="button" className="regCancelBtn">
     <div>{buttonName}</div>
    </button>
  );
}

export default RegCancelButton;