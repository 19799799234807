import React, { useCallback, useState } from "react";
import "./style.css";
import UploadPdfImg from "../../assets/icons/File_Upload.svg";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { validateDropzoneMultipleFiles } from "../../utils/helper";
import WarningIcon from "../../assets/icons/errorWarning.svg";
import {
  MAX_FILE_SIZE,
  DROPZONE_PDF_FORMAT,
  DROPZONE_WORD_FORMAT,
  DROPZONE_POWERPOINT_FORMAT,
} from "../../utils/defaults";
import { useEffect } from "react";
import UseMultipleFileUpload from "../../features/fileUpload/multipleFileUploadHook";
import PdfUpload from "../pdfUpload/PdfUpload";
import { useDeleteFileMutation } from "../../features/fileUpload/fileUploadApiSlice";
import { showError } from "../../features/snackbar/snackbarAction";
import PdfIcon from "../pdfUpload/PdfIcon";

function FileUploadBoxDecleration(props) {
  const {
    multiple = true,
    maxFiles = 10,
    folder,
    formik,
    field,
    value,
    error,
    errorIcon,
  } = props;

  const [uploadFile, { data, isSuccess, isError, isLoading }] =
    UseMultipleFileUpload();
  const [deletePdf] = useDeleteFileMutation();
  const dispatch = useDispatch();

  const extractFilenameAndExtension = (url) => {
    const regex = /\/([^\/]+)-see-rating-(.+)\.(\w+)$/;
    const match = url.match(regex);

    if (match) {
      const fileName = match[2]; // Captured filename
      const extension = match[3]; // Captured extension
      return { fileName, extension };
    } else {
      return { fileName: "No filename found in the URL", extension: "" };
    }
  };

  const formatFileName = (result) => {
    if (result.fileName.length >= 6) {
      const shortenedFileName = result.fileName.substring(0, 6);
      return `${shortenedFileName}...${result.extension}`;
    } else {
      // If the filename is less than 6 characters, return the original filename with the extension
      return `${result.fileName}.${result.extension}`;
    }
  };

  const onUpload = (details) => {
    const media = details.map((detail) => {
      return { fileName: detail?.url };
    });
    formik.setFieldValue(`${field}`, [...value, ...media]);
  };

  const handleRemove = (fileName) => {
    deletePdf({ fileName: fileName });
    const updatedMediaList = value.filter((url) => url.fileName !== fileName);
    formik.setFieldValue(`${field}`, updatedMediaList);
  };

  const { getRootProps, getInputProps, inputRef, rootRef } = useDropzone({
    accept: {
      ...DROPZONE_PDF_FORMAT,
      ...DROPZONE_WORD_FORMAT,
      ...DROPZONE_POWERPOINT_FORMAT,
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      validateDropzoneMultipleFiles(rejectedFiles, MAX_FILE_SIZE);
      uploadFile({ files: acceptedFiles, folder: folder });
    },
    disabled: isLoading,
    multiple,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(() => {
    if (isError) {
      dispatch(showError({ message: "Failed to upload" }));
    }

    if (isSuccess && data) {
      onUpload(data?.details);
    }
  }, [isError, isSuccess, data, dispatch]);

  return (
    <React.Fragment>
      <div
        className={`uploadBox cursorPointer ${error ? "fileInputError" : ""}`}
        {...getRootProps({})}
      >
        <div className="uploadBox_innerdiv">
          <img src={UploadPdfImg} alt="pdfimg" />
          <p>Drag & drop files, or click here to upload files</p>
          <input type="file" style={{ display: "none" }} {...getInputProps()} />
        </div>
      </div>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
      <div className="repCompPdf mt-28">
        {value?.map((item, index) => {
          const fileNameAndExtension = extractFilenameAndExtension(
            item?.fileName
          );
          const fileName = formatFileName(fileNameAndExtension);
          return (
            <div key={index} className="pdfUploadRow">
              <PdfIcon
                onRemove={handleRemove}
                url={item?.fileName}
                fileName={fileName}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default FileUploadBoxDecleration;
