import React from "react";
import FormButton from "../../../components/button/FormButton";
import InputBox from "../../../components/InputBox/InputBox";
import { useFormik } from "formik";
import "./style.css";
import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "../../../features/auth/authApiSlice";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const forgotPassword = Yup.object({
    email: Yup.string().required("Required").trim().lowercase()
      .min(5, "Enter valid email id")
      .max(50, "Name must be at most 50 characters")
      .test("valid-email", "Incorrect Email ID. Please check your email ID", (value) => {
      if (!value) return false;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      return emailRegex.test(value);
      }),
  });

const ForgotPasswordSendEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sendForgotPasswordLink, { isSuccess: sendLinkIsSuccess }] =
    useForgotPasswordMutation();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    enableReinitialize: true,
    validationSchema: forgotPassword,
    onSubmit: (values) => {
        sendForgotPasswordLink(values)
        .unwrap()
        .then(()=>{
            dispatch(showSuccess({ message: "Link Sent Successfully" }));
            navigate("/auth/login")
        })
        .catch((e)=>{
            dispatch(
                showError({
                  message: e?.data?.message,
                })
              )
        })
    },
  });
  return (
    <div className="container forgotPasSection">
      <div className="forgotPassCol1">
        <img className="forgotPass_topBg" src={SignUpTopBg} alt="" />
        <img className="forgotPass_bottomBg" src={SignUpBottomBg} alt="" />
        <img className="forgotPass_leftBg" src={SignUpLeftBg} alt="" />
        <div className="logoImgDiv">
          <img src={RegistrationLogo} alt="" />
        </div>
        <div className="welcomSection">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>
        <div className="copyRightSec">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>
      <div className="forgotPassCol2">
        <h1>Forgot Password?</h1>
        <p>Reset your password to a more secure one today!</p>
        <form
          className="formContainer"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <div className="emailIdField">
            <InputBox
              headName={"Email ID"}
              placeholder="example@mail.com"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.email && formik.errors.email}
              required
            />
          </div>
          <div className="cursorPointer">
            <FormButton
              buttonName={"Send Email"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordSendEmail;
