import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";

function FAQ({question, answer  }) {

    return (
        <div className="emisSectionGap" id="faq">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        <div className="faqInfo">
                            <h3>{question}</h3>
                        </div>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="anyFacilitySec">{answer}</div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default FAQ;
