import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./style.css";
import { NativeSelect } from "@mui/material";

function SelectNetYear({ error, errorIcon, formik, ...props }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="selectNetYearCon">
      <div sx={{ display: "block" }} className="netZeroLabel">
        Net Zero Target Year
      </div>
      <FormControl sx={{ m: 1, minWidth: 120 }} className="">
        <NativeSelect
          label="Year"
          labelId="demo-controlled-open-select-label"
          id="demo-customized-select-native"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          {...props}
          className="selectNetYear"
        >
          <option value="">Select</option>
          <option value={2030}>2030</option>
          <option value={2034}>2034</option>
          <option value={2038}>2038</option>
          <option value={2042}>2042</option>
          <option value={2046}>2046</option>
          <option value={2050}>2050</option>
        </NativeSelect>
      </FormControl>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={errorIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}

export default SelectNetYear;
