import react from "react";

import MyRatingHeader from "../../../components/ratingComponents/myRatingHeader/MyRatingHeader";
import MyProfileHeader from "../../../components/myProfile/myProfileHeader/MyProfileHeader";
import MyProfileBasicInfo from "../../../components/myProfile/myProfileBasicInfo/MyProfileBasicInfo";
import MyProfileOrgDetails from "../../../components/myProfile/myProfileOrgDetails/MyProfileOrgDetails";
import MyProfileLoginCred from "../../../components/myProfile/myProfileLoginCred/MyProfileLoginCred";
import MyProfileAdditDetails from "../../../components/myProfile/myProfileAdditDetails/MyProfileAdditDetails";

import './style.css';
import MyProConsFreeOrg from "../../../components/myProfile/myProConsFreeOrg/MyProConsFreeOrg";
import MyProConsFirmOrg from "../../../components/myProfile/myProConsFirmOrg/MyProConsFirmOrg";
import MyProAuditOrg from "../../../components/myProfile/myProAuditOrg/MyProAuditOrg";
import MyProfileAdvisoryOrg from "../../../components/myProfile/myProfileAdvisoryOrg/MyProfileAdvisoryOrg";

function MyProfile() {

  return (
    <div  className='orgProfileSec' >
        <MyRatingHeader />
      <div className='orgProfileInnerCon'>
        <MyProfileHeader />
         {/* Organizations */}
        <MyProfileBasicInfo />
        <MyProfileOrgDetails />
        <MyProfileAdditDetails />
        <MyProfileLoginCred />

        {/* Consultant Organization */}
        <MyProConsFreeOrg />
        <MyProConsFirmOrg />

        {/* Auditor Organization  */}
        <MyProAuditOrg />

        {/* Advisory Board Member Organization  */}
        <MyProfileAdvisoryOrg />


      </div>
     <div className='emissionCopy'>
        <p>© Copyright  |  All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default MyProfile;