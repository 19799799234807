import { useState } from "react";

import "./style.css";

import OrgGHGGaph from "../../assets/images/qqq.png";
import LineChart from "../charts/LineChart";


function GlobalGHGRating() {

  return (
    <div className="graphImg">
      <LineChart />
    </div>
  );
}

export default GlobalGHGRating;
