import React, { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import "./style.css";

import RightArrow from "../../../../assets/icons/Arrow_Right_LG.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import dayjs from 'dayjs';

function YearCalendar({
  headName,
  // startDateLabel,
  // endDateLabel,
  startDateValue,
  startDateName,
  startDateError,
  endDateValue,
  endDateName,
  endDateError,
  formik,
  errorIcon,
}) {
  const [datePopUp, setDatePopUp] = useState({
    startDate: false,
    endDate: false,
  });

  return (
    <div className='yearCalendarCon'>
      { headName === 'From' ? (
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <label className="yearCalendarLabel">{headName}</label>
              <DatePicker 
                  // label={'"year"'}
                  views={['year']} 
                  openTo="year" 
                  // leftArrowButtonProps={{ children: <img src={RightArrow} alt='CalendarIcon' /> }}
                  // slots={{ openPickerIcon: FlightTakeoffIcon }}
                  // icon={<RightArrow />}
                  slots={{
                      calendarIcon: () => <img src={RightArrow} alt='CalendarIcon' />,
                    }}
                  value={dayjs(startDateValue)}
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      `${startDateName}`,
                      newValue.format("YYYY")
                    );
                  }}
                  className={`dateField ${startDateError ? "error" : ""}`}
                  onBlur={formik?.handleBlur}
                  
              />
          </DemoContainer>
          </LocalizationProvider>
          {startDateError && (
            <p error className="inputError">
              {errorIcon && (
                <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
              )}
              {startDateError}
            </p>
          )}
        </div>
      ):(
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <label className="yearCalendarLabel">{headName}</label>
            <DatePicker 
                views={['year']} 
                openTo="year" 
                // slots={{
                //     calendarIcon: () => <img src={RightArrow} alt='CalendarIcon' />,
                //   }}
                value={endDateValue}
                onChange={(newValue) => {
                  formik.setFieldValue(
                    `${endDateName}`,
                    newValue.format("YYYY")
                  );
                }}
                className={`dateField ${endDateError ? "error" : ""}`}
                onBlur={formik?.handleBlur}
                
            />
        </DemoContainer>
        </LocalizationProvider>
        {endDateError && (
          <p error className="inputError">
            {errorIcon && (
              <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
            )}
            {endDateError}
          </p>
        )} 
      </div>
       )
      }


    </div>
  )
}

export default YearCalendar