import React from 'react';

import "./style.css";
import PdfUpload from '../../pdfUpload/PdfUpload';

function MyProAuditOrg() {

  return (
    <div className='myProAuditOrgCon'>
        <h1>Organization Details</h1>
        <div className='myProAuditOrgInner'>
            <div className='myProAuditOrgSec'>
                <div className='myProAuditOrgData'>
                    <span>Name of Auditing Organization</span>
                    <p>Codewave Technologies</p>
                </div>
                <div className='myProAuditOrgData'>
                    <span>Name of Your Organization</span>
                    <p>Codewave Technologies</p>
                </div>
                <div className='myProAuditOrgData'>
                    <span>Website</span>
                    <p>www.company.com</p>
                </div>
            </div>

            <div className='myProAudDocuments'>
                <span className='myProAudDocTitle'>Documents</span>
                <div className='proAudPdfSec'>
                    <div className='proAudfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proAudfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                </div>
            </div>
        </div>
        

    </div>
  )
}

export default MyProAuditOrg;