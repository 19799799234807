import React, { useEffect, useRef, useState } from 'react';

import "./style.css";

import ProfileIcon from "../../../../assets/icons/profileImg.svg";
import EditIcon from "../../../../assets/icons/editPencilIcon.svg";
import userIcon from "../../../../assets/icons/profileUserIcon.svg";


const involevePeopleData = [
    {
        profileIcon: ProfileIcon,
        name: "Mohamed Irfan (You)",
        designation: "Project Manager / Organization"
    },
    {
        profileIcon: ProfileIcon,
        name: "Mark Anthony",
        designation: "Consultant"
    },
    {
        profileIcon: ProfileIcon,
        name: "Tony Danza",
        designation: "Auditor"
    }
]

function RatingInvolveUsers() {

  return (
    <div className='rateUserInvolveCon'>
        <div className='rateUserInvTitle'>
            <h1>People Involved</h1>
            <p>All the people involved in your rating process</p>
        </div>

        {involevePeopleData?.map((item) =>(
        <div className='ratePeopleInvList'>
            <div className='ratePeopleInvCol1'>
                <div className='ratePeopleInvProf'>
                    <img src={item.profileIcon} alt='ProfileIcon' className='cursorPointer' />
                </div>
                <div className='nameWithDesignation'>
                    <h1>{item.name}</h1>
                    <p>{item.designation}</p>
                </div>
            </div>
            <div className='ratePeopleInvCol2'>
                <div className='rateEditRow'>
                    <img src={EditIcon} alt='EditIcon' />
                    <h1>Change / Modify</h1>
                </div>
                <div className='rateEditRow'>
                    <img src={userIcon} alt='userIcon' />
                    <h1>View Profile</h1>
                </div>
                
            </div>
        </div>  
        ))}
    </div>
  )
}

export default RatingInvolveUsers;