import react, { useState } from "react";
import moment from "moment";

import ProfileAudDetails from "../userTypeDetails/ProfileAudDetails";
import ProfileConsDetails from "../userTypeDetails/ProfileConsDetails";
import ProfileAdvisoryBoardDetails from "../userTypeDetails/ProfileAdvisoryBoardDetails";
import RegistraionLog from "../../drawer/registrationLog/RegistraionLog";

import "./style.css";

import UserProfileImg from "../../../assets/images/userImg.png";
import RegLogIcon from "../../../assets/icons/registraionLogIcon.svg";
import NotebookIcon from "../../../assets/icons/Notebook.svg";
import ProfileOrganizationDetails from "../userTypeDetails/ProfileOrganizationDetails";
import ProfileAdminDetails from "../userTypeDetails/ProfileAdminDetails";

function ProfileViewDetails({ userData, userType, userId }) {
  const [registLogs, setRegistLogs] = useState(false);

  const regLogDrawerOpen = () => {
    setRegistLogs(true);
  };
  const regLogDrawerClose = () => {
    setRegistLogs(false);
  };


  return (
    <div className="proViiewDetailContainer">
      <div className="proViewDetCol1">
        <div className="profileUserSec">
          <div className="profileImgDiv">
            <img src={UserProfileImg} />
            <h1>{userData?.data?.fullName}</h1>
            <p>{userData?.data?.orgDetails?.positionInOrg}/{userData?.data?.userType}</p>
          </div>
        </div>
        <div className="profileBottomDiv">
          <div className="requestedUser">
            <p>USER ID</p>
            <h1>{userData?.data?._id}</h1>
          </div>
          <div className="requestedUser">
            <p>Application Date</p>
            <h1>{moment(userData?.data?.createdAt).format("YYYY/MM/DD")}</h1>
          </div>
          { userData?.data?.userType === "admin" ? (
            <div className="requestedUser">
              <p>Joining / Approved Date</p>
              <h1>{moment(userData?.data?.joiningDate).format("YYYY/MM/DD")}</h1>
            </div>
          ) : null }
          <div className="requestedUser">
            <p>Status</p>
            <div className="adminProStatusBtn">
              <p>{userData?.data?.status}</p>
            </div>
          </div>
          <div className="profileHorizontalLine"></div>
          <div className="pastRegistraion">
            <p>Want to see the log for past registration attempts?</p>
          </div>
          <div>
          <button className="regisLogs" onClick={regLogDrawerOpen}>
            <img src={NotebookIcon} alt="registraion Log" />
            <p>Registration Logs</p>
          </button>
         </div>
        </div>
      </div>
      <div className="proViewDetCol2">
        {userData?.data?.userType === "auditor" ? (
          <ProfileAudDetails userData={userData} userType={userType} />
        ) : userData?.data?.userType === "consultant" ? (
          <ProfileConsDetails userData={userData} userType={userType} />
        ) : userData?.data?.userType === "advisoryBoard" ? (
          // <ProfileAdvisoryBoardDetails userData={userData} userType={userType} />
          <h1>advisoryBoard</h1>
          ) : userData?.data?.userType === "organization" ? (
            <ProfileOrganizationDetails userData={userData} userType={userType} />
            ) : 
            // null
            // <ProfileAdvisoryBoardDetails userData={userData} userType={userType} />
            <ProfileAdminDetails userData={userData} userType={userType} />
            
            }
      </div>
      {registLogs &&
        <RegistraionLog 
       isOpen={registLogs} 
       onClose={regLogDrawerClose} 
      />
      }
    </div>
  );
}

export default ProfileViewDetails;
