import apiSlice from "../../../app/api/apiSlice";

export const organizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    OrgRegister: builder.mutation({
      query: (organisationInfo) => ({
        url: "/app/v1/organization/registration/start",
        method: "POST",
        body: organisationInfo,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgBasicInfo: builder.mutation({
      query: (basicData) => ({
        url: "/app/v1/organization/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgInfo: builder.mutation({
      query: (orgData) => ({
        url: "/app/v1/organization/registration/org-details",
        method: "POST",
        body: orgData,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgAdditionalInfo: builder.mutation({
      query: (otherData) => ({
        url: "/app/v1/organization/registration/additional-details",
        method: "POST",
        body: otherData,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgPreviewInfo: builder.mutation({
      query: (previewData) => ({
        url: "/app/v1/organization/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgCompleteInfo: builder.mutation({
      query: (completeData) => ({
        url: "/app/v1/organization/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["Organization"],
    }),

    orgVerifyOtp: builder.mutation({
      query: (otpDetails) => ({
        url: "app/v1/organization/registration/verify-otp",
        method: "POST",
        body: otpDetails,
      }),
    }),
  }),
});

export const {
  useOrgRegisterMutation,
  useOrgBasicInfoMutation,
  useOrgInfoMutation,
  useOrgAdditionalInfoMutation,
  useOrgPreviewInfoMutation,
  useOrgCompleteInfoMutation,
  useOrgVerifyOtpMutation,
} = organizationApiSlice;
