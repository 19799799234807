import react, { useState } from "react";

import ProfileOrgDetails from "./profileOrgDetails/ProfileOrgDetails";
import ProfileAddiDetails from "./profileAddiDetails/ProfileAddiDetails";
import RegistraionLog from "../../drawer/registrationLog/RegistraionLog";

import "./style.css";
import ProfileBasicDetails from "./profileBasicDetails/ProfileBasicDetails";

function ProfileOrganizationDetails({ userData, userType }) {
  const [activeTab, setActiveTab] = useState("organization");
  
  return (
    <div className="proTabsContainer">
      <div className="regisLogsRow">
        <div className="userTypeButton">
          <button
            style={{
              backgroundColor:
                activeTab === "basic" ? "#E6F3F7" : "transparent",
            }}
            className={`cursorPointer ${
              activeTab === "basic" ? "active" : ""
            }`}
            onClick={() => setActiveTab("basic")}
          >
            <p style={{ color: activeTab === "basic" ? "#00637E" : ""}}>Basic Details</p>
          </button>
          <button
            style={{
              backgroundColor:
                activeTab === "organization" ? "#E6F3F7" : "transparent",
            }}
            className={`cursorPointer ${
              activeTab === "organization" ? "active" : ""
            }`}
            onClick={() => setActiveTab("organization")}
          >
            <p style={{ color: activeTab === "organization" ? "#00637E" : ""}}>Organization Details</p>
          </button>
          <button
            style={{
              backgroundColor:
                activeTab === "additional" ? "#E6F3F7" : "transparent",
            }}
            className={`cursorPointer ${
              activeTab === "additional" ? "active" : ""
            }`}
            onClick={() => setActiveTab("additional")}
          >
            <p style={{ color: activeTab === "additional" ? "#00637E" : ""}}>Additional Details</p>
          </button>
        </div>
      </div>
      <div className="userTypeDetContainer">
        {activeTab === "basic" ? (
          <ProfileBasicDetails userData={userData} userType={userType}/>
        ) : null}
        {activeTab === "organization" ? (
          <ProfileOrgDetails userData={userData} userType={userType}/>
        ) : null}
        {activeTab === "additional" ? (
          <ProfileAddiDetails userData={userData} userType={userType}/>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileOrganizationDetails;
