import { useNavigate } from "react-router-dom";
import "./style.css";
import OTPInput from "react-otp-input";
import { useState } from "react";
import FormButton from "../../button/FormButton";

function OtpVerification({otp, setOtp}) {
  const navigate = useNavigate();

  return (
    <div className="otpContainer">
      <OTPInput
        inputStyle={{
          width: '4vw', 
          maxWidth: '48px',
          aspectRatio: '1/1',  
          // margin: '0px 16px 0px 0px', 
          fontSize: '18px',
          borderRadius: '5px',
          border: 'none',
          outline: 'none',  
          backgroundColor: '#eaebee', 
          textAlign: 'center',
          transition: 'background-color 0.3s' 
        }}
      //   inputClassName="otp-input" 
        inputFocusStyle={{
          backgroundColor: 'red ! important', 
        }}
        value={otp}
        onChange={setOtp}
        numInputs={6}
      //   renderSeparator={<span>-</span>}
        renderInput={(props) => <input {...props} type="number" inputMode="numeric"
        pattern="\d*" 
       
        />}
        
      />

   </div>
  );
}

export default OtpVerification;
