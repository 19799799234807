import React from 'react';

import './style.css';

import UpArraow from "../../../assets/icons/Caret_Circle_Up.svg";
import Triangle from "../../../assets/icons/Triangle.svg";

const goodPracticeData = [
    {
      index: "1",
      title: "Keep all documents ready before you proceed.",
    },
    {
      index: "2",
      title:
        "PDF format is preferred for all the documents you upload. Other formats that work are .docx, .doc",
    },
    {
      index: "3",
      title: "Make sure all the documents are as per the GHG standards",
    },
  ];

function EmisGoodPractice() {

  return (
    <div className="GoodSec">
        <div className=" leftGoodPract">
            <p className="secTitle_style">Good Practices</p>
            <img src={UpArraow} alt="" />
        </div>
        <div className='documentLists'>
        {goodPracticeData?.map((item, index) => (
            <div className='goodPractice'>
                <img src={Triangle} alt="" />
                <p>{item.title}</p>
            </div>
        ))}
        </div>
    </div>
  );
}

export default EmisGoodPractice;