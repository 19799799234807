import React from "react";

import "./style.css";

function ChatCommentInputBox({ description, setDescription }) {
  return (
    <input
      type="text"
      placeholder="Type your comments here..."
      className="chatCommentBox"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  );
}

export default ChatCommentInputBox;
