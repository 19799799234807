import React from "react";

import "./style.css";

import AddIcon from "../../../assets/icons/AddIcon.svg";

function AddButton({ btnTitle, addInitiative }) {
  return (
    <div className="addButtonContainer cursorPointer" onClick={addInitiative}>
      <img src={AddIcon} alt="AddIcon" />
      <p>{btnTitle}</p>
    </div>
  );
}

export default AddButton;
