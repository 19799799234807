import react, { Fragment, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import "./style.css";

import Close_MD from "../../assets/icons/Close_MD.svg";


function ReportLog({ isOpen, onClose }) {
    const [reportLogDetails, setReportLogDetails] = useState("approved")
    // const [reportLogDetails, setReportLogDetails] = useState("") // for rejected data

    const handleClose = () => {
        onClose();
    };
    const [state, setState] = useState({
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className="regisLogHeader">
                <h1>Report Log</h1>
                <img src={Close_MD} onClick={handleClose} />
            </div>
            <div className="regisLogDetails">
                <div className={reportLogDetails === 'approved' ? 'approveList' : 'rejectList'}>
                    {reportLogDetails === 'approved' &&
                        <p className="quarterTitleYear">Quarter 2  |  2023</p>
                    }
                    <div className="applyOn">
                        <h2>Reported On:</h2>
                        <h2>12/12/2023</h2>
                    </div>
                    <div className="applyOn">
                        <h2>Action Taken:</h2>
                        <button className={reportLogDetails === 'approved' ? 'approveLog' : 'rejectLog'}>
                            <p>{reportLogDetails === 'approved' ? "Approved" : "Rejected"}</p>
                        </button>
                    </div>
                    { reportLogDetails !== 'approved' &&
                        <div className="applyOn">
                            <h2>Reason:</h2>
                            <h2>Lorem ipsum dolor sit amet consectetur. Eget pellentesque in dignissim amet nibh sagittis turpis interdum.</h2>
                        </div>
                    }
                    <span className="logTime">On 11/12/2023 at 11:04 am</span>
                </div>
            </div>
        </Box>
    );
    return (
        <div>
            {['right'].map((anchor) => (
                <Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
                    <Drawer
                        anchor={anchor}
                        open={isOpen}
                        onClose={onClose}
                    >
                        {list(anchor)}
                    </Drawer>
                </Fragment>
            ))}
        </div>
    );
}

export default ReportLog;
