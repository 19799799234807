import { useEffect, useState } from "react";
import FormButton from "../button/FormButton";

import "./style.css";

import TimerIcon from "../../assets/icons/Timer.svg";
import RightCheck from "../../assets/icons/Check.svg";
import AddIcon from "../../assets/icons/AddPlusBtn.svg";
import AddConsultantPopUp from "../PopUp/consAuditorPopUp.js/AddConsultantAndAuditorPopUp";
import { useNavigate } from "react-router-dom";
import StatusTab from "../tabs/StatusTab";
import ContainedButton from "../button/ContainedButton";
import RightArrow from "../../assets/icons/continueRightArrow.svg";
import OutlinedButton from "../button/OutlinedButton";
import DeclerationTimeline from "../dashTimeline/DeclerationTimline";

function ConsultantTimeline({ status, stageData }) {
  const navigate = useNavigate();

  const handleOpenEmissionDeclerationForm1 = () => {
    navigate("/emissionsDeclaration");
  };

  const handleOpenEmissionDeclerationForm2 = () => {
    navigate("/emissionStep2");
  };

  const handleOpenEmissionDeclerationForm3 = () => {
    navigate("/emissionStep3");
  };

  const handleNavigateToRating = () => {
    navigate("/rating");
  };

  const handleRoadmapResubmit = () => {
    navigate("/emissionStep4");
  };

  const handleQuarterlyReports = () => {
    navigate("/emissionStep6");
  };

  return (
    <div className="orgDashTimeContainer">
      <div className="orgRatingStatus">
        <div className="orgRatingCo1">
          <div className="updateBtnSec">
            <p>Rating Status</p>
            {(stageData?.data?.stage === "Emission Resubmit" ||
              stageData?.data?.stage === "Roadmap Resubmit") &&
            stageData?.data?.isRejected === false ? (
              <StatusTab text="Update Required" type="pending" />
            ) : stageData?.data?.isRejected === true ? (
              <StatusTab text="Rejected" type="rejected" />
            ) : (
              <StatusTab text="Active" type="active" />
            )}
          </div>
        </div>

        {stageData?.data?.stage === "Initial Declaration" ? (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Declare Emisssions"
              bgColor="bgBlue"
              type="button"
              onClick={handleOpenEmissionDeclerationForm1}
              rightIcon={RightArrow}
            />
          </div>
        ) : stageData?.data?.stage === "Emission Declaration" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="Start New Declaration"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
            <ContainedButton
              text="Resume Declaration"
              bgColor="bgBlue"
              type="button"
              onClick={handleOpenEmissionDeclerationForm1}
              rightIcon={RightArrow}
            />
          </div>
        ) : stageData?.data?.stage === "Emission Audit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              onClick={handleNavigateToRating}
            />
          </div>
        ) : stageData?.data?.stage === "Emission Resubmit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              onClick={handleNavigateToRating}
            />
            {stageData?.data?.isRejected === false ? (
              <ContainedButton
                text="Review Comments"
                bgColor="bgBlue"
                type="button"
                onClick={handleOpenEmissionDeclerationForm2}
                rightIcon={RightArrow}
              />
            ) : (
              <ContainedButton
                text="Restart Declaration"
                bgColor="bgDarkBlue"
                type="button"
                onClick={handleOpenEmissionDeclerationForm1}
                rightIcon={RightArrow}
              />
            )}
          </div>
        ) : stageData?.data?.stage === "Roadmap Submission" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
            <ContainedButton
              text="Submit Roadmap"
              bgColor="bgBlue"
              type="button"
              onClick={handleOpenEmissionDeclerationForm3}
              rightIcon={RightArrow}
            />
          </div>
        ) : stageData?.data?.stage === "Roadmap Audit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
          </div>
        ) : stageData?.data?.stage === "Roadmap Resubmit" ? (
          <div className="orgRatingCo2">
            <OutlinedButton
              text="View All Details"
              bgColor="bgWhite"
              type="button"
              // onClick={handleOpenEmissionDeclerationForm2}
            />
            {stageData?.data?.isRejected === true ? (
              <ContainedButton
                text="Review Comments"
                bgColor="bgDarkBlue"
                type="button"
                onClick={handleOpenEmissionDeclerationForm1}
                rightIcon={RightArrow}
              />
            ) : (
              <ContainedButton
                text="Resubmit Roadmap"
                bgColor="bgBlue"
                type="button"
                onClick={handleRoadmapResubmit}
                rightIcon={RightArrow}
              />
            )}
          </div>
        ) : stageData?.data?.stage === "Monitor & Report" ? (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Quarterly Submission"
              bgColor="bgBlue"
              type="button"
              onClick={handleQuarterlyReports}
              rightIcon={RightArrow}
            />
          </div>
        ) : null}

        {stageData?.data?.isRejected === true && (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Register Again"
              bgColor="bgBlue"
              type="button"
            />
          </div>
        )}
      </div>
      <p className="orgDeclarText declarePadd">
        {(() => {
          switch (stageData?.data?.stage) {
            case "Initial Declaration":
              return "You have not yet declared any emissions. Start declaring now to get a rating.";
            case "Emission Declaration":
              return "You’ve not completed your emission declaration yet. Resume it or start a new declaration to get a rating.";
            case "Emission Audit":
              return "Your auditor is auditing the declaration of your organization.";
            case "Emission Resubmit":
              return "Your declaration was reverted with some comments. Please review the comments and update the declaration accordingly to resubmit it.";
            case "Roadmap Submission":
              return "You haven’t submitted the action plan yet. Submit it to continue the process.";
            case "Roadmap Audit":
              return "Your auditor is auditing your roadmap and is in progress.";
            case "Roadmap Resubmit":
              return "Your roadmap was reverted with some comments. Please check the comments on your roadmap documents and resubmit it.";
            case "Grant Rating":
              return "Our AI system is working on providing you a rating based on your declarations and roadmap at the moment.";
            case "Monitor & Report":
              return "Your organization is due for it’s quarterly / yearly Monitoring & Reporting.  Your consultant is working on it right now.";
            default:
              return "";
          }
        })()}
      </p>

      <DeclerationTimeline status={stageData?.data?.stage} />
    </div>
  );
}

export default ConsultantTimeline;
