import React, { useRef } from "react";
import "./style.css";
import scrollToRef from "../../../utils/scrollUtils";

const sectionTitleData = [
  {
    title: "General Information",
    // value: "3 of 3 done",
    sectionId: "generalInfoSection",
  },
  {
    title: "Organizational Boundaries",
    // value: "x of y done",
    sectionId: "orgBoundariesSection",
  },
  {
    title: "Operational Boundaries",
    // value: "x of y done",
    sectionId: "operaBoundariesSection",
  },
  {
    title: "Information On Emissions",
    // value: "3 of 5 done",
    sectionId: "infoEmission1",
  },
  {
    title: "Methodologies and Emission Factors",
    sectionId: "methodologyEmission",
  },
  {
    title: "Information On Emissions",
    sectionId: "infoEmission2",
  },
  {
    title: "Additional Information",
    sectionId: "additionalInfo",
  },
  {
    title: "Information On Offsets",
    sectionId: "infoOnOffsets",
  },
];

function EmisTitleSection({ setActiveSection, activeSection }) {
  // const sectionRef = useRef(null);
  
  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
  };
  console.log("🚀 ~ EmisTitleSection ~ activeSection:", activeSection)

  return (
    <div className="Left_SectionDiv">
      <div className="sectionTitle">
        <p className="secTitle_style">Sections</p>
      </div>
      {sectionTitleData?.map((item, index) => (
        <a
          key={index}
          href={`#${item?.sectionId}`}
          // className={`sectionTitle cursorPointer ${item.activeSection} `}
          className={
            activeSection === item.sectionId
              ? "activeBlueStyle"
              : "sectionTitle"
          }
          onClick={() => {
            handleSectionClick(item.sectionId);
          }}
        >
          <p
            className={
              activeSection === item.sectionId
                ? "activetitle"
                : "sectionTdivext"
            }
          >
            {item.title}
          </p>
          <p className="mandatoryValue">{item.value}</p>
        </a>
      ))}
    </div>
  );
}

export default EmisTitleSection;
