import * as Yup from "yup";
import { getUserFromLocal } from "../utils/storage";
const userDetails = getUserFromLocal();

const isNumber = (value) => {
  if (isNaN(value)) {
    return false;
  }
  return true;
};

const addressSchema = Yup.object({
  fullAddress: Yup.string()
    .required("Required")
    .trim()
    .max(100, "Atmost 100 characters allowed")
    .min(5, "Address must be at least 5 characters"),
  city: Yup.string()
    .required("Required")
    .trim()
    .lowercase()
    .max(100, "Atmost 100 characters allowed")
    .matches(/^[A-Za-z\s]+$/, "Only characters")
    .min(3, "City name must be at least 3 characters"),
  country: Yup.string().required("Required").trim().lowercase(),
  state: Yup.string().required("Required").trim().lowercase().max(100).min(2),
  // zipCode: Yup.number("num").integer().required("Required").min(6, "zipCode must be greater than or equal to 6 digit").typeError('enter only digit for zipCode'),
  zipCode: Yup.string()
    .matches(/^\d{5,6}$/, "Invalid Zip Code")
    .test("valid-zip-code", "Invalid ZIP code", (value) => {
      return value !== "000000";
    }),
});

const emissionGas = Yup.object({
  co2: Yup.number()
    .required("Required")
    .typeError("please enter a number")
    .test("is-number", "Must be a number", isNumber),
  ch2: Yup.number().required("Required").typeError("please enter a number"),
  n2o: Yup.number().required("Required").typeError("please enter a number"),
  hfcs: Yup.number().required("Required").typeError("please enter a number"),
  pfcs: Yup.number().required("Required").typeError("please enter a number"),
  sf6: Yup.number().required("Required").typeError("please enter a number"),
  total: Yup.number().required("Required"),
});

export const basicInfoSchema = Yup.object({
  title: Yup.string().required("Required").trim(),
  firstName: Yup.string()
    .required("First name is required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "First name should not contain numbers")
    .max(50)
    .min(3, "First name must be at least 3 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "Last name should not contain numbers")
    .max(50)
    .min(3, "Last name must be at least 3 characters"),
  email: Yup.string()
    .trim()
    .email("Invalid email format")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email format")
    .required("Email is required"),
  phoneNoCountryCode: Yup.string().required("Required"),
  phoneNo: Yup.string().matches(/^\d{7,13}$/, "Invalid Number"),
  mobileNoCountryCode: Yup.string().required("Required"),
  mobileNo: Yup.string().matches(/^\d{7,13}$/, "Invalid Number"),
  address: addressSchema,
});

export const addConsAudSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "First name should not contain numbers")
    .max(50)
    .min(3, "First name must be at least 3 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "Last name should not contain numbers")
    .max(50)
    .min(3, "Last name must be at least 3 characters"),
  email: Yup.string()
    .trim()
    .email("Invalid email format")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email format")
    .required("Email is required"),
  // mobileNoCountryCode: Yup.string().required("Required"),
  // mobileNo: Yup.string()
  //   .required("Required")
  //   .test("phone-number", "Invalid Mobile Number", (value) =>
  //     /^\d+$/.test(value)
  //   )
  //   .test(
  //     "phone-length",
  //     "Mobile should be either 7 digits or 10 digits long",
  //     (value) => /^[0-9]{7}$|^[0-9]{10}$/.test(value)
  //   ),
});

export const orgDetailsSchema = Yup.object().shape({
  orgBehalfFilling: Yup.boolean().required(),
  orgName: Yup.string()
    .trim()
    .required("Required")
    .max(100)
    .matches(/^[A-Za-z\s]+$/, "Organization name only contain characters")
    .min(5, "Organization name must be at least 5 characters"),
  orgWebsiteLink: Yup.string()
    .trim()
    .min(5, "Minimum 5 Characters")
    .max(500, "Maximum 500 Characters")
    .required("Website link is required"),
  positionInOrg: Yup.string().when("orgBehalfFilling", {
    is: false,
    then: (schema) =>
      schema
        .required("Required")
        .matches(/^[A-Za-z\s]+$/, "Position name only contain characters"),
    otherwise: (schema) => schema.notRequired(),
  }),
  orgCategory: Yup.string().trim(),
  orgHeadquarter: addressSchema.required(),
  // orgBranch: Yup.array().of(addressSchema),
  orgBranch: Yup.array()
    .of(addressSchema)
    .test("unique-branches", "Branches must be unique", function (value) {
      const branchSet = new Set(value.map((branch) => JSON.stringify(branch)));
      return branchSet.size === value.length;
    })
    .test(
      "different-headquarter",
      "Branches must be different from headquarters",
      function (value, { parent }) {
        const headquarter = JSON.stringify(parent.orgHeadquarter);
        return !value.some((branch) => JSON.stringify(branch) === headquarter);
      }
    ),
  noOfEmployees: Yup.string().required("Required"),
  orgActiveSector: Yup.string().required("Required").trim(),
  orgActiveSubCategory: Yup.string().required("Required").trim(),
});

export const otherDetailsSchema = Yup.object({
  otherDetailsFiles: Yup.array()
    .of(Yup.string().trim())
    .max(5, "Atmost 5 file"),
  orgDetailsFiles: Yup.array()
    .of(Yup.string().trim().required("Required"))
    .min(1, "At least one file is required")
    .max(5, "Atmost 5 file"),
  otherWebsiteLink: Yup.array().of(
    Yup.object().shape({
      link: Yup.string()
        .trim()
        .min(5, "Minimum 5 Characters")
        .max(500, "Maximum 500 Characters")
        .required("Required"),
      linkName: Yup.string()
        .trim()
        .required("Required")
        .matches(/^[A-Za-z\s]+$/, "Link name only contain characters")
        .min(3, "Enter valid web link"),
    })
  ),
});

export const workDetailsSchema = Yup.object({
  associatedOrg: Yup.string()
    .trim()
    .max(200, "Organization name must be at most 200 characters")
    .min(5, "Organization name must be at least 5 characters")
    .required("Required"),
  jobType: Yup.string()
    .trim()
    .oneOf(["Freelancer", "Organization or Firm"])
    .required("Required"),
  websiteLink: Yup.string().when("$jobType", {
    is: (jobType) =>
      jobType === "Organization or Firm" || userDetails?.userType === "auditor",
    then: () =>
      Yup.string()
        .trim()
        .min(5, "Minimum 5 Characters")
        .max(500, "Maximum 500 Characters")
        .required("Required"),
    otherwise: () => Yup.string().nullable(true),
  }),
  orgName: Yup.string().when("$jobType", {
    is: (jobType) =>
      jobType === "Organization or Firm" || userDetails?.userType === "auditor",
    then: () =>
      Yup.string()
        .trim()
        .max(200, "Organization name must be at most 200 characters")
        .min(2, "Organization name must be at least 2 characters")
        .required("Required"),
    otherwise: () => Yup.string().nullable(true),
  }),
  orgHeadquarter: Yup.object().when("$jobType", {
    is: (jobType) =>
      jobType === "Organization or Firm" || userDetails?.userType === "auditor",
    then: () => addressSchema.required(),
    otherwise: () => Yup.object().notRequired(),
  }),
});

export const boardTypeSchema = Yup.object({
  boardType: Yup.string()
    .oneOf([
      "Non-Government Organization/Non-Profit Organization",
      "University/Academia",
      "Other",
    ])
    .required("required"),
  other: Yup.string().when("boardType", {
    is: (boardType) => boardType === "Other",
    then: () => Yup.string().required("required"),
    otherwise: () => Yup.string().nullable(true),
  }),
});

export const memberWorkDetailsSchema = Yup.object({
  advisoryBoardType: boardTypeSchema,
  orgWebsiteLink: Yup.string()
    .required("Required")
    .trim()
    .matches(/(\.com|\.in|\.org)$/, "Invalid web link")
    .min(5, "Enter valid web link"),
  positionInOrg: Yup.string()
    .required("Required")
    .matches(/^[A-Za-z\s]+$/, "Position name can only contain characters"),
  orgName: Yup.string()
    .min(5, "Organization name must be at least 5 characters")
    .required("Organization name is required"),
  orgHeadquarter: addressSchema.required(),
  orgBranch: Yup.array().of(
    Yup.object().shape({
      fullAddress: Yup.string()
        .required("Required")
        .trim()
        .max(100, "Atmost 100 characters allowed")
        .min(5, "Address must be at least 5 characters"),
      city: Yup.string()
        .required("Required")
        .trim()
        .lowercase()
        .max(100, "Atmost 100 characters allowed")
        .min(3, "City name must be at least 3 characters"),
      country: Yup.string().required("Required").trim().lowercase(),
      state: Yup.string()
        .required("Required")
        .trim()
        .lowercase()
        .max(100)
        .min(2),
      zipCode: Yup.string()
        .matches(/^\d{5,6}$/, "Invalid Zip Code")
        .test("valid-zip-code", "Invalid ZIP code", (value) => {
          return value !== "000000";
        }),
    })
  ),
  noOfEmployees: Yup.string().required("Required"),
  // typeOfExpertise: Yup.array()
  //   .of(Yup.string().oneOf([
  //     'GHG Emissions Accounting',
  //     'Net Zero Target Setting & Action Planning',
  //     'Nature-based Solutions',
  //     'Industrial Technology such as Carbon Capture and Storage and Direct Air Capture',
  //     'Carbon Offsetting',
  //     'Physical Impacts of Climate Change',
  //   ]))
  //   .min(1, 'At least one expertise must be selected')
  //   .required('At least one expertise must be selected'),
});

export const stepOneSchema = Yup.object().shape({
  generalInfo: Yup.object().shape({
    inventoryYear: Yup.number().required("Required"),
    verifier: Yup.string()
      .required("Required")
      .trim()
      .min(5, "verifier’s name at least 5 characters")
      .matches(/^[A-Za-z\s]+$/, "verifier’s name only contain characters"),
    date: Yup.date().required("Required"),
    email: Yup.string()
      .required("Required")
      .trim()
      .lowercase()
      .min(5, "enter valid email id")
      .test("valid-email", "Enter a valid email address", (value) => {
        if (!value) return false;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(value);
      }),
    phone: Yup.number()
      .integer()
      .required("Required")
      .min(10, "enter the valid phone number")
      .test("phone-number", "Invalid Phone Number", (value) =>
        /^\d+$/.test(value)
      )
      .test(
        "phone-length",
        "Number should be either 7 digits or 10 digits long",
        (value) => /^[0-9]{7}$|^[0-9]{10}$/.test(value)
      ),
    address: Yup.string()
      .required("Required")
      .trim()
      .max(100, "Atmost 100 characters allowed")
      .min(5, "address must be at least 5 characters"),
    isSourceExcudedSpecify: Yup.string()
      .required("Required")
      .min(5, "enter at least 5 characters"),
    reportingFrom: Yup.date().required("Required"),
    reportingTo: Yup.object().shape({
      data: Yup.date().required("Required"),
    }),
  }),

  organizationalBoundaries: Yup.object().shape({
    consolidationApproach: Yup.object().shape({
      data: Yup.string().required("Required").trim().min(1, "select field"),
    }),
    details: Yup.array().of(
      Yup.object().shape({
        legalEntity: Yup.string()
          .required("Required")
          .trim()
          .trim()
          .matches(
            /^[A-Za-z\s]+$/,
            "LegalEntity name should not contain numbers"
          )
          .min(5, "LegalEntity must be at least 5 characters"),
        percentage: Yup.string()
          .required("Required, please enter number")
          .matches(/^[0-9]+%$/, "Enter a valid percentage (e.g., 25%)"),
        isCompanyHasFinancialControl: Yup.boolean().required("Required"),
        isCompanyHasFinancialControl: Yup.boolean().required("Required"),
      })
    ),
    orgDiagramFile: Yup.array().of(
      Yup.object().shape({
        fileName: Yup.string()
          .required("Required")
          .min(1, "upload  at least 1 file"),
      })
    ),
  }),
  operationalBoundaries: Yup.object().shape({
    scope3Included: Yup.object().shape({
      data: Yup.boolean().required("Required"),
    }),
    scope3IncludedScecify: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "activities name must be at least 5 characters"),
    }),
  }),
  infoOnEmission: Yup.object().shape({
    scope1Emissions: emissionGas.required(),
    scope2Emissions: emissionGas.required(),
    scope3Emissions: emissionGas.required(),
    directCo2Emission: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .typeError("please enter a number")
        .min(5, "Please Enter at least 5 characters"),
    }),
    details: Yup.object().shape({
      directEmission: Yup.object().shape({
        stationaryCombustion: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        mobileCombustion: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        processSources: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        fugativeSources: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        aggriculturalSources: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
      }),
      indirectEmission: Yup.object().shape({
        electricity: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        steam: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        heating: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
        cooling: Yup.string()
          .required("Required")
          .matches(/^\d+$/, "Please enter a  number"),
      }),
      emissionsDisaggregatedByFacility: Yup.array().of(
        Yup.object().shape({
          facility: Yup.string()
            .required("Required")
            .matches(/^[A-Za-z\s]+$/, "Facility name only contain characters"),
          scope1Emissions: Yup.string()
            .required("Required")
            .min(5, "Please Enter at least 5 characters"),
        })
      ),
      emissionsDisaggregatedByCountry: Yup.array().of(
        Yup.object().shape({
          country: Yup.string().required("Country name is Required"),
          scope1Emissions: Yup.string()
            .required("Required")
            .min(5, "Please Enter at least 5 characters"),
        })
      ),
      toOwnGeneration: Yup.object().shape({
        data: Yup.string()
          .required("Required")
          .min(5, "Please Enter at least 5 characters"),
      }),
      toTheGeneration: Yup.object().shape({
        data: Yup.string()
          .required("Required")
          .min(5, "Please Enter at least 5 characters"),
      }),
      ghgEmissions: Yup.object().shape({
        data: Yup.string()
          .required("Required")
          .min(5, "Please Enter at least 5 characters"),
      }),
      infoOfCausesOfEmission: Yup.object().shape({
        data: Yup.string()
          .required("Required")
          .min(5, "Please Enter at least 5 characters"),
      }),
      ghgEmissionsDataBetweenYears: Yup.object().shape({
        specify: Yup.string()
          .required("Required")
          .min(5, "Please Enter at least 5 characters"),
        ghgDataFile: Yup.array().of(
          Yup.object().shape({
            fileName: Yup.string().required("Required"),
          })
        ),
      }),
      performanceIndicatorsFile: Yup.array().of(
        Yup.object().shape({
          fileName: Yup.string().required("Required"),
        })
      ),
      ghgReduceStrategiesFile: Yup.array().of(
        Yup.object().shape({
          fileName: Yup.string().required("Required"),
        })
      ),
      supportingDocumentsFile: Yup.array().of(
        Yup.object().shape({
          fileName: Yup.string().required("Required"),
        })
      ),
    }),
  }),

  baseYear: Yup.object().shape({
    baseYear: Yup.number().required("Base year is required"),
    clarificationForBaseYear: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "clarification for the base year must be at least 5 characters")
        .min(5, "Please Enter at least 5 characters"),
    }),
    contextForEmissions: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "Context for emissions must be at least 5 characters")
        .min(5, "Please Enter at least 5 characters"),
    }),
    scope1Emissions: emissionGas.required(),
    scope2Emissions: emissionGas.required(),
    scope3Emissions: emissionGas.required(),
  }),

  methodologies: Yup.object().shape({
    methodologiesUsed: Yup.string()
      .required("Required")
      .typeError("provide a valid reference or link ")
      .min(5, "Methodologies and factors must be at least 5 characters"),
    link: Yup.string()
      .required("Required")
      .typeError("please provide a valid web link")
      .trim()
      .matches(/(\.com|\.in|\.org)$/, "Invalid web link")
      .min(5, "Enter valid web link"),
  }),

  additionalInfo: Yup.object().shape({
    infoOfGhgObligations: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "Please Enter at least 5 characters"),
    }),
    outlineOfAssuranceFile: Yup.object().shape({
      fileName: Yup.string().required("Required"),
    }),
    infoOnQuality: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "Please Enter at least 5 characters"),
    }),
    infoOnGhgSequestration: Yup.object().shape({
      data: Yup.string()
        .required("Required")
        .min(5, "Please Enter at least 5 characters"),
    }),
  }),
  infoOnOffsets: Yup.object().shape({
    outsideInventoryBoundary: Yup.array().of(
      Yup.object().shape({
        quantityOfGhgs: Yup.number()
          .required("Required")
          .typeError("Please enter a valid number"),
        offsetProjectType: Yup.string().required("Required"),
        offsetsverified: Yup.boolean().required("Required"),
      })
    ),
    insideInventoryBoundary: Yup.array().of(
      Yup.object().shape({
        quantityOfGhgs: Yup.number()
          .required("Required")
          .typeError("Please enter a valid number"),
        offsetProjectType: Yup.string().required("Required"),
        offsetsverified: Yup.boolean().required("Required"),
      })
    ),
  }),
});
