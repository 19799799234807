import react from "react";

import "./style.css";
import OrgsRatingBreadcrumb from "../breadcrumbs/orgsRatingBreadcrumb/OrgsRatingBreadcrumb";

function AdminOrgTitle() {
  return (
    <div className="adminOrgaContainer">
      <OrgsRatingBreadcrumb />
      <div className="adminOrgTitleSec">
        <div className="adOrgTitleCol1">
          <h1>Organizations</h1>
        </div>
      </div>
    </div>
  );
}

export default AdminOrgTitle;
