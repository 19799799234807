import apiSlice from "../../app/api/apiSlice";

export const auditorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerAud: builder.mutation({
      query: (auditorInfo) => ({
        url: "/auditor/registration/check",
        method: "POST",
        body: auditorInfo,
      }),
      invalidatesTags: ["Auditors"],
    }),

    basicAudInfo: builder.mutation({
      query: (basicData) => ({
        url: "/auditor/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    orgAudInfo: builder.mutation({
      query: (orgData) => ({
        url: "/auditor/registration/org-details",
        method: "POST",
        body: orgData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    otherAudInfo: builder.mutation({
      query: (otherData) => ({
        url: "/auditor/registration/other-details",
        method: "POST",
        body: otherData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    previewAudInfo: builder.mutation({
      query: (previewData) => ({
        url: "/auditor/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    completeAudInfo: builder.mutation({
      query: (completeData) => ({
        url: "/auditor/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["Auditors"],
    })
  }),
});

export const {
  useRegisterAudMutation,
  useBasicAudInfoMutation,
  useOrgAudInfoMutation,
  useOtherAudInfoMutation,
  usePreviewAudInfoMutation,
  useCompleteAudInfoMutation
} = auditorApiSlice;
