import react, { useState } from "react";
import { useFormik } from "formik";

import MyRatingHeader from "../../../components/ratingComponents/myRatingHeader/MyRatingHeader";
import MyProfileHeader from "../../../components/myProfile/myProfileHeader/MyProfileHeader";

import './style.css';
import MyProfileOrgDetails from "../../../components/myProfile/myProfileOrgDetails/MyProfileOrgDetails";
import MyProfileAdditDetails from "../../../components/myProfile/myProfileAdditDetails/MyProfileAdditDetails";
import MyProfileLoginCred from "../../../components/myProfile/myProfileLoginCred/MyProfileLoginCred";
import MyProEditBasicInfo from "../../../components/myProfile/myProEditBasicInfo/MyProEditBasicInfo";
import MyProEditLoginCred from "../../../components/myProfile/myProEditLoginCred/MyProEditLoginCred";

function MyProfileEdit() {

  const [editProfile, setEditProfile] = useState(false);
  console.log("🚀 ~ MyProfileEdit ~ editProfile:", editProfile)


    const formik = useFormik({
        initialValues: {
        },
        enableReinitialize: true,
    
        onSubmit: (values) => {
        },
      });

     
  return (
    <div  className='orgPreProfileSec' >
        <MyRatingHeader />
      <div className='orgPreProInnerCon'>
        <MyProfileHeader editProfile={setEditProfile}/>
        <MyProEditBasicInfo formik={formik}/>
        {/* <ProfilePrevOrgaDetails formik={formik} /> */}
        <MyProfileOrgDetails />
        <MyProfileAdditDetails />
        <MyProfileLoginCred />
        <MyProEditLoginCred formik={formik} />
       
      </div>
     <div className='emissionCopy'>
        <p>© Copyright  |  All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default MyProfileEdit;