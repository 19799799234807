import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";
import RoadmapComments from "../../../chats/RoadmapComments";

function ExternalPartnerPreview({
  value,
  declarationId,
  priorityPillarId,
  initiativeId,
  issueId,
}) {
  return (
    <div className="externalPreCon">
      <h1 className="externalTitle">External Partners</h1>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Business / Industry</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.business}</p>
          </div>
        </div>
      </div>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Government</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.government}</p>
          </div>
        </div>
      </div>
      <div className="externalPreRowCon">
        <div className="externalPreRow">
          <div className="externalPreCol1">
            <h2>Non-Profit Organizations</h2>
          </div>
          <div className="externalPreCol2">
            <p>{value?.nonProfitOrganizations}</p>
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.externalPartners"
            issueId={issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"External Partners"}
            question="External Partners"
            answer={""}
          />
        </div>
      </div>
    </div>
  );
}

export default ExternalPartnerPreview;
