import React, { useState } from "react";
import "./style.css";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import PhoneInputBox from "../../../../components/InputBox/phoneInputBox/PhoneInputBox";
import InputBox from "../../../../components/InputBox/InputBox";
import TextArea from "../../../../components/InputBox/textAreaBox/TextArea";
import FormButton from "../../../../components/button/FormButton";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import PhoneInput from "../../../../components/InputBox/phoneInput/PhoneInput";

const getInTouchSchema = Yup.object({
  yourName: Yup.string()
    .required("Required")
    .trim()
    .matches(/^[A-Za-z\s]+$/, "Full name only contain characters")
    .max(50)
    .min(3, "First name must be at least 3 characters"),
  email: Yup.string()
    .required("Required")
    .trim()
    .lowercase()
    .min(5, "enter valid email id")
    .test("valid-email", "Enter a valid email address", (value) => {
      if (!value) return false;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      return emailRegex.test(value);
    }),
  phoneNo: Yup.string()
    .required("Required")
    .test("phone-number", "Invalid Phone Number", (value) =>
      /^\d+$/.test(value)
    )
    .test(
      "phone-length",
      "Number should be either 7 digits or 10 digits long",
      (value) => /^[0-9]{7}$|^[0-9]{10}$/.test(value)
    ),
  specifyData: Yup.string()
    .required("Required")
    .min(5, "Please Enter at least 5 characters"),
});

function GetInTouch({ contactSectionRef }) {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState();
  const [emailId, setEmailId] = useState();
  const [alertMsg, setAlertMsg] = useState("inputAlert");

  const formik = useFormik({
    initialValues: {
      yourName: "",
      email: "",
      phoneNoCountryCode: "",
      phoneNo: "",
      specifyData: "",
    },
    enableReinitialize: true,
    validationSchema: getInTouchSchema,

    onSubmit: (values) => {
      console.log("🚀 ~ file: GetInTouch.js:65 ~ GetInTouch ~ values:", values);
    },
  });

  console.log(formik, "formik");

  return (
    <div className="getTouchTop_sec">
      <div className="container getTouch_section" ref={contactSectionRef}>
        <div className="getTouchSec_Col1">
          <h1>Want to Get In Touch?</h1>
          <p>
            Whether you are looking to obtain a rating, act as a consultant or
            auditor, or participate in any other way, send a message and a
            member of our team will be in get in touch with you.
          </p>
        </div>
        <div className="getTouchSec_Col2">
          <form>
            <div>
              <div className="">
                <InputBox
                  headName={"Your Name"}
                  placeholder="Type here..."
                  name="yourName"
                  value={formik.values.yourName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.yourName && formik.errors.yourName}
                  errorIcon={WarningIcon}
                  required
                />
              </div>
              <div className="mt-12">
                <InputBox
                  headName={"Email ID"}
                  placeholder="example@mail.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={!!formik.touched.email && formik.errors.email}
                  errorIcon={WarningIcon}
                  required
                />
              </div>
              <div className="mt-12">
                <PhoneInputBox
                  mobileNo={true}
                  headName={"Phone Number"}
                  placeholder="Phone Number here"
                  name="phoneNo"
                  value={formik?.values?.phoneNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.phoneNo && formik.errors.phoneNo}
                  countryCodeName={"phoneNoCountryCode"}
                  countryCodeValue={formik?.values?.phoneNoCountryCode}
                  defaultCountry="AE"
                  formik={formik}
                />
              </div>

              <div className="mt-12">
                <TextArea
                  headName={"Message"}
                  type="text"
                  // className='textarea'
                  placeholder="Type here..."
                  name="specifyData"
                  value={formik.values.specifyData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched.specifyData && formik.errors.specifyData
                  }
                  errorIcon={WarningIcon}
                  required
                />
              </div>
              <div className=" mt-12 cursorPointer">
                <FormButton
                  buttonName={"Submit"}
                  backgroundColor={"#00637E"}
                  buttonText={"#ffffff"}
                  btnFont={"1.1rem"}
                  btnPdd={"12px 20px"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GetInTouch;
