import React from "react";
import ConsultantTimeline from "./ConsultantTimeline";
import ConsultantStatus from "./ConsultantStatus";

const ConsultantDashboard2 = ({ status, stageData }) => {
  let timeline = null;
  if (
    status?.data?.organization?.organizationStatus === "active" &&
    status?.data?.consultant?.consultantStatus === "active" &&
    status?.data?.auditor?.auditorStatus === "active"
  ) {
    timeline = <ConsultantTimeline status={status} stageData={stageData} />;
  } else {
    timeline = <ConsultantStatus status={status} stageData={stageData} />;
  }
  return <div>{timeline}</div>;
};

export default ConsultantDashboard2;
