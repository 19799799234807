import React, { useEffect, useState } from 'react';


import GreenCheckBox from "../../../../assets/images/greenCheckBox.svg";
import DimondCheckBox from "../../../../assets/images/dimondCheckBox.svg";
import TimeLineStepsData from '../../../ratingComponents/timeLineStepsData/TimeLineStepsData';
import ViewCommentsBtn from '../../../button/viewCommentsBtn/ViewCommentsBtn';
import AuditTimelineData from '../../../ratingComponents/auditTimelineData/AuditTimelineData';
import PreviewDeclaration from '../../../button/previewDeclaration/PreviewDeclaration';

const emissData = [
    {
        title: "Submitted on:",
        value: "20th November 2022"
    },
    {
        title: "Issues Resolved:",
        value: "4 of 4"
    },
    {
        title: "Corrections Made:",
        value: "4 of 4"
    },
    {
        title: "Resubmitted On:",
        value: "22nd November 2022"
    },
]

const auditEmissData = [
    {
        title: "Issues Reported:",
        value: "4 Issues"
    },
    {
        title: "Audited on:",
        value: "24th November 2022"
    },
]

function AdminRatingProcess() {

  return (
    <div className='ratingTimeline'>
        <div className='ratingProcessSec'>
            <h1>Rating Process</h1>
            <p>Your organization is due for it’s quarterly / yearly Monitoring & Reporting.  Your consultant is working on it right now</p>
        </div>

        <div className='' style={{position:"relative"}}>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={GreenCheckBox} alt='GreenCheckBox' />
              <div className='ratTimelineTitle'>
                <h1>Emissions Declaration</h1>
                <TimeLineStepsData data={emissData} />
              </div>
            </div>
            <PreviewDeclaration btnName={"Preview Declaration"} />
          </div>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={GreenCheckBox} alt='GreenCheckBox' />
              <div className='ratTimelineTitle'>
                <h1>Assure/Audit Emissions</h1>
                <AuditTimelineData data={auditEmissData} />
              </div>
            </div>
            <PreviewDeclaration btnName={"View Audited Declaratiom"} />
          </div>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={GreenCheckBox} alt='GreenCheckBox' />
              <div className='ratTimelineTitle'>
                <h1>Action Plan To Net Zero</h1>
                <TimeLineStepsData data={emissData} />
              </div>
            </div>
            <PreviewDeclaration btnName={"Preview Declaration"} />
          </div>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={GreenCheckBox} alt='GreenCheckBox' />
              <div className='ratTimelineTitle'>
                <h1>Assure/Audit Action Plan</h1>
                <AuditTimelineData data={auditEmissData} />
              </div>
            </div>
            <PreviewDeclaration btnName={"View Audited Declaratiom"} />
          </div>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={DimondCheckBox} alt='GreenCheckBox' />
              <div className='timelineUnactiveTitle'>
                <h1>Granting A Rating</h1>
                <div>
                  <p>Our AI system is currently allocating a rating for your organization. Please wait for a while longer</p>
                </div>
              </div>
            </div>
          </div>
          <div className='timelineTitleRow'>
            <div className='timelineTitleCol1'>
              <img src={DimondCheckBox} alt='GreenCheckBox' />
              <div className='timelineUnactiveTitle'>
                <h1>Granting A Rating</h1>
              </div>
            </div>
          </div>

        <div className='ratingTimelineScale'>
          <div className='ratingActiveScale' style={{height:"40%"}}>

          </div>
        </div>

        </div>

    </div>
  )
}

export default AdminRatingProcess;