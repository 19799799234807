import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import {
  useGetAllQuery,
  useUpdateAccessMutation,
} from "../../../features/accessControlApiSlice";
import PageLoader from "../../Loader/PageLoader";
import { getUserFromLocal } from "../../../utils/storage";

const roleManageList = [
  {
    feedbackTitle: "Feedback",
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
  },
];

const RoleManageDetails = () => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const userDetails = getUserFromLocal();

  const [updateAccess] = useUpdateAccessMutation();
  const {
    data: accessData,
    isLoading: accessDataIsLoading,
    isSuccess: accessDataIsSuccess,
  } = useGetAllQuery();

  useEffect(() => {
    if (accessDataIsSuccess) {
      setSelectedRoles(accessData?.data[0]?.userTypes);
    }
  }, [accessDataIsSuccess, accessData]);

  const handleChange = (role) => {
    const selectedIndex = selectedRoles.indexOf(role);
    let newSelectedRoles = [];

    if (selectedIndex === -1) {
      newSelectedRoles = newSelectedRoles.concat(selectedRoles, role);
    } else if (selectedIndex === 0) {
      newSelectedRoles = newSelectedRoles.concat(selectedRoles.slice(1));
    } else if (selectedIndex === selectedRoles.length - 1) {
      newSelectedRoles = newSelectedRoles.concat(selectedRoles.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRoles = newSelectedRoles.concat(
        selectedRoles.slice(0, selectedIndex),
        selectedRoles.slice(selectedIndex + 1)
      );
    }

    setSelectedRoles(newSelectedRoles);

    // Call API with updated selectedRoles
    updateAccess({
      _id: "6637ecf688ce22748844a58e",
      userTypes: newSelectedRoles,
    });
  };

  return (
    <div className="userDetailsContainer">
      {accessDataIsLoading ? (
        <PageLoader />
      ) : (
        <table className="orgRatingTable">
          <thead>
            <tr className="userDetailTitle">
              <th className="heading"></th>
              <th className="heading roleTd">Admin</th>
              <th className="heading roleTd">B&I Organization</th>
              <th className="heading roleTd">Consultant</th>
              <th className="heading roleTd">Auditor</th>
              <th className="heading roleAdvMember">
                Member Of Advisory Board
              </th>
            </tr>
          </thead>
          <tbody>
            {roleManageList.map((item, index) => (
              <tr className="adminUserList" key={index}>
                <td className="roleManagTextCol">
                  <div className="roleManageText">
                    <h1>{item.feedbackTitle}</h1>
                    <p>{item.feedback}</p>
                  </div>
                </td>
                <td className="checkTd">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectedRoles.includes("admin")}
                          onChange={() => handleChange("admin")}
                          style={{
                            color: "#008BB2",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </td>
                <td className="checkTd">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectedRoles.includes("organization")}
                          onChange={() => handleChange("organization")}
                          style={{
                            color: "#008BB2",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </td>
                <td className="checkTd">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectedRoles.includes("consultant")}
                          onChange={() => handleChange("consultant")}
                          style={{
                            color: "#008BB2",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </td>
                <td className="checkTd">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectedRoles.includes("auditor")}
                          onChange={() => handleChange("auditor")}
                          style={{
                            color: "#008BB2",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </td>
                <td className="checkTd">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectedRoles.includes("advisoryBoard")}
                          onChange={() => handleChange("advisoryBoard")}
                          style={{
                            color: "#008BB2",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                    />
                  </FormGroup>
                </td>
                {/* Add similar code for other roles */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RoleManageDetails;
