import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

export const fileUploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getS3UploadUrl: builder.query({
      query: (queries) => {
        return {
          url: "/app/v1/data-upload/file",
          params: omitNullishKeys(queries),
        };
      },
    }),
    uploadUrl: builder.query({
      query: (queries) => {
        return {
          url: "/app/v1/data-upload/file",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
    }),
    deleteFile: builder.mutation({
      query: (queries) => {
        return {
          url: "app/v1/data-upload/file",
          params: queries,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetS3UploadUrlQuery,
  useLazyUploadUrlQuery,
  useDeleteFileMutation,
} = fileUploadApiSlice;
