import { useFormik } from "formik";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { useRegisterOrgMutation } from "../../../features/organization/organizationApiSlice";
import { useRegisterAudMutation } from "../../../features/auditor/auditorApiSlice";
import { useRegisterConsMutation } from "../../../features/consultant/consultantApiSlice";

import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import SelectInputBox from "../../../components/InputBox/selectBox/SelectInputBox";
import PageLoader from "../../../components/Loader/PageLoader";
import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import { useOrgRegisterMutation } from "../../../features/registration/organization/organizatonApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useAdvisoryBoardRegisterMutation } from "../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import { useEffect } from "react";

const signUpSchema = Yup.object({
  fullName: Yup.string()
    .required("Full Name cannot be empty")
    .transform((value) => value.trim())
    // .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(/^[A-Za-z\s]+$/, "Full name should not contain numbers")
    .max(50, "Name must be at most 50 characters")
    .min(3, "First name must be at least 3 characters"),
  email: Yup.string()
    .required("Email ID is incorrect. Please check your email ID")
    .trim()
    .lowercase()
    .min(5, "enter valid email id")
    .max(50, "Name must be at most 50 characters")
    .matches(/^\S*$/, "Whitespace is not allowed")
    .test("valid-email", "Enter a valid email address", (value) => {
      if (!value) return false;
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      return emailRegex.test(value);
    }),
  userType: Yup.string().required("Please choose an option before proceeding"),
});

const userType = [
  { value: "organization", name: "An organization seeking rating" },
  // { value: "auditor", name: "An Auditor" },
  // { value: "consultant", name: "A Consultant" },
  { value: "advisoryBoard", name: "Member of advisory board" },
];

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = decodeURIComponent(searchParams.get("userType") ?? "");
  const email = decodeURIComponent(searchParams.get("email") ?? "");
  const fullName = decodeURIComponent(searchParams.get("fullName") ?? "");

  console.log("fullName", fullName);
  console.log("email", email);

  const [
    registerOrg,
    {
      isLoading: organisationIsLoading,
      isSuccess: organisationIsSuccess,
      error: organisationIsError,
    },
  ] = useOrgRegisterMutation();

  const [
    registerAdvisoryBoardMember,
    {
      isLoading: advisoryBoardMemberIsLoading,
      isSuccess: advisoryBoardMemberIsSuccess,
      error: advisoryBoardMemberIsError,
    },
  ] = useAdvisoryBoardRegisterMutation();

  const [
    registerAud,
    {
      isLoading: auditorIsLoading,
      isSuccess: auditorIsSuccess,
      error: auditorIsError,
    },
  ] = useRegisterAudMutation();

  const [
    registerCons,
    {
      isLoading: consultantIsLoading,
      isSuccess: consultantIsSuccess,
      error: consultantIsError,
    },
  ] = useRegisterConsMutation();

  const formik = useFormik({
    initialValues: {
      fullName: fullName ?? "",
      email: email ?? "",
      userType: user ?? "",
    },
    enableReinitialize: true,
    validationSchema: signUpSchema,

    onSubmit: (values) => {
      console.log("values", values);
      const userTypes = ["organization", "advisoryBoard"];
      const registerFunctions = [registerOrg, registerAdvisoryBoardMember];

      const userTypeIndex = userTypes.indexOf(values.userType);
      if (userTypeIndex !== -1) {
        registerFunctions[userTypeIndex](values)
          .unwrap()
          .then((res) => {
            navigate({
              pathname: "/auth/otpVerify",
              search: `?identifier=${encodeURIComponent(
                res?.data?.identifier
              )}&userType=${encodeURIComponent(
                values?.userType
              )}&email=${encodeURIComponent(
                values?.email
              )}&fullName=${encodeURIComponent(
                values?.fullName
              )}&resendInstant=${encodeURIComponent(res?.data?.resendInstant)}`,
            });
          })
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? "Something went wrong",
              })
            )
          );
      }
    },
  });

  const loginFuc = () => {
    navigate("/auth/login");
  };

  console.log("formikErrors", formik?.errors);
  console.log("formikValuesSignUp", formik?.values);

  console.log("US", "\uD83C\uDDFA\uD83C\uDDF8");

  return (
    <div className="container signUp_section">
      {(organisationIsLoading || advisoryBoardMemberIsLoading) && (
        <PageLoader />
      )}
      <div className="signSec_Col1">
        <img className="signUp_topBg" src={SignUpTopBg} />
        <img className="signUp_bottomBg" src={SignUpBottomBg} />
        <img className="signUp_leftBg" src={SignUpLeftBg} />
        <div className="signUp_logoDiv">
          <img src={RegistrationLogo} />
        </div>
        <div className="signUp_divContents">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="signUp_leftContent">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>

        <div className="copyRight_div">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>
      <div className="signSec_Col2">
        <h1>Registration</h1>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur. Lacus habitasse et ridiculus
          ut pharetra ac duis vulputate massa
        </p> */}

        <form className="signUp_form" noValidate onSubmit={formik.handleSubmit}>
          <div className="signIn_fullname">
            <InputBox
              headName={"Full Name"}
              placeholder="Enter your name here"
              name="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorIcon={WarningIcon}
              error={!!formik.touched.fullName && formik.errors.fullName}
              required
            />
          </div>

          <div className="signUpEmail">
            <InputBox
              headName={"Email ID"}
              placeholder="example@mail.com"
              name="email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.email && formik.errors.email}
              required
            />
          </div>
          <div className="signUp_selectInput">
            <SelectInputBox
              data={userType}
              headName={"I'm here as"}
              selectPlaceholder={"Select"}
              name="userType"
              value={formik?.values?.userType}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              formik={formik}
              defaultValue={""}
              required
              errorIcon={WarningIcon}
              error={!!formik.touched.userType && formik.errors.userType}
            />
          </div>
          <div className="cursorPointer">
            <FormButton
              buttonName={"Proceed With Registration"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
          <div className=" signUpalready_HaveAcc cursorPointer">
            <p>
              Already have an account?
              <span onClick={loginFuc}> Log In</span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
