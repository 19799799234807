import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";

import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";
import { findAllByAltText } from "@testing-library/react";

function EmissDisaggreType({ findOneData }) {
  return (
    <div className="emisDisggCon">
      <div className="directOwned">
        <div className="directOwnedRow">
          <div className="directOwnedCol1">
            <h1>Scope 1: Direct Emissions from Owned/Controlled Operations</h1>
          </div>
          <div className="addCmtRow">
            <EmissionComments
              className="addCmtRow"
              mapping="infoOnEmission2.directEmission"
              issueId={
                findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  .issueId
              }
              declarationId={findOneData?.data?.declaration?._id}
              secName="INFORMATION ON EMISSIONS 2"
              question="Scope 1: Direct Emissions from Owned/Controlled Operations"
            />
          </div>
        </div>
        <div className="infoEmisRow">
          <div className="infoEmis">
            <div>
              <h1>Direct Emissions from Stationary Combustion</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.stationaryCombustion?.value}
              </p>
            </div>
            {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
              ?.stationaryCombustion?.notRecorded && (
              <div>
                <h1>Reason</h1>
                <p>
                  {
                    findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.stationaryCombustion?.specify
                  }
                </p>
              </div>
            )}
          </div>
          <div className="infoEmis">
            <h1>Direct Emissions from Mobile Combustion</h1>
            <p>
              {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.mobileCombustion?.notRecorded
                ? "Not Declared"
                : findOneData?.data?.declaration?.infoOnEmission2
                    ?.directEmission?.mobileCombustion?.value}
            </p>
            {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
              ?.mobileCombustion?.notRecorded && (
              <div>
                <h1>Reason</h1>
                <p>
                  {
                    findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.mobileCombustion?.specify
                  }
                </p>
              </div>
            )}
          </div>
          <div className="infoEmis">
            <div>
              <h1>Direct Emissions from Process Sources</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  ?.processSources?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.processSources?.value}
              </p>
              {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.processSources?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>
                    {
                      findOneData?.data?.declaration?.infoOnEmission2
                        ?.directEmission?.processSources?.specify
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="directEmisRow">
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Fugitive Sources</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  ?.fugativeSources?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.fugativeSources?.value}
              </p>
              {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.fugativeSources?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>
                    {
                      findOneData?.data?.declaration?.infoOnEmission2
                        ?.directEmission?.fugativeSources?.specify
                    }
                  </p>
                </div>
              )}
            </div>
            <div className="infoEmis wd-50">
              <h1>Direct Emissions from Agricultural Sources</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.directEmission?.agriculturalSources?.value}
              </p>
              {findOneData?.data?.declaration?.infoOnEmission2?.directEmission
                ?.agriculturalSources?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>
                    {
                      findOneData?.data?.declaration?.infoOnEmission2
                        ?.directEmission?.agriculturalSources?.specify
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="directOwned">
        <div className="directOwnedRow">
          <div className="scopeCooling1">
            <h1>
              Scope 2: Indirect Emissions from the Use of Purchased Electricity,
              Steam, Heating & Cooling
            </h1>
          </div>
          <EmissionComments
            className="addCmtRow"
            mapping="infoOnEmission2.indirectEmission"
            issueId={
              findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                .issueId
            }
            declarationId={findOneData?.data?.declaration?._id}
            secName="INFORMATION ON EMISSIONS 2"
            question="Scope 2: Indirect Emissions from the Use of Purchased Electricity, Steam, Heating & Cooling"
          />
        </div>
        <div className="infoEmisRow">
          <div className="infoEmis">
            <div>
              <h1>Indirect Emissions from Purchased/Acquired Electricity</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2
                  ?.indirectEmission?.electricity?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.indirectEmission?.electricity?.value}
              </p>
            </div>
            {findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
              ?.electricity?.notRecorded && (
              <div>
                <h1>Reason</h1>
                <p>
                  {
                    findOneData?.data?.declaration?.infoOnEmission2
                      ?.indirectEmission?.electricity?.specify
                  }
                </p>
              </div>
            )}
          </div>
          <div className="directEmisRow">
            <div className="infoEmis wd-50">
              <h1>Indirect Emissions from Purchased/Acquired Steam</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2
                  ?.indirectEmission?.steam?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.indirectEmission?.steam?.value}
              </p>
              {findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.steam?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>
                    {
                      findOneData?.data?.declaration?.infoOnEmission2
                        ?.indirectEmission?.steam?.specify
                    }
                  </p>
                </div>
              )}
            </div>
            <div className="infoEmis wd-50">
              <h1>Indirect Emissions from Purchased/Acquired Heating</h1>
              <p>
                {findOneData?.data?.declaration?.infoOnEmission2
                  ?.indirectEmission?.heating?.notRecorded
                  ? "Not Declared"
                  : findOneData?.data?.declaration?.infoOnEmission2
                      ?.indirectEmission?.heating?.value}
              </p>
              {findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.heating?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>
                    {
                      findOneData?.data?.declaration?.infoOnEmission2
                        ?.indirectEmission?.heating?.specify
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="infoEmis">
            <h1>Indirect Emissions from Purchased/Acquired Cooling</h1>
            <p>
              {findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
                ?.cooling?.notRecorded
                ? "Not Declared"
                : findOneData?.data?.declaration?.infoOnEmission2
                    ?.indirectEmission?.cooling?.value}
            </p>
            {findOneData?.data?.declaration?.infoOnEmission2?.indirectEmission
              ?.cooling?.notRecorded && (
              <div>
                <h1>Reason</h1>
                <p>
                  {
                    findOneData?.data?.declaration?.infoOnEmission2
                      ?.indirectEmission?.cooling?.specify
                  }
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmissDisaggreType;
