import React, { useState } from "react";

import AuditorTextWithPdf from "../commonComponents/auditorTextWithPdf/AuditorTextWithPdf";
import AuditorChatTextOnly from "../commonComponents/auditorChatTextOnly/AuditorChatTextOnly";
import AuditorInfoPart1Scopes from "../infoOnEmssPart1Components/auditorInfoPart1Scopes/AuditorInfoPart1Scopes";
import TitleWithValue from "../commonComponents/titleWithValue/TitleWithValue";

import "./style.css";
import AuditorInfoPart2Scopes from "../infoOnEmissPart2Components/auditorInfoPart2Scopes/AuditorInfoPart2Scopes";
import RecieverChatBox from "../recieverChatBox/RecieverChatBox";
import { getUserFromLocal } from "../../../../utils/storage";
import moment from "moment";
import AuditorInfoPart2ScopesIndirect from "../infoOnEmissPart2Components/auditorInfoPart2Scopes/AuditorInfoPart2ScopesIndirect";
import MappingEditResponse from "../infoOnEmissPart2Components/auditorInfoPart2Scopes/MappingEditResponse";

function MessageEditChatBox({
  item,
  index,
  text,
  pdf,
  pdfData,
  reciever = false,
  infoPart1,
  scope1,
  equity,
  infoPart2,
}) {
  const userDetails = getUserFromLocal();
  console.log(
    "check",
    (userDetails?.userType === "auditor" &&
      item?.senderUserType === "auditor") ||
      (userDetails?.userType === "consultant" &&
        item?.senderUserType === "consultant")
      ? false
      : true
  );

  const [editMessage, setEditMessage] = useState(true);

  let chatBoxContent = null;
  if (item.mapping === "generalInformation.facilitiesExcluded") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={false}
        summaryText={""}
        text={
          (item?.updateDeclaration?.isExcluded ? "Yes" : "No") +
          ", " +
          item?.updateDeclaration?.exclusionDetails
        }
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "generalInformation.reportingPeriod") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={`${moment(item?.updateDeclaration?.from).format(
          "MM/DD/YYYY"
        )} to ${moment(item?.updateDeclaration?.to).format("MM/DD/YYYY")}`}
        isSummary={false}
        item={item}
      />
    );
  } else if (
    item.mapping === "organizationalBoundaries.consolidationApproach"
  ) {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={[
          item?.updateDeclaration?.equityShare && "Equity Share",
          item?.updateDeclaration?.financialControl && "Financial Control",
          item?.updateDeclaration?.operationalControl && "Operational Control",
        ]
          .filter(Boolean)
          .join(", ")}
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "organizationalBoundaries.entities") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        text={[
          item?.updateDeclaration?.legalEntityOrFacility,
          item?.updateDeclaration?.percentageEquityShare,
          item?.updateDeclaration?.isCompanyHasFinancialControl ? "Yes" : "No",
          item?.updateDeclaration?.isCompanyHasOperationalControl
            ? "Yes"
            : "No",
        ].join(", ")}
        isSummary={false}
        item={item}
      />
    );
  } else if (item.mapping === "organizationalBoundaries.orgDiagramFile") {
    chatBoxContent = (
      <RecieverChatBox
        key={index}
        reciever={
          (userDetails?.userType === "auditor" &&
            item?.senderUserType === "auditor") ||
          (userDetails?.userType === "consultant" &&
            item?.senderUserType === "consultant")
            ? false
            : true
        }
        summaryText={""}
        AuditorInfoPart2Scopes
        text={item?.updateDeclaration?.fileName}
        pdfData={item?.updateDeclaration?.fileName}
        isSummary={false}
        item={item}
      />
    );
  } else if (
    item?.mapping === "infoOnEmission1.scope1Emissions" ||
    item?.mapping === "infoOnEmission1.scope2Emissions" ||
    item?.mapping === "infoOnEmission1.scope3Emissions"
  ) {
    chatBoxContent = (
      <AuditorInfoPart1Scopes
        co2Value={item?.updateDeclaration?.co2?.value}
        co2Reason={item?.updateDeclaration?.co2?.specify}
        ch4Value={item?.updateDeclaration?.ch4?.value}
        ch4Reason={item?.updateDeclaration?.ch4?.specify}
        n2oValue={item?.updateDeclaration?.n2o?.value}
        n2oReason={item?.updateDeclaration?.n2o?.specify}
        hfcsValue={item?.updateDeclaration?.hfcs?.value}
        hfcsReason={item?.updateDeclaration?.hfcs?.specify}
        pfcsValue={item?.updateDeclaration?.pfcs?.value}
        pfcsReason={item?.updateDeclaration?.pfcs?.specify}
        sf6Value={item?.updateDeclaration?.sf6?.value}
        sf6Reason={item?.updateDeclaration?.sf6?.specify}
        total={item?.updateDeclaration?.total}
      />
    );
  } else if (item?.mapping === "infoOnEmission2.directEmission") {
    chatBoxContent = (
      <AuditorInfoPart2Scopes
        stationaryCombustionValue={
          item?.updateDeclaration?.stationaryCombustion?.value
        }
        stationaryCombustionReason={
          item?.updateDeclaration?.stationaryCombustion?.specify
        }
        mobileCombustionValue={
          item?.updateDeclaration?.mobileCombustionValue?.value
        }
        mobileCombustionReason={
          item?.updateDeclaration?.mobileCombustionValue?.specify
        }
        processSourcesValue={
          item?.updateDeclaration?.processSourcesValue?.value
        }
        processSourcesReason={
          item?.updateDeclaration?.processSourcesValue?.specify
        }
        fugitiveSourcesValue={
          item?.updateDeclaration?.fugitiveSourcesValue?.value
        }
        fugitiveSourcesReason={
          item?.updateDeclaration?.fugitiveSourcesValue?.specify
        }
        agriculturalSourcesValue={
          item?.updateDeclaration?.agriculturalSourcesValue?.value
        }
        agriculturalSourcesReason={
          item?.updateDeclaration?.agriculturalSourcesValue?.specify
        }
      />
    );
  } else if (item?.mapping === "infoOnEmission2.indirectEmission") {
    chatBoxContent = (
      <AuditorInfoPart2ScopesIndirect
        electricityValue={item?.updateDeclaration?.stationaryCombustion?.value}
        electricityReason={
          item?.updateDeclaration?.stationaryCombustion?.specify
        }
        steamValue={item?.updateDeclaration?.mobileCombustionValue?.value}
        steamReason={item?.updateDeclaration?.mobileCombustionValue?.specify}
        coolingValue={item?.updateDeclaration?.processSourcesValue?.value}
        coolingReason={item?.updateDeclaration?.processSourcesValue?.specify}
        heatingValue={item?.updateDeclaration?.fugitiveSourcesValue?.value}
        heatingReason={item?.updateDeclaration?.fugitiveSourcesValue?.specify}
      />
    );
  } else if (item.mapping === "priorityPillars") {
    chatBoxContent = (
      <MappingEditResponse
        scopeData={[
          {
            title: "PRIORITY PILLAR FOR ACTION",
            value: item?.updateDeclaration?.objective,
          },
          {
            title: "DESCRIPTION",
            value: item?.updateDeclaration?.description,
          },
        ]}
        heading={"PRIORITY PILLAR"}
      />
    );
  }

  return (
    <div className={reciever ? "auditChatBox" : "auditRightChatBox"}>
      <div className="auditChatBoxCon">
        <p className="auditChatTitle">
          An edit made on the response to this question
        </p>
        <p style={{ overflowWrap: "anywhere", margin: "0px" }}>
          {chatBoxContent}
        </p>
      </div>
    </div>
  );
}

export default MessageEditChatBox;
