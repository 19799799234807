import apiSlice from "../../app/api/apiSlice";

export const consultantApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    registerCons: builder.mutation({
      query: (consultantInfo) => ({
        url: "/consultant/registration/check",
        method: "POST",
        body: consultantInfo,
      }),
      invalidatesTags: ["Consultants"],
    }),

    basicConsInfo: builder.mutation({
      query: (basicData) => ({
        url: "/consultant/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["Consultants"],
    }),

    orgConsInfo: builder.mutation({
      query: (orgData) => ({
        url: "/consultant/registration/org-details",
        method: "POST",
        body: orgData,
      }),
      invalidatesTags: ["Consultants"],
    }),

    otherConsInfo: builder.mutation({
      query: (otherData) => ({
        url: "/consultant/registration/other-details",
        method: "POST",
        body: otherData,
      }),
      invalidatesTags: ["Consultants"],
    }),

    previewConsInfo: builder.mutation({
      query: (previewData) => ({
        url: "/consultant/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["Consultants"],
    }),

    completeConsInfo: builder.mutation({
      query: (completeData) => ({
        url: "/consultant/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["Consultants"],
    })
  }),
});

export const {
    useRegisterConsMutation,
    useBasicConsInfoMutation,
    useOrgConsInfoMutation,
    useOtherConsInfoMutation,
    usePreviewConsInfoMutation,
    useCompleteConsInfoMutation
} = consultantApiSlice;
