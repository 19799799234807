import react from "react";

import "./style.css";

function ProfileHeadquarters({ address, HeadquartersTitle }) {
  return (
    <div className="profileHeadqSection">
      <h1 className="adminHeadquarterTitle">{HeadquartersTitle}</h1>
      <div className="proDetailsSection">
        <div className="proHeadqDetDiv">
          <div className="proHeadqDetails">
            <p>Address</p>
            <h1>{address?.fullAddress}</h1>
          </div>
          <div className="proHeadqDetails">
            <p>City / Town / Suburb</p>
            <h1>{address?.city}</h1>
          </div>
        </div>
        <div className="proHeadqDetDiv">
          <div className="proHeadqDetails">
            <p>Country</p>
            <h1>{address?.country}</h1>
          </div>
          <div className="proHeadqDetails">
            <p>State / Region</p>
            <h1>{address?.state}</h1>
          </div>
        </div>
        <div className="proHeadqDetDiv">
          <div className="proHeadqDetails">
            <p>Zipcode / Postal Code</p>
            <h1>{address?.zipCode}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeadquarters;
