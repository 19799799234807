import React from "react";

import "./style.css";

import AddIcon from "../../../assets/icons/addTimeLineIcon.svg";

function AddTimeLine({ addTimeline }) {
  return (
    <div className="addTimeLineBtn" onClick={addTimeline}>
      <img src={AddIcon} alt="AddIcon" />
      <p>Add</p>
    </div>
  );
}

export default AddTimeLine;
