import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faFileArrowUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import PdfUploadImg from "../../assets/icons/Pdf _File_Icon.svg";

const PdfIcon = ({ faXmarkDisplay, onRemove, url, fileName }) => {
  return (
    // <a href={src} download={fileName} className="pdf-link">
    <div className="pdfUpload">
      <FontAwesomeIcon
        icon={faXmark}
        className="pdfUpload_closeBtn cursorPointer"
        style={{ color: "rgb(118 118 114)", display: faXmarkDisplay }}
        onClick={() => {
          onRemove(url);
        }}
      />
      <div className="pdfUpload_innerdiv">
        <img className="pdfIcon" src={PdfUploadImg} alt="pdfimg" />
        <p>{fileName}</p>
      </div>
    </div>
    // </a>
  );
};

export default PdfIcon;
