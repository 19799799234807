import React from 'react';

import "./style.css";

import CurrentStatusImg from "../../../../assets/images/currentStatusImg.svg";

function RatingCard() {
  return (
    <div className='ratingCardCon'>
        <p>Current Rating</p>
        <h1>EEE+</h1>
        <div className="ratingCardImage">
            <img src={CurrentStatusImg} alt='CurrentStatusImg' />
        </div>
    </div>
  )
}

export default RatingCard;