import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";

import "./style.css";
import GHGEmission from "../ghgEmission/GHGEmission";
import OffsetsAndCredits from "../offsetsAndCredits/OffsetsAndCredits";
import GHGEmissionPreview from "../ghgEmissionPreview/GHGEmissionPreview";
import OffsetsAndCreditsPrev from "../offsetsAndCreditsPrev/OffsetsAndCreditsPrev";
import PdfUpload from "../../../pdfUpload/PdfUpload";

function QuarterTwoPreview({ quarterlyReportsData }) {
  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>2024 | Quarter 2</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="quarterTwoCon">
            <GHGEmissionPreview
              resultHeaderName={"GHG Emissions Increases"}
              resultTitle1={"Scope 1"}
              resultTitle2={"Scope 2"}
              resultTitle3={"Scope 3"}
              value1={quarterlyReportsData?.data?.ghgEmissionsIncrease?.scope1}
              value2={quarterlyReportsData?.data?.ghgEmissionsIncrease?.scope2}
              value3={quarterlyReportsData?.data?.ghgEmissionsIncrease?.scope3}
            />
            <GHGEmissionPreview
              resultHeaderName={"GHG Emissions Reductions"}
              resultTitle1={"Scope 1"}
              resultTitle2={"Scope 2"}
              resultTitle3={"Scope 3"}
              value1={
                quarterlyReportsData?.data?.ghgEmissionsReductions?.scope1
              }
              value2={
                quarterlyReportsData?.data?.ghgEmissionsReductions?.scope2
              }
              value3={
                quarterlyReportsData?.data?.ghgEmissionsReductions?.scope3
              }
            />
            <GHGEmissionPreview
              resultHeaderName={"Removals"}
              resultTitle1={"Within Its Boundaries"}
              resultTitle2={"In The Wider Value Chain"}
              resultTitle3={"Outside The Value Chain"}
              value1={quarterlyReportsData?.data?.removals?.withinBoundaries}
              value2={
                quarterlyReportsData?.data?.removals?.inTheWiderValueChain
              }
              value3={
                quarterlyReportsData?.data?.removals?.outsideTheValueChain
              }
            />
            <OffsetsAndCreditsPrev
              resultHeaderName={"Offsets and credits outside the value chain"}
              data={
                quarterlyReportsData?.data?.offsetsAndCredits
                  ?.outsideTheValueChain
              }
            />
            <OffsetsAndCreditsPrev
              resultHeaderName={"Net GHG Emissions"}
              data={
                quarterlyReportsData?.data?.offsetsAndCredits?.netGHGEmission
              }
            />
            <OffsetsAndCreditsPrev
              resultHeaderName={"Expected GHG Reduction Percentage"}
              data={
                quarterlyReportsData?.data?.offsetsAndCredits
                  ?.expectedGHGReductionPercentage
              }
            />
            <OffsetsAndCreditsPrev
              resultHeaderName={"Achieved GHG Reduction Percentage"}
              data={
                quarterlyReportsData?.data?.offsetsAndCredits
                  ?.achievedGHGReductionPercentage
              }
            />
            <OffsetsAndCreditsPrev
              resultHeaderName={"Gap for SEE Rating Target"}
              data={
                quarterlyReportsData?.data?.offsetsAndCredits
                  ?.gapForSEERatingTarget
              }
              gapForSee={true}
            />

            <div className="creditEmissPreCon">
              <h1 className="creditEmissPreTitle">GHG accounting document</h1>
              {quarterlyReportsData?.data.relatedDocuments?.map(
                (item, index) => (
                  <PdfUpload
                    faXmarkDisplay={"none"}
                    key={index}
                    pdfType={item?.fileName}
                  />
                )
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default QuarterTwoPreview;
