import apiSlice from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/app/v1/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (userDetails) => ({
        url: "/user/register",
        method: "POST",
        body: userDetails,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/login/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    verifyForgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/login/verify-forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    resendOtp: builder.mutation({
      query: (bodyDetails) => ({
        url: "app/v1/auth/sign-up/resend-otp",
        method: "POST",
        body: bodyDetails,
      }),
    }),
    googleLogin: builder.query({
      query: () => ({
        url: "/connect/google",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGoogleLoginQuery,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation,
  useResendOtpMutation,
} = authApiSlice;
