import React, { useEffect, useRef, useState } from "react";

import "./style.css";

import ExclamationMark from "../../../../../assets/icons/exclamation.svg";
import WavyCheckIcon from "../../../../../assets/icons/Wavy_Check.svg";
import DocAttachIcon from "../../../../../assets/icons/docAttachIcon.svg";
import TrashEmpty from "../../../../../assets/icons/Trash_Empty.svg";
import RightArrow from "../../../../../assets/icons/rightArrow.svg";

function ChatFooterMenu({
  handleSubMenuOptions,
  menuRef,
  subMenuRef,
  openSubMenu,
  handleSelectValue,
}) {
  return (
    <div>
      <div className="chatFooterMenuCon" ref={menuRef}>
        <div
          className="footerMenuRow cursorPointer"
          //   style={{ background: "#E6F3F7" }}
          onClick={handleSubMenuOptions}
        >
          <img src={ExclamationMark} alt="ExclamationMark" />
          <p>Change Issue Type</p>
          <img src={RightArrow} alt="RightArrow" className="rightArraowIcon" />
        </div>
        <div
          className="footerMenuRow"
          onClick={() => handleSelectValue("markAsResolved")}
        >
          <img src={WavyCheckIcon} alt="WavyCheckIcon" />
          <p>Mark As Resolved</p>
        </div>
        <div
          className="footerMenuRow"
          onClick={() => handleSelectValue("attachDocument")}
        >
          <img src={DocAttachIcon} alt="DocAttachIcon" />
          <p>Attach Document</p>
        </div>
        <div
          className="footerMenuRow"
          onClick={() => handleSelectValue("delete")}
        >
          <img src={TrashEmpty} alt="TrashEmpty" />
          <p>Delete Issue</p>
        </div>
      </div>

      {openSubMenu && (
        <div className="chatFooterMenuCon recContainer" ref={subMenuRef}>
          <div
            className="footerMenuRow"
            onClick={() => handleSelectValue("Recommendation")}
          >
            <p>Recommendation</p>
          </div>
          <div
            className="footerMenuRow"
            onClick={() => handleSelectValue("Clarification")}
          >
            <p>Clarification Needed</p>
          </div>
          <div
            className="footerMenuRow"
            onClick={() => handleSelectValue("Corrective Action Required")}
          >
            <p>Corrective Action Required</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatFooterMenu;
