import React from 'react';

import "./style.css";

import ArrowIcon from "../../../../../assets/icons/ArrowForQuestions.svg"

function ChatBoxQuestionComponent({
    secName, 
    question, 
    answer
}) {
  return (
    <div className='chatBoxQuesContainer'>
        <div className='chatQuesSection'>
            <div className='cmntQuesRow'>
                <h1>{secName}</h1>
                <img src={ArrowIcon} alt='ArrowImage' />
                <h1>Question 1</h1>
            </div>
            <div className='cmtQuestions'>
                <h1>{question}</h1>
            </div>
        </div>
        <div className='cmntAnswer'>
            <p>{answer}</p>
        </div>
    </div>
  )
}

export default ChatBoxQuestionComponent;