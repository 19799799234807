import React, { useState } from "react";

import "./style.css";

import AddComment from "../../../assets/icons/chatAddBtnIcon.svg";
import ChatBoxLayout from "../ChatBoxLayout/ChatBoxLayout";

function AddComments({
  secName,
  question,
  answer,
  mapping,
  issueId,
  declarationId,
  arrayId,
  type,
  setType,
  summary,
  setSummary,
  description,
  setDescription,
  handleCreateIssueAndChats,
  openChatBox,
  handleChatBoxOpen,
  handleChatBoxClose,

}) {
  return (
    <div className="auditAddCommentSec cursorPointer">
      <div className="addCommentBtnSec">
        <img src={AddComment} />
        <h1
          onClick={() => {
            handleChatBoxOpen();
          }}
        >
          Add Comment
        </h1>
      </div>
      <ChatBoxLayout
        declarationId={declarationId}
        issueId={issueId}
        arrayId={arrayId}
        mapping={mapping}
        openChatBox={openChatBox}
        handleChatBoxClose={handleChatBoxClose}
        secName={secName}
        question={question}
        answer={answer}
        type={type}
        setType={setType}
        summary={summary}
        setSummary={setSummary}
        description={description}
        setDescription={setDescription}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
      />
    </div>
  );
}

export default AddComments;
