import React from "react";
import "./style.css";

const OutlinedButton = ({
  text,
  bgColor,
  rightIcon,
  leftIcon,
  onClick,
  type,
  fontSize,
  fontColor,
  borderColor,
}) => {
  return (
    <button
      type={type}
      className={`outlined-button ${bgColor}`}
      style={{
        border: borderColor ? borderColor : "1px solid #008bb2",
      }}
      onClick={onClick}
    >
      {leftIcon && <img src={leftIcon} alt="leftIcon" />}
      <span
        style={{
          paddingTop: "2px",
          textWrap: "nowrap",
          fontSize: fontSize ? fontSize : "12px",
          color: fontColor ? fontColor : "#000000",
        }}
      >
        {text}
      </span>
      {rightIcon && <img src={rightIcon} alt="RightIcon" />}
    </button>
  );
};

export default OutlinedButton;
