import react, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import ChatBoxHeader from "../../chatBox/chatBoxComponents/commonComponents/chatBoxHeader/ChatBoxHeader";
import ChatBoxQuestionComponent from "../../chatBox/chatBoxComponents/commonComponents/chatBoxQuestionComponent/ChatBoxQuestionComponent";
import RecieverChatBox from "../../chatBox/chatBoxComponents/recieverChatBox/RecieverChatBox";
import PdfDocuments from "../../pdfDocuments/PdfDocuments";
import PdfUpload from "../../pdfUpload/PdfUpload";
import ChatBoxFooter2 from "../../chatBox/chatBoxComponents/commonComponents/chatBoxFooter/ChatBoxFooter2";

import "./style.css";
import MessageEditChatBox from "../../chatBox/chatBoxComponents/messageEditChatBox/MessageEditChatBox";
import SenderChatBox from "../../chatBox/chatBoxComponents/messageEditChatBox/MessageEditChatBox";
import { getUserFromLocal } from "../../../utils/storage";
import ChatBoxFooter from "../ChatBoxFooter/ChatBoxFooter";
import PageLoader from "../../Loader/PageLoader";
import IssueStatusChange from "../../chatBox/chatBoxComponents/commonComponents/chatBoxQuestionComponent/IssueStatusChange";

function ChatBoxLayout({
  openChatBox,
  handleChatBoxClose,
  secName,
  question,
  answer,
  mapping,
  issueId,
  declarationId,
  issue,
  chats,
  arrayId,
  type,
  setType,
  summary,
  setSummary,
  description,
  setDescription,
  handleCreateIssueAndChats,
  openMenu,
  openSubMenu,
  menuRef,
  subMenuRef,
  handleMenuOptions,
  handleSubMenuOptions,
  handleSelectValue,
  chatsIsLoading,
  issuesIsLoading,
  createChatIsLoading,
  createChatIsFetching,
  chatsIsFetching,
}) {
  const userDetails = getUserFromLocal();

  const handleClose = (e) => {
    e.stopPropagation();
    handleChatBoxClose();
  };

  const [state, setState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const reversedChats = chats?.data?.slice().reverse();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="commentDrawContainer">
        <ChatBoxHeader handleClose={handleClose} />
        <div className="chatBoxInnerSec">
          <ChatBoxQuestionComponent
            secName={secName}
            question={question}
            answer={answer}
          />
          <div className="cmntChatSection">
            {issueId && userDetails?.userType === "auditor" ? (
              <RecieverChatBox
                reciever={false}
                summaryText={issue?.data?.summary}
                text={issue?.data?.description}
                isSummary={true}
                item={issue?.data}
              />
            ) : issueId && userDetails?.userType === "consultant" ? (
              <RecieverChatBox
                reciever={true}
                summaryText={issue?.data?.summary}
                text={issue?.data?.description}
                isSummary={true}
                item={issue?.data}
              />
            ) : null}
            {reversedChats?.map((item, index) =>
              item?.type === "updateDeclaration" ? (
                <MessageEditChatBox
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : item?.type === "issueTypeChange" ? (
                <IssueStatusChange
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : item?.type === "issueStatusChange" ? (
                <IssueStatusChange
                  item={item}
                  index={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                />
              ) : (
                <RecieverChatBox
                  key={index}
                  reciever={
                    userDetails?.userType === "auditor" &&
                    item?.senderUserType === "auditor"
                      ? false
                      : userDetails?.userType === "consultant" &&
                          item?.senderUserType === "consultant"
                        ? false
                        : true
                  }
                  summaryText={""}
                  text={item?.message}
                  isSummary={false}
                  item={item}
                />
              )
            )}
          </div>
        </div>
        <ChatBoxFooter
          mapping={mapping}
          declarationId={declarationId}
          issueId={issueId}
          arrayId={arrayId}
          type={type}
          setType={setType}
          summary={summary}
          setSummary={setSummary}
          description={description}
          setDescription={setDescription}
          handleCreateIssueAndChats={handleCreateIssueAndChats}
          openMenu={openMenu}
          openSubMenu={openSubMenu}
          menuRef={menuRef}
          subMenuRef={subMenuRef}
          handleMenuOptions={handleMenuOptions}
          handleSubMenuOptions={handleSubMenuOptions}
          handleSelectValue={handleSelectValue}
        />
      </div>
    </Box>
  );

  return (
    <div>
      {(chatsIsLoading ||
        issuesIsLoading ||
        createChatIsLoading ||
        createChatIsFetching ||
        chatsIsFetching) && <PageLoader />}
      {["right"].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={openChatBox}
            onClose={handleChatBoxClose}
            className="cmntDrawer"
            ModalProps={{ onBackdropClick: handleClose }}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}

export default ChatBoxLayout;
