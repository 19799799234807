import React from 'react';

import "./style.css";
import PdfUpload from '../../pdfUpload/PdfUpload';


function MyProConsFirmOrg() {

  return (
    <div className='profileFirmOrgCon'>
        <h1>Organization Details</h1>
        <div className='proFirmOrgSec'>
            <div className='proFirmOrgSecRow'>
                <div className='firmOrgSecData'>
                    <span>Name of Consulting Organization</span>
                    <p>Codewave Technologies</p>
                </div>
                <div className='firmOrgSecData'>
                    <span>Your Organization Type</span>
                    <p>Organization or Firm</p>
                </div>
            </div>
            <div className='proFirmOrgSecRow'>
                <div className='firmOrgSecData'>
                    <span>Name of Organization</span>
                    <p>ConsultantOrgName</p>
                </div>
                <div className='firmOrgSecData'>
                    <span>Website link</span>
                    <p>www.company.com</p>
                </div>
            </div>

            <div className='myProFirmOrgHeadQ'>
                <h1 className='firmHeadqTitle'>Headquarters</h1>
                <div className='proFirmHeadqInner'>
                    <div className='firmOrgSecData'>
                        <span>Address</span>
                        <p>123, ABC Street, XYZ Colony</p>
                    </div>
                    <div className='firmOrgSecData'>
                        <span>City / Town / Suburb</span>
                        <p>Dubai</p>
                    </div>
                    <div className='firmOrgSecData'>
                        <span>Country</span>
                        <p>United Arab Emirates</p>
                    </div>
                    <div className='firmOrgSecData'>
                        <span>State / Region</span>
                        <p>Dubai</p>
                    </div>
                    <div className='firmOrgSecData'>
                        <span>Zipcode / Postal Code</span>
                        <p>50819</p>
                    </div>
                </div>
            </div>

            <div className='proConFirmDocuments'>
                <span className='proConFirmDocTitle'>Documents</span>
                <div className='proConFirmPdfSec'>
                    <div className='proConFirmPdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proConFirmPdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                </div>
            </div>

        </div>

    </div>
  )
}

export default MyProConsFirmOrg;