import react from "react";

import PdfUpload from "../../../pdfUpload/PdfUpload";

import "./style.css";

function ProfileAddiDetails({ userData }) {
  return (
    <div className="proAddiDetContainer">
      <div className="proRegisDetails">
        <h1>Registration Details</h1>
        <div className="profilePdfSection">
          {userData?.data?.additionalDetails?.orgDetailsFiles.map(
            (item, index) => (
              <PdfUpload faXmarkDisplay={"none"} pdfType={item} key={index} />
            )
          )}
        </div>
      </div>

      <div className="proAddiDetails">
        <h1 className="relevantInfoTitle">Other Relevant Information</h1>
        <div className="proAddiDetSection">
          <div className="addDetPdfRow">
            {userData?.data?.additionalDetails?.otherDetailsFiles.map(
              (item, index) => (
                <PdfUpload faXmarkDisplay={"none"} pdfType={item} key={index} />
              )
            )}
          </div>
          <div className="proLinkSection">
            <div className="proOtherLinks">
              <p>Website link</p>
              {userData?.data?.additionalDetails?.otherWebsiteLink.map(
                (item, index) => (
                  <h1>{item?.link}</h1>
                )
              )}
            </div>
            <div className="proOtherLinks">
              <p>link Name</p>
              {userData?.data?.additionalDetails?.otherWebsiteLink.map(
                (item, index) => (
                  <h1>{item?.linkName}</h1>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAddiDetails;
