import React from "react";
import InputBox from "../../../InputBox/InputBox";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

function OffsetsAndCredits({
  formik,
  resultHeaderName,
  gapForSee,
  name,
  value,
  error,
  touched,
}) {
  return (
    <div className="creditEmissionCon">
      <h1 className="creditEmissTitle">{resultHeaderName}</h1>
      <div className="creditEmissRow">
        <InputBox
          type="number"
          headName={!gapForSee ? "Q1 Result GHG (tCO2e)" : "Gap = E- A"}
          placeholder="Enter the value"
          name={`${name}.value`}
          value={value?.value}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={touched?.value && error?.value}
          errorIcon={WarningIcon}
        />
        <TextArea
          type="text"
          headName={"Explanatory Notes"}
          placeholder="Tell us more here..."
          name={`${name}.explanatoryNotes`}
          value={value?.explanatoryNotes}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={touched?.explanatoryNotes && error?.explanatoryNotes}
          errorIcon={WarningIcon}
        />
      </div>
    </div>
  );
}

export default OffsetsAndCredits;
