
import React from 'react';
import RatingImg from '../../../../assets/homeImages/RatingImage.svg';
import RatingGraph from '../../../../assets/homeImages/RatingGraph.svg';
import './style.css';


function Rating() {
  const EEERatingData = [
    {
      heading: "EEE+",
      year: "2030",
      EEEText: "EEE_color1",
    },
    {
      heading: "EEE",
      year: "2035",
      EEEText: "EEE_color2",
    },
    {
      heading: "EEE-",
      year: "2040",
      EEEText: "EEE_color3",
    },
    {
      heading: "EE+",
      year: "2045",
      EEEText: "EEE_color4",
    },
    {
      heading: "EE-",
      year: "2050",
      EEEText: "EEE_color5",
    },
   
  ];

  return (
    <div className='container rating_section'>
        <h1>What Are The Ratings?</h1>
        <p>
          SEE rating classification rewards those that achieve net zero earlier than 2050. 
          EEE, EEE-, EE+, EE & EE- must all achieve 45% reduction by 2030, followed by net zero in 
          their target year. This is what is required by climate science, to keep the 1.5oC target
          of the Paris Agreement within reach.
        </p>
       <div className='ratingImg_section'>
          <img src={RatingImg} alt="" />
          {/* <div className='ratingEEE_row'>
          {EEERatingData?.map((item, index) => (
             <div className='ratingEEE_cols'>
               <div className='EEE_box'>
                  <h1 className={` ${item.EEEText} `}>{item.heading}</h1>
                </div>
               <div className='dashes_line'></div>
               <div className='year_row'>
                 <h2 className="" >{item.year}</h2>
               </div>
              </div>
          ))}
          </div>
          <div className='theQuicker_text'> 
            <h3>The quicker the Net-zero Target is achieved, Higher the rating</h3>
          </div>*/}
          {/* <img src={RatingGraph} alt="" className='globle_ghg' /> */}
        </div>
        <div className='ratingsBackground-text'>Ratings</div>

      
        
    </div>
    
  );
}

export default Rating;