import React, { useEffect } from "react";

import "./style.css";

import ImgUploadIcon from "../../../assets/images/uploadImg.svg";
import UseFileUpload from "../../../features/fileUpload/fileUploadHook";
import { DROPZONE_IMAGE_FORMAT, MAX_FILE_SIZE } from "../../../utils/defaults";
import { useDropzone } from "react-dropzone";
import { validateDropzoneSingleFile } from "../../../utils/helper";
import { showError } from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";

function ImgUploadBtn({
  multiple = false,
  maxFiles = 1,
  folder = "registration",
  formik,
  field,
}) {
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseFileUpload();
  const dispatch = useDispatch();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
    inputRef,
    rootRef,
  } = useDropzone({
    accept: {
      ...DROPZONE_IMAGE_FORMAT,
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!multiple) {
        validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
        if (acceptedFiles[0]) {
          uploadFile({ file: acceptedFiles[0], folder });
        }
      }
    },
    disabled: isLoading,
    multiple,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
  });

  const onUpload = (url) => {
    formik.setFieldValue(`${field}`, url);
  };

  useEffect(() => {
    if (isError) {
      dispatch(showError({ message: "Failed to upload" }));
    }

    if (isSuccess && data) {
      onUpload(data?.url);
    }
  }, [isError, isSuccess, data, dispatch]);

  return (
    <div className="imgUploadCon cursorPointer" {...getRootProps({})}>
      <img src={ImgUploadIcon} alt="ImgUploadIcon" />
      <p>
        {formik?.values?.profileImage ? "Change Profile Image" : "Upload Image"}
      </p>
      <input type="file" style={{ display: "none" }} {...getInputProps()} />
    </div>
  );
}

export default ImgUploadBtn;
