import React from 'react';

import "./style.css";

function MyProfileLoginCred() {

  return (
    <div className='myProLoginInfoCon'>
        <h1>Login Credentials</h1>
        <div className='myProLogCredSec'>
            <div className='myProLogSecData'>
                <span>Email ID</span>
                <p>karthik@codewave.com</p>
            </div>
            <div className='myProLogSecData'>
                <span>Password</span>
                <p>qwertyuiop1234567890</p>
            </div>
        </div>
    </div>
  )
}

export default MyProfileLoginCred;