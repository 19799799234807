import React from "react";

import "./style.css";
import moment from "moment";

function YearQuarterCard({ year, quarter }) {
  return (
    <div className="yearQuarterCard">
      <div className="yearQuartCardRow">
        <div className="yearQuartCardCol1">
          <h2>Year</h2>
          <p>{moment(year).format("YYYY")}</p>
        </div>
        <div className="dashBorder"></div>
        <div className="yearQuartCardCol2">
          <h2>Quarter</h2>
          <p>{quarter}</p>
        </div>
      </div>
    </div>
  );
}

export default YearQuarterCard;
