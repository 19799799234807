import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import AuditInventoryBoundaries from "../auditInventoryBoundaries/AuditInventoryBoundaries";

function AuditInfoOffset({ findOneData, activeSection, setActiveSection }) {
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "infoOnOffsets" : false);
  };

  return (
    <div className="emisSectionGap" id="infoOnOffsets">
      <Accordion
        expanded={activeSection === "infoOnOffsets"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON OFFSETS</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="auditInfoOffsetContainer">
            {findOneData?.data?.declaration.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo?.offset?.map(
              (item, index) => (
                <AuditInventoryBoundaries
                  key={index}
                  addOffsetTitle="Information on offsets that have been purchased or developed outside the inventory boundary"
                  sectionTitle="Offset 1"
                  value1={item?.quantityOfGhgs}
                  value2={item?.offsetProjectType}
                  value3={item?.offsetsverified ? "Yes" : "No"}
                  mapping="infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset"
                  issueId={item?.issueId}
                  arrayId={item._id}
                  declarationId={findOneData?.data?.declaration?._id}
                />
              )
            )}
            {findOneData?.data?.declaration?.infoOnOffsets?.soldTransferredReductionsInfo?.reduction?.map(
              (item, index) => (
                <AuditInventoryBoundaries
                  key={index}
                  addOffsetTitle="Information on reductions inside the inventory boundary that have been sold/transferred as offsets to a third party."
                  sectionTitle="Reduction 1"
                  value1={item?.quantityOfGhgs}
                  value2={item?.offsetProjectType}
                  value3={item?.offsetsverified ? "Yes" : "No"}
                  mapping="infoOnOffsets.soldTransferredReductionsInfo.reduction"
                  issueId={item?.issueId}
                  arrayId={item._id}
                  declarationId={findOneData?.data?.declaration?._id}
                />
              )
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AuditInfoOffset;
