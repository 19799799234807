import React, { useState, useEffect } from "react";

import "./style.css";

import ImpactOnWater from "../../../assets/homeImages/ImpactOnwater.svg";
import UserProfileImg from "../../../assets/images/userImg.png";
import VerticalMenu from "../../../assets/icons/VerticalMenu.svg";
import AdminPagination from "../pagination/AdminPagination";
import { Switch } from "@mui/material";
import moment from "moment";
import { useUserStatusChangeMutation } from "../../../features/admin/adminApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import PageLoader from "../../Loader/PageLoader";
import { useNavigate } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";




const userDetailsList = [
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "B&I Organization",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Consultant",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Auditor",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Advisory Board",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "B&I Organization",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Consultant",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Auditor",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Advisory Board",
    joinDate: "5/12/2023",
    country: "UAE",
    userStatus: "",
    status: "active",
  },
];

const UserDetails = ({
  isLoading,
  isFetching,
  error,
  list,
  selectedDates,
  changeRowsPerPage,
  rowsPerPage,
  changePage,
  page,
  totalCount,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    changeUserStatus,
    {
      isLoading: userStatusIsLoading,
      isSuccess: userStatusIsSuccess,
      error: userStatusError,
      isError: userStatusIsError,
    },
  ] = useUserStatusChangeMutation();

  // const filteredList = list?.data?.filter((user) => {
  //   const userRegistrationDate = moment(user?.joiningDate);
  //   return (
  //     userRegistrationDate.isSameOrAfter(selectedDates[0]) &&
  //     userRegistrationDate.isSameOrBefore(selectedDates[1])
  //   );
  // });

  const handleChange = (item, event) => {
    const { userType, _id } = item?.user || {};
    const status = event.target.checked ? "active" : "inactive";

    changeUserStatus({ userType, status, userId: _id })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: `Status updated successfully` }));
      })
      .catch((e) => {
        dispatch(
          showError({
            message: e?.data?.message ?? "Something went wrong",
          })
        );
      });
  };

  const handleViewUserProfile = (item) => {
    navigate({
      pathname: "/ProfileView",
      search: `?userType=${item?.user?.userType}&userId=${item?.user?._id}&hideRequestsLink=${true}`,
     });
  };

  return (
    <div className="userDetailsContainer">
      {(userStatusIsLoading || isLoading || isFetching) && <PageLoader />}
      <table>
        <tr className="userDetailTitle">
          <th className="heading">Name & Email</th>
          <th className="heading">Organization’s Name</th>
          <th className="heading">User Type</th>
          <th className="heading">Date of Joining</th>
          <th className="heading">Country</th>
          <th className="heading">Status</th>
          <th className="heading"></th>
        </tr>
        {list?.data?.map((item, index) => (
          <tr className="adminUserList" key={index}>
            <td>
              <div
                onClick={() => {
                  handleViewUserProfile(item);
                }}
                className="listedUser cursorPointer"
              >
                <img src={UserProfileImg} alt="ProfileIcon" />
                <div className="userGmailData">
                  <h1>{item?.user?.fullName}</h1>
                  <h3>{item?.user?.email}</h3>
                </div>
              </div>
            </td>
            <td>
              <p>
                {item?.user?.userType === "organization"
                  ? item?.user?.orgDetails?.orgName
                  : item?.user?.workDetails?.orgName}
              </p>
            </td>
            <td>
              <p>
                {item?.user?.userType === "organization"
                  ? "Organization"
                  : item?.user?.userType === "advisoryBoard"
                    ? "Advisory Board"
                    : item?.user?.userType === "auditor"
                      ? "Auditor"
                      : item?.user?.userType === "consultant"
                        ? "Consultant"
                        : ""}
              </p>
            </td>
            <td>
              <p>{moment(item?.user?.joiningDate).format("DD/MM/YYYY")}</p>
            </td>
            <td>
              <p>{item?.user?.basicInfo?.address?.country}</p>
            </td>
            <td>
              <div
                className={`activeBtn ${
                  item?.user?.status === "rejected"
                    ? "rejectBtnStyle"
                    : "" || item?.user?.status === "inactive"
                      ? "inactiveStatus"
                      : ""
                }`}
              >
                <span>
                  {item?.user?.status === "rejected"
                    ? "Rejected"
                    : item?.user?.status === "active"
                      ? "Active"
                      : item?.user?.status === "inactive"
                        ? "Inactive"
                        : item?.user?.status === "registered"
                          ? "Registered"
                          : ""}
                </span>
              </div>
            </td>
            {
              <td className="">
                {item?.user?.status !== "rejected" && (
                  <Switch
                    checked={item?.user?.status === "active"}
                    onChange={(event) => {
                      handleChange(item, event);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              </td>
            }
          </tr>
        ))}
      </table>
      <AdminPagination
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        page={page}
        totalCount={totalCount}
      />
    </div>
  );
};

export default UserDetails;
