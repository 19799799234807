import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormButton from "../../../components/button/FormButton";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import { getUserFromLocal } from "../../../utils/storage";

function SignUpSuccess() {
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();

  return (
    <div className="container signUp_section">
      <div className="signSec_Col1">
        <img className="signUp_topBg" src={SignUpTopBg} />
        <img className="signUp_bottomBg" src={SignUpBottomBg} />
        <img className="signUp_leftBg" src={SignUpLeftBg} />
        <div className="signUp_logoDiv">
          <img src={RegistrationLogo} />
        </div>
        <div className="signUp_divContents">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="signUp_leftContent">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>

        <div className="copyRight_div">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>

      <div className="signSec_Col2">
        {/* <div className="gifDiv"></div> */}
        <div className="successHeading">
          <h1>Registration Successful</h1>
        </div>
        <form className="signUp_form">
          <div className="verifySec">
            <p>
              {userDetails?.userType === "auditor" ||
              userDetails?.userType === "consultant"
                ? "Your profile has been approved successfully"
                : userDetails?.userType === "organization"
                  ? "You have successfully registered your Organization onto SEE Rating."
                  : userDetails?.userType === "advisoryBoard"
                    ? "You have successfully registered onto SEE Rating."
                    : ""}
            </p>
          </div>

          <div
            className="cursorPointer"
            onClick={() => {
              navigate("/auth/login");
            }}
          >
            <FormButton
              buttonName={"Proceed To Login"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpSuccess;
