import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import YearQuarterCard from "../yearQuarterCard/YearQuarterCard";

import "./style.css";

import RightArrow from "../../../../assets/icons/Arrow_Right_LG.svg";
import YearQuarterTimeline from "../../../chatBox/chatBoxComponents/yearQuarterTimeline/YearQuarterTimeline";
import RoadmapComments from "../../../chats/RoadmapComments";

function TimelinePreview({
  value,
  declarationId,
  priorityPillarId,
  initiativeId,
}) {
  return (
    <div className="timelinePreCon">
      <h1 className="timelineTitle">Timeline</h1>
      <div className="timelinePreRowCon">
        <div className="timelinePreRow">
          <div className="timelinePreCol1">
            <h2>Planning</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {value?.planning?.data?.map((item) => (
              <div className="timelinePreCol2">
                <YearQuarterCard
                  year={item?.fromYear}
                  quarter={item?.fromQuater}
                />
                <img src={RightArrow} alt="RightArrow" />
                <YearQuarterCard year={item?.toYear} quarter={item?.toQuater} />
              </div>
            ))}
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.timelines.planning"
            issueId={value?.planning?.issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"PLANNING"}
            question="PLANNING"
            answer={""}
          />
        </div>
      </div>
      <div className="timelinePreRowCon">
        <div className="timelinePreRow">
          <div className="timelinePreCol1">
            <h2>Execution</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {value?.execution?.data?.map((item) => (
              <div className="timelinePreCol2">
                <YearQuarterCard
                  year={item?.fromYear}
                  quarter={item?.fromQuater}
                />
                <img src={RightArrow} alt="RightArrow" />
                <YearQuarterCard year={item?.toYear} quarter={item?.toQuater} />
              </div>
            ))}
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.timelines.execution"
            issueId={value?.execution?.issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"EXECUTION"}
            question="EXECUTION"
            answer={""}
          />
        </div>
      </div>
      <div className="timelinePreRowCon">
        <div className="timelinePreRow">
          <div className="timelinePreCol1">
            <h2>Monitoring</h2>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            {value?.monitoring?.data?.map((item) => (
              <div className="timelinePreCol2">
                <YearQuarterCard
                  year={item?.fromYear}
                  quarter={item?.fromQuater}
                />
                <img src={RightArrow} alt="RightArrow" />
                <YearQuarterCard year={item?.toYear} quarter={item?.toQuater} />
              </div>
            ))}
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.timelines.monitoring"
            issueId={value?.monitoring?.issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"MONITORING"}
            question="MONITORING"
            answer={""}
          />
        </div>
      </div>
    </div>
  );
}

export default TimelinePreview;
