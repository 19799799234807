import react from "react";

import "./style.css";
import ProfileBasicAddress from "../profileBasicHeadquarters/ProfileBasicAddress";

function ProfileBasicDetails({ userData, userType }) {
  return (
    <div className="viewBasDetContainer">
      <div className="userBasDetails">
        <p>Email ID</p>
        <h1>
          { userType === "organization"? (
            userData?.data?.basicInfo?.email
          ) : null }
          { userType === "consultant"? (
            userData?.data?.basicInfo?.email
          ) : null }
          { userType === "auditor"? (
            userData?.data?.basicInfo?.email
          ) :  null }
          { userType === "advisoryBoard"? (
            userData?.data?.basicInfo?.email
          ) : null }
          { userType === "admin"? (
            userData?.data?.basicInfo?.email
          ) : null }
  
        </h1>
      </div>

      <div className="userProBasDetailsRow">
        <div className="userBasDetails">
          <p>Phone Number</p>
          <h1>
            { userType === "organization"? (
              userData?.data?.basicInfo?.phoneNo
            ) : null }
            { userType === "consultant"? (
              userData?.data?.basicInfo?.phoneNo
            ) : null }
            { userType === "auditor"? (
              userData?.data?.basicInfo?.phoneNo
            ) : null }
            { userType === "advisoryBoard"? (
              userData?.data?.basicInfo?.phoneNo
            ) : null }
            { userType === "admin"? (
              userData?.data?.basicInfo?.phoneNo
            ) : null }
          </h1>
        </div>
        <div className="userBasDetails">
          <p>Mobile Number</p>
          <h1>
            { userType === "organization"? (
              userData?.data?.basicInfo?.mobileNo
            ) : null }
            { userType === "consultant"? (
              userData?.data?.basicInfo?.mobileNo
            ) : null }
            { userType === "auditor"? (
              userData?.data?.basicInfo?.mobileNo
            ) : null }
            { userType === "advisoryBoard"? (
              userData?.data?.basicInfo?.mobileNo
            ) : null }
            { userType === "admin"? (
              userData?.data?.basicInfo?.mobileNo
            ) : null }
          </h1>
        </div>
      </div>

      {/* Headquarter Section */}
      <ProfileBasicAddress
       address={userData?.data?.basicInfo?.address}
       userType={userType}
      />
    </div>
  );
}

export default ProfileBasicDetails;
