import react from "react";

import "./style.css";
import AddUserButton from "../../button/addUserButton/AddUserButton";
import AdminBreadcrumbs from "../breadcrumbs/adminBreadcrumb/AdminBreadcrumbs";
import { useNavigate } from "react-router-dom";

function AddUser() {
  const navigate = useNavigate();

  const addAdminFunc = () => {
    navigate("/addAdmin");
  };

  return (
    <div className="addUserContainer">
      <AdminBreadcrumbs />
      <div className="addUserSection">
        <div className="addUserCol1">
          <h1>All Users</h1>
        </div>
        <div className="addUserCol2" onClick={addAdminFunc}>
          <AddUserButton buttonName={"Add Admin"} />
        </div>
      </div>
    </div>
  );
}

export default AddUser;
