import React from "react";

import "./style.css";
import CurrentRatingSection from "../currentRatingSection/CurrentRatingSection";

function MyRatingSection({ rating }) {
  return (
    <div className="myRatingSec">
      <div className="myRatingTitle">
        <h1>My Rating</h1>
        <p>
          You can see everything about your ratings and the process at the
          moment here
        </p>
      </div>
      <CurrentRatingSection currentRating = {rating?.rating}/>
    </div>
  );
}

export default MyRatingSection;
