import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextArea from "../../../InputBox/textAreaBox/TextArea";
import InputBox from "../../../InputBox/InputBox";
import Initiative1 from "../initiative1/Initiative1";
import AddButton from "../../../button/addButton/AddButton";
import RemoveButton from "../../../button/removeButton/RemoveButton";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function PriorityPillar({
  formik,
  headName,
  removePriorityPillar,
  index,
  value,
}) {
  const addInitiative = () => {
    formik.setFieldValue(`actionPlan.priorityPillars[${index}].initiatives`, [
      ...formik.values.actionPlan.priorityPillars[index].initiatives,
      {
        description: {
          issueId: "",
          data: "",
        },
        location: {
          issueId: "",
          location1: "",
          location2: "",
        },
        leadAndSupport: {
          issueId: "",
          leadName: "",
          leadEmail: "",
          leadContactType: "",
          supportName: "",
          supportEmail: "",
          supportContactType: "",
        },
        externalPartners: {
          issueId: "",
          business: "",
          government: "",
          nonProfitOrganizations: "",
        },
        timelines: {
          planning: {
            issueId: "",
            data: [
              {
                fromYear: "",
                fromQuater: "",
                toYear: "",
                toQuater: "",
              },
            ],
          },
          execution: {
            issueId: "",
            data: [
              {
                fromYear: "",
                fromQuater: "",
                toYear: "",
                toQuater: "",
              },
            ],
          },
          monitoring: {
            issueId: "",
            data: [
              {
                fromYear: "",
                fromQuater: "",
                toYear: "",
                toQuater: "",
              },
            ],
          },
        },
      },
    ]);
  };

  const removeInitiative = ({ deleteIndex }) => {
    const updatedInitiative = formik?.values?.actionPlan?.priorityPillars?.[
      index
    ]?.initiatives?.filter((_, index) => index !== deleteIndex);
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives`,
      updatedInitiative
    );
  };

  return (
    <div className="emisSectionGap">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="typographyRow">
            <Typography>
              <div className="GenrlInfo">
                <h3>{headName}</h3>
              </div>
            </Typography>

            {formik.values?.actionPlan?.priorityPillars?.length > 1 && (
              <RemoveButton
                btnTitle={"Remove"}
                remove={removePriorityPillar}
                index={index}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="priorityPillarCon">
            <div className="pillarActionCon">
              <div className="pillarActionCol1">
                <InputBox
                  headName={"Priority Pillar for Action"}
                  placeholder="Add description / objective"
                  name={`actionPlan.priorityPillars[${index}].objective`}
                  value={value?.objective}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  error={
                    formik.touched?.actionPlan?.priorityPillars &&
                    formik.touched?.actionPlan?.priorityPillars?.[index]
                      ?.objective &&
                    formik.errors?.actionPlan?.priorityPillars &&
                    formik.errors?.actionPlan?.priorityPillars?.[index]
                      ?.objective
                  }
                  errorIcon={WarningIcon}
                />
              </div>
              <div className="pillarActionCol2">
                <TextArea
                  type="text"
                  headName={"Description"}
                  placeholder="Enter the value"
                  name={`actionPlan.priorityPillars[${index}].description`}
                  value={value?.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched?.actionPlan?.priorityPillars &&
                    formik.touched?.actionPlan?.priorityPillars?.[index]
                      ?.description &&
                    formik.errors?.actionPlan?.priorityPillars &&
                    formik.errors?.actionPlan?.priorityPillars?.[index]
                      ?.description
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            </div>
            {formik?.values?.actionPlan?.priorityPillars[
              index
            ]?.initiatives?.map((_, initiativeIndex) => (
              <Initiative1
                key={initiativeIndex}
                formik={formik}
                removeInitiative={removeInitiative}
                index={index}
                initiativeIndex={initiativeIndex}
                headName={`${index + 1}.${initiativeIndex + 1}`}
                name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}]`}
                value={
                  formik.values.actionPlan.priorityPillars[index].initiatives[
                    initiativeIndex
                  ]
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.actionPlan?.priorityPillars?.[index]
                //     ?.initiatives?.[initiativeIndex] &&
                //   formik.errors.actionPlan?.priorityPillars?.[index]
                //     ?.initiatives?.[initiativeIndex]
                // }
                errorIcon={WarningIcon}
              />
            ))}

            <AddButton
              btnTitle={"Add Initiative"}
              addInitiative={addInitiative}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PriorityPillar;
