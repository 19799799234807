import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import InputBox from "../../../InputBox/InputBox";
import FileUploadBox from "../../../fileUploadBox/FileUploadBox";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { eventFromMessage } from "@sentry/react";
import FileUploadBoxRegistration from "../../../fileUploadBox/FileUploadBoxRegistration";
import FileUploadBoxDecleration from "../../../fileUploadBox/FileUploadBoxDecleration";

function OrgBoundaries({ formik, activeSection, setActiveSection }) {
  const addEntity = () => {
    formik.setFieldValue("organizationalBoundaries.entities", [
      ...formik.values.organizationalBoundaries.entities,
      {
        legalEntityOrFacility: "",
        percentageEquityShare: "",
        isCompanyHasFinancialControl: true,
        isCompanyHasOperationalControl: false,
      },
    ]);
  };

  const onRemoveEntity = ({ deleteIndex }) => {
    const updatedEntity =
      formik?.values?.organizationalBoundaries?.entities?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue("organizationalBoundaries.entities", updatedEntity);
  };

  // const handleCheckBoxChange = (event) => {
  //   const { value } = event.target;
  //   const path = "organizationalBoundaries.consolidationApproach.data";
  //   const currentValue =
  //     formik.values?.organizationalBoundaries?.consolidationApproach?.data ||
  //     [];
  //   const updatedValue = currentValue.includes(value)
  //     ? currentValue.filter((val) => val !== value)
  //     : [...currentValue, value];
  //   formik.setFieldValue(path, updatedValue);
  // };

  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "orgBoundariesSection" : false);
  };
  return (
    <div className="emisSectionGap" id="orgBoundariesSection">
      <Accordion
        expanded={activeSection === "orgBoundariesSection"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>ORGANIZATIONAL BOUNDARIES</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="orgConsolidateSec">
            <h1>
              Which consolidation approach was chosen (check each consolidation
              approach for which your company is reporting emissions.) If your
              company is reporting according to more than one consolidation
              approach, please complete and attach an additional completed
              reporting template that provides your company’s emissions data
              following the other consolidation approach(es).
            </h1>
            <div className="form-row financCheck">
              <FormGroup>
                <FormControlLabel
                  value="Equity Share"
                  label="Equity Share"
                  control={
                    <Checkbox
                      onChange={formik?.handleChange}
                      checked={
                        formik?.values?.organizationalBoundaries
                          ?.consolidationApproach?.equityShare
                      }
                      name="organizationalBoundaries.consolidationApproach.equityShare"
                      value={
                        formik?.value?.organizationalBoundaries
                          ?.consolidationApproach?.equityShare
                      }
                      size="small"
                    />
                  }
                />
                <FormControlLabel
                  value="Financial Control"
                  label="Financial Control"
                  control={
                    <Checkbox
                      onChange={formik?.handleChange}
                      checked={
                        formik?.values?.organizationalBoundaries
                          ?.consolidationApproach?.financialControl
                      }
                      name="organizationalBoundaries.consolidationApproach.financialControl"
                      value={
                        formik?.value?.organizationalBoundaries
                          ?.consolidationApproach?.financialControl
                      }
                      size="small"
                    />
                  }
                />
                <FormControlLabel
                  value="Operational Control"
                  label="Operational Control"
                  control={
                    <Checkbox
                      onChange={formik?.handleChange}
                      checked={
                        formik?.values?.organizationalBoundaries
                          ?.consolidationApproach?.operationalControl
                      }
                      name="organizationalBoundaries.consolidationApproach.operationalControl"
                      value={
                        formik?.value?.organizationalBoundaries
                          ?.consolidationApproach?.operationalControl
                      }
                      size="small"
                    />
                  }
                />
              </FormGroup>
            </div>
          </div>

          <div className="orgListDownSec">
            <h1>
              List down all legal entities or facilities over which reporting
              company has equity share, financial control or operational control
            </h1>
            {formik.values?.organizationalBoundaries?.entities?.map(
              (_, index) => (
                <div className="equitySection" key={index}>
                  <div className="remove_div">
                    <p>
                      Equity Share, Financial & Operation Control Information
                    </p>
                    {formik.values?.organizationalBoundaries?.entities?.length >
                      1 && (
                      <p
                        className="remove cursorPointer"
                        onClick={onRemoveEntity.bind(null, {
                          deleteIndex: index,
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faCircleMinus}
                          className="fontAwesomeIcon_img"
                          style={{ color: "#008BB2" }}
                        />
                        Remove
                      </p>
                    )}
                  </div>
                  <div className="equityForm">
                    <div className="equityFormCol1">
                      <InputBox
                        headName={"Legal Entity or Facility"}
                        placeholder="Enter the address here"
                        name={`organizationalBoundaries.entities[${index}].legalEntityOrFacility`}
                        value={
                          formik?.values?.organizationalBoundaries?.entities[
                            index
                          ]?.legalEntityOrFacility
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.organizationalBoundaries?.entities &&
                          !!formik.touched.organizationalBoundaries?.entities[
                            index
                          ]?.legalEntityOrFacility &&
                          formik.errors.organizationalBoundaries?.entities &&
                          formik.errors.organizationalBoundaries?.entities[
                            index
                          ]?.legalEntityOrFacility
                        }
                        errorIcon={WarningIcon}
                      />
                    </div>
                    <div className="equityFormCol2">
                      <InputBox
                        type="text"
                        headName={"Percentage equity share in legal entity"}
                        placeholder="Enter the value"
                        name={`organizationalBoundaries.entities[${index}].percentageEquityShare`}
                        value={
                          formik?.values?.organizationalBoundaries?.entities?.[
                            index
                          ]?.percentageEquityShare
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik?.touched?.organizationalBoundaries?.entities &&
                          !!formik?.touched?.organizationalBoundaries?.entities[
                            index
                          ]?.percentageEquityShare &&
                          formik?.errors?.organizationalBoundaries?.entities &&
                          formik?.errors?.organizationalBoundaries?.entities[
                            index
                          ]?.percentageEquityShare
                        }
                        errorIcon={WarningIcon}
                      />
                    </div>
                  </div>

                  <div className="flexRow">
                    <div className="wd-50">
                      <p className="doRepText">
                        Does reporting company have financial control?
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="wd-100"
                        value={
                          formik?.values?.organizationalBoundaries?.entities[
                            index
                          ]?.isCompanyHasFinancialControl
                        }
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `organizationalBoundaries.entities[${index}].isCompanyHasFinancialControl`,
                            e.target.value
                          );
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Yes"
                          defaultChecked
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="No"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>

                    <div className="wd-50">
                      <p className="doRepText">
                        Does reporting company have operational control?
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="wd-100"
                        value={
                          formik?.values?.organizationalBoundaries?.entities[
                            index
                          ]?.isCompanyHasOperationalControl
                        }
                        onChange={(e) => {
                          formik?.setFieldValue(
                            `organizationalBoundaries.entities[${index}].isCompanyHasOperationalControl`,
                            e.target.value
                          );
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Yes"
                          defaultChecked
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="No"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="addBranch_div cursorPointer">
              <p className="addBranch" onClick={addEntity}>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  className="fontAwesomeIcon_img"
                  style={{ color: "#008BB2" }}
                />
                Add Entity Or Facility
              </p>
            </div>
          </div>

          <div className="wd-100 subsidiarySec">
            <div>
              <h1>
                If the reporting company’s parent company does not report
                emissions, include an organizational diagram that clearly
                defines relationship of the reporting subsidiary as well as
                other subsidiaries
              </h1>
            </div>
            <div className="wd-100 mb-28 addPdfWidth">
              <FileUploadBoxDecleration
                formik={formik}
                field="organizationalBoundaries.orgDiagramFile"
                value={formik?.values?.organizationalBoundaries?.orgDiagramFile}
                folder="registration"
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.organizationalBoundaries?.orgDiagramFile &&
                  formik.errors.organizationalBoundaries?.orgDiagramFile
                }
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default OrgBoundaries;
