import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import InputBox from "../../InputBox/InputBox";

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import WavyCheck from "../../../assets/icons/WavyCheck.svg";
import { useLocation } from "react-router-dom";

const passCriRul = [
  { rule: "The password should be at least 8 characters or more." },
  {
    rule: "Password must contain at least one uppercase and one lowercase alphabet.",
  },
  { rule: "Password must contain at least one numeric character." },
  { rule: "Password must contain at least one special character." },
];

function AdminLogCreds({ formik }) {
  const [currentStep, setCurrentStep] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const location = useLocation();
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const currentPasswordVisibe = () => {
    setCurrentStep(!currentStep);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const confirmPassVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  return (
    <div className="adminBasicCon">
      <h1>Login Credentials</h1>
      <form className="adminBsForm" noValidate onSubmit={formik.handleSubmit}>
        <div className="adminBsInputRow">
          <div className="relativePosition adminPassInp">
            {location.pathname === "/admin/editAdmin" ? (
              <>
                <InputBox
                  headName={"Current Password"}
                  placeholder="Current Password"
                  type={currentStep ? "text" : "password"}
                  name="oldPassword"
                  value={formik?.values?.oldPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  required
                />
                <div className="login_Eyeicon_position">
                  <FontAwesomeIcon
                    icon={currentStep ? faEye : faEyeSlash}
                    onClick={() => currentPasswordVisibe()}
                    className="login_Eye_icon eye_color"
                  />
                </div>
              </>
            ) : (
              <InputBox
                headName={"Email ID"}
                placeholder="Enter your email here"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.email && formik.errors.email}
                required
                readOnly
              />
            )}
          </div>
          <div className="relativePosition adminPassInp">
            <InputBox
              headName={"Password"}
              placeholder="Password"
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              value={formik?.values?.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.password && formik.errors.password}
              required
            />
            <div className="login_Eyeicon_position">
              <FontAwesomeIcon
                icon={isPasswordVisible ? faEye : faEyeSlash}
                onClick={() => togglePasswordVisibility()}
                className="login_Eye_icon eye_color"
              />
            </div>
          </div>
          <div className="relativePosition adminPassInp">
            <InputBox
              headName={"Retype Password"}
              placeholder="Retype password"
              type={isConfirmPasswordVisible ? "text" : "password"}
              name="retypePassword"
              value={formik?.values?.retypePassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik.touched.retypePassword && formik.errors.retypePassword
              }
              required
            />
            <div className="login_Eyeicon_position">
              <FontAwesomeIcon
                icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                onClick={() => confirmPassVisibility()}
                className="login_Eye_icon eye_color"
              />
            </div>
          </div>

          <div className="editPassRule">
            <h1 className="editPassRuleTitle">Password Criteria:</h1>
            {passCriRul?.map((item) => (
              <div className="editPassCriteria">
                <img src={WavyCheck} />
                <p>{item.rule}</p>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminLogCreds;
