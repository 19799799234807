import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";
import RoadmapComments from "../../../chats/RoadmapComments";

function LeadAndSupportPreview({
  value,
  declarationId,
  priorityPillarId,
  initiativeId,
  issueId,
}) {
  return (
    <div className="supportPreCon">
      <h1 className="supportTitle">Lead & Support (Internal)</h1>
      <div className="supportPreRowCon">
        <div className="supportPreRow">
          <div className="supportPreCol1">
            <h2>Lead</h2>
          </div>
          <div className="supportPreCol2">
            <p>{value?.leadContactType}</p>
            <p>Name: {value?.leadName}</p>
            <p>Email: {value?.leadEmail}</p>
          </div>
        </div>
      </div>
      <div className="supportPreRowCon">
        <div className="supportPreRow">
          <div className="supportPreCol1">
            <h2>Support</h2>
          </div>
          <div className="supportPreCol2">
            <p>{value?.supportContactType}</p>
            <p>Name: {value?.supportName}</p>
            <p>Email: {value?.supportEmail}</p>
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.leadAndSupport"
            issueId={issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"Lead & Support (Internal)"}
            question="LEAD & SUPPORT"
            answer={""}
          />
        </div>
      </div>
    </div>
  );
}

export default LeadAndSupportPreview;
