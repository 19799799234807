import React from "react";
import EditPencil from "../../assets/icons/Edit_Pencil.svg";
import Headquarters from "./Headquarters";
import { useNavigate } from "react-router-dom";
import Branch from "./Branch";
const AdvisoryBoardWorkDetails = ({ workDetails }) => {
  const navigate = useNavigate();
  const advisoryBoardDetails = [
    {
      title: "Type of Organization",
      value: workDetails?.advisoryBoardType?.boardType,
    },
    {
      title: "Name of Your Organization",
      value: workDetails?.orgName,
    },
    {
      title: "Website",
      value: workDetails?.orgWebsiteLink,
    },
    {
      title: "Position in the organization",
      value: workDetails?.positionInOrg,
    },
  ];

  return (
    <div className="orgDetailsEdit_section">
      <div className="edit_div">
        <div className="wd-50">
          <h3>Work Details</h3>
        </div>
        <div className="wd-50 editContainer" onClick={()=>{navigate("/auth/registration/memberWorkDetails")}}>
          <p className="edit cursorPointer">
            <img className="editIcon" src={EditPencil} alt="EditPencil" />
            Edit
          </p>
        </div>
      </div>
      {advisoryBoardDetails.map((item) => (
        <div className="detailsRowDiv" key={item.index}>
          <div className="wd-30">
            <h4 className="preTitle">{item.title}</h4>
          </div>
          <div className="wd-40 mL-2r">
            <h4 className="preValue">{item.value}</h4>
          </div>
        </div>
      ))}

      <Headquarters workDetails={workDetails} />
      {workDetails?.orgBranch?.map((item, index)=>(
        <Branch item={item} index = {index}/>
      )) }

      <div className="detailsRowDiv">
        <div className="wd-30">
          <h4 className="preTitle">Number of Employess</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{workDetails?.noOfEmployees}</h4>
        </div>
      </div>

      <div className="detailsRowDiv">
        <div className="wd-30">
          <h4 className="preTitle">Type Of Expertise</h4>
        </div>
        <div className="wd-40 mL-2r">
          <ul className="preValue">
            {workDetails?.typeOfExpertise.map((expertise, index) => (
              <li key={index}>{expertise}mnmnm</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdvisoryBoardWorkDetails;
