import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "./style.css";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Calendar = ({
  onChange,
  headName,
  value,
  error,
  startDate1,
  endDate1,
  ...props
}) => {
  const [registrationDate, setRegistrationDate] = useState();
  const [cleared, setCleared] = React.useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  console.log("startDateCheck", startDate);
  console.log("endDateTimeCheck", endDate);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const inputLabelProps = {
    style: {
      color: "gray",
      fomtSize: "5px",
    },
  };

  return (
    <div>
      <label className="calendarLabel">{headName}</label>
      <DatePicker 
        className={`customInputCalender ${error ? "error" : ""}`}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        showIcon
        toggleCalendarOnIconClick
        onChange={(update) => {
          setDateRange(update);
          onChange(update);
        }}
        isClearable={true}
        placeholderText="Registration Date"
      />
      {error && (
        <p error className="inputError">
          {error}
        </p>
      )}
    </div>
  );
};

export default Calendar;
