import "./style.css";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function PhoneInputBox({
  headName,
  placeholder,
  inputBoxMb,
  type,
  inputBorder,
  error,
  errorIcon,
  required,
  countryCodeName,
  formik,
  countryCodeValue,
  defaultCountry,
  countryCodeError,
  ...props
}) {
  return (
    <div className="input-item" style={{ marginBottom: inputBoxMb }}>
      <label className="input_label">
        {headName}
        {/* {required && <small className='error'> &nbsp;*</small>} */}
      </label>
      <div
        style={{
          display: "flex",
          gridGap: "5px",
          alignItems: "flex-start",
        }}
        className="containerPhoneCountryCode"
      >
        <div className="inputCountryContainerPhoneCountryCode">
          <PhoneInput
            international
            defaultCountry={defaultCountry}
            value={countryCodeValue}
            className={`custom-input ${countryCodeError ? "error" : ""}`}
            onChange={(value) => formik.setFieldValue(countryCodeName, value)}
            style={{
              border: "2px solid #B9B9B7",
              height: "40px",
              borderRadius: "3px",
              marginBottom: 0,
              marginTop: "5px",
            }}
          />
          {countryCodeError && (
            <p error className="inputError">
              {errorIcon && (
                <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
              )}
              {error || "lasj"}
            </p>
          )}
        </div>
        <div
          className="inputPhoneContainerPhoneCountryCode"
          style={{
            width: "-webkit-fill-available",
          }}
        >
          <input
            type={type}
            placeholder={placeholder}
            className={`custom-input ${error ? "error" : ""}`}
            {...props}
            spellCheck="false"
          />
          {error && (
            <p error className="inputError">
              {errorIcon && (
                <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
              )}
              {error || "lasj"}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default PhoneInputBox;
