import React from "react";

import AddPillarButton from "../../../button/addPillarButton/AddPillarButton";

import "./style.css";

function AddPriorityPillar({ addPriorityPillar }) {
  return (
    <div className="addPriorityCon">
      <AddPillarButton addPriorityPillar={addPriorityPillar} />
      <p>You can add a new Priority Pillar to your action plan here</p>
    </div>
  );
}

export default AddPriorityPillar;
