const DROPZONE_IMAGE_FORMAT = {
  "image/jpeg": [],
  "image/jpg": [],
  "image/png": [],
  "image/svg": [],
};

const DROPZONE_VIDEO_FORMAT = {
  "video/mp4": [],
  "video/avi": [],
  "video/mkv": [],
  "video/quicktime": [],
};

const DROPZONE_PDF_FORMAT = {
  "application/pdf": [],
};

const DROPZONE_WORD_FORMAT = {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // .docx
  "application/msword": [], // .doc
};

const DROPZONE_POWERPOINT_FORMAT = {
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    [], // .pptx
  "application/vnd.ms-powerpoint": [], // .ppt
};

const IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "svg"];

const VIDEO_EXTENSIONS = ["mp4", "avi", "mkv", "mov"];

const PDF_EXTENSIONS = ["pdf"];

const WORD_EXTENSIONS = ["docx", "doc"];

const POWERPOINT_EXTENSIONS = ["pptx", "ppt"];

const SNACKBAR_DURATION = 5000;

const MAX_FILE_SIZE = 5 * 1024 * 1024;

const UNITS = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export {
  DROPZONE_IMAGE_FORMAT,
  DROPZONE_VIDEO_FORMAT,
  DROPZONE_PDF_FORMAT,
  DROPZONE_WORD_FORMAT,
  DROPZONE_POWERPOINT_FORMAT,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  PDF_EXTENSIONS,
  WORD_EXTENSIONS,
  POWERPOINT_EXTENSIONS,
  SNACKBAR_DURATION,
  MAX_FILE_SIZE,
  UNITS,
};
