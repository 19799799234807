import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormButton from "../../../button/FormButton";

import "./style.css";


import Button from "../../../button/Button";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AcceptProfilePopUp({
  open,
  onClose,
  title,
  onAccept,
  question,
  statement,
  acceptButtonText,
  rejectButtonText,
  icon,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="acceptTitleBox">
          <div className="acceptTitleDiv">
            <img src={icon} alt="acceptImage" />
            <h1>{question}</h1>
            <p>{statement}</p>
          </div>

          <div className="acceptBtnRow">
            <button
              type="cancel"
              className="acceptGoBackBtn"
              onClick={handleClose}
            >
              <p>{rejectButtonText}</p>
            </button>
            <div className="proAcceptBtn cursorPointer" onClick={onAccept}>
              <Button buttonName={acceptButtonText} />
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default AcceptProfilePopUp;
