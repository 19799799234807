import react, { useState } from "react";

import "./style.css";
import ProfileBasicDetails from "./profileBasicDetails/ProfileBasicDetails";
import AuditorOrganizationDetails from "./profileOrgDetails/AuditorOrganizationDetails";

function ProfileAudDetails({ userData, userType }) {
  const [activeTab, setActiveTab] = useState("Work");
  
  return (
    <div className="proTabsContainer">
      <div className="regisLogsRow">
          <div className="userTypeButton">
          <button
            style={{
              backgroundColor:
                activeTab === "basic" ? "#E6F3F7" : "transparent",
            }}
            className={`cursorPointer ${
              activeTab === "basic" ? "active" : ""
            }`}
            onClick={() => setActiveTab("basic")}
          >
            <p style={{ color: activeTab === "basic" ? "#00637E" : ""}}>Basic Details</p>
          </button>
          <button
            style={{
              backgroundColor:
                activeTab === "Work" ? "#E6F3F7" : "transparent",
            }}
            className={`cursorPointer ${
              activeTab === "Work" ? "active" : ""
            }`}
            onClick={() => setActiveTab("Work")}
          >
            <p style={{ color: activeTab === "Work" ? "#00637E" : ""}}>Work Details</p>
          </button>
        </div>
        </div>
        <div className="userTypeDetContainer">
        {activeTab === "basic" ? (
          <ProfileBasicDetails userData={userData} userType={userType}/>
        ) : null}
        {activeTab === "Work" ? (
          <AuditorOrganizationDetails userData={userData} userType={"auditor"}/>
        ) : null}
      </div>
    </div>  
  );
}

export default ProfileAudDetails;
