import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faCirclePlus,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import SelectInputBox from "../../../../components/InputBox/selectBox/SelectInputBox";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import FileUploadBox from "../../../../components/fileUploadBox/FileUploadBox";
import PdfUpload from "../../../../components/pdfUpload/PdfUpload";
import RegSubmitButton from "../../../../components/button/regSubmitBtn/RegSubmitButton";

import "./style.css";

import EditPencil from "../../../../assets/icons/Edit_Pencil.svg";
import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginHandler } from "../../../../features/auth/authActions";
import { setUserHandler } from "../../../../features/user/userAction";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";
import { getUserFromLocal } from "../../../../utils/storage";
import {
  useOrgCompleteInfoMutation,
  useOrgPreviewInfoMutation,
} from "../../../../features/registration/organization/organizatonApiSlice";
import { userActions } from "../../../../features/user/userSlice";
import {
  useAdvisoryBoardCompleteInfoMutation,
  useAdvisoryBoardPreviewInfoMutation,
} from "../../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import {
  useCompleteConsultantMutation,
  usePreviewConsultantMutation,
} from "../../../../features/registration/consultant/consultantApiSlice";
import AdvisoryBoardWorkDetails from "../../../../components/workDetails/AdvisoryBoardWorkDetails";
import ConsultantWorkDetails from "../../../../components/workDetails/ConsultantWorkDetails";
import AuditorWorkDetails from "../../../../components/workDetails/AuditorWorkDetails";
import PageLoader from "../../../../components/Loader/PageLoader";
import {
  useCompleteAuditorMutation,
  usePreviewAuditorMutation,
} from "../../../../features/registration/auditor/auditorApiSlice";
import Acknowledgement from "../../../../components/emissionComponents/step1/acknowledgement/Acknowledgement";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { showError } from "../../../../features/snackbar/snackbarAction";

function PreviewForm() {
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);
  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("finishing-up");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const dispatch = useDispatch();

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { basicInfo, orgDetails, additionalDetails, workDetails } =
    response || {};

  const [
    previewInfo,
    {
      isLoading: previewIsLoading,
      isSuccess: previewIsSuccess,
      error: previewIsError,
    },
  ] = useOrgPreviewInfoMutation();

  const [
    completeInfo,
    {
      data: completeInfoData,
      isLoading: completeIsLoading,
      isSuccess: completeIsSuccess,
      error: completeIsError,
    },
  ] = useOrgCompleteInfoMutation();

  const [
    advisoryBoardPreviewInfo,
    {
      isLoading: advisoryBoardPreviewIsLoading,
      isSuccess: advisoryBoardPreviewIsSuccess,
      error: advisoryBoardPreviewIsError,
    },
  ] = useAdvisoryBoardPreviewInfoMutation();

  const [
    advisoryBoardCompleteInfo,
    {
      data: advisoryBoardCompleteInfoData,
      isLoading: advisoryBoardCompleteIsLoading,
      isSuccess: advisoryBoardCompleteIsSuccess,
      error: advisoryBoardCompleteIsError,
    },
  ] = useAdvisoryBoardCompleteInfoMutation();

  const [
    previewAudInfo,
    {
      isLoading: previewAudIsLoading,
      isSuccess: previewAudIsSuccess,
      error: previewAudIsError,
    },
  ] = usePreviewAuditorMutation();

  const [
    completeAudInfo,
    {
      isLoading: completeAudIsLoading,
      isSuccess: completeAudIsSuccess,
      error: completeAudIsError,
    },
  ] = useCompleteAuditorMutation();

  const [
    previewConsInfo,
    {
      isLoading: previewConsIsLoading,
      isSuccess: previewConsIsSuccess,
      error: previewConsIsError,
    },
  ] = usePreviewConsultantMutation();

  const [
    completeConsultant,
    {
      isLoading: completeConsIsLoading,
      isSuccess: completeConsIsSuccess,
      error: completeConsIsError,
    },
  ] = useCompleteConsultantMutation();

  const values = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    password: userReduxDetails?.loginPassword,
  };

  useEffect(() => {
    if (userDetails?.userType === "organization") {
      previewInfo(values).then((res) => {
        setResoponse(res);
      });
    } else if (userDetails?.userType === "auditor") {
      previewAudInfo(values).then((res) => {
        setResoponse(res);
      });
    } else if (userDetails?.userType === "consultant") {
      previewConsInfo(values).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "advisoryBoard") {
      advisoryBoardPreviewInfo(values).then((res) => {
        setResoponse(res);
      });
    }
  }, []);

  const handleForm = (event) => {
    event.preventDefault();
    if (isConfirmed) {
      if (userDetails?.userType === "organization") {
        completeInfo(values)
          .unwrap()
          .then((res) => {
            dispatch(userActions.reset());
            navigate("/auth/registration/signUpSuccess");
          })
          .catch((err) => {});
      } else if (userDetails?.userType === "auditor") {
        completeAudInfo(values)
          .unwrap()
          .then((res) => {
            // dispatch(userActions.reset());
            navigate("/auth/registration/signUpSuccess");
          })
          .catch((err) => {});
      } else if (userDetails?.userType === "consultant") {
        completeConsultant(values)
          .unwrap()
          .then((res) => {
            // dispatch(userActions.reset());
            navigate("/auth/registration/signUpSuccess");
          })
          .catch((err) => {});
      } else if (userDetails?.userType === "advisoryBoard") {
        advisoryBoardCompleteInfo(values)
          .unwrap()
          .then((res) => {
            dispatch(userActions.reset());
            navigate("/auth/registration/signUpSuccess");
          })
          .catch((err) => {});
      }
    } else {
      dispatch(
        showError({
          message: `Please check the Monitoring & Reporting Acknowledgement before submitting your declaration`,
        })
      );
      return;
    }
  };

  const basicDetails = [
    {
      index: "1",
      title: "Title",
      value: basicInfo?.title,
    },
    {
      index: "2",
      title: "First Name",
      value: basicInfo?.firstName,
    },
    {
      index: "3",
      title: "Last Name",
      value: basicInfo?.lastName,
    },
    {
      index: "4",
      title: "Email ID",
      value: basicInfo?.email,
    },
    {
      index: "5",
      title: "Phone Number",
      value: `${basicInfo?.phoneNoCountryCode} ${basicInfo?.phoneNo}`,
    },
    {
      index: "6",
      title: "mobile Number",
      value: `${basicInfo?.mobileNoCountryCode} ${basicInfo?.mobileNo}`,
    },
    {
      index: "7",
      title: "Your Address",
      value: basicInfo?.address?.fullAddress,
    },
    {
      index: "8",
      title: "City / Town / Suburb",
      value: basicInfo?.address?.city,
    },
    {
      index: "9",
      title: "Country",
      value: basicInfo?.address?.country,
    },
    {
      index: "10",
      title: "State / Region",
      value: basicInfo?.address?.state,
    },
    {
      index: "11",
      title: "Zipcode / Postal Code",
      value: basicInfo?.address?.zipCode,
    },
  ];

  const orgDetails2 = [
    {
      index: "1",
      title: "Name of Organization",
      value: orgDetails?.orgName,
    },
    {
      index: "2",
      title: "Position in the organization",
      value: orgDetails?.positionInOrg,
    },
    {
      index: "3",
      title: "Sub - Category",
      value: orgDetails?.orgActiveSubCategory,
    },
    {
      index: "4",
      title: "Number of Employees",
      value: orgDetails?.noOfEmployees,
    },
    {
      index: "5",
      title: "Sector",
      value: orgDetails?.orgActiveSector,
    },
    {
      index: "6",
      title: "Sub - Category",
      value: orgDetails?.orgActiveSubCategory,
    },
  ];

  const advBoardDetails = [
    {
      index: "1",
      title: "Type of Organization",
      value: workDetails?.advisoryBoardType?.boardType,
    },
    {
      index: "2",
      title: "Name of Your Organization",
      value: workDetails?.orgName,
    },
    {
      index: "3",
      title: "Website",
      value: workDetails?.orgWebsiteLink,
    },
    {
      index: "4",
      title: "Position in the organization",
      value: workDetails?.positionInOrg,
    },
    {
      index: "5",
      title: "Number of Employess",
      value: workDetails?.noOfEmployees,
    },
    {
      index: "6",
      title: "Type Of Expertise",
      value: workDetails?.typeOfExpertise,
    },
  ];

  const advBoardHeadquarters = [
    {
      index: "1",
      title: "Address",
      value: workDetails?.orgHeadquarter?.fullAddress,
    },
    {
      index: "2",
      title: "City / Town / Suburb",
      value: workDetails?.orgHeadquarter?.city,
    },
    {
      index: "3",
      title: "Country",
      value: workDetails?.orgHeadquarter?.country,
    },
    {
      index: "4",
      title: "State / Region",
      value: workDetails?.orgHeadquarter?.state,
    },
    {
      index: "5",
      title: "Zipcode / Postal Code",
      value: workDetails?.orgHeadquarter?.zipCode,
    },
  ];

  const HeadquartersDetails = [
    {
      index: "1",
      title: "Address",
      value: orgDetails?.orgHeadquarter?.fullAddress,
    },
    {
      index: "2",
      title: "City / Town / Suburb",
      value: orgDetails?.orgHeadquarter?.city,
    },
    {
      index: "3",
      title: "Country",
      value: orgDetails?.orgHeadquarter?.country,
    },
    {
      index: "4",
      title: "State / Region",
      value: orgDetails?.orgHeadquarter?.state,
    },
    {
      index: "5",
      title: "Zipcode / Postal Code",
      value: orgDetails?.orgHeadquarter?.zipCode,
    },
  ];

  const loginData = [
    {
      index: "1",
      title: "Email ID",
      value: "irfan@gmail.com",
    },
    {
      index: "2",
      title: "Password",
      value: "irfan@sd123",
    },
  ];

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  console.log("lengthCheck", additionalDetails?.otherDetailsFiles);

  const changeValue = (event) => {
    setIsConfirmed(event.target.checked);
  };

  return (
    <div className="container preview_section">
      {(previewIsLoading ||
        completeIsLoading ||
        advisoryBoardPreviewIsLoading ||
        advisoryBoardCompleteIsLoading ||
        previewAudIsLoading ||
        completeAudIsLoading ||
        previewConsIsLoading ||
        completeConsIsLoading) && <PageLoader />}
      <div className="previewSec_Col1">
        <img className="preview_topBg" src={SignUpTopBg} alt="" />
        <img className="preview_bottomBg" src={SignUpBottomBg} alt="" />
        <img className="preview_leftBg" src={SignUpLeftBg} alt="" />

        <div className="preview_timeLineContents">
          <div className="preview_logoDiv">
            <img src={RegistrationLogo} alt="" />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>
      <div className="previewSec_Col2">
        <h1>Preview Registration Form</h1>
        {/* <p>Lorem ipsum dolor sit amet consectetur. Dictum eget viverra at pellentesque consectetur fames ullamcorper. Aliquet volutpat cras cursus at morbi consectetur. Gravida nunc in sit porta.</p> */}

        {basicInfo && (
          <div className="basicDetailsEdit_section">
            <div className="edit_div">
              <div className="wd-50">
                <h3>Basic Details </h3>
              </div>
              <div
                className="wd-50"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  className="edit cursorPointer"
                  onClick={() => {
                    // setFormNumber(1);
                    navigate(`/auth/registration/basicDetails`);
                  }}
                >
                  <img className="editIcon" src={EditPencil} alt="EditPencil" />
                  Edit
                </p>
              </div>
            </div>

            {basicDetails?.map(
              (item) =>
                item?.value && (
                  <div className="detailsRowDiv">
                    <div className="wd-30">
                      <h4 className="preTitle">{item?.title}</h4>
                    </div>
                    <div className="wd-40 mL-2r">
                      <h4 className="preValue">{item?.value}</h4>
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        {orgDetails && (
          <div className="orgDetailsEdit_section">
            <div className="edit_div">
              <div className="wd-50">
                <h3>Organization Details</h3>
              </div>
              <div
                className="wd-50"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  className="edit cursorPointer"
                  onClick={() => {
                    // setFormNumber(2);
                    navigate(`/auth/registration/organizationDetails`);
                  }}
                >
                  <img className="editIcon" src={EditPencil} alt="EditPencil" />
                  Edit
                </p>
              </div>
            </div>
            {orgDetails2.slice(0, 3).map((item) => (
              <div className="detailsRowDiv" key={item.index}>
                <div className="wd-30">
                  <h4 className="preTitle">{item.title}</h4>
                </div>
                <div className="wd-40 mL-2r">
                  <h4 className="preValue">{item.value}</h4>
                </div>
              </div>
            ))}
            <div className="preview_headQuarter  mb_1r">
              <p className="Headquarters_title">Headquarters</p>
              {HeadquartersDetails.map(
                (item) =>
                  item?.value && (
                    <div className="headqDetails">
                      <div className="wd-30">
                        <h4 className="preTitle">{item.title}</h4>
                      </div>
                      <div className="wd-40 mL-2r">
                        <h4 className="preValue">{item.value}</h4>
                      </div>
                    </div>
                  )
              )}
            </div>

            {orgDetails?.orgBranch.map((item, index) => (
              <div className="preview_headQuarter  mb_1r" key={index}>
                <p className="Headquarters_title">Branch {index + 1}</p>
                <div className="headqDetails">
                  <div className="wd-30">
                    <h4 className="preTitle">Address</h4>
                  </div>
                  <div className="wd-40 mL-2r">
                    <h4 className="preValue">{item?.fullAddress}</h4>
                  </div>
                </div>
                <div className="headqDetails">
                  <div className="wd-30">
                    <h4 className="preTitle">City / Town / Suburb</h4>
                  </div>
                  <div className="wd-40 mL-2r">
                    <h4 className="preValue">{item?.city}</h4>
                  </div>
                </div>
                <div className="headqDetails">
                  <div className="wd-30">
                    <h4 className="preTitle">Country</h4>
                  </div>
                  <div className="wd-40 mL-2r">
                    <h4 className="preValue">{item.country}</h4>
                  </div>
                </div>
                <div className="headqDetails">
                  <div className="wd-30">
                    <h4 className="preTitle">State / Region</h4>
                  </div>
                  <div className="wd-40 mL-2r">
                    <h4 className="preValue">{item.state}</h4>
                  </div>
                </div>
                {item.zipCode && (
                  <div className="headqDetails">
                    <div className="wd-30">
                      <h4 className="preTitle">Zipcode / Postal Code</h4>
                    </div>
                    <div className="wd-40 mL-2r">
                      <h4 className="preValue">{item.zipCode}</h4>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {orgDetails2.slice(3, 6).map((item) => (
              <div className="detailsRowDiv" key={item.index}>
                <div className="wd-30">
                  <h4 className="preTitle">{item.title}</h4>
                </div>
                <div className="wd-40 mL-2r">
                  <h4 className="preValue">{item.value}</h4>
                </div>
              </div>
            ))}
          </div>
        )}

        {additionalDetails && (
          <div className="orgDetailsEdit_section">
            <div className="edit_div">
              <div className="wd-50">
                <h3>Additional Details</h3>
              </div>
              <div
                className="wd-50"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  className="edit cursorPointer"
                  onClick={() => {
                    // setFormNumber(3);
                    navigate(`/auth/registration/additionalDetails`);
                  }}
                >
                  <img className="editIcon" src={EditPencil} alt="EditPencil" />
                  Edit
                </p>
              </div>
            </div>
            <div className="detailsRowDiv">
              <div className="wd-33">
                <h4 className="preTitle">Registration Details</h4>
              </div>
              <div className="wd-65 preview_pdfRow preePdfRow">
                {additionalDetails?.orgDetailsFiles?.map((item) => (
                  <div className=" mb_1r">
                    <PdfUpload pdfType={item} faXmarkDisplay={"none"} />
                  </div>
                ))}
              </div>
            </div>

            {(additionalDetails?.otherDetailsFiles?.length > 0 ||
              additionalDetails?.otherWebsiteLink?.length > 0) && (
              <div className="detailsRowDiv mt_2r">
                <div className="wd-33">
                  <h4 className="preTitle">Other Relevant Information</h4>
                </div>
                <div className="wd-65 preview_pdfRow">
                  <div className="releventDelDiv">
                    <h4 className="preOtherDelText">Other Documents</h4>
                    {additionalDetails?.otherDetailsFiles?.length > 0 && (
                      <div className="preePdfRow">
                        {additionalDetails?.otherDetailsFiles?.map((item) => (
                          <div className=" mb_1r addPdfWidth preePdfRow">
                            <PdfUpload pdfType={item} faXmarkDisplay={"none"} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {additionalDetails?.otherWebsiteLink?.map((item) => (
                    <>
                      <div className="wd-100 pdf_Row">
                        <h4 className="preTitle">Website</h4>
                        <h4 className="preValue webLinkData">{item?.link}</h4>
                      </div>
                      <div className="wd-100 pdf_Row">
                        <h4 className="preTitle">Link Name</h4>
                        <h4 className="preValue webLinkData">
                          {item.linkName}
                        </h4>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {userDetails?.userType === "advisoryBoard" && (
          <AdvisoryBoardWorkDetails workDetails={workDetails} />
        )}

        {userDetails?.userType === "consultant" && (
          <ConsultantWorkDetails workDetails={workDetails} />
        )}

        {userDetails?.userType === "auditor" && (
          <AuditorWorkDetails workDetails={workDetails} />
        )}

        <div className="orgDetailsEdit_section">
          <div className="edit_div">
            <div className="wd-50">
              <h3>Login Credentials</h3>
            </div>
            <div
              className="wd-50"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <p
                className="edit cursorPointer"
                onClick={() => {
                  // setFormNumber(4);
                  navigate(`/auth/registration/loginCredentials`);
                }}
              >
                <img className="editIcon" src={EditPencil} alt="EditPencil" />
                Edit
              </p>
            </div>
          </div>
          <div className="detailsRowDiv">
            <div className="wd-30">
              <h4 className="preTitle">EMAIL ID</h4>
            </div>
            <div className="wd-40 mL-2r">
              <h4 className="passSec preValue"> {userDetails?.email}</h4>
            </div>
          </div>
          <div className="detailsRowDiv">
            <div className="wd-30">
              <h4 className="preTitle">PASSWORD</h4>
            </div>
            <div className="wd-40 mL-2r">
              <h4 className="passSec preValue">
                {" "}
                {/* {userReduxDetails?.loginPassword} */}
                {userReduxDetails?.loginPassword && (
                  <>
                    <h4 className="PrePassBlock">
                      {isPasswordVisible ? (
                        userReduxDetails?.loginPassword
                      ) : (
                        <span className="password-hidden">********</span>
                      )}
                      <div className="viewSec">
                        <FontAwesomeIcon
                          icon={isPasswordVisible ? faEye : faEyeSlash}
                          onClick={() => togglePasswordVisibility()}
                          className="login_Eye_icon eye_color "
                        />
                        <p className="preViewText">View</p>
                      </div>
                    </h4>
                  </>
                )}
              </h4>
            </div>
          </div>
        </div>

        <div className="horizontalLine preHoriLine"></div>

        <div className="quarterRow">
          <div className="quarterCol">
            <FormGroup>
              <FormControlLabel
                value="Control"
                label=""
                control={
                  <Checkbox
                    size="small"
                    checked={isConfirmed}
                    onChange={changeValue}
                  />
                }
              />
            </FormGroup>
            <p>
              Please confirm that you have been authorized by your company to
              fill in this form on their behalf.
            </p>
          </div>
        </div>

        <form className="mt_2r">
          <div className="form-row od_BtnRow">
            <div
              className=" cursorPointer"
              onClick={() => {
                navigate("/auth/registration/loginCredentials");
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div className=" cursorPointer cancelBtn_Row">
              {/* <RegCancelButton buttonName={"Cancel"} /> */}
              <div
                className=" NextBtn cursorPointer"
                onClick={handleForm}
                backgroundColor={"#00637E"}
                // style={{
                //   backgroundColor: !isConfirmed ? "#7badba" : "#00637E",
                // }}
                // disabled={!isConfirmed}
              >
                <RegSubmitButton buttonName={"Submit & Register"} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PreviewForm;
