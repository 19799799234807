import React from "react";

import "./style.css";
import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import EmissionComments from "../../../chats/EmissionComments";

function ScopEmissonTable({
  scopeTitle,
  mapping,
  issueId,
  declarationId,
  secName,
  question,
  answer,
  value,
}) {
  const ghgGasData = [
    {
      name: "CO2 (mt)",
      value: value?.co2?.value,
      specify: value?.co2?.specify,
      notRecorded: value?.co2?.notRecorded,
    },
    {
      name: "CH2 (mt)",
      value: value?.ch4?.value,
      specify: value?.ch4?.specify,
      notRecorded: value?.ch4?.notRecorded,
    },
    {
      name: "N2O(mt)",
      value: value?.n2o?.value,
      specify: value?.n2o?.specify,
      notRecorded: value?.n2o?.notRecorded,
    },
    {
      name: "HFCs (mt)",
      value: value?.hfcs?.value,
      specify: value?.hfcs?.specify,
      notRecorded: value?.hfcs?.notRecorded,
    },
    {
      name: "PFCs (mt)",
      value: value?.pfcs?.value,
      specify: value?.pfcs?.specify,
      notRecorded: value?.pfcs?.notRecorded,
    },
    {
      name: "SF6 (mt)",
      value: value?.sf6?.value,
      specify: value?.sf6?.specify,
      notRecorded: value?.sf6?.notRecorded,
    },
  ];

  return (
    <div className="infoScopOneSec">
      <div className="scope1Row">
        <h1 className="emScopePart1Ques">{scopeTitle}</h1>
        <div className="addCmtRow">
          <EmissionComments
            className="addCmtRow"
            mapping={mapping}
            issueId={issueId}
            declarationId={declarationId}
            secName={secName}
            question={question}
            answer={answer}
          />
        </div>
      </div>
      <div className="info1GasRow">
        <div className="info1GasCol1">
          {ghgGasData?.map((item, index) => (
            <div className="info1GasData" key={index}>
              <div>
                <h1>{item.name}</h1>
                <p>{item?.notRecorded ? "" : item.value}</p>
              </div>
              {item?.notRecorded && (
                <div>
                  <h1>Reason</h1>
                  <p>{item?.specify}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="scopeRightBorder"></div>
        <div className="info1GasCol2">
          <h1>Total (mtCO2e)</h1>
          <p>{value?.total}</p>
        </div>
      </div>
    </div>
  );
}

export default ScopEmissonTable;
