import React from "react";
import FormButton from "../../../components/button/FormButton";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import { getUserFromLocal } from "../../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useOrgPreviewInfoMutation } from "../../../features/registration/organization/organizatonApiSlice";
import { useAdvisoryBoardPreviewInfoMutation } from "../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import { useState } from "react";
import { useCheckConsultantQuery } from "../../../features/registration/consultant/consultantApiSlice";
import { setUserHandler } from "../../../features/user/userAction";
import { userActions } from "../../../features/user/userSlice";
import { useCheckAuditorQuery } from "../../../features/registration/auditor/auditorApiSlice";

const VerifySuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const userType = searchParams.get("userType");
  const identifier = searchParams.get("identifier");
  const [response, setResponse] = useState("");

  const [
    previewInfo,
    {
      isLoading: previewIsLoading,
      isSuccess: previewIsSuccess,
      error: previewIsError,
    },
  ] = useOrgPreviewInfoMutation();

  const [
    advisoryBoardPreviewInfo,
    {
      isLoading: advisoryBoardPreviewIsLoading,
      isSuccess: advisoryBoardPreviewIsSuccess,
      error: advisoryBoardPreviewIsError,
    },
  ] = useAdvisoryBoardPreviewInfoMutation();

  const {
    data: consultantData,
    isSuccess: consultantIsSuccess,
    error: consultantError,
  } = useCheckConsultantQuery(
    { identifier: identifier },
    {
      skip:
        userType === "organization" ||
        userType === "advisoryBoard" ||
        userType === "auditor",
    }
  );

  const {
    data: auditorData,
    isSuccess: auditorIsSuccess,
    error: auditorError,
  } = useCheckAuditorQuery(
    { identifier: identifier },
    {
      skip:
        userType === "organization" ||
        userType === "advisoryBoard" ||
        userType === "consultant",
    }
  );

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };

  useEffect(() => {
    if (userType === "organization") {
      previewInfo(basicValues).then((res) => {
        setResponse(res);
      });
    }
    if (userType === "advisoryBoard") {
      advisoryBoardPreviewInfo(basicValues).then((res) => {
        setResponse(res);
      });
    }
  }, [userDetails?.userId, userReduxDetails?.trackingCode, userType]);

  const handleRedirectionToForm = () => {
    if (userType === "organization") {
      if (response?.data?.data?.user?.stageAdditionalDetails === true) {
        navigate("/auth/registration/loginCredentials");
      } else if (response?.data?.data?.user?.stageOrgDetails === true) {
        navigate("/auth/registration/additionalDetails");
      } else if (response?.data?.data?.user?.stageBasic === true) {
        navigate("/auth/registration/organizationDetails");
      } else {
        navigate("/auth/registration/basicDetails");
      }
    } else if (userType === "advisoryBoard") {
      if (response?.data?.data?.user?.stageWorkDetails === true) {
        navigate("/auth/registration/loginCredentials");
      } else if (response?.data?.data?.user?.stageBasic === true) {
        navigate("/auth/registration/memberWorkDetails");
      } else {
        navigate("/auth/registration/basicDetails");
      }
    } else if (userType === "consultant" && consultantIsSuccess) {
      if (consultantData?.data?.user?.stageWorkDetails === true) {
        dispatch(
          setUserHandler({
            email: consultantData?.data?.user?.email,
            userType: consultantData?.data?.user?.userType,
            userId: consultantData?.data?.user?._id,
            // organizationName:
            //   consultantData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: consultantData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/loginCredentials");
      } else if (consultantData?.data?.user?.stageBasic === true) {
        dispatch(
          setUserHandler({
            email: consultantData?.data?.user?.email,
            userType: consultantData?.data?.user?.userType,
            userId: consultantData?.data?.user?._id,
            // organizationName:
            //   consultantData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: consultantData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/workDetails");
      } else {
        dispatch(
          setUserHandler({
            email: consultantData?.data?.user?.email,
            userType: consultantData?.data?.user?.userType,
            userId: consultantData?.data?.user?._id,
            // organizationName:
            //   consultantData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: consultantData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/basicDetails");
      }
    } else if (userType === "auditor" && auditorIsSuccess) {
      if (auditorData?.data?.user?.stageWorkDetails === true) {
        dispatch(
          setUserHandler({
            email: auditorData?.data?.user?.email,
            userType: auditorData?.data?.user?.userType,
            userId: auditorData?.data?.user?._id,
            // organizationName:
            //   auditorData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: auditorData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/loginCredentials");
      } else if (auditorData?.data?.user?.stageBasic === true) {
        dispatch(
          setUserHandler({
            email: auditorData?.data?.user?.email,
            userType: auditorData?.data?.user?.userType,
            userId: auditorData?.data?.user?._id,
            // organizationName:
            //   auditorData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: auditorData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/workDetails");
      } else {
        dispatch(
          setUserHandler({
            email: auditorData?.data?.user?.email,
            userType: auditorData?.data?.user?.userType,
            userId: auditorData?.data?.user?._id,
            // organizationName:
            //   auditorData?.data?.user?.associatedOrg[0]?.organizationName,
          })
        );
        dispatch(
          userActions.setTrackingCode({
            trackingCode: auditorData?.data?.trackingCode,
          })
        );
        navigate("/auth/registration/basicDetails");
      }
    }
  };

  // <script type="text/javascript">
  //   window.history.forward(); function noBack() {window.history.forward()}
  // </script>;
  // useEffect(() => {
  //   window.history.forward();
  //   function noBack() {
  //     window.history.forward();
  //   }
  //   window.addEventListener("popstate", noBack);
  //   return () => {
  //     window.removeEventListener("popstate", noBack);
  //   };
  // }, []);

  console.log("responseff", response?.data?.data?.user?.stageBasic);
  console.log("consultantData", consultantData?.data?.user?.userType);
  console.log("consultantIsSuccess", consultantIsSuccess);

  return (
    <div className="container signUp_section">
      <div className="signSec_Col1">
        <img className="signUp_topBg" src={SignUpTopBg} />
        <img className="signUp_bottomBg" src={SignUpBottomBg} />
        <img className="signUp_leftBg" src={SignUpLeftBg} />
        <div className="signUp_logoDiv">
          <img src={RegistrationLogo} />
        </div>
        <div className="signUp_divContents">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="signUp_leftContent">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>

        <div className="copyRight_div">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>

      <div className="signSec_Col2">
        <div className="verifySuccessSec">
          <h1>
            {userType === "consultant" || userType === "auditor"
              ? "Proceed With Registration"
              : "Verification Successful"}
          </h1>
          <p>
            Please continue with your account registration to start your rating
            process
          </p>
          <div
            className="cursorPointer verifiOtpBtn"
            onClick={handleRedirectionToForm}
          >
            <FormButton
              buttonName={"Continue Registration"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifySuccess;
