import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  email: null,
  userId: null,
  userName: null,
  userType: null,
  trackingCode: null,
  loginPassword: null,
  authToken : null,
  organizationName : null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setTrackingCode(state, action) {
      const { trackingCode } = action.payload;
      state.trackingCode = trackingCode;
    },

    setLoginPassword(state, action) {
      const { loginPassword } = action.payload;
      state.loginPassword = loginPassword;
    },

    set(state, action) {
      const {
        email,
        userId,
        userName,
        userType,
        authToken,
        organizationName
      } = action.payload;
      state.email = email;
      state.userId = userId;
      state.userName = userName;
      state.userType = userType;
      state.authToken = authToken;
      state.organizationName = organizationName ;
    },
    reset(state) {
      state.email = initialUserState.email;
      state.userId = initialUserState.userId;
      state.userName = initialUserState.userName;
      state.userType = initialUserState.userType;
      state.trackingCode = initialUserState.trackingCode;
      state.loginPassword = initialUserState.loginPassword;
      state.organizationName = initialUserState.organizationName;
    },
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
