import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import AdminOrgRateTitle from "../../../components/adminComponents/adminOrgTitle/AdminOrgRateTitle";
import RatingRegDetails from "../../../components/adminComponents/admRatingComponent/ratingInvolveUsers/RatingRegDetails";
import RatingInvolveUsers from "../../../components/adminComponents/admRatingComponent/ratingInvolveUsers/RatingInvolveUsers";
import AdminRatingProcess from "../../../components/adminComponents/admRatingComponent/adminRatingProcess/AdminRatingProcess";

import "./style.css";


function AdminRatingDetails() {
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AdminOrgRateTitle />

          <div className="registOnRow">
            <div className="registOnCol1">
              <RatingRegDetails />
            </div>
            <div className="registOnCol2">
              <RatingInvolveUsers />
            </div>
          </div>

          <AdminRatingProcess />
        </div>
      </div>
    </div>
  );
}

export default AdminRatingDetails;
