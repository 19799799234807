import React from 'react'



const Headquarters = ({workDetails}) => {

    const advBoardHeadquarters = [
        {
          index: "1",
          title: "Address",
          value: workDetails?.orgHeadquarter?.fullAddress,
        },
        {
          index: "2",
          title: "City / Town / Suburb",
          value: workDetails?.orgHeadquarter?.city,
        },
        {
          index: "3",
          title: "Country",
          value: workDetails?.orgHeadquarter?.country,
        },
        {
          index: "4",
          title: "State / Region",
          value: workDetails?.orgHeadquarter?.state,
        },
        {
          index: "5",
          title: "Zipcode / Postal Code",
          value: workDetails?.orgHeadquarter?.zipCode,
        },
      ];

  return (
    <div className="preview_headQuarter  mb_1r">
    <p className="Headquarters_title">Headquarters</p>
    {advBoardHeadquarters.map((item) => (
      (item?.value && <div className="detailsRowDiv">
        <div className="wd-30">
          <h4 className="preTitle">{item.title}</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item.value}</h4>
        </div>
      </div>)
    ))}
  </div>
  )
}

export default Headquarters