import react from "react";

import "./style.css";
import ProfileHeadquarters from "../profileHeadquarters/ProfileHeadquarters";
import PdfUpload from "../../../pdfUpload/PdfUpload";

function ConsultantOrganizationDetails({ userData, userType }) {

  return (
    <div className="viewOrgDetContainer">
      <div className="userProDetailsRow">
        <div className="userOrgDetails">
          <p>
            Name of Associated Organization
          </p>
          <h1>{userData.data.workDetails.associatedOrg}</h1>
        </div>
        <div className="userOrgDetails">
          <p>Job Type</p>
          <h1>{userData.data.workDetails.jobType}</h1>
        </div>
      </div>
      <div className="proRegisDetails">
        <h1>Documents</h1>
        <div className="profilePdfSection">
          {userData?.data?.workDetails?.proofOfConsultancyFiles.map(
            (item, index) => (
              <PdfUpload faXmarkDisplay={"none"} pdfType={item} key={index} />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default ConsultantOrganizationDetails;
