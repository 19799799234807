import React, { useEffect, useMemo, useReducer, useState } from "react";

import FormButton from "../button/FormButton";

import "./style.css";

import UnreadIcom from "../../assets/icons/unreadNotificationIcon.svg";
import NotificationArrowIcon from "../../assets/images/whiteTriangleIcon.png";
import { useNavigate } from "react-router-dom";
import { useGetNotificationQuery } from "../../features/notificationApiSlice";
import moment from "moment/moment";

function Notifications() {
  const navigate = useNavigate();
  const [hideUnreadIcon, setHideUnreadIcon] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 5;

  const {
    data: notifications,
    isLoading: notificationIsLoading,
    isFetching: notificationIsFetching,
  } = useGetNotificationQuery({
    pageNo,
    pageSize,
  });

  useEffect(() => {
    const onScroll = () => {
      const popupElement = document.getElementById("popupScrollContainer");
      if (!popupElement) return;
      const scrolledToBottom =
        popupElement.clientHeight + popupElement.scrollTop + 1 >=
        popupElement.scrollHeight;

      if (scrolledToBottom && !notificationIsFetching) {
        setPageNo(pageNo + 1);
      }
    };

    const popupElement = document.getElementById("popupScrollContainer");
    if (popupElement) {
      popupElement.addEventListener("scroll", onScroll);
    }

    return function () {
      const popupElement = document.getElementById("popupScrollContainer");
      if (popupElement) {
        popupElement.removeEventListener("scroll", onScroll);
      }
    };
  }, [pageNo, notificationIsFetching]);

  const handleMarkAsRead = () => {
    setHideUnreadIcon(true);
    setBackgroundColor("#E0E0E0");
  };

  return (
    <div className="notificationContainer">
      <img src={NotificationArrowIcon} alt="icon" className="notifiArrowIcon" />
      <header className="notificationHeader" style={{ position: "relative" }}>
        <div className="markAsReadRow">
          <h1>Notifications</h1>
          <div onClick={handleMarkAsRead} className="cursorPointer">
            <h2>Mark As Read</h2>
          </div>
        </div>
        <div className="unreadRow">
          <div className="emisSubmitBtn">
            <FormButton
              buttonName={"All"}
              backgroundColor={"#000000"}
              buttonText={"#ffffff"}
              btnFont={"12px"}
              btnPdd={"3px 6px"}
            />
          </div>
          <div className="unreadButton">Unread (2)</div>
          <div
            className="unreadButton"
            onClick={() => {
              navigate("/emissionStep6");
            }}
          >
            Cons Redirect
          </div>
          <div
            className="unreadButton"
            onClick={() => {
              navigate("/emissionStep6Preview");
            }}
          >
            Aud Redirect
          </div>
        </div>
      </header>
      <div className="bottomBorder" />
      <div className="notifiBoxsection" id="popupScrollContainer">
        {notifications?.data?.map((item, index) => (
          <div
            className="notifiTextContainer"
            style={{
              backgroundColor: item?.isRead === true ? backgroundColor : "",
            }}
            key={index}
          >
            <div className="notificationTextBox">
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  textWrap: "wrap",
                }}
              >
                <h3 className="notifiText">{item?.message} </h3>
                {!hideUnreadIcon && item?.isRead === false && (
                  <img
                    src={UnreadIcom}
                    alt="unreadIcom"
                    className="unreadIcomStyle"
                  />
                )}
              </div>
              <div className="notificationBy">
                <p>by you (Auditor)</p>
                <div className="notifiByRightBorder"></div>
                <span>{moment(item?.createdAt).format("hh:mm a")}</span>
              </div>
            </div>
          </div>
        ))}
        {notificationIsLoading && (
          <div
            style={{
              color: "black",
              textAlign: "center",
            }}
          >
            Loading....
          </div>
        )}
      </div>
    </div>
  );
}

export default Notifications;
