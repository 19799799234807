import React from 'react';

import "./style.css";
import PdfUpload from '../../pdfUpload/PdfUpload';


function MyProfileAdvisoryOrg() {

  return (
    <div className='profileAdvOrgCon'>
        <h1>Organization Details</h1>
            <div className='advSecData' style={{width:"100%"}}>
                <span>User Category</span>
                <p>Non-Government Organization / Non-Profit Organization </p>
            </div>
            <div className='proAdvOrgSecRow'>
                <div className='advSecData' style={{width:"34%"}}>
                    <span>Name of Organization</span>
                    <p>Codewave Technologies</p>
                </div>
                <div className='advSecData' style={{width:"32"}}>
                    <span>Website link</span>
                    <p>www.codewave.com</p>
                </div>
                <div className='advSecData' style={{width:"33"}}>
                    <span>Position in the organization</span>
                    <p>VP Sustainability</p>
                </div>
            </div>
        <div className='proAdvOrgSec'>

            <div className='myProAdvOrgHeadQ'>
                <h1 className='advHeadqTitle'>Headquarters</h1>
                <div className='proAdvHeadqInner'>
                    <div className='advSecData'>
                        <span>Address</span>
                        <p>123, ABC Street, XYZ Colony</p>
                    </div>
                    <div className='advSecData'>
                        <span>City / Town / Suburb</span>
                        <p>Dubai</p>
                    </div>
                    <div className='advSecData'>
                        <span>Country</span>
                        <p>United Arab Emirates</p>
                    </div>
                    <div className='advSecData'>
                        <span>State / Region</span>
                        <p>Dubai</p>
                    </div>
                    <div className='advSecData'>
                        <span>Zipcode / Postal Code</span>
                        <p>50819</p>
                    </div>
                </div>
            </div>
            <div className='myProAdvOrgHeadQ'>
                <h1 className='advHeadqTitle'>Headquarters</h1>
                <div className='proAdvHeadqInner'>
                    <div className='advSecData'>
                        <span>Address</span>
                        <p>123, ABC Street, XYZ Colony</p>
                    </div>
                    <div className='advSecData'>
                        <span>City / Town / Suburb</span>
                        <p>Dubai</p>
                    </div>
                    <div className='advSecData'>
                        <span>Country</span>
                        <p>United Arab Emirates</p>
                    </div>
                    <div className='advSecData'>
                        <span>State / Region</span>
                        <p>Dubai</p>
                    </div>
                    <div className='advSecData'>
                        <span>Zipcode / Postal Code</span>
                        <p>50819</p>
                    </div>
                </div>
            </div>
        </div>
            <div className='advSecData'>
                <span>Number of Employees</span>
                <p>500 to 1000</p>
            </div>
            <div className='advSecData'>
                <span>Type of Expertise</span>
                <p>GHG Emissions Accounting</p>
                <p>Net Zero Target Setting & Action Planning</p>
                <p>Nature-based Solutions</p>
                <p>Industrial Technology such as Carbon Capture and Storage and Direct Air Capture</p>
                <p>Carbon Offsetting</p>
                <p>Physical Impacts of Climate Change</p>
            </div>
    </div>
  )
}

export default MyProfileAdvisoryOrg;