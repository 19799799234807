import React from "react";
import SummerySection from "../commonComponents/summerySection/SummerySection";
import TextComponent from "../commonComponents/textComponent/TextComponent";

import "./style.css";
import TextWithPdf from "../commonComponents/textWithPdf/TextWithPdf";

function RecieverChatBox({
  text,
  pdf,
  pdfData,
  reciever = false,
  isSummary = false,
  summaryText,
  item,
  // descText,
}) {
  console.log("text", text);
  return (
    <div className={reciever ? "cmntRecieverChatBox" : "cmntSenderChatBox"}>
      {isSummary ? (
        <div className={reciever ? "recieverChatBoxCon" : "senderChatBoxCon"}>
          <SummerySection
            summaryText={summaryText}
            descText={text}
            item={item}
          />
        </div>
      ) : pdfData ? (
        <div className={reciever ? "recieverChatBoxCon" : "senderChatBoxCon"}>
          <TextWithPdf text={text} pdfData={pdfData} item={item} />
        </div>
      ) : (
        <div className={reciever ? "recieverChatBoxCon" : "senderChatBoxCon"}>
          <TextComponent text={text} item={item} />
        </div>
      )}
    </div>
  );
}

export default RecieverChatBox;
