import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import"./style.css";

function RatingSelectProfile({
  data,
  value,
  onChange,
  defaultValue
}) {
    const [age, setAge] = React.useState('');
    const [profileName, setProfileName] = useState(value || "");

    const handleChange = (event) => {
        setProfileName(event.target.value);
    };

  return (
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className='selectProfileCon'>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value || ""}
          onChange={onChange}
          label="profileName"
          displayEmpty
          className='selectProfile'
          defaultValue={defaultValue}
        >
          {data?.map((item) => (
            <MenuItem  key={item.value} value={item.value}>
            {item.name}
          </MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}

export default RatingSelectProfile;