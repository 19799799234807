import apiSlice from "../../../app/api/apiSlice";

export const advisoryBoardMemberApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    advisoryBoardRegister: builder.mutation({
      query: (organisationInfo) => ({
        url: "/advisory-board/registration/start",
        method: "POST",
        body: organisationInfo,
      }),
      invalidatesTags: ["AdvisoryBoardMember"],
    }),

    advisoryBoardVerifyOtp: builder.mutation({
      query: (otpDetails) => ({
        url: "/advisory-board/registration/verify-otp",
        method: "POST",
        body: otpDetails,
      }),
    }),

    advisoryBoardBasicInfo: builder.mutation({
      query: (basicData) => ({
        url: "/advisory-board/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["AdvisoryBoardMember"],
    }),

    advisoryBoardWorkDetails: builder.mutation({
      query: (workData) => ({
        url: "/advisory-board/registration/work-details",
        method: "POST",
        body: workData,
      }),
      invalidatesTags: ["AdvisoryBoardMember"],
    }),

    advisoryBoardCompleteInfo: builder.mutation({
      query: (completeData) => ({
        url: "/advisory-board/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["AdvisoryBoardMember"],
    }),

    advisoryBoardPreviewInfo: builder.mutation({
      query: (previewData) => ({
        url: "/advisory-board/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["AdvisoryBoardMember"],
    }),
  }),
});

export const {
  useAdvisoryBoardRegisterMutation,
  useAdvisoryBoardBasicInfoMutation,
  useAdvisoryBoardWorkDetailsMutation,
  useAdvisoryBoardCompleteInfoMutation,
  useAdvisoryBoardPreviewInfoMutation,
  useAdvisoryBoardVerifyOtpMutation,
} = advisoryBoardMemberApiSlice;
