
import React from 'react';
import Organizations from '../../../../assets/homeImages/Organizations.svg';
import Auditors from '../../../../assets/homeImages/Auditors.svg';
import Consultants from '../../../../assets/homeImages/Consultants.svg';
import NGOs from '../../../../assets/homeImages/NGOs.svg';
import Government from '../../../../assets/homeImages/Government.svg';
import Academia from '../../../../assets/homeImages/Academia.svg';
import Carbon from '../../../../assets/homeImages/Carboncredit.svg';
import whoIsRightBg from '../../../../assets/bgImages/whoiIs-bg1.svg';
import whoIsLeftBg from '../../../../assets/bgImages/whoIs-bg2.svg';
import SoultionImages from '../../../../assets/homeImages/SolutionImg.svg';
import SoultionImg from '../../../../assets/homeImages/solutionNewImg.svg';
import './style.css';


function WhoIs({whoIsSectionRef}) {
    const WhoIsData = [
        {
          img: Organizations,
          heading: 'Organizations',
          description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
        },
        {
            img: Auditors,
            heading: 'Auditors',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
          {
            img: Consultants,
            heading: 'Consultants',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
          {
            img: NGOs,
            heading: 'NGOs',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
          {
            img: Government,
            heading: 'Government Bodies',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
          {
            img: Academia,
            heading: 'Academia',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
          {
            img: Carbon,
            heading: 'Carbon Credit Orgs',
            description: 'Lorem ipsum dolor sit amet consectetur. Dictumst eu vehicula mi amet a elit quam in mauris. Vestibulum cursus purus sit quis eu interdum arcu leo senectus.',
          },
      ];
    
  return (
  <div className='whoIsItMainSecion'>
    <div className='container whoIs_section' ref={whoIsSectionRef}>
      <div className='whoIs_headSection'>
        <div className='whoIsSec_Col1'>
            <h1>Who Is It For?</h1>
            <p>SEE rating is designed for a variety of stakeholders, all of whom will benefit from it.</p>
        </div>
        <div className='whoIsSec_Col2'>
        </div>
      </div>
      {/* <div className='whoIs_Rows'>
       {WhoIsData?.map((item, index) => (
         <div className='whoIs_Cols'>
           <img alt='image' src={item.img}/>
           <h2>{item.heading}</h2>
           <p>{item.description}</p>
        </div>
       ))}
      </div> */}
   <img src={SoultionImg} className="solutiionImage" />

   <div className='whoIsItBackground-text'>Stake- <br/> holders</div>
   <img className='whoRightBg' src={whoIsRightBg} alt="" />
   <img className='leftLeftBg' src={whoIsLeftBg} alt="" />
  </div>
  </div>
  );
}

export default WhoIs;