import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";

function TextArea({
  headName,
  placeholder,
  textAreaBoxMb,
  type,
  inputBorder,
  required,
  errorIcon,
  error,
  ...props
}) {
  console.log("TextArea -> error", error);

  return (
    <div className="textAreaDiv" style={{ marginBottom: textAreaBoxMb }}>
      <label className="textAreaLabel">
        {headName}
        {/* {required && <small className='error'> &nbsp;*</small>} */}
      </label>
      <div>
        <textarea
          type={type}
          placeholder={placeholder}
          className={`textarea ${error ? "error" : ""}`}
          {...props}
          spellcheck="false"
        />
      </div>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}

export default TextArea;
