import React, { useState } from "react";

import "./style.css";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

function Acknowledgement({ setIsConfirmed, isConfirmed, title, confirmationText }) {
  // console.log("isConfirmed", isConfirmed);

  const changeValue = (event) => {
    setIsConfirmed(event.target.checked);
  };

  return (
    <div className="acknowContainer">
      <h1 className="confirmationTitle">{title}</h1>
      <div className="quarterRow">
        <div className="quarterCol">
          <FormGroup>
            <FormControlLabel
              value="Control"
              label=""
              control={
                <Checkbox
                  size="small"
                  checked={isConfirmed}
                  onChange={changeValue}
                />
              }
            />
          </FormGroup>
          <p>{confirmationText}</p>
        </div>
      </div>
    </div>
  );
}

export default Acknowledgement;
