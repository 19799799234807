import React, { useEffect } from "react";
import "./style.css";

function Button({
  buttonName,
  btnWidth,
  backgroundColor,
  border,
  buttonText,
  btnMR,
  btnFont,
  btnPdd,
  type = "button",
  onClick,
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <button
      type={type}
      onClick={onClick}
      className="form_SubmitBtn"
      style={{
        width: btnWidth,
        backgroundColor: backgroundColor,
        border: border,
        marginRight: btnMR,
      }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
          border: "none",
          color: buttonText,
          fontSize: btnFont,
          padding: btnPdd,
        }}
      >
        {buttonName}
      </div>
    </button>
  );
}

export default Button;
