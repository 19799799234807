import react, { useEffect, useReducer, useState } from "react";

import "./style.css";

import AdminLogo from "../../../assets/logo/adminDashLogo.svg";
import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AddUser from "../../../components/adminComponents/adminAddUser/AddUser";
import UserFilterForm from "../../../components/adminComponents/userFilterForm/UserFilterForm";
import UserDetails from "../../../components/adminComponents/userDetails/UserDetails";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import UserRequest from "../../../components/adminComponents/userRequest/UserRequest";
import { useUserListQuery } from "../../../features/admin/adminApiSlice";
import { showError } from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import PageLoader from "../../../components/Loader/PageLoader";
import { date } from "yup";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  identifier: "",
  userType: "",
  country: "",
  status: "",
  date: "",
  startDate: null,
  endDate: null,
  createdAt: "-1",
  // pendingRequest: true,
  alphabetical: null,
  location: "",
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      identifier: action.identifier,
    };
  }
  if (action.type === "SET_USER_TYPE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      userType: action.userType,
    };
  }
  if (action.type === "SET_COUNTRY") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      country: action.country,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status ? action.status : initialQueryFilterState.status,
    };
  }
  if (action.type === "SET_STARTDATE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      startDate: action.startDate,
    };
  }
  if (action.type === "SET_ENDDATE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      endDate: action.endDate,
    };
  }
  if (action.type === "SET_ALPHABETICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      alphabetical: action.alphabetical,
      createdAt: null,
    };
  }
  if (action.type === "SET_CRONOLOGICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      createdAt: action.createdAt,
      alphabetical: null,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...action.filters,
    };
  }

  return initialQueryFilterState;
};

function AdminDashboard() {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState([]);

  console.log(queryFilterState, "queryFilterState");

  const {
    data: userList,
    isLoading: userListIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: userListIsError,
    isFetching: userListIsFetching,
  } = useUserListQuery({ ...queryFilterState });

  const handleSearchChange = (e) => {
    dispatchQueryFilter({ type: "SEARCH", identifier: e.target.value });
  };

  const handleChangeUserType = (e) => {
    dispatchQueryFilter({ type: "SET_USER_TYPE", userType: e.target.value });
  };

  const handleChangeCountry = (e) => {
    dispatchQueryFilter({ type: "SET_COUNTRY", country: e.target.value });
  };

  const handleChangeStatus = (e) => {
    dispatchQueryFilter({ type: "SET_STATUS", status: e.target.value });
  };

  // const handleChangeCalender = (dateRange) => {
  //   const [startDate, endDate] = dateRange;
  //   const formattedStartDate = moment(startDate)
  //     .startOf("day")
  //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //   const formattedEndDate = moment(endDate)
  //     .endOf("day")
  //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  //   console.log("checkdateStartDate", startDate);
  //   console.log("checkdateEndDate", endDate);
  //   console.log("formattedStartDate", formattedStartDate);
  //   console.log("formattedEndDate", formattedEndDate);

  //   dispatchQueryFilter({
  //     type: "SET_STARTDATE",
  //     startDate: formattedStartDate,
  //   });
  //   dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });
  // };

  const handleChangeCalender = (dateRange) => {
    const [startDate, endDate] = dateRange;
    if (startDate === null && endDate === null) {
      const formattedStartDate = null;
      const formattedEndDate = null;
      dispatchQueryFilter({
        type: "SET_STARTDATE",
        startDate: formattedStartDate,
      });
      dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });

      return;
    }

    // Continue with formatting when at least one of startDate or endDate is not null
    const formattedStartDate = startDate
      ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;

    const formattedEndDate = endDate
      ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;

    console.log("checkdateStartDate", startDate);
    console.log("checkdateEndDate", endDate);
    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);

    dispatchQueryFilter({
      type: "SET_STARTDATE",
      startDate: formattedStartDate,
    });
    dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };

  useEffect(() => {
    if (userListIsError) {
      if (userListError?.message) {
        dispatch(showError({ message: userListError.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
    if (userListIsSuccess) {
      setUserData(userList);
    }
    // if (userListIsSuccess) {
    //     if (selectedDates.length && userList?.data) {
    //       const filteredUsers = userList.data.filter((user) => {
    //         const userRegistrationDate = moment(user?.joiningDate);
    //         return (
    //           userRegistrationDate.isSameOrAfter(selectedDates[0]) &&
    //           userRegistrationDate.isSameOrBefore(selectedDates[1])
    //         );
    //       });
    //       setUserData(filteredUsers);
    //     } else {
    //       setUserData(userList.data || []);
    //     }
    //   }
  }, [userList, userListIsSuccess, userListError, dispatch, selectedDates]);

  console.log("SearchValue", queryFilterState?.identifier);
  console.log("userType", queryFilterState?.userType);
  console.log("initialValues", queryFilterState);

  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AddUser />
          <UserFilterForm
            handleSearchChange={handleSearchChange}
            queryFilterState={queryFilterState}
            onChangeUserType={handleChangeUserType}
            onChangeCountry={handleChangeCountry}
            onChangeStatus={handleChangeStatus}
            onChangeRegistrationDate={handleChangeCalender}
          />
          <UserRequest />
          {userList?.data[0].data?.length ? (
            <UserDetails
              isLoading={userListIsLoading}
              isFetching={userListIsFetching}
              error={userListIsError}
              list={userList?.data[0]}
              // edit={editCategoryPageNavigationHandler}
              totalCount={userList?.data[0]?.metadata[0]?.totalCount}
              // deleteData={deleteVendor}
              // editVendor={editVendor}
              // bulkEdit={bulkEdit}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              selectedDates={selectedDates}
            />
          ) : userListIsLoading ? (
            <PageLoader />
          ) : (
            <span className="d-flex justify-content-center m-3">
              <h1 style={{ textAlign: "center", marginTop: "150px" }}>
                No Data Found
              </h1>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
