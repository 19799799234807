import apiSlice from "../../app/api/apiSlice";

export const step2EmissionDeclerationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    emissionSubmit: builder.mutation({
      query: (draftStepInfo) => ({
        url: "/rating/v1/emissions-declaration/submit",
        method: "POST",
        body: draftStepInfo,
      }),
      invalidatesTags: ["SubmitStep1"],
    }),
    emissionResubmit: builder.mutation({
      query: (data) => ({
        url: "/rating/v1/emissions-resubmit/submit",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ReSubmitStep1"],
    }),
    emissionDrafts: builder.mutation({
      query: (data) => ({
        url: "/rating/v1/emissions-declaration/draft",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["findOne"],
    }),
    emissionCreateIssue: builder.mutation({
      query: (issueBody) => ({
        url: "/rating/v1/emissions-audit/create-issue",
        method: "POST",
        body: issueBody,
      }),
      invalidatesTags: ["findOne"],
    }),
    emissionUpdateIssue: builder.mutation({
      query: (issueBody) => ({
        url: "/rating/v1/emissions-audit/update-issue",
        method: "POST",
        body: issueBody,
      }),
      invalidatesTags: ["Chats", "Issues", "findOne"],
    }),
    emissionRevert: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/emissions-audit/revert",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    emissionReject: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/emissions-audit/reject",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    emissionApprove: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/emissions-audit/approve",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
  }),
});

export const {
  useEmissionSubmitMutation,
  useEmissionResubmitMutation,
  useEmissionDraftsMutation,
  useEmissionCreateIssueMutation,
  useEmissionUpdateIssueMutation,
  useEmissionRevertMutation,
  useEmissionRejectMutation,
  useEmissionApproveMutation,
} = step2EmissionDeclerationApiSlice;
