import React from 'react';

import "./style.css";

function SaveProfileBtn() {
  return (
    <div className='profileEditBtn cursorPointer'>
      <p className='profileEdtBtnText'>Save Profile</p>
    </div>
  )
}

export default SaveProfileBtn;