import react from "react";

import "./style.css";

function ProfileBasicAddress({ address, userType }) {
  return (
    <div className="profileBasicHeadSec">
      <h1 className="profileHomeAddrTitle">Home Location</h1>
      <div className="basicProDetails">
        <div className="basicHeadqDetDiv">
          <div className="basHeadqDetails">
            <p>Home Address</p>
            <h1>
              { userType === "organization"? (
                address?.fullAddress
              ) : null }
              { userType === "consultant"? (
                address?.fullAddress
              ) : null }
              { userType === "auditor"? (
                address?.fullAddress
              ) : null }
              { userType === "advisoryBoard"? (
                address?.fullAddress
              ) : null }
              { userType === "admin"? (
                address?.fullAddress
              ) : null }
            </h1>
          </div>
          <div className="basHeadqDetails">
            <p>City / Town / Suburb</p>
            <h1>
              { userType === "organization"? (
                address?.city
              ) : null }
              { userType === "consultant"? (
                address?.city
              ) : null }
              { userType === "auditor"? (
                address?.city
              ) : null }
              { userType === "advisoryBoard"? (
                address?.city
              ) : null }
              { userType === "admin"? (
                address?.city
              ) : null }
            </h1>
          </div>
        </div>
        <div className="basicHeadqDetDiv">
          <div className="basHeadqDetails">
            <p>Country</p>
            <h1>
              { userType === "organization"? (
                address?.country
              ) : null }
              { userType === "consultant"? (
                address?.country
              ) : null }
              { userType === "auditor"? (
                address?.country
              ) : null }
              { userType === "advisoryBoard"? (
                address?.country
              ) : null }
              { userType === "admin"? (
                address?.country
              ) : null }
            </h1>
          </div>
          <div className="basHeadqDetails">
            <p>State / Region</p>
            <h1>
              { userType === "organization"? (
                address?.state
              ) : null }
              { userType === "consultant"? (
                address?.state
              ) : null }
              { userType === "auditor"? (
                address?.state
              ) : null }
              { userType === "advisoryBoard"? (
                address?.state
              ) : null }
              { userType === "admin"? (
                address?.state
              ) : null }
            </h1>
          </div>
        </div>
        <div className="basicHeadqDetDiv">
          <div className="basHeadqDetails">
            <p>Zipcode / Postal Code</p>
            <h1>
              { userType === "organization"? (
                address?.zipCode
              ) : null }
              { userType === "consultant"? (
                address?.zipCode
              ) : null }
              { userType === "auditor"? (
                address?.zipCode
              ) : null }
              { userType === "advisoryBoard"? (
                address?.zipCode
              ) : null }
              { userType === "admin"? (
                address?.zipCode
              ) : null }
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileBasicAddress;
