import react, { useState } from "react";

import "./style.css";

import { useNavigate } from "react-router-dom";
import { usePendingUserListCountQuery } from "../../../features/admin/adminApiSlice";

function UserRequest() {
  const navigate = useNavigate();

  const userRequestFunc = () => {
    navigate("/UserRequestPage");
  };

  const { data: count } = usePendingUserListCountQuery();

  return (
    <>
      {count?.data?.totalPending ? (
        <div className="userRequestContainer">
          <div className="viewReqCol1">
            <p>
              You have {count?.data?.totalPending} pending registrations
              requests
            </p>
          </div>
          <div className="viewReqCol2">
            <div className="viewReqBtn cursorPointer" onClick={userRequestFunc}>
              <p>View Requests</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default UserRequest;
