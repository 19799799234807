import React from "react";

import TextArea from "../../../InputBox/textAreaBox/TextArea";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function ExternalPartners({ formik, value, index, initiativeIndex }) {
  return (
    <div className="externalPartnerCon">
      <div className="exterPartnerCol1">
        <h1>External Partners</h1>
      </div>
      <div className="exterPartnerCol2">
        <TextArea
          headName={"Business / Industry"}
          placeholder="Type here..."
          name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].externalPartners.business`}
          value={
            formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
              initiativeIndex
            ]?.externalPartners?.business
          }
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.business &&
            formik.errors?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.business
          }
          errorIcon={WarningIcon}
        />
        <TextArea
          headName={"Government"}
          placeholder="Type here..."
          name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].externalPartners.government`}
          value={
            formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
              initiativeIndex
            ]?.externalPartners?.government
          }
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.government &&
            formik.errors?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.government
          }
          errorIcon={WarningIcon}
        />
        <TextArea
          headName={"Non-Profit Organizations"}
          placeholder="Type here..."
          name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].externalPartners.nonProfitOrganizations`}
          value={
            formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
              initiativeIndex
            ]?.externalPartners?.nonProfitOrganizations
          }
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.nonProfitOrganizations &&
            formik.errors?.actionPlan?.priorityPillars?.[index]?.initiatives?.[
              initiativeIndex
            ]?.externalPartners?.nonProfitOrganizations
          }
          errorIcon={WarningIcon}
        />
      </div>
    </div>
  );
}

export default ExternalPartners;
