import React from "react";

import "./style.css";
import moment from "moment";

function AuditTimelineData({ value }) {
  const data = [
    {
      title: "Issues Reported:",
      value: "4 Issues",
    },
    {
      title: "Audited on:",
      value: value?.sumission
        ? moment(value?.sumission).format("Do MMMM YYYY")
        : "_",
    },
  ];
  return (
    <div className="auditTimelineData">
      {data?.map((item) => (
        <div className="trakAuditDataRow">
          <div className="trakAuditDataCol1">
            <h1>{item.title}</h1>
          </div>
          <div className="trakAuditDataCol2">
            <p>{item.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AuditTimelineData;
