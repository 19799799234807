
import React from 'react';
// import SituationGraph from '../../../../assets/homeImages/populationGraph.svg';
import SituationGraphImg from '../../../../assets/homeImages/WhereWeAreGraph.svg';
import './style.css';


function SituationToday({whereWeAreSectionRef}) {
  return (
  <div ref={whereWeAreSectionRef}>
    <div className='container situation_section'>
        <div className='situationSec_Col1'>
            <h1>Where Are We Heading?</h1>
        </div>
        <div className='situationSec_Col2'>
           <p>
            Our planet is already just over 1˚C since industrial times and we are predicted to
            rise by atleast 3˚C by the end of this century if current policies are implemented.
            This means we are on course to not meet the objective of the Paris Agreement to limit warming to 1.5˚C.
           </p>
        </div>
    </div>
    <div className='situationGraph_img'>
      <img src={SituationGraphImg} alt="" />
    </div>
  </div>
  );
}

export default SituationToday;