import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import TextArea from "../../../InputBox/textAreaBox/TextArea";
import InputBox from "../../../InputBox/InputBox";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function MethodoEmission({ formik, activeSection, setActiveSection }) {
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "methodologyEmission" : false);
  };
  return (
    <div className="emisSectionGap" id="methodologyEmission">
      <Accordion
        expanded={activeSection === "methodologyEmission"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>METHODOLOGIES AND EMISSION FACTORS</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <h1>
              Methodologies used to calculate or measure emissions other than
              those provided by the GHG Protocol. (Provide a reference or link
              to any non-GHG Protocol calculation tools used)
            </h1>
          </div>
          <div className="form-row wd-100 typeOfActivity">
            <div className="input-item">
              <TextArea
                headName={"Specify Here"}
                type="text"
                placeholder="Tell us what methodologies and factors are used"
                name="methodologiesAndEmissionFactors.description"
                value={
                  formik.values?.methodologiesAndEmissionFactors?.description
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.methodologiesAndEmissionFactors
                    ?.description &&
                  formik.errors.methodologiesAndEmissionFactors?.description
                }
                errorIcon={WarningIcon}
              />
            </div>
            <div className="wd-100">
              <InputBox
                headName={"Reference / Tool Link"}
                placeholder="www.website.com"
                name="methodologiesAndEmissionFactors.referenceLink"
                value={
                  formik.values?.methodologiesAndEmissionFactors?.referenceLink
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.methodologiesAndEmissionFactors
                    ?.referenceLink &&
                  formik.errors.methodologiesAndEmissionFactors?.referenceLink
                }
                errorIcon={WarningIcon}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MethodoEmission;
