import React from "react";

import "./style.css";

import RemoveIcon from "../../../assets/icons/removeTimeLineIcon.svg";

function RemoveTimeLine({ remove, index }) {
  return (
    <div
      className="removeTimeLineBtn"
      onClick={() => remove({ deleteIndex: index })}
    >
      <img src={RemoveIcon} alt="AddIcon" />
      <p>Remove</p>
    </div>
  );
}

export default RemoveTimeLine;
