import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import RatingSelectProfile from "../ratingSelectProfile/RatingSelectProfile";
import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import Location from "../../../assets/icons/location.svg";
import BellIcon from "../../../assets/icons/bell.svg";
import UserImg from "../../../assets/icons/profileImg.svg";
import ProfileIcon from "../../../assets/icons/ProfileUser.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import ArrowDownWhite from "../../../assets/icons/ArrowDownWhite.svg";
import LogOutPopUp from "../../adminComponents/profileApprovePopUp/logOutPopUp/LogOutPopUp";
import { logoutHandler } from "../../../features/auth/authActions";
import LogOutIcon from "../../../assets/icons/logOutIcon.svg";
import { useDispatch } from "react-redux";
import { getFromLocal, getUserFromLocal } from "../../../utils/storage";

const pages = ["Dashboard", "My Rating", "Help & Support"];

const userName = [
  { name: "Mohamed Irfan", value: "" },
  { name: "Irfan", value: 1 },
  { name: "Sunita", value: 2 },
  { name: "Suni", value: 3 },
  { name: "Tani", value: 4 },
];

function MyRatingHeader({ width }) {
  const [open, setOpen] = useState(false);
  const [openLogoutPopUp, setOpenLogoutPopUp] = useState(false);
  // const [profileName, setProfileName] = useState();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const location = getFromLocal("country");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // const handleChange = (event) => {
  //   setProfileName(event.target.value);
  // };

  const togglePopUp = () => {
    setOpen(!open);
  };

  const handleOpenLogoutPopUp = () => {
    setOpenLogoutPopUp(true);
  };

  const handleCloseLogoutPopUp = () => {
    setOpenLogoutPopUp(false);
  };
  const logout = () => {
    dispatch(logoutHandler());
  };

  const handleEditAdmin = () => {
    // navigate("/admin/editAdmin");
  };

  const handleNavigate = (value) => {
    if (value === "Dashboard") navigate("/dashboard");
    else if (value === "My Rating") navigate("/rating");
    else if (value === "Help & Support") navigate("/helpAndSupport");
  };

  return (
    <AppBar position="static" style={{ width: width }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <div className="ratingHeadLogo">
            <img src={EmissionDecLogo} alt="EmissionDecLogo" />
          </div>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  handleNavigate(page);
                }}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "capitalize",
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <div className="myRatingHeader">
              <div className="myRatCity">
                <p>{location}</p>
                <img src={Location} alt="Location" className="cursorPointer" />
              </div>
              <div className="rightBorder"></div>
              <div>
                <img src={BellIcon} alt="BellIcon" className="cursorPointer" />
              </div>
              <div className="rightBorder"></div>
              <div
                style={{ display: "flex", gap: "12px", alignItems: "center" }}
              >
                <img
                  src={UserImg}
                  alt="ProfileIcon"
                  className="ratingProfilePic"
                />
                {/* <RatingSelectProfile
                  data={userName}
                  value={profileName}
                  onChange={handleChange}
                  defaultValue={userName[0].value}
                /> */}
                <p>{userDetails?.userName}</p>
                <img
                  className="cursorPointer"
                  src={ArrowDownWhite}
                  alt="ArrowDown"
                  onClick={togglePopUp}
                ></img>
              </div>
            </div>
            {open && (
              <div className="logOutContainer" ref={menuRef}>
                <div
                  className="cursorPointer"
                  style={{
                    padding: "10px 10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  <div className="logoutText" onClick={handleEditAdmin}>
                    <img src={ProfileIcon} alt="ProfileIcon" />
                    <p
                      className="cursorPointer"
                      style={{ color: "black", margin: "0" }}
                    >
                      {" "}
                      My Profile{" "}
                    </p>
                  </div>
                  <div className="logoutText" onClick={handleOpenLogoutPopUp}>
                    <img src={LogOutIcon} alt="LogOutIcon" />
                    <p
                      className="cursorPointer"
                      style={{ color: "black", margin: "0" }}
                    >
                      {" "}
                      Logout{" "}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <LogOutPopUp
              open={openLogoutPopUp}
              onClose={handleCloseLogoutPopUp}
              heading={"Are you sure you want to logout?"}
              statement={
                "You will be taken back to the login screen if you proceed. You can log back in to continue managing the platform"
              }
              acceptButtonText={"Yes, Logout"}
              rejectButtonText={"No, Go Back"}
              onclickAcceptButton={logout}
              onClickRejectButton={handleCloseLogoutPopUp}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default MyRatingHeader;
