import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./style.css";
import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import InitLocationPreview from "../initLocationPreview/InitLocationPreview";
import LeadAndSupportPreview from "../leadAndSupportPreview/LeadAndSupportPreview";
import ExternalPartnerPreview from "../externalPartnerPreview/ExternalPartnerPreview";
import NewCommentsBtn from "../../../button/newCommentsBtn/NewCommentsBtn";
import EditButton from "../../../button/editButton/EditButton";
import TimelinePreview from "../timelinePreview/TimelinePreview";
import Comments from "../../../chats/Comments";
import RoadmapComments from "../../../chats/RoadmapComments";

function Initiative1Preview({
  initiativeIndex,
  headName,
  value,
  priorityPillarId,
  declarationId,
}) {
  return (
    <div className="initiativeSections">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="typographyRow">
            <Typography>
              <div className="GenrlInfo">
                <h3>{headName}</h3>
              </div>
            </Typography>
            {/* <div className="editBtnRow">
              <NewCommentsBtn btnTitle={"2 New Issue"} />
              <EditButton />
            </div> */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="initiativePreCon">
            <div className="initiativePreRowCon">
              <div className="initiativePreRow">
                <div className="initiativePreCol1">
                  <h2>Initiative Description</h2>
                </div>
                <div className="initiativePreCol2">
                  <p>{value?.description?.data}</p>
                </div>
              </div>
              <div className="addCmtRow">
                <RoadmapComments
                  className="addCmtRow"
                  mapping="initiatives.description"
                  issueId={value?.description?.issueId}
                  priorityPillarId={priorityPillarId}
                  initiativeId={value?._id}
                  declarationId={declarationId}
                  secName={headName}
                  question="Initiative Description"
                  answer={value?.description?.data}
                />
              </div>
            </div>

            <div className="horizontalLine"></div>
            <InitLocationPreview
              value={value?.location}
              declarationId={declarationId}
              priorityPillarId={priorityPillarId}
              initiativeId={value?._id}
              issueId={value?.location?.issueId}
            />

            <div className="horizontalLine"></div>
            <LeadAndSupportPreview
              value={value?.leadAndSupport}
              declarationId={declarationId}
              priorityPillarId={priorityPillarId}
              initiativeId={value?._id}
              issueId={value?.leadAndSupport?.issueId}
            />

            <div className="horizontalLine"></div>
            <ExternalPartnerPreview
              value={value?.externalPartners}
              declarationId={declarationId}
              priorityPillarId={priorityPillarId}
              initiativeId={value?._id}
              issueId={value?.externalPartners?.issueId}
            />

            <div className="horizontalLine"></div>
            <TimelinePreview
              value={value?.timelines}
              declarationId={declarationId}
              priorityPillarId={priorityPillarId}
              initiativeId={value?._id}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Initiative1Preview;
