import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useLoginMutation } from "../../../features/auth/authApiSlice";
import { loginHandler } from "../../../features/auth/authActions";
import { setUserHandler } from "../../../features/user/userAction";

import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";


function ForgotPassFailed() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const identifier = searchParams.get("identifier");

    const forgotPassFunc = () => {
        navigate(`/ForgotPassword?identifier=${identifier}`)
      };

  return (
    <div className="container forgotPasSection">
      <div className="forgotPassCol1">
        <img className="forgotPass_topBg" src={SignUpTopBg} alt="" />
        <img className="forgotPass_bottomBg" src={SignUpBottomBg} alt="" />
        <img className="forgotPass_leftBg" src={SignUpLeftBg} alt="" />
        <div className="logoImgDiv">
          <img src={RegistrationLogo} alt="" />
        </div>
        <div className="welcomSection">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>
        <div className="copyRightSec">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>
      <div className="forgotPassCol2">
        <div className="resetPassFailedSec">
        <div className="gifDiv"></div>
        <h1>Password Reset Failed</h1>
        <p>Uh oh! There was an error resetting your password. Please try resetting it again</p>
        </div>
        <div className="cursorPointer resetPassBtn" onClick={forgotPassFunc}>
          <FormButton
             buttonName={"Retry Password Reset"}
             btnWidth={"80%"}
             backgroundColor={"#008BB2"}
             buttonText={"#ffffff"}
             btnFont={"14px"}
             btnPdd={"12px 20px"}
          />
        </div>

        {/* <div className="resetPassFailedSec">
        <div className="gifDiv"></div>
        <h1>Password Reset Successful</h1>
        <p>You have successfully reset your password. Please login again to <br/> access your account</p>
        </div>
        <div className="cursorPointer resetPassBtn">
          <FormButton
             buttonName={"Proceed To Login"}
             btnWidth={"81%"}
             backgroundColor={"#008BB2"}
             buttonText={"#ffffff"}
             btnFont={"14px"}
             btnPdd={"12px 20px"}
          />
        </div> */}

      </div>
    </div>
  );
}

export default ForgotPassFailed;
