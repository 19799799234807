import React from "react";

import "./style.css";

function MappingTable({ tableTitle, tableValue, reason }) {
  return (
    <div className="infoPart2ScopeCon">
      <div style={{ display: "flex", gap: "1rem" }}>
        <h1>{tableTitle}</h1>
        <p style={{ textTransform: "none" }}>{tableValue}</p>
      </div>
      {reason && (
        <div>
          <h1>Reason</h1>
          <p>Lorem ipsum dolor consequat at proin tincidunt.</p>
        </div>
      )}
    </div>
  );
}

export default MappingTable;
