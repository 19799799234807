import * as React from "react";
import { useState, useEffect } from "react";
import OrganizationTimeline from "./OrganizationTimeline";
import AdvisoryBoardTimeline from "./AdvisoryBoardTimeline";
import ConsultantTimeline from "./ConsultantTimeline";
import AuditorTimeline from "./AuditorTimeline";

export default function BasicTimeline({ currentStep, setCurrentStep, userType }) {

console.log(userType, 'userType');
  return (
    <>
     {userType === "organization" && (
        <OrganizationTimeline
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}

      {userType === "advisoryBoard" && (
        <AdvisoryBoardTimeline
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}

      {userType === "consultant" && (
        <ConsultantTimeline
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}

      {userType === "auditor" && (
        <AuditorTimeline
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
    </>
  );
}
