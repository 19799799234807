import React from "react";

import "./style.css";

import ChatBoxIcon from "../../../assets/icons/ChatBoxBtnIcon.svg";

function ViewCommentsBtn({ onClick }) {
  return (
    <div className="viewCommentBtnCon cursorPointer" onClick={onClick}>
      <img src={ChatBoxIcon} alt="ChatBoxIcon" />
      <p>View Comments</p>
    </div>
  );
}

export default ViewCommentsBtn;
