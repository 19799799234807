import React, { useEffect, useRef, useState } from "react";

import BarChart from "../../charts/BarChart";
import RatingSelectProfile from "../../ratingComponents/ratingSelectProfile/RatingSelectProfile";

import "./style.css";

const BIData = [
  { name: "All B&Is", value: "" },
  { name: "Apple", value: 1 },
  { name: "Google Inc.", value: 2 },
  { name: "Microsoft Inc.", value: 3 },
  { name: "Atlassian", value: 4 },
];

function TotalEmissionDeclareGraph({ totalEmissionsData }) {
  const [BIName, setBIName] = useState();

  const total = totalEmissionsData?.data?.reduce(
    (acc, curr) => acc + curr?.totalEmissions,
    0
  );

  const handleChange = (event) => {
    setBIName(event.target.value);
  };

  return (
    <div className="chartLayoutSection">
      <div className="chartLayoutTextSec">
        <div className="totalEmissDeclareRow">
          <div className="totalEmissDeclareCol">
            <span>Total Emissions Declared</span>
            <h1>{total}</h1>
            <p>mtCO2E</p>
          </div>
          <RatingSelectProfile
            data={BIData}
            value={BIName}
            onChange={handleChange}
            defaultValue={BIData[0].value}
          />
        </div>
      </div>
      <div className="" style={{ width: "30vw" }}>
        <BarChart totalEmissionsData={totalEmissionsData} />
      </div>
    </div>
  );
}

export default TotalEmissionDeclareGraph;
