import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import "./style.css";

import IndicatopnIcon from "../../assets/icons/indicationIcon.svg";

function IndicationMessage() {
    const positionRef = React.useRef({
        x: 0,
        y: 0,
      });
      const popperRef = React.useRef(null);
      const areaRef = React.useRef(null);
    
      const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };
    
        if (popperRef.current != null) {
          popperRef.current.update();
        }
      };

      const basedMessage = (
        <div className='msgTitleCon'>
            <p>HCFC 1:</p>
            <ul>
                <li>1 mt = 13 mtCO2e</li>
            </ul>
            <p>HCFC 1:</p>
            <ul>
                <li>1 mt = 13 mtCO2e</li>
            </ul>
        </div>
    );
    
  return (
    <Tooltip
        title={basedMessage}
        placement="right"
        arrow
        followCursor
        PopperProps={{
        popperRef,
        anchorEl: {
            getBoundingClientRect: () => {
            return new DOMRect(
                positionRef.current.x,
                areaRef.current.getBoundingClientRect().y,
                20,
                -15,
            );
            },
        },
        }}
        componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#FFF",
                color: "#1D1D1B",
                boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.08)",
                width: "172px",
                height: "auto",
            }
        },
        arrow:{
            sx: {
                color: "#FFF",
                height: "24px",
                width: "25px",
                marginTop: "-35px",
                }
            }
          }}
    >
        <Box
            ref={areaRef}
            onMouseMove={handleMouseMove}
            sx={{
                // bgcolor: 'primary.main',
                // color: 'primary.contrastText',
                // p: 2,
                width: 'maxWidth',
            }}
        >
            <img src={IndicatopnIcon} alt='IndicatopnIcon' className='indicationIcon' />
        </Box>
    </Tooltip>
  )
}

export default IndicationMessage;