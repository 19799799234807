import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { getUserFromLocal } from "../../../utils/storage";
import moment from "moment";

import "./style.css";

import SelectInputBox, {
  SelectCountryInputBox,
} from "../../InputBox/selectBox/SelectInputBox";
import InputBox from "../../InputBox/InputBox";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import Calendar from "../../calendar/Calendar";

const countryStateMasterData = {
  All: [],
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};
const userType = [
  { value: "All", name: "All" },
  { value: "organization", name: "B&I Organization" },
  { value: "advisoryBoard", name: "Advisory Board" },
  { value: "consultant", name: "Consultant" },
  { value: "auditor", name: "Auditor" },
];
const userStatus = [
  { value: "All", name: "All" },
  { value: "active", name: "Active" },
  { value: "inactive", name: "Inactive" },
];

function UserFilterForm({
  queryFilterState,
  handleSearchChange,
  onChangeUserType,
  onChangeCountry,
  onChangeStatus,
  onChangeRegistrationDate,
}) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      userType: "",
      country: "",
      userStatus: "",
      joiningDate: "",
    },
    // enableReinitialize: true,
    validationSchema: "",

    onSubmit: (values) => {},
  });

  const handleDateRangeChange = (selectedDates) => {
    console.log("selectedDates", selectedDates);
    onChangeRegistrationDate(selectedDates);
  };

  const handleRegistrationDate = (event) => {
    const formattedDate = moment(event).format("DD-MM-YYYY");
    formik.setFieldValue("generalInfo.reportingFrom", formattedDate);
  };

  return (
    <form className="adminForm">
      <div className="adminFormCol1">
        <div className="adminSearchBox">
          <img src={SearchIcon} alt="searchIcon" className="searchIcon" />
          <InputBox
            placeholder="Search"
            onChange={handleSearchChange}
            value={queryFilterState?.identifier}
          />
        </div>
      </div>
      <div className="adminFormCol2">
        <div className="adminInputBox">
          <SelectInputBox
            data={userType}
            selectPlaceholder={"Select user Type"}
            value={queryFilterState?.userType}
            onChange={onChangeUserType}
          />
        </div>
        <div className="adminInputBox">
          <SelectCountryInputBox
            data={countryStateMasterData}
            selectPlaceholder={"Select country"}
            value={queryFilterState?.country}
            onChange={onChangeCountry}
          />
        </div>
        <div className="adminInputBox">
          <SelectInputBox
            data={userStatus}
            selectPlaceholder={"Select status"}
            value={queryFilterState?.status}
            onChange={onChangeStatus}
          />
        </div>
        <div className="adminDateInput">
          <Calendar
            // headName={"Registration Date"}
            // name="generalInfo.registrationDate"
            // value={formik.values.generalInfo?.registrationDate}
            // onBlur={formik.handleBlur}
            startDate1={queryFilterState?.startDate}
            endDate1={queryFilterState?.endDate}
            onChange={handleDateRangeChange}

            // error={
            //   !!formik.touched.generalInfo?.registrationDate &&
            //   formik.errors.generalInfo?.registrationDate
            // }
          />
        </div>
      </div>
    </form>
  );
}

export default UserFilterForm;
