import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextArea from "../../../InputBox/textAreaBox/TextArea";
import EmisCalendar from "../../emisCalendar/EmisCalendar";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import InputBox from "../../../InputBox/InputBox";

function GenInformation({ formik, activeSection, setActiveSection }) {
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "generalInfoSection" : false);
  };
  return (
    <div className="emisSectionGap" id="generalInfoSection">
      <Accordion
        expanded={activeSection === "generalInfoSection"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>General Information</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="anyFacilitySec">
            <h1>
              Have any facilities, operations and/or emissions sources been
              excuded from this inventory? If yes, please specify
            </h1>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.generalInformation?.facilitiesExcluded
                  ?.isExcluded
              }
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "generalInformation.facilitiesExcluded.isExcluded",
                  value
                );
                formik.setFieldValue(
                  "generalInformation.facilitiesExcluded.exclusionDetails",
                  ""
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
            {formik?.values?.generalInformation?.facilitiesExcluded
              ?.isExcluded && (
              <div className="specifyRow specifyRowArea">
                <TextArea
                  headName={"Specify Here"}
                  type="text"
                  placeholder="Tell us what has been excluded"
                  name={`generalInformation.facilitiesExcluded.exclusionDetails`}
                  value={
                    formik?.values?.generalInformation?.facilitiesExcluded
                      ?.exclusionDetails
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik?.touched?.generalInformation?.facilitiesExcluded
                      ?.exclusionDetails &&
                    formik.errors.generalInformation?.facilitiesExcluded
                      ?.exclusionDetails
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            )}
          </div>

          <div className="genReportingContainer">
            <div className="reportingTimeSec">
              <h1>Reporting period covered by this inventory</h1>
              <div className="reportingCalendar">
                <div className="">
                  <EmisCalendar
                    startDateLabel="From"
                    endDateLabel="To"
                    startDateName="generalInformation.reportingPeriod.from"
                    endDateName="generalInformation.reportingPeriod.to"
                    startDateValue={
                      formik.values.generalInformation?.reportingPeriod?.from
                    }
                    endDateValue={
                      formik.values.generalInformation?.reportingPeriod?.to
                    }
                    formik={formik}
                    startDateError={
                      !!formik.touched.generalInformation?.reportingPeriod
                        ?.from &&
                      formik.errors.generalInformation?.reportingPeriod?.from
                    }
                    endDateError={
                      !!formik.touched.generalInformation?.reportingPeriod
                        ?.to &&
                      formik.errors.generalInformation?.reportingPeriod?.to
                    }
                    errorIcon={WarningIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GenInformation;
