import { useState } from "react";
import EmisTitleSection from "../../../components/emissionComponents/emisTitleSection/EmisTitleSection";
import "./style.css";
import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import AuditGenInfo from "../../../components/emissionComponents/step2Components/auditGenInfo/AuditGenInfo";
import AuditOrgBoundaries from "../../../components/emissionComponents/step2Components/auditOrgBoundaries/AuditOrgBoundaries";
import AuditOperaBoundaries from "../../../components/emissionComponents/step2Components/auditOperaBoundaries/AuditOperaBoundaries";
import AuditInfoOnEmissionPart1 from "../../../components/emissionComponents/step2Components/auditInfoOnEmissionPart1/AuditInfoOnEmissionPart1";
import AuditMethoFactors from "../../../components/emissionComponents/step2Components/auditMethoFactors/AuditMethoFactors";
import AuditInfoOnEmissionPart2 from "../../../components/emissionComponents/step2Components/auditInfoOnEmissionPart2/AuditInfoOnEmissionPart2";
import AuditAdditionalInfo from "../../../components/emissionComponents/step2Components/auditAdditionalInfo/AuditAdditionalInfo";
import AuditInfoOffset from "../../../components/emissionComponents/step2Components/auditInfoOffset/AuditInfoOffset";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import PageLoader from "../../../components/Loader/PageLoader";
import FormButton from "../../../components/button/FormButton";
import { useNavigate } from "react-router-dom";

function EmissionStep2Preview() {
  const [activeSection, setActiveSection] = useState("generalInfoSection");
  const navigate = useNavigate();

  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({}, { refetchOnMountOrArgChange: true });

  return (
    <div className="container emisStep2Container">
      {(findOneIsLoading || findOneIsFetching) && <PageLoader />}
      <div className="emission2TitleDiv">
        <div className="">
          <img src={EmissionDecLogo} alt="" />
        </div>
        <div className="emis2CancelBtn">
          <FormButton
            buttonName={"Back To My Rating"}
            buttonText={"#ffffff"}
            backgroundColor={"transparent"}
            btnFont={"14px"}
            btnPdd={"12px 20px"}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      </div>

      <div className="emis2InnerContainer">
        <div className="emission2SecCol1">
          <div className="emis2NowNextStep">
            <div className="emis2leftTitle">
              <h1>Emission Declaration</h1>
              <div className="nextStep">
                <p>Next Step:</p>
                <h3>Assure/Audit Emissions</h3>
              </div>
            </div>
            <EmisTitleSection setActiveSection={setActiveSection} />
          </div>
        </div>

        <div className="emission2SecCol2">
          <div className="Emiss2CodewaveTitleDiv">
            <div className="emis2CodeCol1">
              <div className="emiss2InventoryYear">
                <div className="emis2CodwaveLogo">
                  <img src={CodeLogo} />
                  <h3>Codewave’s Greenhouse Gas Emissions Inventory</h3>
                </div>
                <div>
                  <p>Inventory Year: 2021</p>
                </div>
              </div>
            </div>
          </div>

          <div class="emiss2InnerMainBlock">
            <AuditGenInfo
              findOneData={findOneData}
              sectionId="generalInfoSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditOrgBoundaries
              findOneData={findOneData}
              sectionId="orgBoundariesSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditOperaBoundaries
              findOneData={findOneData}
              sectionId="operaBoundariesSection"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditInfoOnEmissionPart1
              findOneData={findOneData}
              sectionId="infoEmission1"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditMethoFactors
              findOneData={findOneData}
              sectionId="methodologyEmission"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditInfoOnEmissionPart2
              findOneData={findOneData}
              sectionId="infoEmission2"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditAdditionalInfo
              findOneData={findOneData}
              sectionId="additionalInfo"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
            <AuditInfoOffset
              findOneData={findOneData}
              sectionId="infoOnOffsets"
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </div>
        </div>
      </div>

      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default EmissionStep2Preview;
