import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../src/styles/Style.css";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
import "../src/assets/fonts/woodmanFont/Woodman-HeavyRounded.otf";
import "../src/assets/fonts/uniformFont/Uniform.ttf";
import { Provider } from "react-redux";
import store from "./app/store";
import setupLocatorUI from "@locator/runtime";
import { SnackbarProvider } from "notistack";
import { SNACKBAR_DURATION } from "./utils/defaults";
import {
  ErrorMessage,
  InfoMessage,
  SuccessMessage,
  WarningMessage,
} from "./components/snackbar/snackbar";
import { SnackbarUtilsConfigurator } from "./features/snackbar/useSnackbar";

const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
  setupLocatorUI();
}

root.render(
  <Provider store={store}>
    <SnackbarProvider
      autoHideDuration={SNACKBAR_DURATION}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      Components={{
        success: SuccessMessage,
        error: ErrorMessage,
        warning: WarningMessage,
        info: InfoMessage,
      }}
      maxSnack={3}
    >
      <SnackbarUtilsConfigurator />
      <App />
    </SnackbarProvider>
  </Provider>
);

reportWebVitals();
