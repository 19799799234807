import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useVerifyForgotPasswordMutation } from "../../../features/auth/authApiSlice";
import { loginHandler } from "../../../features/auth/authActions";
import { setUserHandler } from "../../../features/user/userAction";

import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";


const forgotPassword = Yup.object({
  // email: Yup.string().required("Required").trim().lowercase()
  //   .min(5, "Enter valid email id")
  //   .max(50, "Name must be at most 50 characters")
  //   .test("valid-email", "Incorrect Email ID. Please check your email ID", (value) => {
  //   if (!value) return false;
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  //   return emailRegex.test(value);
  //   }),
  password: Yup.string()
    .min(8, "Password must include one capital alphabet and 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
  retypePassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(8, "Password must include one capital alphabet and 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
});



function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isconfirmPassword, setIsconfirmPassword] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const identifier = searchParams.get("identifier");
  
  const [verifyForgotPassword, 
    { isSuccess: verifyForgotPasswordIsSuccess }
  ] = useVerifyForgotPasswordMutation();


  const formik = useFormik({
    initialValues : {
      email : "",
      password : "",
      retypePassword : "",
      identifier: identifier,
    },
    enableReinitialize: true,
    validationSchema: forgotPassword,
    onSubmit: (values) => {
      const body = {
        email : values.email,
        password : values.password,
        identifier: values.identifier,
      };
      verifyForgotPassword(body)
      .unwrap()
      .then(()=>{
        dispatch(showSuccess({ message: "Verified Successfully" }));
        navigate("/ForgotPassSuccess")
        console.log(body);
      })
      .catch((e)=>{
        dispatch(
          showError({
            message: e?.data?.message,
          })
        )
        navigate(`/ForgotPassFailed?identifier=${identifier}`)
      })

    },
  });

 
  const passwordVisibleFunc = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const  confirmPassFunc = () => {
    setIsconfirmPassword(!isconfirmPassword);
  };


  return (
    <div className="container forgotPasSection">
      <div className="forgotPassCol1">
        <img className="forgotPass_topBg" src={SignUpTopBg} alt="" />
        <img className="forgotPass_bottomBg" src={SignUpBottomBg} alt="" />
        <img className="forgotPass_leftBg" src={SignUpLeftBg} alt="" />
        <div className="logoImgDiv">
          <img src={RegistrationLogo} alt="" />
        </div>
        <div className="welcomSection">
          <h1>Let’s bring an impact! </h1>
          <h2>Welcome to SeeRating!</h2>
          <p className="">
            We are looking for business and industry that have taken action to
            declare their GHG emissions, set science-based net zero targets and
            development and implemented practical action plans to achieve these
            goals. Please fill in further details about yourself and the
            organization that you are representing.
          </p>
        </div>
        <div className="copyRightSec">
          <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        </div>
      </div>
      <div className="forgotPassCol2">
        <h1>Forgot Password?</h1>
        <p>Reset your password to a more secure one today!</p>
        <form className="formContainer" noValidate onSubmit={formik.handleSubmit}>
          {/* <div className="emailIdField">
            <InputBox
              headName={"Email ID"}
              placeholder="example@mail.com"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.email && formik.errors.email}
              required
            />
          </div> */}
          <div className="passwordField">
            <div className="relativePosition">
              <InputBox
                headName={"New Password"}
                placeholder="Enter Password"
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.password && formik.errors.password}
                required
              />
              <div className="login_Eyeicon_position">
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                  onClick={() => passwordVisibleFunc()}
                  className="login_Eye_icon eye_color"
                />
              </div>
            </div>
          </div>
          <div className="signIn_password">
            <div className="relativePosition">
              <InputBox
                headName={"Retype Password"}
                placeholder="Retype Password"
                type={isconfirmPassword ? "text" : "password"}
                name="retypePassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.retypePassword && formik.errors.retypePassword}
                required
              />
              <div className="login_Eyeicon_position">
                <FontAwesomeIcon
                  icon={isconfirmPassword ? faEye : faEyeSlash}
                  onClick={() => confirmPassFunc()}
                  className="login_Eye_icon eye_color"
                />
              </div>
            </div>
          </div>
          <div className="passRulSec">
            <p>Your password must contain:</p>
           <ul>
            <li>A minimum of 8 characters</li>
            <li>One lowercase alphabet</li>
            <li>One uppercase alphabet</li>
            <li>One special character</li>
           </ul>
          </div>

          <div className="cursorPointer">
            <FormButton
              buttonName={"Confirm Password"}
              btnWidth={"100%"}
              backgroundColor={"#008BB2"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
