import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import PriorityPillarPreview from "../../../components/emissionComponents/step4/priorityPillarPreview/PriorityPillarPreview";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import PageLoader from "../../../components/Loader/PageLoader";
import FormButton from "../../../components/button/FormButton";

function EmissionStep4Preview() {
  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({}, { refetchOnMountOrArgChange: true });

  return (
    <div className="emisPreviewSection">
      {(findOneIsLoading || findOneIsFetching) && <PageLoader />}
      <div className="emisPreTitleHeader">
        <div className="">
          <img src={EmissionDecLogo} alt="EmissionDecLogo" />
        </div>
        <div className="emis2CancelBtn">
          <FormButton
            buttonName={"Back To My Rating"}
            buttonText={"#ffffff"}
            backgroundColor={"transparent"}
            btnFont={"14px"}
            btnPdd={"12px 20px"}
          />
        </div>
      </div>

      <div className="emisPreInnerCon">
        <div className="emisPreSecCol1">
          <div className="roadmapTitle">
            <h1>Roadmap To Net Zero</h1>
            <div
              className="stepsNext"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <p> Frequency: </p>
              <span>Quarterly </span>
            </div>
          </div>
          <EmisChecklistSection />
          <EmisGoodPractice />
        </div>
        <div className="emisPreSecCol2">
          <div className="net4YearRow">
            <div className="stepsCodewaveLogo">
              <img src={CodeLogo} />
              <h3>Codewave’s Roadmap To NetZero Emissions</h3>
            </div>
            <div className="preNetZeroValue">
              <h1>Net Zero Target: 2030</h1>
            </div>
          </div>

          {findOneData?.data?.declaration?.actionPlan?.priorityPillars?.map(
            (item, priorityPillarIndex) => (
              <PriorityPillarPreview
                key={priorityPillarIndex}
                priorityPillarIndex={priorityPillarIndex}
                headName={`Priority Pillar ${priorityPillarIndex + 1}`}
                value={item}
                declarationId={findOneData?.data?.declaration?._id}
              />
            )
          )}
          <div className="horizontalLine"></div>
        </div>
      </div>

      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </div>
  );
}

export default EmissionStep4Preview;
