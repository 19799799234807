import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { getUserFromLocal } from "../../../utils/storage";
import moment from "moment";

import SelectInputBox, {
  SelectCountryInputBox,
} from "../../InputBox/selectBox/SelectInputBox";
import InputBox from "../../InputBox/InputBox";

import "./style.css";

import SearchIcon from "../../../assets/icons/SearchIcon.svg";

const countryStateMasterData = {
  All: [],
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};
const userType = [
  { value: "All", name: "All" },
  { value: "organization", name: "B&I Organization" },
  { value: "advisoryBoard", name: "Advisory Board" },
  { value: "consultant", name: "Consultant" },
  { value: "auditor", name: "Auditor" },
];

function UserReqForm({
  queryFilterState,
  handleSearchChange,
  onChangeUserType,
  onChangeCountry,
  onChangeStatus,
}) {
  const navigate = useNavigate();

  const formik = useFormik(
    {
      initialValues: {
        userType: "",
        country: "",
      },
    }
    //     enableReinitialize: true,
    //     validationSchema: '',

    //     onSubmit: (values) => {
    //   }
  );

  return (
    <form className="userReqForm">
      <div className="userReqFormCol1">
        <div className="userSearchBox">
          <img src={SearchIcon} alt="searchIcon" className="userSearchIcon" />
          <InputBox
            placeholder="Search"
            onChange={handleSearchChange}
            value={queryFilterState?.identifier}
          />
        </div>
      </div>
      <div className="userReqFormCol2">
        <div className="userReqInputBox">
          <SelectInputBox
            data={userType}
            selectPlaceholder={"User Type"}
            value={queryFilterState?.userType}
            onChange={onChangeUserType}
          />
        </div>
        <div className="userReqInputBox">
          <SelectCountryInputBox
            data={countryStateMasterData}
            selectPlaceholder={"Country"}
            value={queryFilterState?.country}
            onChange={onChangeCountry}
          />
        </div>
      </div>
    </form>
  );
}

export default UserReqForm;
