import React from "react";
import OrganizationTimeline from "./OrganizationTimeline";
import { Add } from "@mui/icons-material";
import AddConsultantAndAuditor2 from "./AddConsultantAndAuditor2";

const OrganizationDashboard2 = ({ status }) => {
  let timeline = null;
  if (
    status?.data?.organization?.organizationStatus === "active" &&
    status?.data?.consultant?.consultantStatus === "active" &&
    status?.data?.auditor?.auditorStatus === "active"
  ) {
    timeline = <OrganizationTimeline status={status} />;
  } else {
    timeline = <AddConsultantAndAuditor2 status={status} />;
  }
  return <div>{timeline}</div>;
};

export default OrganizationDashboard2;
