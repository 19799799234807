import react, { useEffect, useState } from "react";

import "./style.css";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import ProfileViewSection from "../../../components/adminComponents/profileViewSection/ProfileViewSection";
import ProfileViewDetails from "../../../components/adminComponents/profileViewDetails/ProfileViewDetails";
import { useGetUserQuery } from "../../../features/admin/adminApiSlice";
import { useSearchParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../../components/Loader/PageLoader";

function ProfileView({}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const userType = searchParams.get("userType"); 
  const userId = searchParams.get("userId");
  const hideRequestsLink= searchParams.get("hideRequestsLink");
  
  console.log("🚀 ~ file: ProfileView.js:24 ~ ProfileView ~ hideRequestsLink:", hideRequestsLink)


  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const {
    data: userList,
    isLoading: userListIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: userListIsError,
    isFetching: userListIsFetching,
  } = useGetUserQuery({
    userId: userId,
    userType: userType,
  });

  useEffect(() => {
    if (userListIsError) {
      if (userListError?.message) {
        dispatch(showError({ message: userListError.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
    if (userListIsSuccess) {
      setUserData(userList);
    }
  }, [userList, userListIsSuccess, userListError, dispatch]);
  console.log("🚀 ~ ProfileView ~ userType:..", userType)

  return (
    <div className="profileViewContainer">
      {(userListIsLoading || userListIsFetching) && <PageLoader />}
      <div className="profileViewCol1">
        <AdminSideMenu />
      </div>
      <div className="profileViewCol2">
        <AdminDashHeader />
        <div className="userProDetails">
          <ProfileViewSection userType={userType} userId={userId} userData={userData}  hideRequestsLink={hideRequestsLink}/>
          <ProfileViewDetails userData={userData} userType={userType} userId={userId}/>
        </div>
      </div>
    </div>
  );
}

export default ProfileView;
