import React from "react";

import "./style.css";

function ViewAllDetailsBtn() {
  return (
    <button className="viewAllDetailCon cursorPointer">
      <p>View All Details</p>
    </button>
  );
}

export default ViewAllDetailsBtn;
