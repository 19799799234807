
import React from 'react';
import '../styles/Style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';

import scrollToRef from '../utils/scrollUtils';

import FooterLogo from '../assets/logo/FootLogo.svg';
import FooterLeftBG from '../assets/footerLeftBG.png';
import FooterRightBG from '../assets/footerRightBG.png';


function Footer({aboutSectionRef, whoIsSectionRef, ourProcessSectionRef, chairmanSectionRef, contactSectionRef}) {
  return (
    <div className='footTop_Sec'>
    <footer className="footer-area">
      <div className="foot-container">
        <div className="footer-main">
          <div className="row">
          <div className=" logo_column">
              <div className="footer-item">
                <img className='footer_logoImg' src={FooterLogo} alt="" />
              </div>
              <div className="social-media">
            <ul>
              <li>
                <a>
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </a>
              </li>
              <li>
                <a>
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </li>
              <li>
                <a>
                  <FontAwesomeIcon icon={faLinkedin} size="lg" />
                </a>
              </li>
              <li>
                <a>
                  <FontAwesomeIcon icon={faYoutube} size="lg" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer_rightCol'>
        <img className='foot_leftbg' src={FooterRightBG} alt="" />

           <div className="child_div">
              <div className="footer-item foot_ul">
                <ul>
                  <li className='foot_li_title'>Navigation:</li>
                  <li className='FooterMenu' onClick={() => scrollToRef(aboutSectionRef)} >About Us</li>
                  <li className='FooterMenu' onClick={() => scrollToRef(ourProcessSectionRef)} >Our Process</li>
                  <li className='FooterMenu' onClick={() => scrollToRef(whoIsSectionRef)} >Who Is It For?</li>
                  <li className='FooterMenu' onClick={() => scrollToRef(contactSectionRef)}>Message</li>
                </ul>
              </div>
            </div>
            <div className="foot_location">
              <div className="footer-item footer-middle foot_ul ">
                <ul>
                  <li className='foot_li_title'>Location:</li>
                  <p className='footerAddress'>27GG+H3V The Sustainable City Dubai, UAE</p>
                  
                </ul>
              </div>
            </div>
            <img className='foot_rightbg' src={FooterLeftBG} alt="" />
         </div>
       </div>
     </div>
    </div>
   </footer>
    <div className='copyRight'>
      <div className='copyRight_col1'>
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
      </div>
      <div className='copyRight_col2'>
        <p>Privacy Policy</p>
      </div>
    </div>
 </div>
    
  );
}

export default Footer;