import React from "react";

import "./style.css";
import PdfDocuments, {
  PdfDocuments2,
} from "../../../pdfDocuments/PdfDocuments";

function ActionPlanTemplate() {
  return (
    <div className="actionPlanCon">
      <div className="actionPlanCol1">
        <p>
          Refer to the “Action Plan Template” to develop your action plan and
          the “Target Information” document to verify your plan accordingly
        </p>
      </div>
      <div className="actionPlanCol2">
        <PdfDocuments2
          fileName={"Action Plan Template"}
          downloadLink="https://see-rating.s3.amazonaws.com/Documents/SEE+Rating+-+Step+3+-+action+plan_template+(2).xlsx"
        />
        <PdfDocuments2
          fileName={"Target Information"}
          downloadLink="https://see-rating.s3.amazonaws.com/Documents/SEE+Rating+-+Step+3+-+TARGET_template+%281%29.xlsx"
        />
      </div>
    </div>
  );
}

export default ActionPlanTemplate;
