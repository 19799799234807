import React from 'react';

import "./style.css";
import PdfUpload from '../../pdfUpload/PdfUpload';

function MyProConsFreeOrg() {

  return (
    <div className='myProConOrgCon'>
        <h1>Organization Details</h1>
        <div className='myProConOrgInner'>
            <div className='myProConOrgSec'>
                <div className='myProConOrgData'>
                    <span>Name of Consulting Organization</span>
                    <p>Codewave Technologies</p>
                </div>
                <div className='myProLogSecData'>
                    <span>Organization Type</span>
                    <p>Organization or Firm</p>
                </div>
            </div>

            <div className='myProConDocuments'>
                <span className='myProConDocTitle'>Documents</span>
                <div className='proConPdfSec'>
                    <div className='proConfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proConfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proConfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proConfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                    <div className='proConfilePdfRow'>
                        <PdfUpload faXmarkDisplay={"none"} />
                    </div>
                </div>
            </div>
        </div>
        

    </div>
  )
}

export default MyProConsFreeOrg;