import react, { useEffect, useReducer, useState } from "react";
import { useFormik } from "formik";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import AdminOrgTitle from "../../../components/adminComponents/adminOrgTitle/AdminOrgTitle";
import AdminBasicDetails from "../../../components/adminComponents/addAdminData/AdminBasicDetails";

import "./style.css";
import AdminWorkInfo from "../../../components/adminComponents/addAdminData/AdminWorkInfo";
import AdminLogCreds from "../../../components/adminComponents/addAdminData/AdminLogCreds";
import FormButton from "../../../components/button/FormButton";
import * as Yup from "yup";
import { useAddAdminMutation } from "../../../features/admin/adminApiSlice";
import { cleanObject } from "../../../utils/helper";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const COUNTRIES = ["UAE", "India", "Saudi Arabia"];
const STATES = [
  "Abu Dhabi",
  "Ajman",
  "Dubai",
  "Fujairah",
  "Ras Al Khaimah",
  "Sharjah",
  "Umm Al Quwain",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "'Asir",
  "Al Bahah",
  "Al Jawf",
  "Al Madinah",
  "Al Qasim",
  "Eastern Province",
  "Ha'il",
  "Jazan",
  "Mecca",
  "Najran",
  "Northern Borders",
  "Riyadh",
  "Tabuk",
];

const addressSchema = Yup.object().shape({
  fullAddress: Yup.string()
    .required("Full address is required.")
    .trim()
    .min(5, "Full address must be at least 5 characters long.")
    .max(200, "Full address cannot exceed 200 characters."),
  city: Yup.string()
    .required("City is required.")
    .trim()
    .min(2, "City must be at least 2 characters long.")
    .max(100, "City cannot exceed 100 characters."),
  country: Yup.string()
    .required("Country is required.")
    .trim()
    .oneOf(COUNTRIES, "Invalid country."),
  state: Yup.string()
    .required("State is required.")
    .trim()
    .oneOf(STATES, "Invalid state."),
  zipCode: Yup.string()
    .trim()
    .max(10, "Zip code cannot exceed 10 characters.")
    .min(5, "Zip code must be at least 5 characters long.")
    .matches(/^(?!0{5,})\d{5,10}$/, "Invalid zip code."), // Ensure zip code is not all zeros
});

const createAdminSchema = Yup.object().shape({
  profileImage: Yup.string()
    .trim()
    .min(10, "Profile image must be at least 10 characters long.")
    .max(500, "Profile image cannot exceed 500 characters."),
  title: Yup.string()
    .required("Title is required.")
    .trim()
    .oneOf(["Mr", "Mrs", "Ms"], 'Title must be "Mr", "Mrs", or "Ms".'),
  firstName: Yup.string()
    .required("First name is required.")
    .trim()
    .min(1, "First name must be at least 1 character long.")
    .max(70, "First name cannot exceed 70 characters."),
  lastName: Yup.string()
    .required("Last name is required.")
    .trim()
    .min(1, "Last name must be at least 1 character long.")
    .max(70, "Last name cannot exceed 70 characters."),
  email: Yup.string()
    .required("Email is required.")
    .trim()
    .email("Invalid email address."),
  phoneNo: Yup.string()
    .trim()
    .max(20, "Phone number cannot exceed 20 characters.")
    .min(5, "Phone number must be at least 5 characters long.")
    .matches(/^[0-9]+$/, { message: "Phone number must be numeric" })
    .nullable(true), // Allow null values
  phoneNoCountryCode: Yup.string()
    .trim()
    .max(10, "Phone number country code cannot exceed 10 characters.")
    .min(2, "Phone number country code must be at least 2 characters long.")
    .nullable(true), // Allow null values
  mobileNo: Yup.string()
    .required("Required")
    .trim()
    .max(20, "Mobile number cannot exceed 20 characters.")
    .min(5, "Mobile number must be at least 5 characters long.")
    .matches(/^[0-9]+$/, { message: "Mobile number must be numeric" }),
  mobileNoCountryCode: Yup.string()
    .required("Required")
    .trim()
    .max(10, "Mobile number country code cannot exceed 10 characters.")
    .min(2, "Mobile number country code must be at least 2 characters long."),
  address: addressSchema.required("Address is required."),
  workInfo: Yup.object()
    .shape({
      organizationName: Yup.string()
        .required("Organization name is required.")
        .trim()
        .min(2, "Organization name must be at least 2 characters long.")
        .max(100, "Organization name cannot exceed 100 characters."),
      designation: Yup.string()
        .required("Designation is required.")
        .trim()
        .min(2, "Designation must be at least 2 characters long.")
        .max(100, "Designation cannot exceed 100 characters."),
      employeeId: Yup.string()
        .trim()
        .min(1, "Employee ID must be at least 1 character long.")
        .max(100, "Employee ID cannot exceed 100 characters."),
      officeAddress: addressSchema.required("Office address is required."),
    })
    .required("Work information is required."),
  password: Yup.string()
    .trim()
    .min(5, "Password must be at least 5 characters long.")
    .max(30, "Password cannot exceed 30 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
    )
    .required("Password is required."),
  retypePassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Retype password is required."),
});

function AddAdmin() {
  const [addAdmin, { isSuccess: addAdminIsSuccess }] = useAddAdminMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      workInfo: {
        organizationName: "",
        designation: "",
        employeeId: "",
        officeAddress: {
          fullAddress: "",
          city: "",
          country: "",
          state: "",
          zipCode: "",
        },
      },
      profileImage: "",
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      phoneNoCountryCode: "",
      mobileNo: "",
      mobileNoCountryCode: "",
      address: {
        fullAddress: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
      },
      password: "",
      retypePassword: "",
    },
    enableReinitialize: true,
    validationSchema: createAdminSchema,
    onSubmit: (values) => {
      const data = structuredClone(values);
      cleanObject(data);
      addAdmin(data)
        .unwrap()
        .then(() => {
          dispatch(
            showSuccess({
              message: "Admin added successfully",
            })
          );
          navigate("/adminDashboard");
        })
        .catch(() => {
          dispatch(
            showError({
              message: "Something went wrong !",
            })
          );
        });
    },
  });

  console.log("FormikAddAdminValues", formik?.values);
  console.log("FormikAddAdminErrors", formik?.errors);

  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AdminOrgTitle />
          <form onSubmit={formik.handleSubmit} noValidate>
            <AdminBasicDetails formik={formik} />
            <AdminWorkInfo formik={formik} />
            <AdminLogCreds formik={formik} />
            <div className="form-row saveBtnRow">
              <div className=" cursorPointer">
                <FormButton
                  type="button"
                  buttonName={"Cancel"}
                  buttonText={"#1D1D1B"}
                  backgroundColor={"transparent"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Save & Add"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddAdmin;
