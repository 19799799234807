import React from 'react';
import './style.css';

function RegSubmitButton({buttonName }) {


  return (
    <button type="submit" className="regSubmitBtn" >
      <div className='subBtnText'>{buttonName}</div>
    </button>
  );
}

export default RegSubmitButton;