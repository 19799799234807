import { createBrowserRouter, Navigate } from "react-router-dom";
import React from "react";

import Home from "../pages/home/Home";
import SignUp from "../pages/auth/signUp/SignUp";
import Login from "../pages/auth/login/Login";
import BasicDetails from "../pages/registration/orgRegistration/basicDetails/BasicDetails";
import ActionLayout from "../layouts/ActionLayout";
import OrganizationDetails from "../pages/registration/orgRegistration/orgDetails/OrganizationDetails";
import OtherDetails from "../pages/registration/orgRegistration/otherDetails/OtherDetails";
import PreviewForm from "../pages/registration/orgRegistration/previewRegForm/PreviewForm";
import Dashboard from "../pages/dashboard/Dashboard";
import EmissionsDeclaration from "../pages/emissionsSteps/emissionStep1/EmissionsDeclaration";
import EmissionStep3 from "../pages/emissionsSteps/emissionStep3/EmissionStep3";
import EmissionStep4 from "../pages/emissionsSteps/emissionStep4/EmissionStep4";
import LoginCredentials from "../pages/registration/orgRegistration/loginCredentials/LoginCredentials";
import WorkDetails from "../pages/registration/consRegistraion/workDetails/WorkDetails";
import SignUpSuccess from "../pages/auth/signUp/SignUpSuccess";
import EmailVerification from "../pages/auth/signUp/EmailVerification";
import ForgotPassword from "../pages/auth/forgotPassword/ForgotPassword";
import ForgotPassSuccess from "../pages/auth/forgotPassword/ForgotPassSuccess";
import ForgotPassFailed from "../pages/auth/forgotPassword/ForgotPassFailed";
import ConsAuditDashboard from "../pages/dashboard/consAuditDashboard/ConsAuditDashboard";
import RegistratonLayout from "../layouts/RegistratonLayout";
import OtpVerification from "../components/otpVerification/emailVerification/OtpVerification";
import MemberWorkDetails from "../pages/registration/AdvBoardRegistration/MemberWorkDetails";
import VerifyFailure from "../pages/auth/signUp/VerifyFailure";
import VerifySuccess from "../pages/auth/signUp/VerifySuccess";
import AdvisoryBoardWorkDetails from "../components/workDetails/AdvisoryBoardWorkDetails";
import ConsultantWorkDetails from "../components/workDetails/ConsultantWorkDetails";
import AuditorWorkDetails from "../components/workDetails/AuditorWorkDetails";
import ForgotPasswordSendEmail from "../pages/auth/forgotPassword/ForgotPasswordSendEmail";
import AdminDashboard from "../pages/admin/userManagement/AdminDashboard";
import ProfileView from "../pages/admin/userProfileView/ProfileView";
import UserRequestPage from "../pages/admin/userRequest/UserRequestPage";
import EmissionStep2 from "../pages/emissionsSteps/emissionStep2/EmissionStep2";
import OrganizationRating from "../pages/myRating/organizationRating/OrganizationRating";
import EmissionStep6 from "../pages/emissionsSteps/emissionStep6/EmissionStep6";
import EmissionStep6Preview from "../pages/emissionsSteps/emissionStep6Preview/EmissionStep6Preview";
import MyProfile from "../pages/profiles/myProfile/MyProfile";
import MyProfileEdit from "../pages/profiles/myProfileEdit/MyProfileEdit";
import AdminRatingDetails from "../pages/admin/userManagement/AdminRatingDetails";
import AdminOrgsRating from "../pages/admin/userManagement/AdminOrgsRating";
import AddAdmin from "../pages/admin/userManagement/AddAdmin";
import AdminFeedback from "../pages/admin/userManagement/AdminFeedback";
import AdminRoleManage from "../pages/admin/userManagement/AdminRoleManage";
import AdminProgressDashboard from "../pages/admin/userManagement/AdminProgressDashboard";
import UserFeedback from "../pages/dashboard/userFeedback/UserFeedback";
import EditAdmin from "../pages/admin/userManagement/EditAdmin";
import EmissionStep2Preview from "../pages/emissionsSteps/emissionStep2/EmissionStep2Preview";
import EmissionStep4Preview from "../pages/emissionsSteps/emissionStep4/EmissionStep4Preview";

const Router = () => {
  return createBrowserRouter([
    {
      element: <ActionLayout />,
      children: [
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="home" replace={true} />,
            },
            {
              path: "home",
              element: <Home />,
            },
          ],
        },
        {
          path: "auth",
          children: [
            {
              index: true,
              element: <Navigate to="login" replace={true} />,
            },
            {
              path: "login",
              element: <Login />,
            },
            {
              path: "signup",
              element: <SignUp />,
            },
            {
              path: "otpVerify",
              element: <EmailVerification />,
            },
            {
              path: "verifySuccess",
              element: <VerifySuccess />,
            },
            {
              path: "verifyFailure",
              element: <VerifyFailure />,
            },
            {
              path: "resetPasswordLink",
              element: <ForgotPasswordSendEmail />,
            },
            {
              path: "forgotPassword",
              element: <ForgotPassword />,
            },
            {
              path: "registration",
              // element: <RegistratonLayout />,
              children: [
                {
                  path: "basicDetails",
                  element: <BasicDetails />,
                },
                {
                  path: "organizationDetails",
                  element: <OrganizationDetails />,
                },
                {
                  path: "additionalDetails",
                  element: <OtherDetails />,
                },
                {
                  path: "memberWorkDetails",
                  element: <MemberWorkDetails />,
                },
                {
                  path: "workDetails",
                  element: <WorkDetails />,
                },
                {
                  path: "loginCredentials",
                  element: <LoginCredentials />,
                },
                {
                  path: "previewForm",
                  element: <PreviewForm />,
                },
                {
                  path: "signUpSuccess",
                  element: <SignUpSuccess />,
                },
              ],
            },
          ],
        },
        {
          path: "admin",
          children: [
            {
              index: true,
              element: <Navigate to="dashboard" replace={true} />,
            },
            {
              path: "dashboard",
              element: <AdminProgressDashboard />,
            },
            {
              path: "rating",
              element: <AdminOrgsRating />,
            },
            {
              path: "userManagement",
              element: <AdminDashboard />,
            },
            {
              path: "roleManagement",
              element: <AdminRoleManage />,
            },
            {
              path: "feedback",
              element: <AdminFeedback />,
            },
            {
              path: "helpAndSupport",
              element: (
                <>
                  <h1
                    style={{
                      textAlign: "center",
                      left: "0",
                      lineHeight: "200px",
                      marginTop: "-100px",
                      position: "absolute",
                      top: "50%",
                      width: "100%",
                    }}
                  >
                    Page Under Development
                  </h1>
                </>
              ),
            },
            {
              path: "addAdmin",
              element: <AddAdmin />,
            },
            {
              path: "editAdmin",
              element: <EditAdmin />,
            },
          ],
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "rating",
          element: <OrganizationRating />,
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="BasicDetails" replace={true} />,
            },
            {
              path: "BasicDetails",
              element: <BasicDetails />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="OrganizationDetails" replace={true} />,
            },
            {
              path: "OrganizationDetails",
              element: <OrganizationDetails />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="OtherDetails" replace={true} />,
            },
            {
              path: "OtherDetails",
              element: <OtherDetails />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="PreviewForm" replace={true} />,
            },
            {
              path: "PreviewForm",
              element: <PreviewForm />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="adminDashboard" replace={true} />,
            },
            {
              path: "adminDashboard",
              element: <AdminDashboard />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="UserRequestPage" replace={true} />,
            },
            {
              path: "UserRequestPage",
              element: <UserRequestPage />,
            },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: <Navigate to="ProfileView" replace={true} />,
            },
            {
              path: "ProfileView",
              element: <ProfileView />,
            },
          ],
        },
      ],
    },

    {
      path: "LoginCredentials",
      element: <LoginCredentials />,
    },
    {
      path: "WorkDetails",
      element: <WorkDetails />,
    },
    {
      path: "SignUpSuccess",
      element: <SignUpSuccess />,
    },
    {
      path: "EmailVerification",
      element: <EmailVerification />,
    },
    {
      path: "ForgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "sendEmail",
      element: <ForgotPasswordSendEmail />,
    },
    {
      path: "ForgotPassSuccess",
      element: <ForgotPassSuccess />,
    },
    {
      path: "ForgotPassFailed",
      element: <ForgotPassFailed />,
    },
    {
      path: "MemberWordDetails",
      element: <MemberWorkDetails />,
    },
    {
      path: "ConsAuditDashboard",
      element: <ConsAuditDashboard />,
    },
    {
      path: "advisoryBoard",
      element: <AdvisoryBoardWorkDetails />,
    },
    {
      path: "consultantWorkDetails",
      element: <ConsultantWorkDetails />,
    },
    {
      path: "auditorWorkDetails",
      element: <AuditorWorkDetails />,
    },
    {
      path: "additionalDetails",
      element: <OtherDetails />,
    },
    {
      path: "emissionDeclerations",
      element: <EmissionsDeclaration />,
    },
    {
      path: "EmissionStep2Preview",
      element: <EmissionStep2Preview />,
    },
    {
      path: "EmissionStep4Preview",
      element: <EmissionStep4Preview />,
    },
    {
      path: "signUpSuccess",
      element: <SignUpSuccess />,
    },
    {
      path: "advisoryBoard",
      element: <AdvisoryBoardWorkDetails />,
    },
    {
      path: "consultantWorkDetails",
      element: <ConsultantWorkDetails />,
    },
    {
      path: "auditorWorkDetails",
      element: <AuditorWorkDetails />,
    },
    {
      path: "additionalDetails",
      element: <OtherDetails />,
    },
    {
      path: "advisoryBoard",
      element: <AdvisoryBoardWorkDetails />,
    },
    {
      path: "consultantWorkDetails",
      element: <ConsultantWorkDetails />,
    },
    {
      path: "auditorWorkDetails",
      element: <AuditorWorkDetails />,
    },
    {
      path: "additionalDetails",
      element: <OtherDetails />,
    },
    {
      path: "advisoryBoard",
      element: <AdvisoryBoardWorkDetails />,
    },
    {
      path: "consultantWorkDetails",
      element: <ConsultantWorkDetails />,
    },
    {
      path: "auditorWorkDetails",
      element: <AuditorWorkDetails />,
    },
    {
      path: "additionalDetails",
      element: <OtherDetails />,
    },
    {
      path: "emissionStep2",
      element: <EmissionStep2 />,
    },
    {
      path: "emissionStep3",
      element: <EmissionStep3 />,
    },
    {
      path: "emissionStep4",
      element: <EmissionStep4 />,
    },
    {
      path: "emissionStep6",
      element: <EmissionStep6 />,
    },
    {
      path: "emissionStep6Preview",
      element: <EmissionStep6Preview />,
    },
    {
      path: "organizationRating",
      element: <OrganizationRating />,
    },
    {
      path: "myProfile",
      element: <MyProfile />,
    },
    {
      path: "myProfileEdit",
      element: <MyProfileEdit />,
    },
    {
      path: "adminProgressDashboard",
      element: <AdminProgressDashboard />,
    },
    {
      path: "adminOrgsRating",
      element: <AdminOrgsRating />,
    },
    {
      path: "adminRatingDetails",
      element: <AdminRatingDetails />,
    },
    {
      path: "adminFeedback",
      element: <AdminFeedback />,
    },
    {
      path: "adminRoleManage",
      element: <AdminRoleManage />,
    },
    {
      path: "addAdmin",
      element: <AddAdmin />,
    },
    {
      path: "userFeedback",
      element: <UserFeedback />,
    },
    {
      path: "emissionsDeclaration",
      element: <EmissionsDeclaration />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
  ]);
};

export default Router;
