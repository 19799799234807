import React from 'react';

import AuditInfo2ScopeTable from '../auditInfo2ScopeTable/AuditInfo2ScopeTable';

import "./style.css";


function AuditorInfoPart2Scopes() {


  return (
    <div className='auditChatInfo2ScopesCon'>
        <h1 className='AudInfo2ScopeTitle'>Scope 1: Direct Emissions from Owned/Controlled Operations</h1>
        <div className='auditInfo2ScopesValue'>
            <AuditInfo2ScopeTable  tableTitle={"Direct Emissions from Stationary Combustion"} tableValue={"123445"} />
            <AuditInfo2ScopeTable  tableTitle={"Direct Emissions from Mobile Combustion"} tableValue={"234123412"} />
            <AuditInfo2ScopeTable  tableTitle={"Direct Emissions from Process Sources"} tableValue={"234123412"} />
            <AuditInfo2ScopeTable  tableTitle={"Direct Emissions from Fugitive Sources"} tableValue={"234123412"} />
            <AuditInfo2ScopeTable  tableTitle={"Direct Emissions from Agricultural Sources"} tableValue={"234123412"} />
        </div>
    </div>
  )
}

export default AuditorInfoPart2Scopes;