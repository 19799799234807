import React from "react";
import ChatBoxIcon from "../../../assets/icons/Chat_Circle_Dots.svg";
import ListIcon from "../../../assets/icons/List_Unordered.svg";

import "./style.css";
import ChatBoxLayout from "../ChatBoxLayout/ChatBoxLayout";

function ViewComments({
  secName,
  question,
  answer,
  issueId,
  declarationId,
  mapping,
  handleGetChatsAndIssues,
  description,
  setDescription,
  openChatBox,
  handleChatBoxClose,
  handleChatBoxOpen,
  issue,
  chats,
  handleCreateIssueAndChats,
  openMenu,
  openSubMenu,
  menuRef,
  subMenuRef,
  handleMenuOptions,
  handleSubMenuOptions,
  handleSelectValue,
  chatsIsLoading,
  issuesIsLoading,
  createChatIsLoading,
  chatsIsFetching,
  createChatIsFetching,
}) {
  return (
    <div className="viewCommentContainer viewCommentBtnSec">
      <div className="viewCommentCol1 cursorPointer">
        <img src={ChatBoxIcon} alt="chatBoxIcon" />
        <p
          onClick={() => {
            handleChatBoxOpen();
            handleGetChatsAndIssues();
          }}
        >
          View Comment
        </p>
      </div>
      <div className="viewCommentCol2 cursorPointer">
        <img src={ListIcon} alt="listIcon" />
        <p>View Edits</p>
      </div>
      <ChatBoxLayout
        issue={issue}
        chats={chats}
        declarationId={declarationId}
        issueId={issueId}
        mapping={mapping}
        openChatBox={openChatBox}
        handleChatBoxClose={handleChatBoxClose}
        secName={secName}
        question={question}
        answer={answer}
        description={description}
        setDescription={setDescription}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
        openMenu={openMenu}
        openSubMenu={openSubMenu}
        menuRef={menuRef}
        subMenuRef={subMenuRef}
        handleMenuOptions={handleMenuOptions}
        handleSubMenuOptions={handleSubMenuOptions}
        handleSelectValue={handleSelectValue}
        chatsIsLoading={chatsIsLoading}
        chatsIsFetching={chatsIsFetching}
        issuesIsLoading={issuesIsLoading}
        createChatIsLoading={createChatIsLoading}
        createChatIsFetching={createChatIsFetching}
      />
    </div>
  );
}

export default ViewComments;
