import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  cleanObject,
} from "../../../../utils/helper";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import {
  SelectCountryInputBox,
  SelectStateInputBox,
} from "../../../../components/InputBox/selectBox/SelectInputBox";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";
import FileUploadBoxRegistration from "../../../../components/fileUploadBox/FileUploadBoxRegistration";
import { workDetailsSchema } from "../../../../Validators/register.validators";

import "./style.css";

import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { getUserFromLocal } from "../../../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import {
  useConsultantWorkDetailsMutation,
  usePreviewConsultantMutation,
} from "../../../../features/registration/consultant/consultantApiSlice";
import { userActions } from "../../../../features/user/userSlice";
import { setUserHandler } from "../../../../features/user/userAction";
import {
  useAuditorWorkDetailsMutation,
  usePreviewAuditorMutation,
} from "../../../../features/registration/auditor/auditorApiSlice";
import { showError } from "../../../../features/snackbar/snackbarAction";
import PageLoader from "../../../../components/Loader/PageLoader";

const countryStateMasterData = {
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};

function WorkDetails() {
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("work-details");
  const dispatch = useDispatch();
  const userReduxDetails = useSelector((state) => state.user);

  const { data } = resposnse || {};

  const [
    previewConsInfo,
    {
      isLoading: previewConsIsLoading,
      isSuccess: previewConsIsSuccess,
      error: previewConsIsError,
    },
  ] = usePreviewConsultantMutation();

  const [
    previewAudInfo,
    {
      isLoading: previewAudIsLoading,
      isSuccess: previewAudIsSuccess,
      error: previewAudIsError,
    },
  ] = usePreviewAuditorMutation();

  const [
    auditorWorkDetails,
    {
      isLoading: auditorIsLoading,
      isSuccess: auditorIsSuccess,
      error: auditorIsError,
    },
  ] = useAuditorWorkDetailsMutation();

  const [
    consultantWorkDetails,
    {
      isLoading: consultantIsLoading,
      isSuccess: consultantIsSuccess,
      error: consultantIsError,
    },
  ] = useConsultantWorkDetailsMutation();

  useEffect(() => {
    if (userDetails?.userType === "auditor") {
      previewAudInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "consultant") {
      previewConsInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
  }, [userDetails?.userId, userDetails?.trackingCode, userDetails?.userType]);

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };
  const emptyValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    associatedOrg:
      data?.data?.user?.workDetails?.associatedOrg ||
      userDetails?.organizationName,
    jobType: "Freelancer",
    orgName: "",
    websiteLink: "",
    orgHeadquarter: {
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
    },
    proofOfConsultancyFiles: [],
    proofOfAuditorshipFiles: [],
  };

  const formik = useFormik({
    initialValues: {
      _id: userDetails?.userId,
      trackingCode: userReduxDetails?.trackingCode,
      associatedOrg:
        data?.data?.user?.workDetails?.associatedOrg ||
        userDetails?.organizationName ||
        "",
      jobType: data?.data?.user?.workDetails?.jobType || "Freelancer",
      orgName: data?.data?.user?.workDetails?.orgName || "",
      websiteLink: data?.data?.user?.workDetails?.websiteLink || "",
      orgHeadquarter: {
        fullAddress:
          data?.data?.user?.workDetails?.orgHeadquarter?.fullAddress || "",
        city: data?.data?.user?.workDetails?.orgHeadquarter?.city || "",
        country: data?.data?.user?.workDetails?.orgHeadquarter?.country || "",
        state: data?.data?.user?.workDetails?.orgHeadquarter?.state || "",
        zipCode: data?.data?.user?.workDetails?.orgHeadquarter?.zipCode || "",
      },
      proofOfConsultancyFiles:
        data?.data?.user?.workDetails?.proofOfConsultancyFiles || [],
      proofOfAuditorshipFiles:
        data?.data?.user?.workDetails?.proofOfAuditorshipFiles || [],
    },
    enableReinitialize: true,
    validationSchema: workDetailsSchema,

    onSubmit: (values) => {
      const workDetailValues = structuredClone(values);
      cleanObject(workDetailValues);
      const userTypes = ["auditor", "consultant"];
      const workDetailsFunctions = [auditorWorkDetails, consultantWorkDetails];
      const userTypeIndex = userTypes.indexOf(userDetails?.userType);
      if (userTypeIndex !== -1) {
        workDetailsFunctions[userTypeIndex](workDetailValues)
          .unwrap()
          .then((res) => {
            navigate("/auth/registration/loginCredentials");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
                // organizationName:
                //   res?.data?.user?.associatedOrg[0]?.organizationName,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(showError({ message: e?.data?.message }));
          });
      }
    },
  });

  const handleRadioButtonChange = (e) => {
    formik?.setFieldValue("jobType", e.target.value);
    formik.setFieldValue("orgName", "");
    formik.setFieldValue("websiteLink", "");
    formik.setFieldValue("orgHeadquarter.fullAddress", "");
    formik.setFieldValue("orgHeadquarter.city", "");
    formik.setFieldValue("orgHeadquarter.country", "");
    formik.setFieldValue("orgHeadquarter.state", "");
    formik.setFieldValue("orgHeadquarter.zipCode", "");
  };

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  console.log("formikConsultantWorkDetailsValues", formik?.values);
  console.log("formikConsultantWorkDetailsErrors", formik?.errors);

  return (
    <div className="container workDetailsSection">
      {(previewConsIsLoading ||
        previewAudIsLoading ||
        auditorIsLoading ||
        consultantIsLoading) && <PageLoader />}
      <div className="workDelCol1">
        <img className="workDetails_topBg" src={SignUpTopBg} />
        <img className="workDetails_bottomBg" src={SignUpBottomBg} />
        <img className="workDetails_leftBg" src={SignUpLeftBg} />

        <div className="workDetails_timeLineContents">
          <div className="workDetails_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>

      <div className="workDelCol2">
        <h1>Work Details</h1>
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="formInnerDiv">
            <div className="">
              <p className="workDHeadings">
                Please add the name of the organisation for which you are either
                a consultant or an auditor/assurance provider
              </p>
              <div className="wd-100">
                <InputBox
                  headName={"Name of Organization"}
                  placeholder=" Enter a name"
                  name="associatedOrg"
                  value={formik?.values?.associatedOrg}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.associatedOrg &&
                    formik.errors.associatedOrg
                  }
                  required
                  // readOnly
                />
              </div>
            </div>
            {userDetails?.userType === "consultant" && (
              <div>
                <p className="workDHeadings mb-16">
                  Choose the option that best describes your job
                </p>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-.preViewTextlabel"
                  name="row-radio-buttons-group"
                  className="wd-100"
                  value={formik.values.jobType}
                  onChange={handleRadioButtonChange}
                >
                  <FormControlLabel
                    value="Freelancer"
                    control={<Radio size="small" />}
                    label="Freelancer"
                    defaultChecked
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 14,
                        fontFamily: "Uniform",
                        fontWeight: 400,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Organization or Firm"
                    control={<Radio size="small" />}
                    label="Organization or Firm"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 14,
                        fontFamily: "Uniform",
                        fontWeight: 400,
                      },
                    }}
                  />
                </RadioGroup>
              </div>
            )}

            {(formik?.values?.jobType === "Organization or Firm" ||
              userDetails?.userType === "auditor") && (
              <>
                <div className="wd-100">
                  <InputBox
                    headName={"Name of your Organization"}
                    placeholder=" Enter a name"
                    name="orgName"
                    value={formik?.values?.orgName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={!!formik.touched.orgName && formik.errors.orgName}
                    required
                  />
                </div>
                <div className="wd-100 titleRow">
                  <InputBox
                    headName={"Website Link:"}
                    placeholder="www.website.com"
                    name="websiteLink"
                    value={formik?.values?.websiteLink}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    errorIcon={WarningIcon}
                    error={
                      !!formik.touched.websiteLink && formik.errors.websiteLink
                    }
                    required
                  />
                </div>
                <div>
                  <p className="workDHeadings mb-5">
                    Please tell us the location of your organisation
                  </p>
                  <div className="headQuarterSection">
                    <div className="headSections">
                      <div className="wd-70">
                        <InputBox
                          headName={"Your Address"}
                          placeholder="Enter the address here"
                          name="orgHeadquarter.fullAddress"
                          value={formik?.values?.orgHeadquarter?.fullAddress}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          errorIcon={WarningIcon}
                          error={
                            !!formik.touched.orgHeadquarter?.fullAddress &&
                            formik.errors.orgHeadquarter?.fullAddress
                          }
                          required
                        />
                      </div>
                      <div className="orCityDiv">
                        <InputBox
                          headName={"City / Town / Suburb"}
                          placeholder="Enter city / town / suburb"
                          name="orgHeadquarter.city"
                          value={formik?.values?.orgHeadquarter?.city}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          errorIcon={WarningIcon}
                          error={
                            !!formik.touched.orgHeadquarter?.city &&
                            formik.errors.orgHeadquarter?.city
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="headSections">
                      <div className="wd-33">
                        <SelectCountryInputBox
                          data={countryStateMasterData}
                          headName={"Country"}
                          selectPlaceholder={"Enter Country"}
                          name="orgHeadquarter.country"
                          value={formik?.values?.orgHeadquarter?.country}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          formik={formik}
                          errorIcon={WarningIcon}
                          error={
                            !!formik.touched.orgHeadquarter?.country &&
                            formik.errors.orgHeadquarter?.country
                          }
                          required
                        />
                      </div>
                      <div className="wd-33">
                        <SelectStateInputBox
                          headName={"State / Region"}
                          selectPlaceholder={" Enter state / region"}
                          data={countryStateMasterData}
                          selectedData={formik?.values?.orgHeadquarter?.country}
                          name="orgHeadquarter.state"
                          value={formik?.values?.orgHeadquarter?.state}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          formik={formik}
                          errorIcon={WarningIcon}
                          error={
                            !!formik.touched.orgHeadquarter?.state &&
                            formik.errors.orgHeadquarter?.state
                          }
                          required
                        />
                      </div>
                      <div className="wd-33">
                        <InputBox
                          headName={"Zipcode / Postal Code"}
                          placeholder="Phone Number here"
                          name="orgHeadquarter.zipCode"
                          value={formik?.values?.orgHeadquarter?.zipCode}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          errorIcon={WarningIcon}
                          error={
                            !!formik.touched.orgHeadquarter?.zipCode &&
                            formik.errors.orgHeadquarter?.zipCode
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {userDetails?.userType === "consultant" ? (
              <div>
                {userDetails?.userType === "consultant" ? (
                  <p className="workDHeadings mb-5">
                    Please upload documents of your proof of Consultancy
                    (Contract of Consultancy, Experience of work)
                  </p>
                ) : (
                  <p className="workDHeadings mb-5">
                    Please upload documents of your proof of Auditorship
                    (Contract of Audition, Experience of work)
                  </p>
                )}
                <p className="attachmentsPara">
                  Attachments cannot exceed 5 Maximum size per file cannot
                  exceed Xmb per attachment All video formats will be accepted
                </p>
                <div className="wd-100 mb-28 addPdfWidth">
                  <FileUploadBoxRegistration
                    formik={formik}
                    field="proofOfConsultancyFiles"
                    value={formik?.values?.proofOfConsultancyFiles}
                    folder="registration"
                  />
                </div>
              </div>
            ) : (
              <div>
                <p className="workDHeadings mb-5">
                  Please upload documents of your proof of consultancy (Contract
                  of Consultancy, Experience of work)
                </p>
                <p className="attachmentsPara">
                  Attachments cannot exceed 5 Maximum size per file cannot
                  exceed Xmb per attachment All video formats will be accepted
                </p>
                <div className="wd-100 mb-28 addPdfWidth">
                  <FileUploadBoxRegistration
                    formik={formik}
                    field="proofOfAuditorshipFiles"
                    value={formik?.values?.proofOfAuditorshipFiles}
                    folder="registration"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="form-row wd_BtnRow">
            <div
              className="cursorPointer"
              onClick={() => {
                navigate("/auth/registration/basicDetails");
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div className=" cursorPointer cancelBtn_Row">
              <div
                onClick={() => {
                  formik.setValues(emptyValues);
                }}
              >
                <RegCancelButton buttonName={"Cancel"} />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Next"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px"}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WorkDetails;
