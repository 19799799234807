import React from 'react';
import './style.css';
import AddIcon from "../../../assets/icons/AddPlusBtn.svg";

function AddUserButton({buttonName }) {
  return (
    <button type="submit" className="addUserBtn cursorPointer" >
      <img src={AddIcon} />
      <div className='addUserBtnText'>{buttonName}</div>
    </button>
  );
}

export default AddUserButton;