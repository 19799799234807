import React from 'react';

import "./style.css";

import Close_MD from "../../../../../assets/icons/Close_MD.svg";
import PreviousArrow from "../../../../../assets/icons/ArrowLeft.svg";
import NextArrow from "../../../../../assets/icons/Arrow_Right.svg";

function ChatBoxHeader({handleClose}) {
  return (
    <header className='cmtDrawHeader'>
        <div className='drawHeadRow'>
            <div className='drawHeadCol1'>
                <h1>Comments</h1>
                <div className='ongoingBtn cursorPointer'>
                    <p>Ongoing</p>
                </div>
                <div className='correctionBtn cursorPointer'>
                    <p>Correction</p>
                </div>
            </div>
            <div className='drawHeadCol2'>
                <div className='nextBtnRow'>
                    <div className='nextBtnCol cursorPointer'>
                        <img src={PreviousArrow} alt='previousArrow' />
                        <p>Previous</p>
                    </div>
                    <div className='nextBtnCol cursorPointer'>
                        <p>Next</p>
                        <img src={NextArrow} alt='nextArrow' />
                    </div>
                </div>
                <div className='cmtHeaderRightBorder'></div>
                <div className='commentDrawClose cursorPointer' onClick={handleClose}>
                    <img src={Close_MD} alt='closeBtn' />
                </div>
            </div>

        </div>
        
    </header>
  )
}

export default ChatBoxHeader;