import react, { Fragment, useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import "./style.css";

import Close_MD from "../../../assets/icons/Close_MD.svg";
import { useGetRegistrationLogsQuery } from "../../../features/admin/adminApiSlice";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";


function RegistraionLog({ isOpen, onClose }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const userType = searchParams.get("userType");
  const userId = searchParams.get("userId");
  const dispatch = useDispatch();
  const {
    data: regisLogDetailsData,
  } = useGetRegistrationLogsQuery({
    userId: userId,
    userType: userType,
  });

  console.log("regisLogDetailsData", regisLogDetailsData)

  const handleClose = () => {
    onClose();
  };

  const [state, setState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="regisLogHeader">
        <h1>Registration Log</h1>
        <img src={Close_MD} onClick={handleClose} />
      </div>
      <div className="regisLogDetails">
        {regisLogDetailsData?.data.map((item, index) => (
          <div key={index} className={item.action === 'approved' ? 'approveList' : 'rejectList'}>
            <div className="applyOn">
              <h2>Applied On:</h2>
              <h2>{moment(item?.createdAt).format('DD/MM/YYYY')}</h2>
            </div>
            <div className="applyOn">
              <h2>Action Taken:</h2>
              <button className={item?.action === 'approved' ? 'approveLog' : 'rejectLog'}>
                <p>{item?.action}</p>
              </button>
            </div>
            {item?.action === 'rejected' && (
              <div className="applyOn">
                <h2>Reason:</h2>
                <h2>{item?.reason}</h2>
              </div>
            )}
            <span className="logTime">On {moment(item?.createdAt).format('DD/MM/YYYY')} at {moment(item?.createdAt).format('h:mm')}</span>
          </div>
        ))}
      </div>
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            //   open={state[anchor]}
            //   onClose={toggleDrawer(anchor, false)}
            open={isOpen}
            onClose={onClose}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}

export default RegistraionLog;
