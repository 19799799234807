import React from 'react';

import "./style.css"

function PreviewDeclaration({btnName}) {
  return (
    <button className='previewDeclCon cursorPointer'>
      <p>{btnName}</p>
    </button>
  )
}

export default PreviewDeclaration;