import React from "react";

import "./style.css";
import EmissionComments from "../../../chats/EmissionComments";

function AuditInventoryBoundaries({
  addOffsetTitle,
  sectionTitle,
  value1,
  value2,
  value3,
  mapping,
  issueId,
  arrayId,
  declarationId,
}) {
  return (
    <div className="auditInveBoundariesCon">
      <h1>{addOffsetTitle}s</h1>
      <div className="auditInvBoundInnerCon">
        <div className="auditEmissOffset1row">
          <div className="auditEmissOffset1Col1">
            <h1 className="offset2Title">{sectionTitle}</h1>
          </div>
          <div className="addCmtRow">
            <EmissionComments
              className="addCmtRow"
              mapping={mapping}
              issueId={issueId}
              declarationId={declarationId}
              arrayId={arrayId}
              secName={"Information on offsets"}
              question={sectionTitle}
            />
          </div>
        </div>
        <div className="auditInvBoundRow">
          <div className="auditInvBoundCol1">
            <h1>Quantity of GHGs (mtCO2e)</h1>
          </div>
          <div className="auditInvBoundCol2">
            <p>{value1}</p>
          </div>
        </div>
        <div className="auditInvBoundRow">
          <div className="auditInvBoundCol1">
            <h1>Type of offset project</h1>
          </div>
          <div className="auditInvBoundCol2">
            <p>{value2}</p>
          </div>
        </div>
        <div className="auditInvBoundRow">
          <div className="auditInvBoundCol1">
            <h1>
              Were the offsets verified/certified and/or approved by an external
              GHG program (e.g., CDM)
            </h1>
          </div>
          <div className="auditInvBoundCol2">
            <p>{value3}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditInventoryBoundaries;
