import React from "react";

import "./style.css";

import AddIcon from "../../../assets/icons/addWhiteIcon.svg";

function AddPillarButton({ addPriorityPillar }) {
  return (
    <div
      className="addPillarContainer cursorPointer"
      onClick={addPriorityPillar}
    >
      <img src={AddIcon} alt="AddIcon" />
      <p className="addPillarText">Add Priority Pillar</p>
    </div>
  );
}

export default AddPillarButton;
