import React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const quarter = ["Q1", "Q2", "Q3", "Q4"];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function SelectBox({
  headName,
  name,
  value,
  touch,
  error,
  onChange,
  errorIcon,
  onBlur,
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  console.log("SelectBox -> error", error);

  return (
    <div className="timeSelectBox" id="timeSelectBox1">
      <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <label className="selectLabel">{headName}</label>
        <Select
          displayEmpty
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          input={<OutlinedInput />}
          className="timelineSelectBox"
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
          inputProps={{ "aria-label": "Without label" }}
        >
          {quarter.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}

function SelectBox2({
  headName,
  name,
  value,
  touch,
  error,
  onChange,
  errorIcon,
  onBlur,
  options,
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  return (
    <div className="timeSelectBox" id="timeSelectBox1">
      <FormControl sx={{ m: 1, width: 150, mt: 3 }}>
        <label className="selectLabel">{headName}</label>
        <Select
          displayEmpty
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          input={<OutlinedInput />}
          className="timelineSelectBox"
          renderValue={
            value !== null
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    Select
                  </span>
                )
          }
          inputProps={{ "aria-label": "Without label" }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              // style={getStyles(option, personName, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}

export default SelectBox;
export { SelectBox2 };
