import React from "react";

import AuditInfo2ScopeTable from "../auditInfo2ScopeTable/AuditInfo2ScopeTable";

import "./style.css";

function AuditorInfoPart2ScopesIndirect() {
  return (
    <div className="auditChatInfo2ScopesCon">
      <h1 className="AudInfo2ScopeTitle">
        Scope 1: Direct Emissions from Owned/Controlled Operations
      </h1>
      <div className="auditInfo2ScopesValue">
        <AuditInfo2ScopeTable
          tableTitle={"INDIRECT EMISSIONS FROM PURCHASED/ACQUIRED ELECTRICITY"}
          tableValue={"123445"}
        />
        <AuditInfo2ScopeTable
          tableTitle={"INDIRECT EMISSIONS FROM PURCHASED/ACQUIRED STEAM"}
          tableValue={"234123412"}
        />
        <AuditInfo2ScopeTable
          tableTitle={"INDIRECT EMISSIONS FROM PURCHASED/ACQUIRED COOLING"}
          tableValue={"234123412"}
        />
        <AuditInfo2ScopeTable
          tableTitle={"INDIRECT EMISSIONS FROM PURCHASED/ACQUIRED HEATING"}
          tableValue={"234123412"}
        />
      </div>
    </div>
  );
}

export default AuditorInfoPart2ScopesIndirect;
