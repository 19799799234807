import { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
// import ScrollToTop from "../ScrollToTop";

import { checkUserStatus } from "../features/user/userAction";
import { checkLoginStatus } from "../features/auth/authActions";
import { getUserFromLocal } from "../utils/storage";

let firstRender = true;

const ActionLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetails = getUserFromLocal();
  const loginStatus = useSelector((state) => state.auth.isLoggedIn);
  const [isAuthScreen, setIsAuthScreen] = useState(null);

  useEffect(() => {
    firstRender && dispatch(checkLoginStatus());
  }, []);

  useEffect(() => {
    if (loginStatus) {
      dispatch(checkUserStatus());
      if (isAuthScreen) {
        if (userDetails?.userType === "admin") {
          navigate("/admin/dashboard", { replace: true });
        } else {
          navigate("/dashboard", { replace: true });
        }
      }
    }
    if (loginStatus === false && isAuthScreen === false) {
      navigate("/home", { replace: true });
    }
    if (
      loginStatus === false &&
      (location.pathname === "/auth/forgotPassword" ||
        location.pathname === "/auth/verifySuccess" ||
        location.pathname === "/auth/otpVerify")
    ) {
      if (!location.search) {
        navigate("/auth/signup", { replace: true });
      }
    }
  }, [loginStatus, dispatch, navigate, isAuthScreen]);

  useEffect(() => {
    setIsAuthScreen(
      location.pathname === "/auth" ||
        location.pathname === "/auth/signup" ||
        location.pathname === "/auth/login" ||
        location.pathname === "/auth/otpVerify" ||
        location.pathname === "/auth/verifySuccess" ||
        location.pathname === "/auth/verifyFailure" ||
        location.pathname === "/auth/forgotPassword" ||
        location.pathname === "/auth/resetPasswordLink" ||
        location.pathname === "/home" ||
        location.pathname === "/auth/registration/basicDetails" ||
        location.pathname === "/auth/registration/organizationDetails" ||
        location.pathname === "/auth/registration/loginCredentials" ||
        location.pathname === "/auth/registration/previewForm" ||
        location.pathname === "/auth/registration/additionalDetails" ||
        location.pathname === "/auth/registration/memberWorkDetails" ||
        location.pathname === "/auth/registration/workDetails" ||
        location.pathname === "/auth/registration/signUpSuccess" ||
        location.pathname === "/" ||
        location.pathname === "/BasicDetails" ||
        location.pathname === "/OrganizationDetails" ||
        location.pathname === "/OtherDetails" ||
        location.pathname === "/PreviewForm"
    );
  }, [location]);

  console.log("isAuthScreen", isAuthScreen);
  console.log("loginStatus", loginStatus);

  return (
    <>
      {/* <ScrollToTop /> */}
      <Box className="main-box-secondary">
        {(loginStatus === false && isAuthScreen) ||
        (loginStatus && isAuthScreen === false) ? (
          <Outlet />
        ) : (
          <div className="container-fluid loader">
            <CircularProgress color="secondary" size={60} />
          </div>
        )}
      </Box>
    </>
  );
};

export default ActionLayout;
