import React from "react";
import AuditInfo2ScopeTable from "../auditInfo2ScopeTable/AuditInfo2ScopeTable";
import "./style.css";
import MappingTable from "../auditInfo2ScopeTable/MappingTable";

const MappingEditResponse = ({ scopeData, heading }) => {
  return (
    <div className="auditChatInfo2ScopesCon">
      <h1 className="AudInfo2ScopeTitle">{heading}</h1>
      <div className="auditInfo2ScopesValue">
        {scopeData.map((item, index) => (
          <MappingTable
            key={index}
            tableTitle={item.title}
            tableValue={item.value}
          />
        ))}
      </div>
    </div>
  );
};

export default MappingEditResponse;
