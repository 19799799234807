import react from "react";
import OrgRateDetailsBread from "../breadcrumbs/orgsRatingBreadcrumb/OrgRateDetailsBread";

import "./style.css";

import AppleIcon from "../../../assets/icons/apple.svg"

function AdminOrgRateTitle() {
  return (
    <div className="adminOrgaContainer">
      <OrgRateDetailsBread />
      <div className="adminOrgTitleSec">
        <div className="adOrgTitleCol1 companyLogoRow">
            <img src={AppleIcon} alt="AppleIcon" />
          <h1>Apple Inc.</h1>
        </div>
      </div>
    </div>
  );
}

export default AdminOrgRateTitle;
