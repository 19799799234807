import { useState } from "react";

import "./style.css";

import EmisStatusImg from "../../../assets/images/delclarationStatus.svg";
import DashboardRatingImg from "../../../assets/images/dashboardCurrentRating.svg";

function EmisDeclarationStatus({ userAnalytics }) {
  return (
    <div className="emisRatSection">
      <div className="emisRatInner">
        <div className="emisRatInner">
          <p className="emisRatHeading">Emissions Declared</p>
          <h3>
            {userAnalytics?.data?.totalEmissions?.totalEmissions
              ? userAnalytics?.data?.totalEmissions?.totalEmissions
              : "--"}
          </h3>
          <p className="validValue">mtCO2E</p>
        </div>
      </div>
      <div className="emisRatImg">
        <img src={DashboardRatingImg} />
      </div>
    </div>
  );
}

export default EmisDeclarationStatus;
