import React from "react";

import ViewCommentsBtn from "../../viewComments/viewCommentsBtn/ViewCommentsBtn";
import "./style.css";
import Comments from "../../../chats/Comments";
import RoadmapComments from "../../../chats/RoadmapComments";

function InitLocationPreview({
  value,
  declarationId,
  priorityPillarId,
  initiativeId,
  issueId,
}) {
  return (
    <div className="locationPreCon">
      <h1 className="locationTitle">LOCATIONS</h1>
      <div className="locationPreRowCon">
        <div className="locationPreRow">
          <div className="locationPreCol1">
            <h2>Location 1</h2>
          </div>
          <div className="locationPreCol2">
            <p>{value?.location1}</p>
          </div>
        </div>
        <div className="viewCmtRow"></div>
      </div>
      <div className="locationPreRowCon">
        <div className="locationPreRow">
          <div className="locationPreCol1">
            <h2>Location 2</h2>
          </div>
          <div className="locationPreCol2">
            <p>{value?.location2}</p>
          </div>
        </div>
        <div className="addCmtRow">
          <RoadmapComments
            className="addCmtRow"
            mapping="initiatives.location"
            issueId={issueId}
            priorityPillarId={priorityPillarId}
            initiativeId={initiativeId}
            declarationId={declarationId}
            secName={"LOCATIONS"}
            question="LOCATION 1 & LOCATION 2"
            answer={value?.location1 + ", " + value?.location2}
          />
        </div>
      </div>
    </div>
  );
}

export default InitLocationPreview;
