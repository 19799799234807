import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import "./style.css";

function QuarterOne({  }) {

  return (
    <div className="emisSectionGap">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>
            <div className="GenrlInfo">
            <h3>2024  |  Quarter 1</h3>
            </div>
        </Typography>
        </AccordionSummary>
        <AccordionDetails>
           

        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default QuarterOne;
