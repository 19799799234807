import logo from './logo.svg';
import './App.scss';
import Home from './pages/home/Home';
import Router from './navigation/Routes';
import { RouterProvider } from 'react-router-dom';


function App() {
  return (
    <RouterProvider router={Router()} />
    // <Router/>
  );
}

export default App;
