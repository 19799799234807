import React from "react";
import SendBySection from "../sendBySection/SendBySection";

import "./style.css";

function TextComponent({ text, item }) {
  return (
    <div className="textMsgCol">
      <p className="textMsgPara">{text}</p>
      <SendBySection item={item} />
    </div>
  );
}

export default TextComponent;
