import React, { useEffect, useRef, useState } from "react";

import "./style.css";

import ProfileIcon from "../../../assets/icons/profileImg.svg";
import ThreeDotsIcon from "../../../assets/icons/threeDotsIcon.svg";
import UserIcon from "../../../assets/icons/profileUserIcon.svg";
import ProfileEdit from "../../../assets/icons/profileEditIcon.svg";

function PeopleInvolved({ data }) {
  const [prifileMenu, setProfileMenu] = useState(false);
  const menuRef = useRef(null);

  console.log("dataChecking....", data);
  const involevePeopleData = [
    {
      profileIcon: data?.auditorData?.profileImage,
      name: data?.auditorData?.fullName,
      designation: "Auditor",
    },
    {
      profileIcon: data?.consultantData?.profileImage,
      name: data?.consultantData?.fullName,
      designation: "Consultant",
    },
    {
      profileIcon: data?.organizationData?.profileImage,
      name: data?.organizationData?.fullName,
      designation: data?.organizationData?.positionInOrg,
    },
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setProfileMenu(false);
    }
  };

  const openPrifileMenu = () => {
    setProfileMenu(!prifileMenu);
  };

  return (
    <div className="peopleInvolveCon">
      <div className="peopleInvTitle">
        <h1>People Involved</h1>
        <p>All the people involved in your rating process</p>
      </div>

      {involevePeopleData?.map((item) => (
        <div className="peopleInvList">
          <div className="peopleInvListCol1">
            <div className="peopleInvProfile">
              <img
                src={item.profileIcon}
                alt="ProfileIcon"
                className="cursorPointer"
              />
            </div>
            <div className="nameAndDesignation">
              <h1>{item.name}</h1>
              <p>{item.designation}</p>
            </div>
          </div>
          {/* <div className="cursorPointer" onClick={openPrifileMenu}>
            <img src={ThreeDotsIcon} alt="ThreeDotsIcon" />
          </div> */}
        </div>
      ))}

      {/* {prifileMenu && (
        <div className="ratingProfileMenuCon" ref={menuRef}>
          <div className="ratingProfileMenuRow">
            <img src={UserIcon} alt="UserIcon" />
            <p>View Profile</p>
          </div>
          <div className="ratingProfileMenuRow">
            <img src={ProfileEdit} alt="ProfileEdit" />
            <p>Change / Modify</p>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default PeopleInvolved;
