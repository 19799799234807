import React from "react";
import "./style.css";

const StatusTab = ({ text, type }) => {
  return (
    <div
      className={`statusContainer ${
        type === "rejected"
          ? "rejected"
          : type === "pending"
            ? "pending"
            : type === "active"
              ? "active"
              : ""
      }`}
    >
      <p className="statusText">{text}</p>
    </div>
  );
};

export default StatusTab;
