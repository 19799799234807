import React, { useState } from "react";
import "./style.css";
import DropDownImg from "../../../assets/icons/whiteDropDown.svg";
import {
  useApproveDeclerationMutation,
  useRejectDeclerationMutation,
  useRevertDeclerationMutation,
} from "../../../features/steps/emissionApiSlice";
import { showSuccess } from "../../../features/snackbar/snackbarAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

function ApproveDecButton({
  type,
  declerationId,
  selectedValue,
  setSelectedValue,
  handleChange,
}) {
  return (
    <div className="approveDecBtn">
      <select value={selectedValue} onChange={handleChange}>
        <option value="">Select Action</option>
        <option value="Approve Declaration">Approve Declaration</option>
        <option value="Revert with Comments">Revert with Comments</option>
        <option value="Reject Roadmap">Reject Roadmap</option>
      </select>
      <img src={DropDownImg} />
    </div>
  );
}

export default ApproveDecButton;
