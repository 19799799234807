import apiSlice from "../../../app/api/apiSlice";

export const consultantApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkConsultant: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/app/v1/consultant/registration/check${queryString}`,
        };
      },
      providesTags: ["Consultant"],
    }),

    basicConsultantInfo: builder.mutation({
      query: (basicData) => ({
        url: "/app/v1/consultant/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["Consultant"],
    }),

    consultantWorkDetails: builder.mutation({
      query: (workDetails) => ({
        url: "/app/v1/consultant/registration/work-details",
        method: "POST",
        body: workDetails,
      }),
      invalidatesTags: ["Consultant"],
    }),

    previewConsultant: builder.mutation({
      query: (previewData) => ({
        url: "/app/v1/consultant/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["Consultant"],
    }),

    completeConsultant: builder.mutation({
      query: (completeData) => ({
        url: "app/v1/consultant/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["Consultants"],
    }),
  }),
});

export const {
  useCheckConsultantQuery,
  useBasicConsultantInfoMutation,
  useConsultantWorkDetailsMutation,
  usePreviewConsultantMutation,
  useCompleteConsultantMutation,
} = consultantApiSlice;
