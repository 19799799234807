import React, { useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import SA from "../../../assets/icons/saudi-arabia.png";
import UAE from "../../../assets/icons/united-arab-emirates.png";
import IN from "../../../assets/icons/india.png";
import US from "../../../assets/icons/united-states.png";

const countryList = [
    { value: "saudi arabia", flag: '😅', name: "Saudi Arabia", code:"+966"},
    { value: "uae", flag: '🇸🇿', name: "UAE", code:"+971" },
    { value: "india", flag: 'in', name: "India", code:"+91" },
    { value: "america", flag: '\uD83C\uDDFA\uD83C\uDDF8', name: "America", code:"+1" },
  ];

// Default SelectBox
function PhoneInput({
  headName,
  selectPlaceholder,
  data,
  name,
  value,
  onChange,
  defaultValue,
  errorIcon,
  error,
  formik,
  required,
  placeholder,
  type,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(value || "");

  console.log("US", '\uD83C\uDDFA\uD83C\uDDF8')


  return (
    <div className="phoneSelectItem">
      <label className="input_label">
        {headName}
      </label>
      <div>
        <input
            type={type}
            placeholder={"Enter Phone Number"}
            className={`custom-input ${error ? 'error' : ''}`}
            {...props}
            spellCheck="false"
        />
      </div>
      <div className="countryPosition">
      <FormControl sx={{ m: 0, minWidth: 120, width: "20%" }} className="phoneContainer" size="small">
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          value={value || ""}
          onChange={onChange}
          size="small"
          onBlur={formik?.handleBlur}
          name={name}
          className={`PhoneInputBox ${error ? "error" : ""}`}
          sx={{ fontSize: 13, color: "#1D1D1B", padding: "2.8px 2.8px" }}
          renderValue={
            value !== ""
              ? undefined
              : () => (
                  <span
                    style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                  >
                    country
                  </span>
            ) }
         >
          {countryList?.map((item) => (
            <MenuItem
              value={item?.value}
              sx={{ fontSize: 13, color: "#5c6d8e" }}
            >
              {item?.flag}  {item?.name} {item?.code}
             {/* <img src={item?.flag} className="countryFlag"/> {" "} {item?.name} {item?.code} */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>  
      </div>
      
      {error && (
        <p error className='inputError'>
          {errorIcon && <img src={WarningIcon} alt="Error Icon" className="errorIcon" />}
          {error}
        </p>
      )}
    </div>
  );
}

export default PhoneInput;
