import React, { useState } from "react";
import moment from "moment";
import { useUserStatusChangeMutation } from "../../features/admin/adminApiSlice";

import "./style.css";

import ViewFeedback from "../drawer/registrationLog/ViewFeedback";
import PageLoader from "../Loader/PageLoader";
import { getUserFromLocal } from "../../utils/storage";

const feedbackList = [
  {
    feedback:
      "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "Emissions Declaration",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback:
      "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "Monitoring & Reporting",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Changing Consultant",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback:
      "Lorem ipsum dolor sit amet consectetur. Mus commodo commodo nisi neque. Morbi aliquam commodo porttitor consectetur enim ut dolor. Tortor tempor ipsum convallis risus est lectus. Volutpat amet at ullamcorper integer viverra iaculis odio.",
    subject: "General",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
];

const PreviousFeedback = ({ feedbacks, isLoading }) => {
  const [checked, setChecked] = useState(true);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackSubject, setFeedbackSubject] = useState("");
  const [feedbackDate, setFeedbackDate] = useState("");
  const userDetails = getUserFromLocal();

  const feedbackOpen = (item) => {
    setViewFeedback(true);
    setFeedbackMessage(item?.message);
    setFeedbackSubject(item?.subject);
    setFeedbackDate(moment(item?.createdAt).format("DD/MM/YYYY"));
  };
  const feedbackClose = () => {
    setViewFeedback(false);
  };

  return (
    <div className="userDetailsContainer feedbacktable">
      {isLoading ? (
        <PageLoader />
      ) : (
        <table className="">
          <tr className="">
            <th className="heading">Feedback</th>
            <th className="heading">Subject</th>
            <th className="heading">Sent On</th>
            {/* <th className="heading">Answered On</th> */}
            <th className="heading">Actions</th>
          </tr>
          {feedbacks?.data?.map((item, index) => (
            <tr className="adminUserList" key={index}>
              <td style={{ width: "500px" }}>
                <div className="listedUser cursorPointer">
                  <div className="admFeedbackTitle">
                    <p style={{ width: "100%" }}>{item.message}</p>
                  </div>
                </div>
              </td>
              <td className="">
                <p>{item.subject}</p>
              </td>
              <td>
                <p>{moment(item?.createdAt).format("DD/MM/YYYY")}</p>
              </td>
              {/* <td>
                <p>{moment(item?.user?.joiningDate).format("DD/MM/YYYY")}</p>
              </td> */}
              <td
                className="feedbackAction cursorPointer"
                onClick={() => {
                  feedbackOpen(item);
                }}
              >
                <p>View Feedback</p>
              </td>
            </tr>
          ))}
        </table>
      )}

      <ViewFeedback
        isOpen={viewFeedback}
        onClose={feedbackClose}
        feedbackMessage={feedbackMessage}
        feedbackSubject={feedbackSubject}
        feedbackDate={feedbackDate}
        user={`by You (${userDetails?.userType})`}
      />
    </div>
  );
};

export default PreviousFeedback;
