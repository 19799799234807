
import React from 'react';
import StartQuote from '../../../../assets/startQuote.png';
import EndQuote from '../../../../assets/endQuote.png';
import './style.css';

function OurAim() {
  return (
    <div className=' ourAim_section'>
      <div className='ourAim_Inner'>
        <img className='aim_StartQuote' src={StartQuote} alt="" />
        <p>The greater danger for most of us lies <span className='not_text'>not</span> in</p>
        <h1 className='ourAimPara'>setting our aim too high and falling short,</h1>
        <p>but in setting our aim low and achieving our mark.</p>
        <p className='ourAim_autherName'>-Michelangelo</p>
        <img className='aim_EndQuote' src={EndQuote} alt="" />
       </div>
    </div>
    
  );
}

export default OurAim;