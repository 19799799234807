import React from "react";

import AuditScopeTable from "../auditScopeTable/AuditScopeTable";

import "./style.css";

function AuditorInfoPart1Scopes({
  co2Value,
  co2Reason,
  ch4Value,
  ch4Reason,
  n2oValue,
  n2oReason,
  hfcsValue,
  hfcsReason,
  pfcsValue,
  pfcsReason,
  sf6Value,
  sf6Reason,
  total,
}) {
  return (
    <div className="auditChatScopesCon">
      <h1 className="AudScopeTitle">Scope 1 Emissions</h1>
      <div className="auditScopesValue">
        <AuditScopeTable
          gasType={"CO2 (mt)"}
          gasValue={co2Value ? co2Value : "Not Recorded"}
          reason={co2Reason}
        />
        <AuditScopeTable
          gasType={"CH4 (mt)"}
          gasValue={ch4Value ? ch4Value : "Not Recorded"}
          reason={ch4Reason}
        />
        <AuditScopeTable
          gasType={"N2O(mt)"}
          gasValue={n2oValue ? n2oValue : "Not Recorded"}
          reason={n2oReason}
        />
        <AuditScopeTable
          gasType={"HFCs (mt)"}
          gasValue={hfcsValue ? hfcsValue : "Not Recorded"}
          reason={hfcsReason}
        />
        <AuditScopeTable
          gasType={"PFCs (mt)"}
          gasValue={pfcsValue ? pfcsValue : "Not Recorded"}
          reason={pfcsReason}
        />
        <AuditScopeTable
          gasType={"SF6 (mt)"}
          gasValue={sf6Value ? sf6Value : "Not Recorded"}
          reason={sf6Reason}
        />
        <AuditScopeTable gasType={"Total (mtCO2e)"} gasValue={total} />
      </div>
    </div>
  );
}

export default AuditorInfoPart1Scopes;
