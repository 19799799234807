import apiSlice from "../../app/api/apiSlice";

export const orgApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteStatus: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `app/v1/organization/invite/find-all${queryString}`,
        };
      },
      providesTags: ["Dashboard"],
    }),

    registerOrg: builder.mutation({
      query: (organisationInfo) => ({
        url: "/organization/registration/check",
        method: "POST",
        body: organisationInfo,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    basicInfo: builder.mutation({
      query: (basicData) => ({
        url: "/organization/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    // orgInfo: builder.mutation({
    //   query: (orgData) => ({
    //     url: "/organization/registration/org-details",
    //     method: "POST",
    //     body: orgData,
    //   }),
    //   // invalidatesTags: ["Organizations"],
    // }),

    otherInfo: builder.mutation({
      query: (otherData) => ({
        url: "/organization/registration/other-details",
        method: "POST",
        body: otherData,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    previewInfo: builder.mutation({
      query: (previewData) => ({
        url: "/organization/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    completeInfo: builder.mutation({
      query: (completeData) => ({
        url: "/organization/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      // invalidatesTags: ["Organizations"],
    }),

    sendInvite: builder.mutation({
      query: (inviteData) => ({
        url: "/app/v1/organization/invite/send-invite",
        method: "POST",
        body: inviteData,
      }),
      invalidatesTags: ["stageInfo", "dashBoardStatus"],
    }),

    getUserInfo: builder.mutation({
      query: () => ({
        url: "/organization/user/get-user",
        method: "GET",
      }),
      // invalidatesTags: ["Organizations"],
    }),
  }),
});

export const {
  useInviteStatusQuery,
  useRegisterOrgMutation,
  useBasicInfoMutation,
  // useOrgInfoMutation,
  useOtherInfoMutation,
  usePreviewInfoMutation,
  useCompleteInfoMutation,
  useSendInviteMutation,
  useGetUserInfoMutation,
  useMutation,
} = orgApiSlice;
