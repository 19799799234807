
import React, {useState} from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import './style.css';

import SeeLevelRise from '../../../assets/homeImages/SeeLevelRise.svg';
import ImpactOnWater from '../../../assets/homeImages/ImpactOnwater.svg';
import FoodSecurity from '../../../assets/homeImages/FoodSecurity.svg';
import ImpactOnFauna from '../../../assets/homeImages/ImpactOnFauna.svg';
import FifteenDegreeRight from '../../../assets/icons/fifteenArrow.svg';
import TwoDegreeRight from '../../../assets/icons/TwoDegreeRight.svg';
import ThreeDegreeRight from '../../../assets/icons/ThreeDegreeRightImg.svg';
import FourDegreeRight from '../../../assets/icons/FourDegreeRight.svg';




function MobileClimate() {
  const [value, setValue] = useState('1');

  const climateTabFun = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
       <Box sx={{ width: '100%', typography: 'body1' }} className='climateMobileSec'>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={climateTabFun} aria-label="lab API tabs example" className='climateTabs'>
              <Tab icon={<img src={SeeLevelRise} />} label="Impacts on Sea level Rise" value="1" />
              <Tab icon={<img src={ImpactOnWater} />} label="Impacts on Water" value="2" />
              <Tab icon={<img src={FoodSecurity} />} label="Impacts on Food" value="3" />
              <Tab icon={<img src={ImpactOnFauna} />} label="Impacts on Flora & Fauna" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={FifteenDegreeRight} alt="" /> <span className='degreeSpan mobFifteen'>1.5˚C</span>
              </div>
              <div className='seaLevelText'>
                <p>Rising sea levels displace 46 million people</p>
                <h3 className='mobFifteenHeading'>Sea Level Rise of 48cm</h3>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={TwoDegreeRight} alt="" /> <span className='degreeSpan mobTwoDegree'>2.0˚C</span>
              </div>
              <div className='seaLevelText'>
                <p>Fewer opportunities for infrastructure adaptation</p>
                <h3 className='mobTwoHeading'>Sea Level Rise of 56cm</h3>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobThreeDegree'>3.0˚C</span>
              </div>
              <div className='seaLevelText'>
                <p>Near-complete melting of the Greenland ice sheet</p>
                <h3 className='mobThreeHeading'>Sea Level Rise of 7+ meters</h3>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobFourDegree'>4.0˚C</span>
              </div>
              <div className='seaLevelText'>
                <p>470 to 760 million people at risk</p>
                <h3 className='mobFourHeading'>Sea Level Rise of nearly 9 meters</h3>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={FifteenDegreeRight} alt="" /> <span className='degreeSpan mobFifteen'>1.5˚C</span>
              </div>
              <div>
                <p>Water shortage in the Mediterranean, Australia, Brazil and Asia</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={TwoDegreeRight} alt="" /> <span className='degreeSpan mobTwoDegree'>2.0˚C</span>
              </div>
              <div>
                <p>8% of the global population faces severe water shortages</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobThreeDegree'>3.0˚C</span>
              </div>
              <div>
                <p>Almost half of the Himalayan high mountain glaciers lost</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobFourDegree'>4.0˚C</span>
              </div>
              <div>
                <p>More frequent and severe extreme droughts</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
           <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={FifteenDegreeRight} alt="" /> <span className='degreeSpan mobFifteen'>1.5˚C</span>
              </div>
              <div>
                <p>Wheat, rice, maize, and soybean production suffers</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={TwoDegreeRight} alt="" /> <span className='degreeSpan mobTwoDegree'>2.0˚C</span>
              </div>
              <div>
                <p>Agriculture yields fall rapidlys</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobThreeDegree'>3.0˚C</span>
              </div>
              <div>
                <p>Fish species go extinct locally</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobFourDegree'>4.0˚C</span>
              </div>
              <div>
                <p>High levels of food insecurity, development path reversed</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={FifteenDegreeRight} alt="" /> <span className='degreeSpan mobFifteen'>1.5˚C</span>
              </div>
              <div>
                <p>9 out of 10 coral reefs at risk from severe degradation</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={TwoDegreeRight} alt="" /> <span className='degreeSpan mobTwoDegree'>2.0˚C</span>
              </div>
              <div>
                <p>All coral reefs disappear</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobThreeDegree'>3.0˚C</span>
              </div>
              <div>
                <p>Marine ecosystems may collapse</p>
              </div>
            </div>
            <div className='mobTabSec'>
              <div className='mobileDegreeSec'>
                <img src={ThreeDegreeRight} alt="" /> <span className='degreeSpan mobFourDegree'>4.0˚C</span>
              </div>
              <div>
                <p>Half of all plant and animal species face local extinction</p>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>

  );
}

export default MobileClimate;