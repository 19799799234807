import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import RoleManageDetails from "../../../components/adminComponents/userDetails/RoleManageDetails";

import "./style.css";

function AdminRoleManage() {
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu roleMangeMenu={"roleMangeMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <div>
            <h1 className="feedbackTitle">Role Management</h1>
          </div>
          <RoleManageDetails />
        </div>
      </div>
    </div>
  );
}

export default AdminRoleManage;
