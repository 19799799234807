import React from "react";

import TextComponent from "../textComponent/TextComponent";
import "./style.css";

function IssueStatusChange({ text, item, reciever }) {
  return (
    <div className={reciever ? "changeIssueContainer" : "issueRightChatBox"}>
      <div className="changeIssueInnerCon">
        {item?.type === "issueStatusChange" ? (
          <p style={{ fontSize: "14px", margin: 0 }}>
            This issue has been marked as resolved. If you wish to reopen the
            issue, please comment in the same thread.
          </p>
        ) : (
          <p style={{ fontSize: "14px", margin: 0 }}>
            The Issue type has changed from {item?.issueTypeChange?.from} to{" "}
            {item?.issueTypeChange?.to}
          </p>
        )}
        <TextComponent item={item} />
      </div>
    </div>
  );
}

export default IssueStatusChange;
