import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import "./style.css";

function UserReqBreadcrumbs() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" className="userReqManagBread">
      User Management
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/adminDashboard" className="userReqAllUserBread">
      All Users
    </Link>,
    <Typography key="3" color="text.primary" className="userRequestBread">
      Requests
    </Typography>,
  ];

  return (
    <Stack spacing={2} className="userReqBreadcum">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default UserReqBreadcrumbs;
