
import React, { useEffect, useState } from 'react';
import Logo from '../assets/logo.png';
// import '../styles/Style.css';
import LogoName from "../assets/FooterLogo.png";
import BurgerMenu from "../assets/burderMenu.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faChevronRight,
  faShareNodes,
  faToggleOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import scrollToRef from '../utils/scrollUtils';



function Header({aboutSectionRef, whereWeAreSectionRef, whoIsSectionRef, ourProcessSectionRef, chairmanSectionRef, contactSectionRef}) {
  const navigate = useNavigate();


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isScrolled, setIsScrolled] = useState(false);
  const [mobileHeader, setMobileHeader] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const signUpFuc = () =>{
    navigate("/auth/signup")
  }
  const loginFuc = () =>{
    navigate("/auth/login")
  }


  /* mobile screen */
  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileHeader(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileHeader(window.innerWidth <= 575);
  };

  //  Scroll Header

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //    };
  //  }, []);

  // const headerClasses = isScrolled ? 'header-scroll' : 'header-area';

  // BurgerMenu

  // function toggle(name) {
  //   const element = document.getElementById(name);
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // }


  return (
    <header className="header-area headerClasses">
        {mobileHeader ? (
          <nav className="mobile_header">
            <div className="mobileHeader_Col1" onClick={toggleMenu}>
              <img  style={{ width: "2rem", height: "2rem" }} src={BurgerMenu} alt="" />
            </div>
            <div className="mobileHeader_Col2">
              <img src={LogoName} alt="" />{" "}
            </div>
          </nav>
       ) : (
      <div className="">
        <div className="header-main">
          <div className="header-logo">
            <div className="navbar-brand">
              <img
                src={Logo}
                alt=""
                onClick={""}
              />
            </div>
          </div>

          <div class="collapse navbar-collapse">
            <div>
              <ul class="navbar-nav ms-auto align-items-center">
                <li onClick={() => scrollToRef(aboutSectionRef)}>About</li>
                <li onClick={() => scrollToRef(whereWeAreSectionRef)}>Things To Know</li>
                <li  onClick={() => scrollToRef(whoIsSectionRef)}>Who Is This For?</li>
                <li  onClick={() => scrollToRef(ourProcessSectionRef)}>Our Process</li>
                <li  onClick={() => scrollToRef(chairmanSectionRef)}>Chairman’s Message</li>
                <li onClick={() => scrollToRef(contactSectionRef)}>Contact</li>
             </ul>
            </div>
                <div className='loginSignUp_row loginBtn_'>
                    <ul  className='navbar-nav ms-auto align-items-center'>
                     <li><a class="login-btn  Link loginBtn_Color" onClick={loginFuc}>Login</a></li>
                     <li><a class="login-btn  Link  loginactive" onClick={signUpFuc} >Sign Up</a></li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
       )}



     {/* ..........................BurgerMeue........................... */}
     {isMenuOpen && (
        <div className="mobNav_container">
          <div className="menuList_container pointer" 
            onClick={() => {
             scrollToRef(aboutSectionRef);
             toggleMenu();
             }}
            >
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> About Us </h4>
            </div>
            <div className="menuList_thirdCol"  >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div className="menuList_container pointer" 
            onClick={() => {
             scrollToRef(whereWeAreSectionRef);
             toggleMenu();
             }}
            >
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Things To Know </h4>
            </div>
            <div className="menuList_thirdCol"  >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div className="menuList_container pointer"
            onClick={() => {
              scrollToRef(ourProcessSectionRef);
              toggleMenu();
              }}
           >
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Our Process </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div
            className="menuList_container pointer"
            onClick={() => {
              scrollToRef(chairmanSectionRef);
              toggleMenu();
              }}
          >
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Chairman’s Message </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          <div
            className="menuList_container pointer"
            onClick={() => {
              scrollToRef(contactSectionRef);
              toggleMenu();
              }}
          >
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol">
              <h4 className="burgerMenu_List_Text"> Contact </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div>
          {/* <div className="menuList_container pointer">
            <div className="menuList_firstCol">
              <FontAwesomeIcon
                icon={faUser}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
            <div className="menuList_secondCol" onClick={''}>
              <h4 className="burgerMenu_List_Text"> Log Out </h4>
            </div>
            <div className="menuList_thirdCol">
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fontAwesomeIcon_img"
                style={{ color: "#c4c7d2" }}
              />
            </div>
          </div> */}
        </div>
      )}



    </header>
    
  );
}

export default Header;