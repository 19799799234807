import React from "react";
import InputBox from "../../../InputBox/InputBox";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

function OffsetsAndCreditsPrev({ resultHeaderName, gapForSee, data }) {
  return (
    <div className="creditEmissPreCon">
      <h1 className="creditEmissPreTitle">{resultHeaderName}</h1>
      <div className="ghgEmissCreditPreCol">
        <div className="resultPreCredit">
          <p className="resultCreditTitle">
            {!gapForSee ? "Q1 Result GHG (tCO2e)" : "Gap = E- A"}
          </p>
          <div className="resultCreditValue">
            <h1>tCO2e</h1>
            <p>{data?.value}</p>
          </div>
        </div>
        <div className="resultPreCredit2">
          <p className="resultCreditTitle">Explanatory Notes</p>
          <p className="resultDisrip">{data?.explanatoryNotes}</p>
        </div>
      </div>
    </div>
  );
}

export default OffsetsAndCreditsPrev;
