import apiSlice from "../app/api/apiSlice";

export const adminFeedbackApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFeedbacks: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/user/find-all-feedbacks${queryString}`,
        };
      },
      providesTags: ["AdminFeedback"],
    }),
  }),
});

export const { useGetAllFeedbacksQuery } = adminFeedbackApiSlice;
