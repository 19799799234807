import React from 'react';

import "./style.css";
import PdfUpload from '../../pdfUpload/PdfUpload';

function MyProfileAdditDetails() {

  return (
    <div className='myProAddiDetailsCon'>
        <h1>Additional Details</h1>

        <div className='myProAddiDetailsCol1'>
            <span className='myProAddiTitle'>Registration Details</span>
            <div className='profilePdfSec'>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
            </div>
        </div>

        <div className='myProAddiDetailsCol1'>
            <span className='myProAddiTitle'>Other Relevant Information</span>
            <div className='myProAddiDetSec'>
                <div className='profilePdfRow'>
                    <PdfUpload faXmarkDisplay={"none"} />
                </div>
                <div className='myProAddiDetDataRow'>
                    <div className='myProAddiDetData'>
                        <span>Website link</span>
                        <p>www.organization.com</p>
                    </div>
                    <div className='myProAddiDetData'>
                        <span>link Name</span>
                        <p>SomethingLink</p>
                    </div>
                    <div className='myProAddiDetData'>
                        <span>Website link</span>
                        <p>www.organization.com</p>
                    </div>
                    <div className='myProAddiDetData'>
                        <span>link Name</span>
                        <p>SomethingLink</p>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default MyProfileAdditDetails;