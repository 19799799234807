import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import "./style.css";
import { useLocation } from "react-router-dom";

function AdminProBreadcrumbs({hideRequestsLink}) {
  // console.log("🚀 ~ file: AdminProBreadcrumbs.js:12 ~ AdminProBreadcrumbs ~ hideRequestsLink:", hideRequestsLink)
  const location = useLocation();
  const { state } = location;
  
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" className="viewProManagBread">
      User Management
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/adminDashboard" className="viewProListBread">
      All Users
    </Link>,
    !hideRequestsLink === true && (
      <Link underline="hover" key="3" color="inherit" href="/UserRequestPage" className="viewProListBread">
        Requests
      </Link>
    ),
    <Typography key="4" color="text.primary" className="viewProTextBread">
      Profile
    </Typography>,
  ];

  return (
    <Stack spacing={2} className="viewProBreadcrumbs">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default AdminProBreadcrumbs;
