import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import TextArea from "../../../InputBox/textAreaBox/TextArea";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function AdditInformation({ formik, activeSection, setActiveSection }) {
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "additionalInfo" : false);
  };
  return (
    <div className="emisSectionGap" id="additionalInfo">
      <Accordion
        expanded={activeSection === "additionalInfo"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>ADDITIONAL INFORMATION</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <h1>
            Information on any contractual provisions addressing GHG-related
            risks and obligations
          </h1>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.additionalInfo?.ghgContractualProvisionsInfo
                  ?.isApplicable
              }
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "additionalInfo.ghgContractualProvisionsInfo.isApplicable",
                  value
                );
                formik.setFieldValue(
                  "additionalInfo.ghgContractualProvisionsInfo.specify",
                  ""
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Applicable"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.additionalInfo?.ghgContractualProvisionsInfo
            ?.isApplicable && (
            <div className="form-row addiTextArea specifyRow ">
              <TextArea
                headName={"Specify Here"}
                type="text"
                placeholder="Enter the value"
                name="additionalInfo.ghgContractualProvisionsInfo.specify"
                value={
                  formik?.values?.additionalInfo?.ghgContractualProvisionsInfo
                    ?.specify
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.additionalInfo?.ghgContractualProvisionsInfo
                    ?.specify &&
                  formik.errors.additionalInfo?.ghgContractualProvisionsInfo
                    ?.specify
                }
                errorIcon={WarningIcon}
              />
            </div>
          )}
          <p>Information on any GHG sequestration</p>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.additionalInfo?.ghgSequestrationInfo
                  ?.isApplicable
              }
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "additionalInfo.ghgSequestrationInfo.isApplicable",
                  value
                );
                formik.setFieldValue(
                  "additionalInfo.ghgSequestrationInfo.specify",
                  ""
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Applicable"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.additionalInfo?.ghgSequestrationInfo
            ?.isApplicable && (
            <div className="form-row addiTextArea specifyRow">
              <TextArea
                headName={"Specify Here"}
                type="text"
                placeholder="Enter the value"
                name="additionalInfo.ghgSequestrationInfo.specify"
                value={
                  formik?.values?.additionalInfo?.ghgSequestrationInfo?.specify
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.additionalInfo?.ghgSequestrationInfo
                    ?.specify &&
                  formik.errors.additionalInfo?.ghgSequestrationInfo?.specify
                }
                errorIcon={WarningIcon}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AdditInformation;
