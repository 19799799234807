import React from 'react';

import "./style.css";

function RatingRegDetails() {
  return (
    <div className='rateRegisOnCon'>
        <div className='rateRegisOnRow'>
            <span>Registered On</span>
            <p>2023/03/12</p>
        </div>
        <div className='rateRegisOnRow'>
            <span>Net Zero Target</span>
            <p>2030</p>
        </div>
        <div className='rateRegisOnRow'>
            <span>Gap (expected-Acquired)</span>
            <p>123</p>
        </div>
        <div className='rateRegisOnRow'>
            <span>Rating</span>
            <p>EEE+</p>
        </div>

    </div>
  )
}

export default RatingRegDetails;