
import React from 'react';
import ChairmanImage from '../../../../assets/homeImages/chairmanImage.png';
import ChairmanLeftImg from '../../../../assets/homeImages/chairmainLeftImg.svg';
import ChairmanRightImg from '../../../../assets/homeImages/chairmanRightImg.png';
import './style.css';


function Chairman({chairmanSectionRef}) {
  return (
    <div className='chairman_section' ref={chairmanSectionRef}>
        <div className='chairmanSec_Col1'>
            <h1>What Our Chairman Says!</h1>
            <h2>“Climate change is the biggest challenge we face and we need a system change to ensure we can address it.”</h2>
            <p>
              Our Chairman Eng Faris Saeed, is the visionary behind SEE Rating.
              In his position as Chair of the SEE Rating Advisory Board, he thinks
              we need to move beyond the status quo and create a new system of tracking our
              emissions that mimic financial ratings, based on real measurable action, not just statements.
            </p>
        </div>
        <div className='chairmanSec_Col2'>
          <div className='chairImgDiv'>
            <img src={ChairmanImage} alt="" className='chairImg1' />
            <h2 className='chairmanName'>Faris Saeed</h2>
            <h3>Chairman & CEO</h3>
           <img src={ChairmanLeftImg} alt="" className='chairImg2' />
           <img src={ChairmanRightImg} alt="" className='chairImg3' />
          </div>
        </div>
      <div className='chairmanBackground-text'>Message</div>
   </div>
    
  );
}

export default Chairman;