import React from "react";
import SendBySection from "../sendBySection/SendBySection";

import "./style.css";

function SummerySection({ summaryText, descText, item }) {
  return (
    <div className="reciveSummarySec">
      <div className="summaryCol">
        <h1>Summary:</h1>
        <p>{summaryText}</p>
      </div>
      <div className="descripCol">
        <h1>Description:</h1>
        <p>{descText}</p>
      </div>
      <SendBySection item={item} />
    </div>
  );
}

export default SummerySection;
