import react from "react";

import "./style.css";
import ProfileHeadquarters from "../profileHeadquarters/ProfileHeadquarters";

function ProfileOrgDetails({ userData }) {
  return (
    <div className="viewOrgDetContainer">
      <div className="userProDetailsRow">
        <div className="userOrgDetails">
          <p>Name of Organization</p>
          <h1>{userData?.data?.orgDetails?.orgName}</h1>
        </div>
        <div className="userOrgDetails">
          <p>Website link</p>
          <h1>{userData?.data?.orgDetails?.orgWebsiteLink}</h1>
        </div>
      </div>
      <div className="userOrgDetails">
        <p>Position in the organization</p>
        <h1>{userData?.data?.orgDetails?.positionInOrg}</h1>
      </div>

      {/* Headquarter Section */}
      <ProfileHeadquarters
        HeadquartersTitle={"Headquarters"}
        address={userData?.data?.orgDetails?.orgHeadquarter}
      />

      <div className="userOrgDetails">
        <p>Number of Employees</p>
        <h1>{userData?.data?.orgDetails?.noOfEmployees}</h1>
      </div>
      <div className="userProDetailsRow">
        <div className="userOrgDetails">
          <p>Sector</p>
          <h1>{userData?.data?.orgDetails?.orgActiveSector}</h1>
        </div>
        <div className="userOrgDetails">
          <p>Sub - Category</p>
          <h1>{userData?.data?.orgDetails?.orgActiveSubCategory}​</h1>
        </div>
      </div>
    </div>
  );
}

export default ProfileOrgDetails;
