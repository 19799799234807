import Baitykool from '../../../../assets/homeImages/Baitykool_logo.svg';
import Davis from '../../../../assets/homeImages/UC_Davis_logo.svg';
import Rochester from '../../../../assets/homeImages/Rochester_Institute_logo.svg';
import Bordeaux from '../../../../assets/homeImages/Bordeaux_logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import './style.css';

 function CompanyLogo() {
  const [autoplay, setAutoplay] = useState(true);
  const sliderRef = useRef(null);

  const logoData = [
    {
      img: Baitykool,
    },
    {
      img: Davis,
    },
    {
      img: Rochester,
    },
    {
      img: Bordeaux,
    },

    {
      img: Baitykool,
    },
    {
      img: Davis,
    },
    {
      img: Rochester,
    },
    {
      img: Bordeaux,
    },
  ];

  const repeatedLogoData = [...logoData,...logoData];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    // pauseOnHover: true,
    // autoplayHoverPause: true, // Pause autoplay on hover
    cssEase: "linear",
    responsive: [
            { breakpoint:1350,
              settings:{
                slidesToShow:5,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ]    
    
  };
  return (
    <div className='logoSlider_container'>
    <Slider
      {...settings} 
      className='slider_section'
    >
      {repeatedLogoData?.map((item, index) => (
      <div className='companyLogoSection'>
        <img src={item.img} />
      </div>
      ))}
    </Slider>
    </div>
  );
}
export default CompanyLogo;