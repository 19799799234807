import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";
import { OutlinedInput } from "@mui/material";

function OffsetSelectProject({
  value,
  name,
  onChange,
  headName,
  placeholder,
  error,
  errorIcon,
}) {
  return (
    <FormControl
      variant="standard"
      sx={{ m: 1, minWidth: 120 }}
      className="selectoffsetCon"
      value={value}
    >
      <label className="selectoffsetLabel">{headName}</label>
      <Select
        displayEmpty
        name={name}
        value={value}
        onChange={onChange}
        // input={<OutlinedInput />}
        renderValue={
          value !== ""
            ? undefined
            : () => (
                <span
                  style={{ fontSize: "14px", color: "#1D1D1B", opacity: 0.2 }}
                >
                  {placeholder}
                </span>
              )
        }
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="" className="defalutSelect">
          Select the type
        </MenuItem>
        <MenuItem value="Offset Project 1">Offset Project 1</MenuItem>
        <MenuItem value="Offset Project 2">Offset Project 2</MenuItem>
        <MenuItem value="Offset Project 3">Offset Project 3</MenuItem>
        <MenuItem value="Offset Project 4">Offset Project 4</MenuItem>
        <MenuItem value="Offset Project 5">Offset Project 5</MenuItem>
      </Select>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </FormControl>
  );
}

export default OffsetSelectProject;
