import react, { useState } from "react";

import "./style.css";

import AdminProBreadcrumbs from "../breadcrumbs/adminProfileBreadcrumbs/AdminProBreadcrumbs";

import RejectIcon from "../../../assets/icons/rejectIcon.svg";
import ApproveIcon from "../../../assets/icons/approveIcon.svg";
import RejectProfilePopUp from "../profileApprovePopUp/rejectProfilePopUp/RejectProfilePopUp";
import AcceptProfilePopUp from "../profileApprovePopUp/acceptProfilePopUp/AcceptProfilePopUp";
import { useApproveUserStatusMutation } from "../../../features/admin/adminApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RegistraionLog from "../../drawer/registrationLog/RegistraionLog";
import PageLoader from "../../Loader/PageLoader";
import RejectImage from "../../../assets/icons/rejectImage.svg";
import AcceptImage from "../../../assets/icons/acceptImage.svg";

function ProfileViewSection({ userId, userType, userData, hideRequestsLink }) {
  const [rejectProfile, setRejectProfile] = useState(false);
  const [acceptProfile, setAcceptProfile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const registraionStatus = userData?.data?.status;
  console.log("RegistraionStatus", registraionStatus);

  const [
    approveUserStatus,
    {
      data: editData,
      isLoading: userStatusIsLoading,
      isFetching: userStatusIsFetching,
      isSuccess: userStatusIsSuccess,
      error: userStatusError,
      isError: userStatusIsError,
    },
  ] = useApproveUserStatusMutation();

  const rejectProfilePopUpOpen = () => {
    setRejectProfile(true);
  };

  const rejectProfilePopUpClose = () => {
    setRejectProfile(false);
  };

  const acceptProfilePopUpOpen = () => {
    setAcceptProfile(true);
  };

  const acceptProfilePopUpClose = () => {
    setAcceptProfile(false);
  };

  const handleAcceptUser = () => {
    setAcceptProfile(false);
    approveUserStatus({
      _id: userId,
      userType: userType,
      action: "approved",
    })
      .unwrap()
      .then(() => {
        acceptProfilePopUpClose();
        dispatch(showSuccess({ message: `${userType} Approved Successfully` }));
        navigate("/adminDashboard");
      })
      .catch((e) => {
        dispatch(
          showError({
            message: e?.data?.message ?? "Something went wrong",
          })
        );
      });
  };

  const handleRejectUser = (reason) => {
    approveUserStatus({
      _id: userId,
      userType: userType,
      action: "rejected",
      reason: reason,
    })
      .unwrap()
      .then(() => {
        rejectProfilePopUpClose();
        dispatch(showSuccess({ message: `${userType} Rejected Successfully` }));
        navigate("/adminDashboard");
      })
      .catch((e) => {
        dispatch(
          showError({
            message: e?.data?.message ?? "Something went wrong",
          })
        );
      });
  };

  return (
    <div className="profileTitleContainer">
      {(userStatusIsFetching || userStatusIsLoading) && <PageLoader />}
      <AdminProBreadcrumbs hideRequestsLink={hideRequestsLink} />
      <div className="proViewTitleSec">
        <div className="viewProTitleCol1">
          <h1>Profile View</h1>
        </div>
        {registraionStatus === "registered" ? (
          <div className="viewProTitleCol2">
            <div className="rejectBtn">
              <button
                className="cursorPointer"
                onClick={rejectProfilePopUpOpen}
              >
                <img src={RejectIcon} alt="rejectIcon" />
                <p>Reject</p>
              </button>
            </div>
            <div className="approveProfileBtn">
              <button
                className="cursorPointer"
                onClick={acceptProfilePopUpOpen}
              >
                <img src={ApproveIcon} alt="rejectIcon" />
                <p>Approve</p>
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* Reject Profile */}
      <RejectProfilePopUp
        open={rejectProfile}
        onClose={rejectProfilePopUpClose}
        onReject={handleRejectUser}
        question="Are you sure you want to reject this application?"
        statement="The user will be notified of the rejection. They can apply again to register on SEE Rating Platform"
        acceptButtonText="Yes, Reject"
        rejectButtonText="No, Go back"
        icon={RejectImage}
      />
      {/* Accept Profile */}
      <AcceptProfilePopUp
        open={acceptProfile}
        onClose={acceptProfilePopUpClose}
        onAccept={handleAcceptUser}
        question="Are you sure you want to accept this application?"
        statement="The user will be notified of the successful registration. They can now login and start the rating process"
        acceptButtonText="Yes, Accept"
        rejectButtonText="No, Go back"
        icon={AcceptImage}
      />
    </div>
  );
}

export default ProfileViewSection;
