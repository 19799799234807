import React from "react";

import "./style.css";

function SummarizeIssueInput({ summary, setSummary }) {
  return (
    <input
      type="text"
      placeholder="Summarize the issue"
      className="summarizeIssueBox"
      value={summary}
      onChange={(e) => setSummary(e.target.value)}
    />
  );
}

export default SummarizeIssueInput;
