
import React from 'react';
import BuildingImg from '../../../../assets/homeImages/instituteImage.png';
import './style.css';


function EEEBuilding() {
  return (
   <div className='EEETop_sec'>
    <div className='container building_section'>
        <div className='building_Col1'>
           <img src={BuildingImg} alt="" />
        </div>
        <div className='building_Col2'>
            <p className='institutePara'>
               SEE Institute moved into its new office building in Dubai in 2023.
               All 60 employees are based there and the organisation is aiming for EEE+ rating.
               It has 3,133tCO2e of “embodied carbon” from when the building was constructed and
               the energy demand from the use of the building by its occupants adds annual emissions. 
               However, the SEE Institute building also produces enough solar energy to compensate for 
               these emissions. Currently SEE Institute is undergoing the journey to be SEE rated, 
               including working with an independent third party auditor. 
            </p>
            <div className='buildingBtn_row'> 
               <div className='buildingBtn_Col1'>
                  <h2>5,955 sqm</h2>
                  <p>Built up area</p>
               </div>
               <div className='buildBtn_divider'></div>
               <div className='buildingBtn_Col2'>
                  <h2>~3,000 tCO2e</h2>
                  <p>Total embodied</p>
               </div>
            </div>
        </div>
    </div>
    </div>
    
  );
}

export default EEEBuilding;