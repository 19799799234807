
import React from 'react';
import AboutImg from '../../../../assets/homeImages/aboutImage.svg';
import AboutUsWatermark from '../../../../assets/AboutUsWatermark.png';
import './style.css';

function About({aboutSectionRef}) {
  return (
    <div className='container about_section' ref={aboutSectionRef}>
        <div className='aboutSec_Col1'>
            <h1>About Us</h1>
            <p>
              SEE Rating is the first transparent, robust & comprehensive global gold standard 
              rating system to ensure organisations can take credible action towards a net zero
              future by latest 2050. 
            </p>
            <p className='about_p'>
              We provide an end-to-end solution that impartially 
              assesses and rates business and industry (B&I) organisations to become net 
              zero based on the impact their actions have on their emissions. To address any 
              allegations of greenwash,  we require all B&I to provide third party assured data 
              on their emissions, action plans and impact of their actions.
            </p>
            <p className='about_p'>
            We only provide the platform and make no judgement ourselves – an AI based program will allocate 
            the rating based on key conditions being met.
            Everything is transparent on our platform, where the data and assessments are placed on a blockchain platform. 
            This way people can check that B&I have actually gone through the necessary 
            steps to be allocated SEE rating correctly or not.
            </p>
        </div>
        <div className='aboutSec_Col2'>
           <img src={AboutImg} alt="" />
        </div>

        <div className='aboutBackground-text'>About<br/> Us</div>
    </div>
    
  );
}

export default About; 