import React from "react";

import InputBox from "../../../InputBox/InputBox";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import NotRecorded from "../notRecorded/NotRecorded";

function InfoEmissionScopes({
  formik,
  headName,
  value1,
  value2,
  value3,
  name1,
  name2,
  name3,
  error2,
  error3,
}) {
  const handleChange = (e) => {
    formik?.handleChange(e);
    formik?.setFieldValue(`${name2}`, "");
    formik?.setFieldValue(`${name3}`, "");
  };
  return (
    <div className="infoEmisScopesRow">
      <div className="infoEmisScopesCol1">
        <NotRecorded
          checked={value1}
          value={value1}
          // onChange={formik?.handleChange}
          onChange={handleChange}
          name={name1}
        />
      </div>
      <div className="infoEmisScopesCol2">
        <InputBox
          type="number"
          headName={headName}
          placeholder="Enter the value"
          name={name2}
          value={value2}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={error2}
          errorIcon={WarningIcon}
          disabled={value1}
        />
      </div>
      <div className="infoEmisScopesCol3">
        <TextArea
          headName={"If not recorded, specify the reason"}
          type="text"
          placeholder="Tell us why here"
          name={name3}
          value={value3}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={error3}
          errorIcon={WarningIcon}
          disabled={!value1}
        />
      </div>
    </div>
  );
}

export default InfoEmissionScopes;
