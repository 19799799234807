
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleMinus, faFileArrowUp, faXmark} from "@fortawesome/free-solid-svg-icons";
import PdfUploadImg from '../../assets/icons/Pdf _File_Icon.svg';


function PdfUpload({ pdfType, faXmarkDisplay,onRemove, src, fileName }) {

  const navigate = useNavigate();
  const extractFilename = (url) => {
    const regex = /-see-rating-(.+\.pdf)$/;
    const match = url?.match(regex);

    if (match) {
      return match[1];
    } else {
      return "No filename found in the URL.";
    }
  };

  const pdfName = extractFilename(pdfType);

  function TruncateText({ text }) {
    if (text.length <= 6) {
      return <p>{text}</p>;
    }
  
    const truncatedText = `${text.slice(0, 6)}..`;
    const fileExtension = text.split('.').pop();
  
    return (
      <p>
        {truncatedText}.{fileExtension}
      </p>
    );
  }

  return (
    // <a href={src} download={fileName} className="pdf-link">
   <div className="pdfUpload" >
    <FontAwesomeIcon
       icon={faXmark}
       className="pdfUpload_closeBtn cursorPointer"
       style={{ color: "rgb(118 118 114)", display:faXmarkDisplay }}
       onClick={()=>{onRemove(src)}}
      />
     <div className='pdfUpload_innerdiv'>
        <img className='pdfIcon' src={PdfUploadImg} alt="pdfimg" />
        <TruncateText text={pdfName} />
     </div>
  </div>
  // </a>          
    
  );
}

export default PdfUpload;