import apiSlice from "../app/api/apiSlice";

export const accessControlApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/admin/v1/access-control/find-all${queryString}`,
        };
      },
      providesTags: ["AccessControl"],
    }),
    updateAccess: builder.mutation({
      query: (data) => ({
        url: "/admin/v1/access-control/update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AccessControl"],
    }),
  }),
});

export const { useGetAllQuery, useUpdateAccessMutation } =
  accessControlApiSlice;
