import { useState, useEffect } from "react";
import {
  useGetDashBoardStatusQuery,
  useGetStageQuery,
} from "../../features/status/statusApiSlice";

import "./style.css";

import AddIcon from "../../assets/icons/AddPlusBtn.svg";
import { useNavigate } from "react-router-dom";
import ContinueDeclarBtn from "../button/dashboardBtn/ContinueDeclerationBtn";
import ViewAllDetailsBtn from "../button/dashboardBtn/ViewAllDetailsBtn";
import StatusTab from "../tabs/StatusTab";
import DeclerationTimeline from "../dashTimeline/DeclerationTimline";

function OrganizationTimeline({ userType }) {
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  const {
    data: dashBoardStatusData,
    isLoading: dashBoardStatusIsLoading,
    isSuccess: dashBoardStatusIsSuccess,
    error: dashBoardStatusError,
    isError: dashBoardStatusIsError,
  } = useGetDashBoardStatusQuery({});

  const {
    data: stageData,
    isLoading: stageIsLoading,
    isSuccess: stageIsSuccess,
    error: stageError,
    isError: stageIsError,
  } = useGetStageQuery({});

  useEffect(() => {
    if (dashBoardStatusIsSuccess) {
      setStatus(dashBoardStatusData?.data?.stageInfo?.currentStage);
    }
  }, [dashBoardStatusIsSuccess]);

  const handleOpenEmissionDeclerationForm1 = () => {
    navigate("/emissionsDeclaration");
  };

  const handleOpenEmissionDeclerationPreviewForm2 = () => {
    navigate("/EmissionStep2Preview");
  };

  const handleOpenEmissionDeclerationForm2 = () => {
    navigate("/emissionStep2");
  };

  console.log("stageData", stageData);
  console.log("stageData Current Stage", stageData?.data?.stage);

  return (
    <div className="orgDashTimeContainer">
      <div className="orgRatingStatus">
        <div className="orgRatingCo1">
          <div style={{ display: "flex", gap: "8px" }}>
            <p>Rating Status</p>
            {(stageData?.data?.stage === "Emission Resubmit" ||
              stageData?.data?.stage === "Roadmap Resubmit") && (
              <StatusTab text="Update Required" type="pending" />
            )}
            {stageData?.data?.isRejected === true && (
              <StatusTab text="Rejected" type="rejected" />
            )}
          </div>
          {userType === "organization" &&
            stageData?.data?.stage !== "Initial Declaration" &&
            stageData?.data?.stage !== "Emission Declaration" && (
              <div>
                <ViewAllDetailsBtn />
              </div>
            )}
        </div>
      </div>
      <p className="orgDeclarText declarePadd">
        Your consultant has not yet declared your emissions. Please ask them to
        declare the emissions to see some progress
      </p>
      <DeclerationTimeline status={stageData?.data?.stage} />
    </div>
  );
}

export default OrganizationTimeline;
