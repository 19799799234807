import React from "react";
import ConsultantTimeline from "./ConsultantTimeline";
import ConsultantStatus from "./ConsultantStatus";
import AuditorTimeline from "./AuditorTimeline";
import AuditorStatus from "./AuditorStatus";

const AuditorDashboard2 = ({ status, stageData }) => {
  let timeline = null;
  if (
    status?.data?.organization?.organizationStatus === "active" &&
    status?.data?.consultant?.consultantStatus === "active" &&
    status?.data?.auditor?.auditorStatus === "active"
  ) {
    timeline = <AuditorTimeline status={status} stageData={stageData} />;
  } else {
    timeline = <AuditorStatus status={status} stageData={stageData} />;
  }
  return <div>{timeline}</div>;
};

export default AuditorDashboard2;
