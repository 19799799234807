import React from "react";
import Chart from "react-apexcharts";

function BarChart({ totalEmissionsData }) {
  if (!totalEmissionsData?.data || totalEmissionsData?.data?.length === 0) {
    return null;
  }

  const years = totalEmissionsData?.data?.map((item) => item?._id?.toString());
  const emissions = totalEmissionsData?.data?.map(
    (item) => item?.totalEmissions
  );

  const tablelValues = [0, 5, 10, 15, 20, 25];

  const series = [
    {
      name: "Total Emissions",
      data: emissions,
    },
  ];

  const options = {
    fill: {
      type: "gradient",
    },
    xaxis: {
      categories: years,
      labels: {
        formatter: (val) => val,
      },
    },
    yaxis: {
      title: {
        text: "Global GHG Emissions, mtCO2e",
      },
      tickAmount: 5,
      labels: {
        formatter: (val) => {
          if (val >= 1000) {
            return `${(val / 1000)?.toFixed(0)}k`;
          }
          return val;
        },
      },
    },
    colors: ["#008BB2"],
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      contextMenu: {
        menu: [],
      },
    },
  };

  // const options = {
  //   fill: {
  //     type: "gradient",
  //   },
  //   data: [
  //     {
  //       x: "2011",
  //       y: 12,
  //       goals: [
  //         {
  //           name: "Expected",
  //           value: 14,
  //           strokeWidth: 2,
  //           strokeDashArray: 2,
  //           strokeColor: "#775DD0",
  //         },
  //       ],
  //     },
  //   ],
  //   xaxis: {
  //     categories: [
  //       "2021",
  //       "2022",
  //       "2023",
  //       "2024",
  //       "2025",
  //       "2026",
  //       "2027",
  //       "2028",
  //     ],
  //   },
  //   // Customize the format of y-axis labels
  //   yaxis: {
  //     labels: {
  //       formatter: (val) => {
  //         if (val === 0) return "0k";
  //         return Math.round(val / 1000) + "k";
  //         // return Math.round(val / 1000) + 5*1 + "k"
  //       },
  //     },
  //     title: {
  //       text: "Global GHG Emissions, mtCO2e",
  //     },
  //     tickAmount: 5, // Set the number of ticks on the y-axis
  //     labels: {
  //       formatter: (val, index) => {
  //         return tablelValues[index] + "k";
  //       },
  //     },
  //   },
  //   colors: ["#008BB2", "#008BB2", "#008BB2"],
  //   // Hide data labels (numbers above the bars)
  //   dataLabels: {
  //     enabled: false,
  //   },

  //   // Hide menu options
  //   chart: {
  //     toolbar: {
  //       show: false, // Hide the toolbar
  //     },
  //     zoom: {
  //       enabled: false, // Disable zooming
  //     },
  //     contextMenu: {
  //       menu: [], // Hide all context menu options
  //     },
  //   },
  // };

  return (
    <Chart
      // width={680}
      height={260}
      options={options}
      series={series}
      type="bar"
      style={{ minWidth: "150px", width: "43.5vw" }}
    />
  );
}

export default BarChart;
