import { createSlice } from "@reduxjs/toolkit";
import { clearFromLocal } from "../../utils/storage";

const initialAuthState = {
  accessToken: null,
  refreshToken: null,
  accessTokenExpirationTime: null,
  refreshTokenExpirationTime: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      console.log(action, 'action');
      const {
        accessToken,
        refreshToken,
        accessTokenExpirationTime,
        refreshTokenExpirationTime,
      } = action.payload;

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.accessTokenExpirationTime = accessTokenExpirationTime;
      state.refreshTokenExpirationTime = refreshTokenExpirationTime;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.accessToken = initialAuthState.accessToken;
      state.refreshToken = initialAuthState.refreshToken;
      state.accessTokenExpirationTime =
        initialAuthState.accessTokenExpirationTime;
      state.refreshTokenExpirationTime =
        initialAuthState.refreshTokenExpirationTime;
      state.isLoggedIn = false;
      clearFromLocal();
    },
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;







