import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import TextArea from "../../../InputBox/textAreaBox/TextArea";
import InputBox from "../../../InputBox/InputBox";
import SelectInputBox from "../../../InputBox/selectBox/SelectInputBox";
import FileUploadBox from "../../../fileUploadBox/FileUploadBox";
import PdfUpload from "../../../pdfUpload/PdfUpload";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import FileUploadBoxRegistration from "../../../fileUploadBox/FileUploadBoxRegistration";
import FileUploadBoxDecleration from "../../../fileUploadBox/FileUploadBoxDecleration";
import InfoEmissionScopes from "../infoEmissionScopes/InfoEmissionScopes";

const countryList = [
  { value: "Saudi Arabia", name: "Saudi Arabia" },
  { value: "UAE", name: "UAE" },
  { value: "India", name: "India" },
];

function InfoOnEmission2({ formik, activeSection, setActiveSection }) {
  const addFacility = () => {
    formik.setFieldValue(
      "infoOnEmission2.emissionsDisaggregatedByFacility.facilityData",
      [
        ...formik.values?.infoOnEmission2?.emissionsDisaggregatedByFacility
          ?.facilityData,
        {
          facility: "",
          scope1Emissions: "",
        },
      ]
    );
  };

  const removeFacility = ({ deleteIndex }) => {
    const updatedEntity =
      formik?.values?.infoOnEmission2?.emissionsDisaggregatedByFacility?.facilityData?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      "infoOnEmission2.emissionsDisaggregatedByFacility.facilityData",
      updatedEntity
    );
  };

  const addCountry = () => {
    formik.setFieldValue(
      "infoOnEmission2.emissionsDisaggregatedByCountry.countryData",
      [
        ...formik?.values?.infoOnEmission2?.emissionsDisaggregatedByCountry
          ?.countryData,
        {
          country: "",
          scope1Emissions: "",
        },
      ]
    );
  };

  const removeCountry = ({ deleteIndex }) => {
    const updatedEntity =
      formik?.values?.infoOnEmission2?.emissionsDisaggregatedByCountry?.countryData?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      "infoOnEmission2.emissionsDisaggregatedByCountry.countryData",
      updatedEntity
    );
  };

  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "infoEmission2" : false);
  };

  return (
    <div className="emisSectionGap" id="infoEmission2">
      <Accordion
        expanded={activeSection === "infoEmission2"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON EMISSIONS 2</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <h1>Emissions disaggregated by source types</h1>
          </div>
          <div className="scopeSection wd-90">
            <p>Scope 1: Direct Emissions from Owned/Controlled Operations</p>
            <InfoEmissionScopes
              headName={"Direct Emissions from Stationary Combustion (mtCO2e)"}
              formik={formik}
              name1="infoOnEmission2.directEmission.stationaryCombustion.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion?.notRecorded
              }
              name2="infoOnEmission2.directEmission.stationaryCombustion.value"
              value2={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion?.value &&
                formik.errors?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion?.value
              }
              name3="infoOnEmission2.directEmission.stationaryCombustion.specify"
              value3={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion.specify &&
                formik.errors?.infoOnEmission2?.directEmission
                  ?.stationaryCombustion.specify
              }
            />
            <InfoEmissionScopes
              headName={"Direct Emissions from Mobile Combustion (mtCO2e)"}
              formik={formik}
              name1="infoOnEmission2.directEmission.mobileCombustion.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.mobileCombustion?.notRecorded
              }
              name2="infoOnEmission2.directEmission.mobileCombustion.value"
              value2={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.mobileCombustion?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.mobileCombustion?.value &&
                formik.errors?.infoOnEmission2?.directEmission?.mobileCombustion
                  ?.value
              }
              name3="infoOnEmission2.directEmission.mobileCombustion.specify"
              value3={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.mobileCombustion.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.mobileCombustion?.specify &&
                formik.errors?.infoOnEmission2?.directEmission?.mobileCombustion
                  ?.specify
              }
            />
            <InfoEmissionScopes
              headName={"Direct Emissions from Process Sources (mtCO2e)"}
              formik={formik}
              name1="infoOnEmission2.directEmission.processSources.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.directEmission?.processSources
                  ?.notRecorded
              }
              name2="infoOnEmission2.directEmission.processSources.value"
              value2={
                formik?.values?.infoOnEmission2?.directEmission?.processSources
                  ?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.processSources?.value &&
                formik.errors?.infoOnEmission2?.directEmission?.processSources
                  ?.value
              }
              name3="infoOnEmission2.directEmission.processSources.specify"
              value3={
                formik?.values?.infoOnEmission2?.directEmission?.processSources
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.processSources?.specify &&
                formik.errors?.infoOnEmission2?.directEmission?.processSources
                  ?.specify
              }
            />
            <InfoEmissionScopes
              headName={"Direct Emissions from Fugitive Sources (mtCO2e)"}
              formik={formik}
              name1="infoOnEmission2.directEmission.fugativeSources.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.directEmission?.fugativeSources
                  ?.notRecorded
              }
              name2="infoOnEmission2.directEmission.fugativeSources.value"
              value2={
                formik?.values?.infoOnEmission2?.directEmission?.fugativeSources
                  .value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.fugativeSources?.value &&
                formik.errors?.infoOnEmission2?.directEmission?.fugativeSources
                  ?.value
              }
              name3="infoOnEmission2.directEmission.fugativeSources.specify"
              value3={
                formik?.values?.infoOnEmission2?.directEmission?.fugativeSources
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.fugativeSources?.specify &&
                formik.errors?.infoOnEmission2?.directEmission?.fugativeSources
                  ?.specify
              }
            />
            <InfoEmissionScopes
              headName={"Direct Emissions from Agricultural Sources (mtCO2e)"}
              formik={formik}
              name1="infoOnEmission2.directEmission.agriculturalSources.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.notRecorded
              }
              name2="infoOnEmission2.directEmission.agriculturalSources.value"
              value2={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.value &&
                formik.errors?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.value
              }
              name3="infoOnEmission2.directEmission.agriculturalSources.specify"
              value3={
                formik?.values?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.specify &&
                formik.errors?.infoOnEmission2?.directEmission
                  ?.agriculturalSources?.specify
              }
            />
          </div>

          <div className="scopeSection wd-90">
            <p>
              Scope 2: Indirect Emissions from the Use of Purchased Electricity,
              Steam, Heating and Cooling
            </p>
            <InfoEmissionScopes
              headName={
                "Indirect Emissions from Purchased/Acquired Electricity (mtCO2e)"
              }
              formik={formik}
              name1="infoOnEmission2.indirectEmission.electricity.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.indirectEmission?.electricity
                  ?.notRecorded
              }
              name2="infoOnEmission2.indirectEmission.electricity.value"
              value2={
                formik?.values?.infoOnEmission2?.indirectEmission?.electricity
                  ?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.electricity
                  ?.value &&
                formik.errors?.infoOnEmission2?.indirectEmission?.electricity
                  ?.value
              }
              name3="infoOnEmission2.indirectEmission.electricity.specify"
              value3={
                formik?.values?.infoOnEmission2?.indirectEmission?.electricity
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.electricity
                  ?.specify &&
                formik.errors?.infoOnEmission2?.indirectEmission?.electricity
                  ?.specify
              }
            />
            <InfoEmissionScopes
              headName={
                "Indirect Emissions from Purchased/Acquired Steam (mtCO2e)"
              }
              formik={formik}
              name1="infoOnEmission2.indirectEmission.steam.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.indirectEmission?.steam
                  ?.notRecorded
              }
              name2="infoOnEmission2.indirectEmission.steam.value"
              value2={
                formik?.values?.infoOnEmission2?.indirectEmission?.steam?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.steam
                  ?.value &&
                formik.errors?.infoOnEmission2?.indirectEmission?.steam?.value
              }
              name3="infoOnEmission2.indirectEmission.steam.specify"
              value3={
                formik?.values?.infoOnEmission2?.indirectEmission?.steam
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.steam
                  ?.specify &&
                formik.errors?.infoOnEmission2?.indirectEmission?.steam?.specify
              }
            />
            <InfoEmissionScopes
              headName={
                "Indirect Emissions from Purchased/Acquired Heating (mtCO2e)"
              }
              formik={formik}
              name1="infoOnEmission2.indirectEmission.heating.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.indirectEmission?.heating
                  ?.notRecorded
              }
              name2="infoOnEmission2.indirectEmission.heating.value"
              value2={
                formik?.values?.infoOnEmission2?.indirectEmission?.heating
                  ?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.heating
                  ?.value &&
                formik.errors?.infoOnEmission2?.indirectEmission?.heating?.value
              }
              name3="infoOnEmission2.indirectEmission.heating.specify"
              value3={
                formik?.values?.infoOnEmission2?.indirectEmission?.heating
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.heating
                  ?.specify &&
                formik.errors?.infoOnEmission2?.indirectEmission?.heating
                  ?.specify
              }
            />
            <InfoEmissionScopes
              headName={
                "Indirect Emissions from Purchased/Acquired Cooling (mtCO2e)"
              }
              formik={formik}
              name1="infoOnEmission2.indirectEmission.cooling.notRecorded"
              value1={
                formik?.values?.infoOnEmission2?.indirectEmission?.cooling
                  ?.notRecorded
              }
              name2="infoOnEmission2.indirectEmission.cooling.value"
              value2={
                formik?.values?.infoOnEmission2?.indirectEmission?.cooling
                  ?.value
              }
              error2={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.cooling
                  ?.value &&
                formik.errors?.infoOnEmission2?.indirectEmission?.cooling?.value
              }
              name3="infoOnEmission2.indirectEmission.cooling.specify"
              value3={
                formik?.values?.infoOnEmission2?.indirectEmission?.cooling
                  ?.specify
              }
              error3={
                !!formik.touched?.infoOnEmission2?.indirectEmission?.cooling
                  ?.specify &&
                formik.errors?.infoOnEmission2?.indirectEmission?.cooling
                  ?.specify
              }
            />
          </div>

          <p>
            Emissions disaggregated by facility (recommended for individual
            facilities with stationary combustion emissions over 10,000 mtCO2e)
          </p>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.infoOnEmission2
                  ?.emissionsDisaggregatedByFacility?.aggregationResponse
              }
              onChange={(e) => {
                formik?.setFieldValue(
                  "infoOnEmission2.emissionsDisaggregatedByFacility.aggregationResponse",
                  e.target.value
                );
                formik.setFieldValue(
                  "infoOnEmission2.emissionsDisaggregatedByFacility.facilityData",
                  [
                    {
                      facility: "",
                      scope1Emissions: null,
                    },
                  ]
                );
              }}
            >
              <FormControlLabel
                value="recorded"
                control={<Radio size="small" />}
                label="Recorded"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="notRecorded"
                control={<Radio size="small" />}
                label="Not Recorded"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="notApplicable"
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.infoOnEmission2?.emissionsDisaggregatedByFacility
            ?.aggregationResponse === "recorded" && (
            <div>
              {formik?.values?.infoOnEmission2?.emissionsDisaggregatedByFacility?.facilityData?.map(
                (_, index) => (
                  <div
                    className="equitySection facilitySec"
                    key={index}
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="remove_div">
                      <p className="equityText">Facility 1</p>
                      {formik?.values?.infoOnEmission2
                        ?.emissionsDisaggregatedByFacility?.facilityData
                        ?.length > 1 && (
                        <p
                          className="remove cursorPointer"
                          onClick={removeFacility.bind(null, {
                            deleteIndex: index,
                          })}
                        >
                          <FontAwesomeIcon
                            icon={faCircleMinus}
                            className="fontAwesomeIcon_img"
                            style={{ color: "#008BB2" }}
                          />
                          Remove
                        </p>
                      )}
                    </div>
                    <div className="form-row specifyRow">
                      <div className="wd-100">
                        <InputBox
                          headName={"Facility"}
                          placeholder="Enter the facility name"
                          name={`infoOnEmission2.emissionsDisaggregatedByFacility.facilityData[${index}].facility`}
                          value={
                            formik?.values?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.facility
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik?.touched?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility
                              ?.facilityData &&
                            !!formik?.touched?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.facility &&
                            formik?.errors?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility
                              ?.facilityData &&
                            formik?.errors?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.facility
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                      <div className="input-item wd-100">
                        <InputBox
                          headName={"Scope 1 Emissions (mtCO2e)"}
                          type="number"
                          placeholder="Enter the value"
                          name={`infoOnEmission2.emissionsDisaggregatedByFacility.facilityData[${index}].scope1Emissions`}
                          value={
                            formik?.values?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.scope1Emissions
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik?.touched?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility
                              ?.facilityData &&
                            !!formik?.touched?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.scope1Emissions &&
                            formik?.errors?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility
                              ?.facilityData &&
                            formik?.errors?.infoOnEmission2
                              ?.emissionsDisaggregatedByFacility?.facilityData[
                              index
                            ]?.scope1Emissions
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="addBranch_div cursorPointer">
                <p className="addBranch" onClick={addFacility}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />{" "}
                  Add Facility
                </p>
              </div>
            </div>
          )}
          <p className="">Emissions disaggregated by country</p>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.infoOnEmission2?.emissionsDisaggregatedByCountry
                  ?.aggregationResponse
              }
              onChange={(e) => {
                formik?.setFieldValue(
                  "infoOnEmission2.emissionsDisaggregatedByCountry.aggregationResponse",
                  e.target.value
                );
                formik.setFieldValue(
                  "infoOnEmission2.emissionsDisaggregatedByCountry.countryData",
                  [
                    {
                      country: "",
                      scope1Emissions: null,
                    },
                  ]
                );
              }}
            >
              <FormControlLabel
                value="recorded"
                control={<Radio size="small" />}
                label="Recorded"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="notRecorded"
                control={<Radio size="small" />}
                label="Not Recorded"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="notApplicable"
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.infoOnEmission2?.emissionsDisaggregatedByCountry
            ?.aggregationResponse === "recorded" && (
            <div>
              {formik?.values?.infoOnEmission2?.emissionsDisaggregatedByCountry?.countryData?.map(
                (_, index) => (
                  <div
                    className="equitySection facilitySec"
                    key={index}
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="remove_div">
                      <p className="equityText">Country 1</p>
                      {formik?.values?.infoOnEmission2
                        ?.emissionsDisaggregatedByCountry?.countryData?.length >
                        1 && (
                        <p
                          className="remove cursorPointer"
                          onClick={removeCountry.bind(null, {
                            deleteIndex: index,
                          })}
                        >
                          <FontAwesomeIcon
                            icon={faCircleMinus}
                            className="fontAwesomeIcon_img"
                            style={{ color: "#008BB2" }}
                          />{" "}
                          Remove
                        </p>
                      )}
                    </div>
                    <div className="form-row country">
                      <div className="country1">
                        <SelectInputBox
                          data={countryList}
                          headName={"Country"}
                          selectPlaceholder={"Select"}
                          name={`infoOnEmission2.emissionsDisaggregatedByCountry.countryData[${index}].country`}
                          value={
                            formik?.values?.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.country
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          formik={formik}
                          error={
                            formik.touched.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData &&
                            !!formik.touched.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.country &&
                            formik.errors.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData &&
                            formik.errors.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.country
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                      <div className="input-item wd-70">
                        <InputBox
                          headName={"Scope 1 Emissions (mtCO2e)"}
                          type="number"
                          placeholder="Enter the Value"
                          name={`infoOnEmission2.emissionsDisaggregatedByCountry.countryData[${index}].scope1Emissions`}
                          value={
                            formik?.values?.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.scope1Emissions
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData &&
                            !!formik.touched.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.scope1Emissions &&
                            formik.errors.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData &&
                            formik.errors.infoOnEmission2
                              ?.emissionsDisaggregatedByCountry?.countryData[
                              index
                            ]?.scope1Emissions
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="addBranch_div cursorPointer">
                <p className="addBranch" onClick={addCountry}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />{" "}
                  Add Country
                </p>
              </div>
            </div>
          )}

          <div className=" flexwrap emisHeat">
            <div className="emisHeat1">
              <p>
                Emissions attributable to own generation of electricity, heat,
                or stem that is sold or transferred to another organization
              </p>
            </div>
            <div className="emisHeat2">
              <InputBox
                type="number"
                headName={"Specify Here"}
                placeholder="Tell us about this"
                name={`infoOnEmission2.emissionsFromSoldOrTransferredEnergy.value`}
                value={
                  formik?.values?.infoOnEmission2
                    ?.emissionsFromSoldOrTransferredEnergy?.value
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.infoOnEmission2
                    ?.emissionsFromSoldOrTransferredEnergy?.value &&
                  formik.errors.infoOnEmission2
                    ?.emissionsFromSoldOrTransferredEnergy?.value
                }
                errorIcon={WarningIcon}
              />
            </div>
            <div className="emisHeat1">
              <p>
                Emissions attributable to the generation of electricity, heat or
                steam that is purchased for re-sale to non-end users
              </p>
            </div>
            <div className="emisHeat2">
              <InputBox
              type="number"
                headName={"Specify Here"}
                placeholder="Tell us about this"
                name={`infoOnEmission2.emissionsFromPurchasedForResaleEnergy.value`}
                value={
                  formik?.values?.infoOnEmission2
                    ?.emissionsFromPurchasedForResaleEnergy?.value
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.infoOnEmission2
                    ?.emissionsFromPurchasedForResaleEnergy?.value &&
                  formik.errors.infoOnEmission2
                    ?.emissionsFromPurchasedForResaleEnergy?.value
                }
                errorIcon={WarningIcon}
              />
            </div>
            <div className="emisHeat1">
              <p>
                Emissions from GHGs not covered by the Kyoto Protocol (e.g.,
                CFCs, NOx,)
              </p>
            </div>
            <div className="emisHeat2">
              <InputBox
              type="number"
                headName={"Specify Here"}
                placeholder="Tell us about this"
                name={`infoOnEmission2.emissionsFromNonKyotoGHGs.value`}
                value={
                  formik?.values?.infoOnEmission2?.emissionsFromNonKyotoGHGs
                    ?.value
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  !!formik.touched.infoOnEmission2?.emissionsFromNonKyotoGHGs
                    ?.value &&
                  formik.errors.infoOnEmission2?.emissionsFromNonKyotoGHGs
                    ?.value
                }
                errorIcon={WarningIcon}
              />
            </div>
          </div>

          <div>
            <div className="wd-100">
              <h1>
                Relevant ratio performance indicators (e.g. emissions per
                kilowatt-hour generated, sales, etc.)
              </h1>
              <div className="wd-100 mb-28 addPdfWidth">
                <FileUploadBoxDecleration
                  formik={formik}
                  field="infoOnEmission2.performanceIndicatorsFile"
                  value={
                    formik?.values?.infoOnEmission2?.performanceIndicatorsFile
                  }
                  folder="registration"
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.infoOnEmission2
                      ?.performanceIndicatorsFile &&
                    formik.errors.infoOnEmission2?.performanceIndicatorsFile
                  }
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default InfoOnEmission2;
