import React from "react";

import "./style.css";
import { getUserFromLocal } from "../../../../../utils/storage";
import moment from "moment";

function SendBySection({ item }) {
  const userDetails = getUserFromLocal();
  const userName = userDetails?.userName;
  const userType = userDetails?.userType;
  console.log("item", item, userName);
  return (
    <div className="sendByRow">
      <p>
        By{" "}
        {item?.senderUserType === userType || item?.createdByType === userType
          ? "you"
          : `${item?.fullName}`}
      </p>
      <div className="cmtHeaderRightBorder"></div>
      <p className="sendByTime">
        {moment(item?.updatedAt).format("YYYY-MM-DD")}{" "}
        {moment(item?.updatedAt).format("HH:mm")}
      </p>
    </div>
  );
}

export default SendBySection;
