import react, { useEffect, useRef, useState } from "react";
import { authActions } from "../../../features/auth/authSlice";
import {
  getFromLocal,
  getUserFromLocal,
  removeAuthFromLocal,
} from "../../../utils/storage";
import { useDispatch } from "react-redux";

import RatingSelectProfile from "../../ratingComponents/ratingSelectProfile/RatingSelectProfile";

import "./style.css";

import NotificationIconn from "../../../assets/icons/notificationIcon.svg";
import UserProfileImg from "../../../assets/images/userImg.png";
import ProfileIcon from "../../../assets/icons/ProfileUser.svg";
import LogOutIcon from "../../../assets/icons/logOutIcon.svg";
import ArrowDown from "../../../assets/icons/ArrowDown.svg";
import LogOutPopUp from "../profileApprovePopUp/logOutPopUp/LogOutPopUp";
import { logoutHandler } from "../../../features/auth/authActions";
import { useNavigate } from "react-router-dom";

const userName = [
  { name: "Mohamed Irfan", value: "" },
  { name: "Irfan", value: 1 },
  { name: "Sunita", value: 2 },
  { name: "Suni", value: 3 },
  { name: "Tani", value: 4 },
];

function AdminDashHeader() {
  const [open, setOpen] = useState(false);
  const [openLogoutPopUp, setOpenLogoutPopUp] = useState(false);
  const [profileName, setProfileName] = useState();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const location = getFromLocal("country");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChange = (event) => {
    setProfileName(event.target.value);
  };

  const togglePopUp = () => {
    setOpen(!open);
  };

  const handleOpenLogoutPopUp = () => {
    setOpenLogoutPopUp(true);
  };

  const handleCloseLogoutPopUp = () => {
    setOpenLogoutPopUp(false);
  };
  const logout = () => {
    console.log("cliecked");
    dispatch(logoutHandler());
  };

  const handleEditAdmin = () => {
    navigate("/admin/editAdmin");
  };

  return (
    <header className="adminHead">
      <div className="adminTopBar">
        <div>
          <img src={NotificationIconn} alt="bellIcon" />
        </div>
        <div className="rightBorder"></div>
        <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
          <div className="profileImg cursorPointer">
            <img src={UserProfileImg} alt="UserProfileImg" />
          </div>
          {/* <RatingSelectProfile
            data={userName}
            value={profileName}
            onChange={handleChange}
            defaultValue={userName[0].value}
          /> */}
          <p>{userDetails?.userName}</p>
          <img
            className="cursorPointer"
            src={ArrowDown}
            alt="ArrowDown"
            onClick={togglePopUp}
          ></img>
        </div>
      </div>
      {open && (
        <div className="adminLogOutContainer" ref={menuRef}>
          <div
            className="cursorPointer"
            style={{
              padding: "10px 10px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div className="logoutTextRow" onClick={handleEditAdmin}>
              <img src={ProfileIcon} alt="ProfileIcon" />
              <p className="cursor-pointer"> My Profile </p>
            </div>
            <div className="logoutTextRow" onClick={handleOpenLogoutPopUp}>
              <img src={LogOutIcon} alt="LogOutIcon" />
              <p className="cursor-pointer"> Logout </p>
            </div>
          </div>
        </div>
      )}
      <LogOutPopUp
        open={openLogoutPopUp}
        onClose={handleCloseLogoutPopUp}
        heading={"Are you sure you want to logout?"}
        statement={
          "You will be taken back to the login screen if you proceed. You can log back in to continue managing the platform"
        }
        acceptButtonText={"Yes, Logout"}
        rejectButtonText={"No, Go Back"}
        onclickAcceptButton={logout}
        onClickRejectButton={handleCloseLogoutPopUp}
      />
    </header>
  );
}

export default AdminDashHeader;
