import React, { useEffect, useState } from "react";
import "./style.css";

//Aseets Import
import RightArrow from "../../../../assets/icons/Arrow_Right_LG.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

//MUI Date Picker Import
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//Dayjs Import
import dayjs from "dayjs";

//Components Import
import SelectBox from "../selectBox/SelectBox";
import RemoveTimeLine from "../../../button/removeTimeLine/RemoveTimeLine";

function Timeline({
  formik,
  headName,
  removeTimelinePlanning,
  index,
  initiativeIndex,
  timelinePlanningIndex,
  value,
  startDateName,
  startDateValue,
  startDateTouch,
  startDateError,
  endDateName,
  endDateValue,
  endDateTouch,
  endDateError,
  fromQuaterName,
  fromQuaterValue,
  fromQuaterTouch,
  fromQuaterError,
  toQuaterName,
  toQuaterValue,
  toQuaterTouch,
  toQuaterError,
  errorIcon,
}) {
  const [cleared, setCleared] = useState(false);
  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  return (
    <div className="timelinePlanningCon">
      <div className="timelinePlanCol2">
        <div className="yearCalendarRow1" style={{ alignItems: "center" }}>
          <div className="selectMinWidth">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker"]}
                className="demoContainer"
              >
                <label className="yearCalendarLabel">From</label>
                <DatePicker
                  defaultValue={""}
                  views={["year"]}
                  name={startDateName}
                  value={dayjs(startDateValue)}
                  onChange={(newValue) => {
                    console.log("newDate", newValue);
                    formik.setFieldValue(
                      `${startDateName}`,
                      newValue?.format("YYYY")
                    );
                  }}
                  // value={startDateValue}
                  // onChange={(newValue) => {
                  //   formik.setFieldValue(`${startDateName}`, newValue);
                  // }}
                  // onChange={(newValue) => {
                  //   // Check if the clear button was clicked
                  //   if (!newValue) {
                  //     formik.setFieldValue(`${startDateName}`, "");
                  //   } else {
                  //     formik.setFieldValue(
                  //       `${startDateName}`,
                  //       newValue?.format("YYYY")
                  //     );
                  //   }
                  // }}
                  onBlur={formik?.handleBlur}
                  // slotProps={{
                  //   field: {
                  //     clearable: true,
                  //     onClear: () =>
                  //       formik.setFieldValue(`${startDateName}`, null),
                  //   },
                  // }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {startDateTouch && Boolean(startDateError) && (
              <p error className="inputError">
                {errorIcon && (
                  <img
                    src={WarningIcon}
                    alt="Error Icon"
                    className="errorIcon"
                  />
                )}
                {startDateError}
              </p>
            )}
          </div>
          <SelectBox
            headName={"Quarter"}
            formik={formik}
            name={fromQuaterName}
            value={fromQuaterValue}
            // touch={fromQuaterTouch}
            // error={fromQuaterError}
            onBlur={formik?.handleBlur}
            onChange={formik.handleChange}
            error={fromQuaterTouch && fromQuaterError}
          />
          <img src={RightArrow} alt="RightArrow" />
          <div className="selectMinWidth">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <label className="yearCalendarLabel">To</label>
                <DatePicker
                  views={["year"]}
                  name={endDateName}
                  value={dayjs(endDateValue)}
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      `${endDateName}`,
                      newValue?.format("YYYY")
                    );
                  }}
                  minDate={dayjs(startDateValue)}
                  // value={endDateValue}
                  // onChange={(newValue) => {
                  //   formik.setFieldValue(`${endDateName}`, newValue);
                  // }}
                  onBlur={formik?.handleBlur}
                />
              </DemoContainer>
            </LocalizationProvider>
            {endDateTouch && Boolean(endDateError) && (
              <p error className="inputError">
                {errorIcon && (
                  <img
                    src={WarningIcon}
                    alt="Error Icon"
                    className="errorIcon"
                  />
                )}
                {endDateError}
              </p>
            )}
          </div>
          <SelectBox
            headName={"Quarter"}
            formik={formik}
            name={toQuaterName}
            value={toQuaterValue}
            touch={toQuaterTouch}
            error={toQuaterError}
            onChange={formik.handleChange}
          />
          {value.length > 1 && (
            <RemoveTimeLine
              remove={removeTimelinePlanning}
              index={timelinePlanningIndex}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
