
import React, {useState, useEffect} from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';

import './style.css';

import SeeLevelRise from '../../../../assets/homeImages/SeeLevelRise.svg';
import ImpactOnWater from '../../../../assets/homeImages/ImpactOnwater.svg';
import FoodSecurity from '../../../../assets/homeImages/FoodSecurity.svg';
import ImpactOnFauna from '../../../../assets/homeImages/ImpactOnFauna.svg';
import FifteenDegreeRight from '../../../../assets/icons/fifteenArrow.svg';
import TwoDegreeRight from '../../../../assets/icons/TwoDegreeRight.svg';
import ThreeDegreeRight from '../../../../assets/icons/ThreeDegreeRightImg.svg';
import FourDegreeRight from '../../../../assets/icons/FourDegreeRight.svg';
import MobileClimate from '../../../../components/mobileSections/mobileClimate/MobileClimate';




function ClimateImpact() {
  const [mobileClimate, setMobileClimate] = useState(false);

  /* mobile screen */
  useEffect(() => {
    if (window.innerWidth <= 575) {
      const bool = window.addEventListener("resize", handleResize);
      if (!bool) return setMobileClimate(window.innerWidth <= 575);
    } else {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleResize = () => {
    setMobileClimate(window.innerWidth <= 575);
  };

  return (
    <div className='container climate_section'>
       <h1>Climate Impact By 2100</h1>
       <p className='climate_heading_p'>
          A world that is 3˚C warmer will face much larger impacts than one that is 1.5˚C 
          warmer. Sea levels will be higher, there will be more extreme heat, food 
          insecurity and drought affecting many more people
        </p>

        {mobileClimate ? (
         <MobileClimate /> 
        ) : (
       <table>
           <tr className=''>
             <th className='heading'></th>
             <th className='heading'>
                <div className='tableHeading_sec'>
                  <img src={SeeLevelRise} alt="" />
                  <span>Impacts on Sea level Rise</span>
                </div>
               </th>
             <th className='heading'>
                <div className='tableHeading_sec'>
                  <img src={ImpactOnWater} alt="" />
                  <span>Impacts on  Water</span>
                </div>
             </th>
             <th className='heading'>
               <div className='tableHeading_sec'>
                 <img src={FoodSecurity} alt="" />
                 <span>Impacts on Food</span>
               </div>
             </th>
             <th className='heading'>
               <div className='tableHeading_sec'>
                 <img src={ImpactOnFauna} alt="" />
                 <span>Impacts on Flora and Fauna</span>
               </div>
             </th>
           </tr>
           
           <tr>
             <td className='threeDegree'>
                <div className='degree_sec'>
                  <img src={FifteenDegreeRight} alt="" /> <span className='degree_span'>1.5˚C</span>
                </div>
             </td>
             <td className='fifteenDegree_Block'>
              <p>Rising sea levels displace 46 million people</p>
              <h3>Sea Level Rise of 48cm</h3>
             </td>
             <td className='fifteenDegree_Block'><p>Water shortage in the Mediterranean, Australia, Brazil and Asia</p></td>
             <td className='fifteenDegree_Block'><p>Wheat, rice, maize, and soybean production suffers</p></td>
             <td className='fifteenDegree_Block'><p>9 out of 10 coral reefs at risk from severe degradation</p></td>
           </tr>
           <tr>
             <td className='fifteenDegree'>
                <div className='degree_sec'>
                  <img src={TwoDegreeRight} alt="" /> <span className='degree_span'>2.0˚C</span>
                </div>
            </td>
            <td className='fifteenDegree_Block twoDegree'>
              <p>Fewer opportunities for infrastructure adaptation</p>
              <h3>Sea Level Rise of 56cm</h3>
             </td>
             <td className='fifteenDegree_Block'><p>8% of the global population faces severe water shortages</p></td>
             <td className='fifteenDegree_Block'><p>Agriculture yields fall rapidlys</p></td>
             <td className='fifteenDegree_Block'><p>All coral reefs disappear</p></td>
           </tr>
           <tr>
             <td className='fifteenDegree'>
                <div className='degree_sec'>
                  <img src={ThreeDegreeRight} alt="" /> <span className='degree_span threeDeg'>3.0˚C</span>
                </div>
            </td>
            <td className='fifteenDegree_Block threeDegree'>
              <p>Near-complete melting of the Greenland ice sheet</p>
              <h3>Sea Level Rise of 7+ meters</h3>
             </td>
             <td className='fifteenDegree_Block'><p>Almost half of the Himalayan high mountain glaciers lost</p></td>
             <td className='fifteenDegree_Block'><p>Fish species go extinct locally</p></td>
             <td className='fifteenDegree_Block'><p>Marine ecosystems may collapse</p></td>
           </tr> 
           <tr>
             <td className='fifteenDegree'>
                <div className='degree_sec'>
                  <img src={FourDegreeRight} alt="" /> <span className='degree_span fourDeg'>4.0˚C</span>
                </div>
            </td>
            <td className='fifteenDegree_Block fourDegree'>
              <p>470 to 760 million people at risk;</p>
              <h3>Sea Level Rise of nearly 9 meters</h3>
             </td>
             <td className='fifteenDegree_Block'><p>More frequent and severe extreme droughts</p></td>
             <td className='fifteenDegree_Block'><p>High levels of food insecurity, development path reversed</p></td>
             <td className='fifteenDegree_Block'><p>Half of all plant and animal species face local extinction</p></td>
           </tr> 

           <tr className='mobile_climate_text'>
           </tr>

       </table>
       )}
    </div>
  );
}

export default ClimateImpact;