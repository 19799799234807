import React, { useEffect, useRef, useState } from "react";

import ChatCommentInputBox from "../../InputBox/chatCommentInputBox/ChatCommentInputBox";
import ChatBoxSubmitBtn from "../../button/chatBoxSubmitBtn/ChatBoxSubmitBtn";
import SummarizeIssueInput from "../../InputBox/summarizeIssueInput/SummarizeIssueInput";
import RecommendedOptions from "../../chatBox/chatBoxComponents/commonComponents/recommendedOptions/RecommendedOptions";

import "./style.css";

import ThreeDotsIcon from "../../../assets/icons/threeDotsIcon.svg";
import {
  useCreateIssueMutation,
  useUpdateIssueMutation,
} from "../../../features/steps/emissionApiSlice";
import ChatFooterMenu from "../../chatBox/chatBoxComponents/commonComponents/chatFooterMenu/ChatFooterMenu";
import { getUserFromLocal } from "../../../utils/storage";

function ChatBoxFooter({
  kebabMenu = true,
  mapping,
  issueId,
  declarationId,
  arrayId,
  type,
  setType,
  summary,
  setSummary,
  description,
  setDescription,
  handleCreateIssueAndChats,
  openMenu,
  openSubMenu,
  menuRef,
  subMenuRef,
  handleMenuOptions,
  handleSubMenuOptions,
  handleSelectValue,
}) {
  const userDetails = getUserFromLocal();

  return (
    <footer className="chatBoxFootContainer">
      {!issueId && userDetails?.userType === "auditor" && (
        <>
          <RecommendedOptions type={type} setType={setType} />
          <SummarizeIssueInput summary={summary} setSummary={setSummary} />
        </>
      )}
      <div className="chatBoxSubmitBtnRow">
        {issueId && (
          <div className="cursorPointer" onClick={handleMenuOptions}>
            <img src={ThreeDotsIcon} alt="ThreeDotsIcon" />
          </div>
        )}
        <ChatCommentInputBox
          description={description}
          setDescription={setDescription}
        />
        <ChatBoxSubmitBtn handleCreateIssueAndChats={handleCreateIssueAndChats} />
      </div>
      {openMenu && issueId && (
        <ChatFooterMenu
          menuRef={menuRef}
          subMenuRef={subMenuRef}
          openSubMenu={openSubMenu}
          handleSubMenuOptions={handleSubMenuOptions}
          handleSelectValue={handleSelectValue}
        />
      )}
    </footer>
  );
}

export default ChatBoxFooter;
