import React, { useEffect, useRef, useState } from "react";
import AddComments from "./AddComments/AddComments";
import ViewComments from "./ViewComments/ViewComments";
import {
  useRoadmapCreateIssueMutation,
  useRoadmapUpdateIssueMutation,
} from "../../features/steps/roadmapApiSlice";
import {
  useAddChatMutation,
  useLazyGetChatsQuery,
  useLazyGetIssueQuery,
} from "../../features/steps/declerationApiSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import { create } from "@mui/material/styles/createTransitions";
import PageLoader from "../Loader/PageLoader";
import { getUserFromLocal } from "../../utils/storage";

const RoadmapComments = ({
  issueId,
  arrayId,
  priorityPillarId,
  initiativeId,
  mapping,
  declarationId,
  secName,
  question,
  answer,
}) => {
  let commentButton = null;

  const [type, setType] = useState("Corrective Action Required");
  const [summary, setSummary] = useState();
  const [description, setDescription] = useState();
  const [openChatBox, setOpenChatBox] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const location = useLocation();

  const menuRef = useRef(null);
  const subMenuRef = useRef(null);

  const [
    getIssues,
    { data: issue, isLoading: issueIsLoading, isFetching: issuesIsFetching },
  ] = useLazyGetIssueQuery();
  const [
    getChats,
    { data: chats, isLoading: chatsIsLoading, isFetching: chatsIsFetching },
  ] = useLazyGetChatsQuery();

  console.log("priorityPillarId", priorityPillarId);
  console.log("initiativeId", initiativeId);

  const [
    roadmapCreateIssue,
    {
      data: roadmapCreateIssueData,
      isLoading: roadmapCreateIssueIsLoading,
      isSuccess: roadmapCreateIssueIsSuccess,
      error: roadmapCreateIssueError,
      isError: roadmapCreateIssueIsError,
    },
  ] = useRoadmapCreateIssueMutation();

  const [
    roadmapUpdateIssue,
    {
      data: roadmapUpdateIssueData,
      isLoading: roadmapUpdateIssueIsLoading,
      isSuccess: roadmapUpdateIssueIsSuccess,
      error: roadmapUpdateIssueError,
      isError: roadmapUpdateIssueIsError,
    },
  ] = useRoadmapUpdateIssueMutation();

  const [
    createChat,
    {
      data: chatData,
      isLoading: createChatIsLoading,
      isSuccess: issueIsSuccess,
      error: chatError,
      isError: chatIsError,
      isFetching: createChatIsFetching,
    },
  ] = useAddChatMutation();

  const handleChatBoxOpen = () => {
    setOpenChatBox(true);
  };
  const handleChatBoxClose = () => {
    setOpenChatBox(false);
    setDescription("");
    setType("");
  };

  const handleCreateIssueAndChats = () => {
    if (issueId) {
      createChat({
        declarationId: declarationId,
        issueId: issueId,
        message: description,
      })
        .unwrap()
        .then(() => {
          setDescription("");
          dispatch(showSuccess({ message: `Chat Added successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else {
      priorityPillarId && initiativeId
        ? roadmapCreateIssue({
            declarationId: declarationId,
            mapping: mapping,
            type: type,
            priorityPillarId: priorityPillarId,
            initiativeId: initiativeId,
            summary: summary,
            description: description,
          })
            .unwrap()
            .then(() => {
              handleChatBoxClose();
              dispatch(showSuccess({ message: `Issue Created successfully` }));
            })
            .catch((e) => {
              dispatch(
                showError({
                  message: e?.data?.message ?? "Something went wrong",
                })
              );
            })
        : roadmapCreateIssue({
            declarationId: declarationId,
            mapping: mapping,
            priorityPillarId: priorityPillarId,
            type: type,
            summary: summary,
            description: description,
          })
            .unwrap()
            .then(() => {
              handleChatBoxClose();
              dispatch(showSuccess({ message: `Issue Created successfully` }));
            })
            .catch((e) => {
              dispatch(
                showError({
                  message:
                    e?.datcreateChatIsLoadinga?.message ??
                    "Something went wrong",
                })
              );
            });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !subMenuRef.current?.contains(event.target)
      ) {
        setOpenMenu(false);
        setOpenSubMenu(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuRef, subMenuRef]);

  const handleMenuOptions = (event) => {
    event.stopPropagation();
    setOpenMenu(!openMenu);
  };

  const handleSubMenuOptions = (event) => {
    event.stopPropagation();
    setOpenSubMenu(!openSubMenu);
  };

  const handleSelectValue = (value) => {
    console.log("value", value);
    if (
      value === "Clarification" ||
      value === "Recommendation" ||
      value === "Corrective Action Required"
    ) {
      setOpenMenu(false);
      setOpenSubMenu(false);
      roadmapUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: "changeType",
        type: value,
      })
        .unwrap()
        .then(() => {
          handleChatBoxClose();
          dispatch(showSuccess({ message: `Issue Updated successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    } else {
      setOpenMenu(false);
      setOpenSubMenu(false);
      roadmapUpdateIssue({
        declarationId: declarationId,
        issueId: issueId,
        updateType: value,
      })
        .unwrap()
        .then(() => {
          handleChatBoxClose();
          dispatch(showSuccess({ message: `Issue Updated successfully` }));
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    }
  };

  const handleGetChatsAndIssues = () => {
    getIssues({
      issueId: issueId,
      declarationId: declarationId,
    });
    getChats({
      issueId: issueId,
      declarationId: declarationId,
    });
  };

  if (issueId) {
    commentButton = (
      <ViewComments
        mapping={mapping}
        issueId={issueId}
        declarationId={declarationId}
        secName={secName}
        question={question}
        answer={answer}
        description={description}
        setDescription={setDescription}
        openChatBox={openChatBox}
        openMenu={openMenu}
        openSubMenu={openSubMenu}
        menuRef={menuRef}
        subMenuRef={subMenuRef}
        handleGetChatsAndIssues={handleGetChatsAndIssues}
        handleChatBoxClose={handleChatBoxClose}
        handleChatBoxOpen={handleChatBoxOpen}
        issue={issue}
        chats={chats}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
        handleMenuOptions={handleMenuOptions}
        handleSubMenuOptions={handleSubMenuOptions}
        handleSelectValue={handleSelectValue}
        chatsIsLoading={chatsIsLoading}
        chatsIsFetching={chatsIsFetching}
        issuesIsLoading={issueIsLoading}
        createChatIsLoading={createChatIsLoading}
        createChatIsFetching={createChatIsFetching}
      />
    );
  } else if (location.pathname === "/emissionStep4" && !issueId) {
    commentButton = (
      <AddComments
        mapping={mapping}
        issueId={issueId}
        declarationId={declarationId}
        secName={secName}
        question={question}
        answer={answer}
        type={type}
        setType={setType}
        summary={summary}
        setSummary={setSummary}
        description={description}
        setDescription={setDescription}
        handleCreateIssueAndChats={handleCreateIssueAndChats}
        openChatBox={openChatBox}
        setOpenChatBox={setOpenChatBox}
        handleChatBoxClose={handleChatBoxClose}
        handleChatBoxOpen={handleChatBoxOpen}
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
        menuRef={menuRef}
        subMenuRef={subMenuRef}
        handleMenuOptions={handleMenuOptions}
        handleSubMenuOptions={handleSubMenuOptions}
        handleSelectValue={handleSelectValue}
      />
    );
  }

  return (
    <>
      {roadmapCreateIssueIsLoading ||
        createChatIsLoading ||
        createChatIsFetching ||
        issueIsLoading ||
        chatsIsLoading ||
        chatsIsFetching ||
        (roadmapUpdateIssueIsLoading && <PageLoader />)}
      {commentButton}
    </>
  );
};

export default RoadmapComments;
