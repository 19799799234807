import React from "react";

import "./style.css";

import InactiveImg from "../../assets/images/currentRatingInactinveImage.svg";
import ReduceRateImg from "../../assets/images/reduceRateImg.svg";

function OrganizationCurrentRating(myRatingData) {
  console.log("myf3f233f44gf", myRatingData?.myRatingData?.data?.rating);
  return (
    <div className="orgsCurrentRatingCon">
      <div className="orgsCurrentRatingInner">
        <div className="currentRatTitle">
          <h1>Current Rating</h1>
          <p style={{ fontSize: "35px" }}>
            {myRatingData?.myRatingData?.data?.rating || "-"}
          </p>
        </div>
        <div className="orgsDashboardBottomImg">
         <img src={InactiveImg} alt="InactiveImg" />
        </div>
      </div>

      <div className="orgsReductionsRateCon">
        <div className="reductionsRateCol1">
          <h1>mtCO2E</h1>
          <p>-</p>
        </div>
        <img src={ReduceRateImg} alt="ReduceRateImg" />
      </div>
    </div>
  );
}

export default OrganizationCurrentRating;
