import * as React from "react";
import Button from "../../../button/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";

import InputBox from "../../../InputBox/InputBox";
import FormButton from "../../../button/FormButton";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function RejectProfilePopUp({
  open,
  onClose,
  title,
  onReject,
  question,
  statement,
  acceptButtonText,
  rejectButtonText,
  icon,
}) {
  const [reason, setReason] = useState("");
  const handleClose = () => {
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      reasonForReject: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {},
  });

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const handleReasonInput = (e) => {
    setReason(e.target.value);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="rejectTitleBox">
          <div className="rejectTitleDiv">
            <img src={icon} alt="rejectIcon" />
            <h1>{question}</h1>
            <p>{statement}</p>
            <form noValidate onKeyDown={onKeyDown} className="rejectPopUpForm">
              <div className="">
                <InputBox
                  placeholder="Reason For rejection"
                  value={reason}
                  onChange={handleReasonInput}
                />
              </div>
            </form>
          </div>

          <div className="rejectBtnRow">
            <button type="cancel" className="goBackBtn" onClick={handleClose}>
              <p>{rejectButtonText}</p>
            </button>
            <div
              className="proRejectBtn cursorPointer"
              onClick={() => {
                onReject(reason);
              }}
            >
              <Button buttonName={acceptButtonText} />
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default RejectProfilePopUp;
