import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import { useFormik } from "formik";

import "./style.css";

import InfoIcon from "../../../assets/icons/infoIcon.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import InputBox from "../../InputBox/InputBox";
import RegBackButton from "../../button/regBackBtn/RegBackButton";
import RegCancelButton from "../../button/regCancelBtn/RegCancelButton";
import FormButton from "../../button/FormButton";
import { useSendInviteMutation } from "../../../features/organization/organizationApiSlice";
import { useDispatch } from "react-redux";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../Loader/PageLoader";
import PhoneInputBox from "../../InputBox/phoneInputBox/PhoneInputBox";
import { addConsAudSchema } from "../../../Validators/register.validators";
import { cleanObject } from "../../../utils/helper";
import ContainedButton from "../../button/ContainedButton";
import OutlinedButton from "../../button/OutlinedButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddConsultantAndAuditorPopUp({ open, onClose, title }) {
  const dispatch = useDispatch();
  const type = title.split(" ");
  const inviteUserType = type[1].toLowerCase();

  const [
    sendInvite,
    {
      data: sendInviteData,
      isLoading: sendInviteIsLoading,
      isSuccess: sendInviteIsSuccess,
      error: sendInviteError,
      isError: sendInviteIsError,
    },
  ] = useSendInviteMutation();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      inviteUserType: inviteUserType,
      firstName: "",
      lastName: "",
      email: "",
      phoneNoCountryCode: "+971",
      phoneNo: "",
      mobileNoCountryCode: "+971",
      mobileNo: "",
    },
    enableReinitialize: true,
    validationSchema: addConsAudSchema,
    onSubmit: (values) => {
      console.log(values.inviteUserType);
      const addConsAudDetailValues = structuredClone(values);
      cleanObject(addConsAudDetailValues);
      sendInvite(addConsAudDetailValues)
        .unwrap()
        .then((res) => {
          handleClose();
          showSuccess({
            message: res?.data?.message || "Submitted Successfully",
          });
        })
        .catch((e) => {
          dispatch(
            showError({
              message: e?.data?.message ?? "Something went wrong",
            })
          );
        });
    },
  });

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  // console.log("formikDashboeardValues", formik?.values);
  // console.log("formikDashboeardErrors", formik?.errors);

  return (
    <React.Fragment>
      {sendInviteIsLoading && <PageLoader />}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="popUpMainContainer"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="popUpTitle"
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="popUpParaSec">
            <Typography gutterBottom className="popUpPara">
              Add the details of the consultant that you’ve hired to invite
              them. They will need to use the same details you enter here to
              register.
            </Typography>
            <div className="infoConsSec">
              <img src={InfoIcon} />
              <p className="popUpPara">
                The consultant will receive an invite on their email ID to
                register as a Consultant. They can start consulting for you once
                they have been verified by us.
              </p>
            </div>
          </div>

          <form noValidate onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
            <div className="popUpForm">
              <div className="popUpInput">
                <InputBox
                  headName={"First Name"}
                  placeholder="Please Enter your full name"
                  name="firstName"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.firstName && formik.errors.firstName}
                  required
                />
                <InputBox
                  headName={"Last Name"}
                  placeholder="Please Enter your last name"
                  name="lastName"
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.lastName && formik.errors.lastName}
                  required
                />
              </div>
              <div className="popUpInput">
                <InputBox
                  headName={"Email ID"}
                  placeholder="example@mail.com"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.email && formik.errors.email}
                  required
                />
              </div>
              <div className="popUpInput2">
                <PhoneInputBox
                  headName={"Phone Number"}
                  placeholder="Phone Number here"
                  name="phoneNo"
                  value={formik.values.phoneNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.phoneNo && formik.errors.phoneNo}
                  countryCodeName={"phoneNoCountryCode"}
                  countryCodeValue={formik?.values?.phoneNoCountryCode}
                  defaultCountry="AE"
                  formik={formik}
                />
                <PhoneInputBox
                  headName={"Mobile Number"}
                  placeholder="Mobile Number here"
                  name="mobileNo"
                  value={formik.values.mobileNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.mobileNo && formik.errors.mobileNo}
                  countryCodeName={"mobileNoCountryCode"}
                  countryCodeValue={formik?.values?.mobileNoCountryCode}
                  countryCodeError={
                    !!formik.touched.mobileNoCountryCode &&
                    formik.errors.mobileNoCountryCode
                  }
                  defaultCountry="AE"
                  formik={formik}
                  required
                />
              </div>
            </div>

            <div className="popUpBtnRow">
              <OutlinedButton
                type="button"
                bgColor="bgWhite"
                text="Cancel"
                fontSize={"14px"}
                onClick={handleClose}
              />
              <ContainedButton
                text="Send Invite"
                bgColor="bgBlue"
                type="submit"
                fontSize={"14px"}
              />
            </div>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default AddConsultantAndAuditorPopUp;
