import react, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";

import "./style.css";

function AdminPagination({
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  page,
  totalCount,
}) {
  // const [page, setPage] = useState(2);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <div className="paginationContainer">
      <div className="paginationCol1">
        <p>Showing results 10 of 200</p>
        {/* <div class="rightBorder"></div>
            <h1>Rows per page</h1> */}
      </div>
      <div className="paginationCol2">
        {/* <p>Page 1 of 20</p> */}
        <div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminPagination;
