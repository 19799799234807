import React, { useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";

function RegBackButton({ buttonName }) {
  const navigate = useNavigate();
  // const handleBack = () => {
  //   navigate(-1);
  // };

  return (
    <button type="back" className="regBackBtn">
      <p>
        {buttonName}
      </p>
    </button>
  );
}

export default RegBackButton;
