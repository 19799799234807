import React from 'react';

import "./style.css";

function MyProfileBasicInfo() {

  return (
    <div className='profileBasicInfoCon'>
        <h1>Basic Information</h1>
        <div className='basicInfoSection'>
            <div className='basicInfoSecData'>
                <span>Email ID</span>
                <p>karthik@codewave.com</p>
            </div>
            <div className='basicInfoSecData'>
                <span>Phone Number</span>
                <p>000-12345678</p>
            </div>
            <div className='basicInfoSecData'>
                <span>Mobile Number</span>
                <p>+971 9876543210</p>
            </div>
            <div className='basicInfoSecData'>
                <span>Address</span>
                <p>123, ABC Street, XYZ Colony</p>
            </div>
            <div className='basicInfoSecData'>
                <span>City / Town / Suburb</span>
                <p>Dubai</p>
            </div>
            <div className='basicInfoSecData'>
                <span>Country</span>
                <p>United Arab Emirates</p>
            </div>
            <div className='basicInfoSecData'>
                <span>State / Region</span>
                <p>Dubai</p>
            </div>
            <div className='basicInfoSecData'>
                <span>Zipcode / Postal Code</span>
                <p>50819</p>
            </div>
        </div>

    </div>
  )
}

export default MyProfileBasicInfo;