import apiSlice from "../../app/api/apiSlice";

export const quarterlyReportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    quarterlyReportFindOne: builder.query({
      query: ({ id }) => {
        return {
          url: `/rating/v1/quaterly-reports/find-one/${id}`,
        };
      },
      // providesTags: ["findOne"],
    }),
    quarterlyReportGetLatest: builder.query({
      query: ({ id }) => {
        return {
          url: `/rating/v1/quaterly-reports/get-latest/${id}`,
        };
      },
      // providesTags: ["findOne"],
    }),
    quarterlyReportSubmit: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/quaterly-reports/submit",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    quarterlyReportAudit: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/quaterly-reports/audit",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
  }),
});

export const {
  useQuarterlyReportFindOneQuery,
  useQuarterlyReportGetLatestQuery,
  useQuarterlyReportSubmitMutation,
  useQuarterlyReportAuditMutation,
} = quarterlyReportApiSlice;
