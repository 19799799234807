import React from "react";

import SelectInputBox, {
  SelectCountryInputBox,
  SelectStateInputBox,
} from "../../InputBox/selectBox/SelectInputBox";
import InputBox from "../../InputBox/InputBox";

import PhoneInputBox from "../../InputBox/phoneInputBox/PhoneInputBox";

import "./style.css";

import WarningIcon from "../../../assets/icons/errorWarning.svg";
import ImgUploadIcon from "../../../assets/images/uploadImg.svg";
import ImgUploadBtn from "../../button/imgUploadBtn/ImgUploadBtn";

const countryStateMasterData = {
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};

const titleList = [
  { value: "Mr", name: "Mr." },
  { value: "Mrs", name: "Mrs." },
  { value: "Ms", name: "Ms." },
];

function AdminWorkInfo({ formik }) {
  return (
    <div className="adminBasicCon">
      <h1>Work Information</h1>
      <div className="adminBasicInfoSec">
        <div className="adminBsInputRow">
          <div className="adminBsInpCols">
            <InputBox
              headName={"Name of Organization"}
              placeholder="SEE Rating"
              name="workInfo.organizationName"
              value={formik?.values?.workInfo?.organizationName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.organizationName &&
                formik?.errors?.workInfo?.organizationName
              }
              required
            />
          </div>
          <div className="adminBsInpCols">
            <InputBox
              headName={"Designation"}
              placeholder="Type here..."
              name="workInfo.designation"
              value={formik?.values?.workInfo?.designation}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.designation &&
                formik?.errors?.workInfo?.designation
              }
              required
            />
          </div>
          <div className="adminBsInpCols">
            <InputBox
              headName={"Employee ID"}
              placeholder="Type here..."
              name="workInfo.employeeId"
              value={formik?.values?.workInfo?.employeeId}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.employeeId &&
                formik?.errors?.workInfo?.employeeId
              }
              required
            />
          </div>
        </div>

        <div className="adminHorizontalLine" />

        <div className="adminBsInputRow">
          <div className="adminBsAddress">
            <InputBox
              headName={"Office Address"}
              placeholder="Enter your address here"
              name="workInfo.officeAddress.fullAddress"
              value={formik?.values?.workInfo?.officeAddress?.fullAddress}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.officeAddress?.fullAddress &&
                formik?.errors?.workInfo?.officeAddress?.fullAddress
              }
              required
            />
          </div>
        </div>

        <div className="adminBsInputRow">
          <div className="adminBsInpCols">
            <SelectCountryInputBox
              headName={"Country"}
              selectPlaceholder={"Country"}
              data={countryStateMasterData}
              name="workInfo.officeAddress.country"
              value={formik?.values?.workInfo?.officeAddress?.country}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik?.handleChange(e);
                formik.setFieldValue("workInfo.officeAddress.state", "");
                formik.setFieldValue("workInfo.officeAddress.zipCode", "");
                formik.setFieldValue("workInfo.officeAddress.city", "");
              }}
              formik={formik}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.officeAddress?.country &&
                formik?.errors?.workInfo?.officeAddress?.councountrytry
              }
              required
            />
          </div>
          <div className="adminBsInpCols">
            <SelectStateInputBox
              headName={"State / Region"}
              selectPlaceholder={"Select"}
              data={countryStateMasterData}
              selectedData={formik?.values?.workInfo?.officeAddress?.country}
              name="workInfo.officeAddress.state"
              value={formik?.values?.workInfo?.officeAddress?.state}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              formik={formik}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.officeAddress?.state &&
                formik?.errors?.workInfo?.officeAddress?.state
              }
              required
            />
          </div>
          <div className="adminBsInpCols">
            <InputBox
              headName={"City / Town / Suburb"}
              placeholder="Enter City / Town / Suburb"
              name="workInfo.officeAddress.city"
              value={formik?.values?.workInfo?.officeAddress?.city}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.officeAddress?.city &&
                formik?.errors?.workInfo?.officeAddress?.city
              }
              required
            />
          </div>
        </div>
        <div className="adminBsInputRow">
          <div className="adminBsInpCols">
            <InputBox
              headName={"Zipcode / Postal Code"}
              placeholder=" Enter Zipcode / postal code"
              name="workInfo.officeAddress.zipCode"
              value={formik?.values?.workInfo?.officeAddress?.zipCode}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={
                !!formik?.touched?.workInfo?.officeAddress?.zipCode &&
                formik?.errors?.workInfo?.officeAddress?.zipCode
              }
              required
            />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default AdminWorkInfo;
