import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import "./style.css";

function NotRecorded({ value, onChange, name, checked }) {
  return (
    <FormGroup>
      <FormControlLabel
        value="Not Recorded"
        label="Not Recorded"
        control={
          <Checkbox
            checked={checked}
            value={value}
            onChange={onChange}
            name={name}
            size="small"
          />
        }
      />
    </FormGroup>
  );
}

export default NotRecorded;
