import react from "react";

import './style.css';

import UserReqBreadcrumbs from "../breadcrumbs/userReqBreadcrumbs/UserReqBreadcrumbs";


function RegistrationRequest() {
  return (
    <div className="registReqContainer">
      <UserReqBreadcrumbs />
      <div className="requestSection">
        <div className="userRequestCol1">
            <h1>Registration Requests</h1>
        </div>
        <div className="userRequestCol2">
        </div>   
      </div>
    </div>
  );
}

export default RegistrationRequest;