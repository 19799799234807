
import React from 'react';
import CarbonNatural from '../../../../assets/carbonNaturallyImg.png';
import './style.css';


function Emission() {
  return (
    <div className=' emission_section'>
      <div className='container emisInnerSec'>
      <div className='emissionSec_Col1'>
        <p>We have set an uncompromising goal of<span className='carbonTitle'> 100% Carbon Neutrality </span> where businesses are held responsible for their</p>
        <p><span className='emission_title'> Emission </span>rather than being</p>
        <p>rewarded for their<span className='reduction_title'> Reductions </span></p>
      </div>
      <div className='emissionSec_Col2'>
          <img src={CarbonNatural} alt="" />
      </div>
      </div>        
    </div>
    
  );
}

export default Emission;