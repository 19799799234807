import React, { useState, useEffect } from "react";

import "./style.css";

import UserProfileImg from "../../../assets/images/userImg.png";
import AdminPagination from "../pagination/AdminPagination";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Loader/PageLoader";

const userDetailsList = [
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "B&I Organization",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Consultant",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Auditor",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Advisory Board",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "B&I Organization",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Consultant",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Auditor",
    country: "UAE",
  },
  {
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    orgName: "Codewave",
    userType: "Advisory Board",
    country: "UAE",
  },
];

function ReqUserDetails({
  isLoading,
  isFetching,
  error,
  list,
  changeRowsPerPage,
  rowsPerPage,
  changePage,
  page,
  totalCount,
}) {
  const navigate = useNavigate();

  const profileViewFun = () => {
    navigate("/ProfileView");
  };

  const handleViewUserProfile = (item) => {
    navigate({
      pathname: "/ProfileView",
      search: `?userType=${item?.user?.userType}&userId=${item?.user?._id}`,
    });
  };

  return (
    <div className="reqUserDetailContainer">
      {(isLoading || isFetching) && <PageLoader />}
      <table>
        <tr className="reqUserDetailTitle">
          <th className="heading">Name & Email</th>
          <th className="heading">Organization’s Name</th>
          <th className="heading">User Type</th>
          <th className="heading">Country</th>
          <th className="heading">Actions</th>
        </tr>
        {list?.data?.map((item, index) => (
          <tr className="reqUserList" key={index}>
            <td>
              <div className="listedUser">
                <img src={UserProfileImg} alt="ProfileIcon" />
                <div className="userGmailData">
                  <h1>{item?.user?.fullName}</h1>
                  <h3>{item?.user?.email}</h3>
                </div>
              </div>
            </td>
            <td>
              <p>
                {item?.user?.userType === "organization"
                  ? item?.user?.orgDetails?.orgName
                  : item?.user?.workDetails?.orgName}
              </p>
            </td>
            <td>
              <p>
                {item?.user?.userType === "organization"
                  ? "Organization"
                  : item?.user?.userType === "advisoryBoard"
                    ? "Advisory Board"
                    : item?.user?.userType === "auditor"
                      ? "Auditor"
                      : item?.user?.userType === "consultant"
                        ? "Consultant"
                        : ""}
              </p>
            </td>
            <td>
              <p>{item?.user?.basicInfo?.address?.country}</p>
            </td>
            <td className="reviewProfile">
              <p
                className="cursorPointer"
                onClick={() => {
                  handleViewUserProfile(item);
                }}
              >
                Review Profile
              </p>
            </td>
          </tr>
        ))}
      </table>
      <AdminPagination
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        page={page}
        totalCount={totalCount}
      />
    </div>
  );
}

export default ReqUserDetails;
