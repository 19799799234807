import react, { useEffect, useReducer, useState } from "react";

import "./style.css";

import AdminLogo from "../../../assets/logo/adminDashLogo.svg";
import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import RegistrationRequest from "../../../components/adminComponents/registrationRequest/RegistrationRequest";
import UserReqForm from "../../../components/adminComponents/userReqForm/UserReqForm";
import ReqUserDetails from "../../../components/adminComponents/requestedUserDetails/ReqUserDetails";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";
import { useUserListQuery } from "../../../features/admin/adminApiSlice";
import PageLoader from "../../../components/Loader/PageLoader";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  identifier: "",
  userType: "",
  country: "",
  status: "",
  pendingRequest: true,
  date: "",
  createdAt: "-1",
  // pendingRequest: true,
  alphabetical: null,
  location: "",
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      identifier: action.identifier,
    };
  }
  if (action.type === "SET_USER_TYPE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      userType: action.userType,
    };
  }
  if (action.type === "SET_COUNTRY") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      country: action.country,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status ? action.status : initialQueryFilterState.status,
    };
  }
  if (action.type === "SET_ALPHABETICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      alphabetical: action.alphabetical,
      createdAt: null,
    };
  }
  if (action.type === "SET_CRONOLOGICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      createdAt: action.createdAt,
      alphabetical: null,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...action.filters,
    };
  }

  return initialQueryFilterState;
};

function UserRequestPage() {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();

  const {
    data: userList,
    isLoading: userListIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: userListIsError,
    isFetching: userListIsFetching,
  } = useUserListQuery({ ...queryFilterState });

  const handleSearchChange = (e) => {
    dispatchQueryFilter({ type: "SEARCH", identifier: e.target.value });
  };

  const handleChangeUserType = (e) => {
    dispatchQueryFilter({ type: "SET_USER_TYPE", userType: e.target.value });
  };

  const handleChangeCountry = (e) => {
    dispatchQueryFilter({ type: "SET_COUNTRY", country: e.target.value });
  };

  const handleChangeStatus = (e) => {
    dispatchQueryFilter({ type: "SET_STATUS", status: e.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };

  useEffect(() => {
    if (userListIsError) {
      if (userListError?.message) {
        dispatch(showError({ message: userListError.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
    if (userListIsSuccess) {
      setUserData(userList);
    }
  }, [userList, userListIsSuccess, userListError, dispatch]);
  return (
    <div className="userReqContainer">
      <div className="userReqCol1">
        <AdminSideMenu />
      </div>
      <div className="userReqCol2">
        <AdminDashHeader />
        <div className="registReqSection">
          <RegistrationRequest />
          <UserReqForm
            handleSearchChange={handleSearchChange}
            queryFilterState={queryFilterState}
            onChangeUserType={handleChangeUserType}
            onChangeCountry={handleChangeCountry}
            onChangeStatus={handleChangeStatus}
          />
          {userList?.data[0].data?.length ? (
            <ReqUserDetails
              isLoading={userListIsLoading}
              isFetching={userListIsFetching}
              error={userListIsError}
              list={userList?.data[0]}
              // edit={editCategoryPageNavigationHandler}
              totalCount={userList?.data[0]?.metadata[0]?.totalCount}
              // deleteData={deleteVendor}
              // editVendor={editVendor}
              // bulkEdit={bulkEdit}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
            />
          ) : userListIsLoading ? (
            <PageLoader />
          ) : (
            <span className="d-flex justify-content-center m-3">
              <h1 style={{ textAlign: "center", marginTop: "150px" }}>
                No Data Found
              </h1>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserRequestPage;
