import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

import TextArea from "../../../InputBox/textAreaBox/TextArea";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function OperaBoundaries({ formik, activeSection, setActiveSection }) {
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "operaBoundariesSection" : false);
  };
  return (
    <div className="emisSectionGap" id="operaBoundariesSection">
      <Accordion
        expanded={activeSection === "operaBoundariesSection"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>Operational Boundaries</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="OperScopSec">
            <h1> Are Scope 3 emissions included in this inventory? </h1>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={formik?.values?.operationalBoundaries?.includeScope3}
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "operationalBoundaries.includeScope3",
                  value
                );
                formik.setFieldValue(
                  "operationalBoundaries.scope3ActivityTypes",
                  ""
                );
                formik.setFieldValue("infoOnEmission1.scope3Emissions", {
                  co2: {
                    value: null,
                    notRecorded: false,
                    specify: "",
                  },
                  ch2: {
                    value: null,
                    notRecorded: true,
                    specify: "",
                  },
                  n2o: {
                    value: null,
                    notRecorded: false,
                    specify: "",
                  },
                  hfcs: {
                    value: null,
                    notRecorded: false,
                    specify: "",
                  },
                  pfcs: {
                    value: null,
                    notRecorded: true,
                    specify: "",
                  },
                  sf6: {
                    value: null,
                    notRecorded: false,
                    specify: "",
                  },
                  total: {
                    value: null,
                  },
                });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>

          <div className="OperScopSec">
            <h1>
              {" "}
              If yes, which types of activities are included in Scope 3
              emissions?
            </h1>
            {formik?.values?.operationalBoundaries?.includeScope3 && (
              <div className="wd-100">
                <TextArea
                  headName={"Specify Here"}
                  type="text"
                  placeholder="Tell us what has been excluded"
                  name="operationalBoundaries.scope3ActivityTypes"
                  value={
                    formik?.values?.operationalBoundaries?.scope3ActivityTypes
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    !!formik.touched.operationalBoundaries
                      ?.scope3ActivityTypes &&
                    formik.errors.operationalBoundaries?.scope3ActivityTypes
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default OperaBoundaries;
