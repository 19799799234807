import apiSlice from "../../app/api/apiSlice";

export const step3RoadmapSubmissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    roadmapSubmit: builder.mutation({
      query: (data) => ({
        url: "/rating/v1/roadmap-submission/submit",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Chats", "Issues", "findOne"],
    }),
    roadmapResubmit: builder.mutation({
      query: (data) => ({
        url: "/rating/v1/roadmap-resubmit/submit",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Chats", "Issues", "findOne"],
    }),
    roadmapDrafts: builder.mutation({
      query: (data) => ({
        url: "/rating/v1/roadmap-submission/draft",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    roadmapCreateIssue: builder.mutation({
      query: (issueBody) => ({
        url: "/rating/v1/roadmap-audit/create-issue",
        method: "POST",
        body: issueBody,
      }),
      invalidatesTags: ["Chats", "Issues", "findOne"],
    }),
    roadmapUpdateIssue: builder.mutation({
      query: (issueBody) => ({
        url: "/rating/v1/roadmap-audit/update-issue",
        method: "POST",
        body: issueBody,
      }),
      invalidatesTags: ["Chats", "Issues", "findOne"],
    }),
    roadmapRevert: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/roadmap-audit/revert",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    roadmapReject: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/roadmap-audit/reject",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
    roadmapApprove: builder.mutation({
      query: (body) => ({
        url: "/rating/v1/roadmap-audit/approve",
        method: "POST",
        body: body,
      }),
      // invalidatesTags: ["Chats", "Issues"],
    }),
  }),
});

export const {
  useRoadmapSubmitMutation,
  useRoadmapResubmitMutation,
  useRoadmapDraftsMutation,
  useRoadmapCreateIssueMutation,
  useRoadmapUpdateIssueMutation,
  useRoadmapRevertMutation,
  useRoadmapRejectMutation,
  useRoadmapApproveMutation,
} = step3RoadmapSubmissionApiSlice;
