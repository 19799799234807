import "./style.css";
import WarningIcon from "../../assets/icons/errorWarning.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function InputBox({
  headName,
  placeholder,
  inputBoxMb,
  type,
  inputBorder,
  error,
  errorIcon,
  required,
  mobileNo,
  countryCodeName,
  formik,
  countryCodeValue,
  defaultCountry,
  countryCodeError,
  ...props
}) {
  return (
    <div className="input-item" style={{ marginBottom: inputBoxMb }}>
      <label className="input_label">
        {headName}
        {/* {required && <small className='error'> &nbsp;*</small>} */}
      </label>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          gridGap: "5px",
        }}
      >
        <input
          type={type}
          placeholder={placeholder}
          className={`custom-input ${error ? "error" : ""}`}
          {...props}
          spellCheck="false"
        />
      </div>
      {error && (
        <p error className="inputError">
          {errorIcon && (
            <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
          )}
          {error}
        </p>
      )}
    </div>
  );
}

export default InputBox;
