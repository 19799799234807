import React from 'react';

import "./style.css";

function ProfileEditBtn() {
  return (
    <div className='profileEditBtn cursorPointer'>
      <p className='profileEdtBtnText'>Edit Profile</p>
    </div>
  )
}

export default ProfileEditBtn;