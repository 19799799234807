import React from "react";

import "./style.css";

import ActiveRatingIcon from "../../../assets/images/currentRatingGreen.svg";
import InactiveRatingIcon from "../../../assets/images/currentRatingWhite.svg";

function CurrentRatingSection({ currentRating }) {
  return (
    <div className="currentRatingCon">
      <div className="currentRatSec">
        <h2>Current Rating</h2>
        <h1>{currentRating ? currentRating : "_"}</h1>
        <div>
          <p>Valid until Aug 2024</p>
        </div>
      </div>

      <div>
        <img src={ActiveRatingIcon} alt="ActiveRatingIcon" />
      </div>
    </div>
  );
}

export default CurrentRatingSection;
