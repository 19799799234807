import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import UserProgressCard from "../../../components/adminComponents/progressDashboardComponents/UserProgressCard";
import TotalEmissionDeclareGraph from "../../../components/adminComponents/progressDashboardComponents/TotalEmissionDeclareGraph";
import BestRatedBI from "../../../components/adminComponents/progressDashboardComponents/BestRatedBI";
import DashboardRating from "../../../components/adminComponents/progressDashboardComponents/DashboardRating";
import ReportedProgressCard from "../../../components/adminComponents/progressDashboardComponents/ReportedProgressCard";

import "./style.css";

import ProgressUp from "../../../assets/icons/progressUpGreen.svg";
import ProgressDown from "../../../assets/icons/progressDownGreen.svg";
import BIRegisteredIcon from "../../../assets/icons/BIRegistered.svg";
import RatingQuarter from "../../../assets/icons/ratingQuarter.svg";
import UserProgress from "../../../assets/icons/userProgress.svg";
import Feedback from "../../../assets/icons/feedbackProgress.svg";
import AuditReported from "../../../assets/icons/auditReportedIssue.svg";
import BoardMember from "../../../assets/icons/advBoardMember.svg";
import EditPerQuarter from "../../../assets/icons/editPerQuarter.svg";
import CountryIcon from "../../../assets/icons/countryIcon.svg";
import AverageRatingTime from "../../../components/adminComponents/progressDashboardComponents/AverageRatingTime";
import PerSector from "../../../components/adminComponents/progressDashboardComponents/PerSector";
import OrganizationCurrentRating from "../../../components/dashboardComponents/OrganizationCurrentRating";
import OrganizationEmissionsDeclared from "../../../components/dashboardComponents/OrganizationEmissionsDeclared";
import {
  useAnalyticsQuery,
  useOrganizationRatingsQuery,
  useRatingsQuery,
  useTotalEmissionsQuery,
} from "../../../features/admin/adminApiSlice";

function AdminProgressDashboard() {
  const { data: analyticsData, isLoading: analyticsIsLoading } =
    useAnalyticsQuery();

  const { data: ratingsData, isLoading: ratingsIsLoading } = useRatingsQuery();

  const {
    data: organizationRatingsData,
    isLoading: organizationRatingsIsLoading,
  } = useOrganizationRatingsQuery();

  const { data: totalEmissionsData, isLoading: totalEmissionsIsLoading } =
    useTotalEmissionsQuery();

  const formatNumberToK = (number) => {
    if (number >= 1000) {
      return (number / 1000).toFixed(0) + "k";
    } else {
      return number?.toString();
    }
  };

  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu progressDashboardMenu={"progressDashboardMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <div className="userProgressRow" style={{ width: "100%" }}>
            <UserProgressCard
              ratingCardIcon={BIRegisteredIcon}
              ratingCardTitle={"B&I Registered"}
              ratingValue={formatNumberToK(
                analyticsData?.data?.organizations?.total
              )}
              ratingGrowIcon={ProgressUp}
              ratingPercentage={`${analyticsData?.data?.organizations?.percentageIncrease}%`}
            />
            <UserProgressCard
              ratingCardIcon={RatingQuarter}
              ratingCardTitle={"Ratings This Quarter"}
              ratingValue={formatNumberToK(analyticsData?.data?.ratings?.total)}
              ratingGrowIcon={ProgressUp}
              ratingPercentage={`${analyticsData?.data?.ratings?.percentageIncrease}%`}
            />
            <UserProgressCard
              ratingCardIcon={UserProgress}
              ratingCardTitle={"Users"}
              ratingValue={formatNumberToK(analyticsData?.data?.users?.total)}
              ratingGrowIcon={ProgressDown}
              ratingPercentage={`${analyticsData?.data?.users?.percentageIncrease}%`}
            />
            <UserProgressCard
              ratingCardIcon={Feedback}
              ratingCardTitle={"Feedbacks"}
              ratingValue={formatNumberToK(
                analyticsData?.data?.feedbacks?.total
              )}
              ratingGrowIcon={ProgressUp}
              ratingPercentage={`${analyticsData?.data?.feedbacks?.percentageIncrease}%`}
            />
          </div>
          <div className="userProgressRow">
            <div className="userProgressCol1">
              <TotalEmissionDeclareGraph
                totalEmissionsData={totalEmissionsData}
              />
            </div>
            <div className="userProgressCol2">
              <BestRatedBI organizationRatingsData={organizationRatingsData} />
            </div>
          </div>
          <div className="userProgressRow">
            <div className="dashboardRatingCol1">
              <DashboardRating ratingsData={ratingsData} />
            </div>
            <div className="dashboardRatingCol2">
              <div className="repotedCardSec">
                <ReportedProgressCard
                  reportCardIcon={AuditReported}
                  reportCardTitle={"Auditor Reported Issues"}
                  reportValue={formatNumberToK(
                    analyticsData?.data?.issues?.total
                  )}
                  reportGrowIcon={ProgressDown}
                  reportPercentage={`${analyticsData?.data?.issues?.percentageIncrease}%`}
                />
                <ReportedProgressCard
                  reportCardIcon={BoardMember}
                  reportCardTitle={"Advisory Board Members"}
                  reportValue={formatNumberToK(
                    analyticsData?.data?.advisoryBoards?.total
                  )}
                  reportGrowIcon={ProgressUp}
                  reportPercentage={`${analyticsData?.data?.advisoryBoards?.percentageIncrease}%`}
                />
                <ReportedProgressCard
                  reportCardIcon={EditPerQuarter}
                  reportCardTitle={"Edits Per Quarter"}
                  reportValue={formatNumberToK(
                    analyticsData?.data?.totalEdits?.total
                  )}
                  reportGrowIcon={ProgressUp}
                  reportPercentage={`${analyticsData?.data?.totalEdits?.percentageIncrease}%`}
                />
                <ReportedProgressCard
                  reportCardIcon={CountryIcon}
                  reportCardTitle={"Countries / Territories"}
                  reportValue={"--"}
                  reportGrowIcon={ProgressUp}
                  reportPercentage={"--%"}
                />
              </div>
            </div>
          </div>
          <div className="userProgressRow">
            <div className="avgRatingTimeCol1">
              <AverageRatingTime days={analyticsData?.data?.avgTimeForRating} />
            </div>
            <div className="avgRatingTimeCol2">
              <PerSector
                sectorwiseOrganization={
                  analyticsData?.data?.sectorwiseOrganization
                }
              />
            </div>
          </div>

          {/* <div style={{ display: "flex" }}>
            <OrganizationCurrentRating />
            <OrganizationEmissionsDeclared />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AdminProgressDashboard;
