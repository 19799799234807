import react, { useState } from "react";

import "./style.css";
import PdfUpload from "../../../pdfUpload/PdfUpload";
import ProfileHeadquarters from "../profileHeadquarters/ProfileHeadquarters";

function AuditorOrganizationDetails({ userData, userType }) {
  
  return (
    <div className="viewOrgDetContainer">
        <div className="userOrgDetails">
          <p>Name of Auditing Organization</p>
          <h1>{userData?.data?.workDetails?.associatedOrg}</h1>
        </div>
        <div className="auditorUserDetailsRow">
          <div className="userOrgDetails">
            <p>Name of Organization</p>
            <h1>{userData?.data?.workDetails?.orgName}</h1>
          </div>
          <div className="userOrgDetails">
            <p>Website link</p>
            <h1>{userData?.data?.workDetails?.websiteLink}</h1>
          </div>
        </div>

         {/* Headquarter Section  */}
        <ProfileHeadquarters
          HeadquartersTitle={"Headquarters"}
          address={userData?.data?.workDetails?.orgHeadquarter}
        />
        <div className="proRegisDetails">
          <h1>Proof Of Auditorship </h1>
          <div className="profilePdfSection">
            {userData?.data?.workDetails?.proofOfAuditorshipFiles?.map(
              (item, index) => (
                <PdfUpload faXmarkDisplay={"none"} pdfType={item} key={index} />
              )
            )}
          </div>
        </div>
    </div> 
   
  );
}

export default AuditorOrganizationDetails;
