import react, { useEffect, useReducer, useState } from "react";

import AdminDashHeader from "../../../components/adminComponents/adminDashHeader/AdminDashHeader";
import AdminSideMenu from "../../../components/adminComponents/adminSideMenu/AdminSideMenu";
import AdminOrgTitle from "../../../components/adminComponents/adminOrgTitle/AdminOrgTitle";
import RatingFilterForm from "../../../components/adminComponents/userFilterForm/RatingFilterForm";
import OrgsRatingDetails from "../../../components/adminComponents/userDetails/OrgsRatingDetails";

import "./style.css";
import { useOrganizationRatingsQuery } from "../../../features/admin/adminApiSlice";
import moment from "moment";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      identifier: action.identifier,
    };
  }
  if (action.type === "SET_USER_TYPE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      userType: action.userType,
    };
  }
  if (action.type === "SET_COUNTRY") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      country: action.country,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status ? action.status : initialQueryFilterState.status,
    };
  }
  if (action.type === "SET_STARTDATE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      startDate: action.startDate,
    };
  }
  if (action.type === "SET_ENDDATE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      endDate: action.endDate,
    };
  }
  if (action.type === "SET_ALPHABETICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      alphabetical: action.alphabetical,
      createdAt: null,
    };
  }
  if (action.type === "SET_CRONOLOGICAL_SORTING") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      createdAt: action.createdAt,
      alphabetical: null,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...action.filters,
    };
  }

  return initialQueryFilterState;
};

function AdminOrgsRating() {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const {data : organizationRatings, isLoading : organizationRatingsIsLoading} = useOrganizationRatingsQuery({ ...queryFilterState })

  const handleSearchChange = (e) => {
    dispatchQueryFilter({ type: "SEARCH", identifier: e.target.value });
  };

  const handleChangeUserType = (e) => {
    dispatchQueryFilter({ type: "SET_USER_TYPE", userType: e.target.value });
  };

  const handleChangeCountry = (e) => {
    dispatchQueryFilter({ type: "SET_COUNTRY", country: e.target.value });
  };

  const handleChangeStatus = (e) => {
    dispatchQueryFilter({ type: "SET_STATUS", status: e.target.value });
  };

  const handleChangeCalender = (dateRange) => {
    const [startDate, endDate] = dateRange;
    if (startDate === null && endDate === null) {
      const formattedStartDate = null;
      const formattedEndDate = null;
      dispatchQueryFilter({
        type: "SET_STARTDATE",
        startDate: formattedStartDate,
      });
      dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });

      return;
    }

    // Continue with formatting when at least one of startDate or endDate is not null
    const formattedStartDate = startDate
      ? moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;

    const formattedEndDate = endDate
      ? moment(endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      : null;

    console.log("checkdateStartDate", startDate);
    console.log("checkdateEndDate", endDate);
    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);

    dispatchQueryFilter({
      type: "SET_STARTDATE",
      startDate: formattedStartDate,
    });
    dispatchQueryFilter({ type: "SET_ENDDATE", endDate: formattedEndDate });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };
  
  return (
    <div className="adminMainContainer">
      <div className="adminDashCol1">
        <AdminSideMenu orgsRatingMenu={"orgsRatingMenu"} />
      </div>
      <div className="adminDashCol2">
        <AdminDashHeader />
        <div className="adminUsersConatiner">
          <AdminOrgTitle />
          {/* <RatingFilterForm /> */}
          <OrgsRatingDetails 
          ratingList = {organizationRatings} 
          isLoading={organizationRatingsIsLoading}
          // totalCount={organizationRatings?.data[0]?.metadata[0]?.totalCount}
          // changeRowsPerPage={handleChangeRowsPerPage}
          // rowsPerPage={queryFilterState.pageSize}
          // changePage={handleChangePage}
          // page={queryFilterState.pageNo}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminOrgsRating;
