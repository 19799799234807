import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

import PdfUploadImg from "../../assets/icons/Pdf _File_Icon.svg";
import PdfDownloadIcon from "../../assets/icons/pdfDownloadIcon.svg";

function PdfDocuments({ pdfType, faXmarkDisplay, onRemove, src }) {
  const extractFilename = (url) => {
    const regex = /-see-rating-(.+\.pdf)$/;
    const match = url?.match(regex);

    if (match) {
      return match[1];
    } else {
      return "No filename found in the URL.";
    }
  };

  const pdfName = extractFilename(pdfType);

  function TruncateText({ text }) {
    if (text.length <= 6) {
      return <p>{text}</p>;
    }

    const truncatedText = `${text.slice(0, 6)}..`;
    const fileExtension = text.split(".").pop();

    return (
      <p>
        {truncatedText}.{fileExtension}
      </p>
    );
  }

  return (
    <div className="pdfDocContainer">
      <FontAwesomeIcon
        //  icon={faXmark}
        className="pdfDocCloseBtn cursorPointer"
        style={{ color: "rgb(118 118 114)", display: faXmarkDisplay }}
        onClick={() => {
          onRemove(src);
        }}
      />
      <div className="pdfDocInnerdiv">
        <img className="pdfDocIcon" src={PdfUploadImg} alt="pdfimg" />
        <TruncateText text={pdfName} />
      </div>
      <img src={PdfDownloadIcon} alt="PdfDownloadIcon" />
    </div>
  );
}

function PdfDocuments2({ pdfType, faXmarkDisplay, fileName, downloadLink }) {
  const handleDownloadFile = () => {
    const link = document.createElement("a");
    link.href = downloadLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="pdfDocContainer"
      onClick={handleDownloadFile}
      style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon
        //  icon={faXmark}
        className="pdfDocCloseBtn cursorPointer"
        style={{ color: "rgb(118 118 114)", display: faXmarkDisplay }}
      />
      <div className="pdfDocInnerdiv">
        <img className="pdfDocIcon" src={PdfUploadImg} alt="pdfimg" />
        <p>{fileName}</p>
      </div>
      <img src={PdfDownloadIcon} alt="PdfDownloadIcon" />
    </div>
  );
}

export default PdfDocuments;
export { PdfDocuments2 };
