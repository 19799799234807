import React from 'react';
import SendBySection from '../sendBySection/SendBySection';
import PdfUpload from '../../../../pdfUpload/PdfUpload';

import "./style.css";

function TextWithPdf({text, pdfData}) {
  return (
    <div className="textMsgCol">
        <p className="textMsgPara">{text}</p>
        <div className='textWithPdfSec'>
          {pdfData}
        </div>
        <SendBySection />
    </div>
  )
}

export default TextWithPdf;