import React from "react";
import InputBox from "../../../InputBox/InputBox";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

function GHGEmissionPreview({
  resultHeaderName,
  resultTitle1,
  resultTitle2,
  resultTitle3,
  value1,
  value2,
  value3,
}) {
  return (
    <div className="ghgEmissionPreCon">
      <h1 className="ghgEmissPreTitle">{resultHeaderName}</h1>
      <div className="ghgEmissPreScopeRow">
        <div className="ghgEmissScopePre">
          <div className="ghgEmissPreCol">
            <h1>{resultTitle1}</h1>
            <div className="resultPreSec">
              <p className="resultPreTitle">Q1 Result GHG (tCO2e)</p>
              <div className="resultValue">
                <h1>tCO2e</h1>
                <p>{value1?.value}</p>
              </div>
            </div>
            <div className="resultPreSec">
              <p className="resultPreTitle">Explanatory Notes</p>
              <p className="resultDisrip">{value1?.explanatoryNotes}</p>
            </div>
          </div>
          <div className="ghgEmissPreCol">
            <h1>{resultTitle2}</h1>
            <div className="resultPreSec">
              <p className="resultPreTitle">Q1 Result GHG (tCO2e)</p>
              <div className="resultValue">
                <h1>tCO2e</h1>
                <p>{value2?.value}</p>
              </div>
            </div>
            <div className="resultPreSec">
              <p className="resultPreTitle">Explanatory Notes</p>
              <p className="resultDisrip">{value2?.explanatoryNotes}</p>
            </div>
          </div>
          <div className="ghgEmissPreCol">
            <h1>{resultTitle3}</h1>
            <div className="resultPreSec">
              <p className="resultPreTitle">Q1 Result GHG (tCO2e)</p>
              <div className="resultValue">
                <h1>tCO2e</h1>
                <p>{value3?.value}</p>
              </div>
            </div>
            <div className="resultPreSec">
              <p className="resultPreTitle">Explanatory Notes</p>
              <p className="resultDisrip">{value3?.explanatoryNotes}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GHGEmissionPreview;
