import apiSlice from "../../../app/api/apiSlice";

export const auditorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkAuditor: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/app/v1/auditor/registration/check${queryString}`,
        };
      },
      providesTags: ["Consultant"],
    }),

    auditorBasicInfo: builder.mutation({
      query: (basicData) => ({
        url: "/app/v1/auditor/registration/basic-info",
        method: "POST",
        body: basicData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    auditorWorkDetails: builder.mutation({
      query: (workDetails) => ({
        url: "/app/v1/auditor/registration/work-details",
        method: "POST",
        body: workDetails,
      }),
      invalidatesTags: ["Auditors"],
    }),

    previewAuditor: builder.mutation({
      query: (previewData) => ({
        url: "/app/v1/auditor/registration/find-one",
        method: "POST",
        body: previewData,
      }),
      invalidatesTags: ["Auditors"],
    }),

    completeAuditor: builder.mutation({
      query: (completeData) => ({
        url: "/app/v1/auditor/registration/complete-registration",
        method: "POST",
        body: completeData,
      }),
      invalidatesTags: ["Auditors"],
    }),
  }),
});

export const {
  useCheckAuditorQuery,
  useAuditorBasicInfoMutation,
  useAuditorWorkDetailsMutation,
  useCompleteAuditorMutation,
  usePreviewAuditorMutation,
} = auditorApiSlice;
