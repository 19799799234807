import apiSlice from "../app/api/apiSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotification: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/app/v1/auth/user/get-notifications${queryString}`,
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache?.data?.push(...newItems?.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ["GetNotification"],
    }),

    readNotification: builder.query({
      query: (id) => {
        return {
          url: `/app/v1/auth/user/read-notification/${id}`,
        };
      },
      providesTags: ["ReadNotification"],
    }),
  }),
});

export const { useGetNotificationQuery, useReadNotificationQuery } =
  notificationApiSlice;
