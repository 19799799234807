import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import TextArea from "../../../InputBox/textAreaBox/TextArea";
import InputBox from "../../../InputBox/InputBox";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import OffsetSelectProject from "../offsetSelectProject/OffsetSelectProject";

function InfoOnOffsets({ formik, activeSection, setActiveSection }) {
  const addOutsideOffset = () => {
    formik.setFieldValue(
      "infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset",
      [
        ...formik?.values?.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo
          ?.offset,
        {
          quantityOfGhgs: "",
          offsetProjectType: "",
          offsetsverified: "",
        },
      ]
    );
  };

  const removeOutsideOffset = ({ deleteIndex }) => {
    const updatedEntity =
      formik?.values?.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo?.offset?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      "infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset",
      updatedEntity
    );
  };

  const addInsideOffset = () => {
    formik.setFieldValue(
      "infoOnOffsets.soldTransferredReductionsInfo.reduction",
      [
        ...formik?.values?.infoOnOffsets?.soldTransferredReductionsInfo
          ?.reduction,
        {
          quantityOfGhgs: "",
          offsetProjectType: "",
          offsetsverified: "",
        },
      ]
    );
  };

  const removeInsideOffset = ({ deleteIndex }) => {
    const updatedEntity =
      formik?.values?.infoOnOffsets?.soldTransferredReductionsInfo?.reduction?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      "infoOnOffsets.soldTransferredReductionsInfo.reduction",
      updatedEntity
    );
  };
  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "infoOnOffsets" : false);
  };

  return (
    <div className="emisSectionGap" id="infoOnOffsets">
      <Accordion
        expanded={activeSection === "infoOnOffsets"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON OFFSETS</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <h1>
            Information on offsets that have been purchased or developed outside
            the inventory boundary
          </h1>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo
                  ?.isApplicable
              }
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "infoOnOffsets.purchasedOrDevelopedOffsetsInfo.isApplicable",
                  value
                );
                formik.setFieldValue(
                  "infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset",
                  [
                    {
                      quantityOfGhgs: null,
                      offsetProjectType: "",
                      offsetsverified: "",
                    },
                  ]
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Applicable"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo
            ?.isApplicable && (
            <div>
              {formik.values?.infoOnOffsets?.purchasedOrDevelopedOffsetsInfo?.offset?.map(
                (_, index) => (
                  <div className="equitySection" key={index}>
                    <div className="remove_div">
                      <p className="equityText">Offset 1</p>
                      {formik.values?.infoOnOffsets
                        ?.purchasedOrDevelopedOffsetsInfo?.offset?.length >
                        1 && (
                        <p
                          className="remove cursorPointer"
                          onClick={removeOutsideOffset.bind(null, {
                            deleteIndex: index,
                          })}
                        >
                          <FontAwesomeIcon
                            icon={faCircleMinus}
                            className="fontAwesomeIcon_img"
                            style={{ color: "#008BB2" }}
                          />{" "}
                          Remove
                        </p>
                      )}
                    </div>
                    <div className="offsetEmis">
                      <div className="offsetEmis1">
                        <InputBox
                          headName={"Quantity of GHGs (mtCO2e)"}
                          placeholder="Enter the value"
                          name={`infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset[${index}].quantityOfGhgs`}
                          value={
                            formik?.values?.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.quantityOfGhgs
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset &&
                            !!formik.touched.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.quantityOfGhgs &&
                            formik.errors.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset &&
                            formik.errors.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.quantityOfGhgs
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                      <div className="offsetEmis2">
                        <OffsetSelectProject
                          headName="Type of offset project"
                          placeholder="Select the type"
                          name={`infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset[${index}].offsetProjectType`}
                          value={
                            formik?.values?.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.offsetProjectType
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset &&
                            !!formik.touched.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.offsetProjectType &&
                            formik.errors.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset &&
                            formik.errors.infoOnOffsets
                              ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                              ?.offsetProjectType
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                    </div>
                    <div className="wd-100">
                      <p className="doRepText">
                        Were the offsets verified/certified and/or approved by
                        an external GHG program (e.g., CDM)
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="wd-100"
                        value={
                          formik?.values?.infoOnOffsets
                            ?.purchasedOrDevelopedOffsetsInfo?.offset[index]
                            ?.offsetsverified
                        }
                        onChange={(e) => {
                          const value = e.target.value === "true";
                          formik?.setFieldValue(
                            `infoOnOffsets.purchasedOrDevelopedOffsetsInfo.offset[${index}].offsetsverified`,
                            value
                          );
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Yes"
                          defaultChecked
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="No"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                )
              )}
              <div className="addBranch_div cursorPointer">
                <p className="addBranch" onClick={addOutsideOffset}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />{" "}
                  Add Offset
                </p>
              </div>
            </div>
          )}
          <p>
            Information on reductions inside the inventory boundary that have
            been sold/transferred as offsets to a third party.
          </p>
          <div className="OperScopSec">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.infoOnOffsets?.soldTransferredReductionsInfo
                  ?.isApplicable
              }
              onChange={(e) => {
                const value = e.target.value === "true";
                formik?.setFieldValue(
                  "infoOnOffsets.soldTransferredReductionsInfo.isApplicable",
                  value
                );
                formik.setFieldValue(
                  "infoOnOffsets.soldTransferredReductionsInfo.reduction",
                  [
                    {
                      quantityOfGhgs: null,
                      offsetProjectType: "",
                      offsetsverified: "",
                    },
                  ]
                );
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Applicable"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="Not Applicaple"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>
          </div>
          {formik?.values?.infoOnOffsets?.soldTransferredReductionsInfo
            ?.isApplicable && (
            <div>
              {formik.values?.infoOnOffsets?.soldTransferredReductionsInfo?.reduction?.map(
                (_, index) => (
                  <div className="equitySection" key={index}>
                    <div className="remove_div">
                      <p className="equityText">Offset 1</p>
                      {formik.values?.infoOnOffsets
                        ?.soldTransferredReductionsInfo?.reduction?.length >
                        1 && (
                        <p
                          className="remove cursorPointer"
                          onClick={removeInsideOffset.bind(null, {
                            deleteIndex: index,
                          })}
                        >
                          <FontAwesomeIcon
                            icon={faCircleMinus}
                            className="fontAwesomeIcon_img"
                            style={{ color: "#008BB2" }}
                          />{" "}
                          Remove
                        </p>
                      )}
                    </div>
                    <div className="offsetEmis">
                      <div className="offsetEmis1">
                        <InputBox
                          headName={"Quantity of GHGs (mtCO2e)"}
                          placeholder="Enter the value"
                          name={`infoOnOffsets.soldTransferredReductionsInfo.reduction[${index}].quantityOfGhgs`}
                          value={
                            formik?.values?.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.quantityOfGhgs
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction &&
                            !!formik.touched.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.quantityOfGhgs &&
                            formik.errors.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction &&
                            formik.errors.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.quantityOfGhgs
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                      <div className="offsetEmis2">
                        <OffsetSelectProject
                          headName="Type of offset project"
                          placeholder="Select the type"
                          name={`infoOnOffsets.soldTransferredReductionsInfo.reduction[${index}].offsetProjectType`}
                          value={
                            formik?.values?.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.offsetProjectType
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction &&
                            !!formik.touched.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.offsetProjectType &&
                            formik.errors.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction &&
                            formik.errors.infoOnOffsets
                              ?.soldTransferredReductionsInfo?.reduction[index]
                              ?.offsetProjectType
                          }
                          errorIcon={WarningIcon}
                        />
                      </div>
                    </div>
                    <div className="wd-100">
                      <p className="doRepText">
                        Were the offsets verified/certified and/or approved by
                        an external GHG program (e.g., CDM)
                      </p>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="wd-100"
                        value={
                          formik?.values?.infoOnOffsets
                            ?.soldTransferredReductionsInfo?.reduction[index]
                            ?.offsetsverified
                        }
                        onChange={(e) => {
                          const value = e.target.value === "true";
                          formik?.setFieldValue(
                            `infoOnOffsets.soldTransferredReductionsInfo.reduction[${index}].offsetsverified`,
                            value
                          );
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Yes"
                          defaultChecked
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="No"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              fontFamily: "Uniform",
                              fontWeight: 400,
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>
                  </div>
                )
              )}

              <div className="addBranch_div cursorPointer">
                <p className="addBranch" onClick={addInsideOffset}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />{" "}
                  Add Offset
                </p>
              </div>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default InfoOnOffsets;
