import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

import {
  useOrgInfoMutation,
  usePreviewInfoMutation,
} from "../../../features/organization/organizationApiSlice";
import {
  useOrgAudInfoMutation,
  usePreviewAudInfoMutation,
} from "../../../features/auditor/auditorApiSlice";
import {
  useOrgConsInfoMutation,
  usePreviewConsInfoMutation,
} from "../../../features/consultant/consultantApiSlice";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { cleanObject, omitEmptyKeys } from "../../../utils/helper";

import BasicTimeline from "../../../components/timeLine/BasicTimeline";
import SelectInputBox, {
  SelectCountryInputBox,
  SelectStateInputBox,
} from "../../../components/InputBox/selectBox/SelectInputBox";
import InputBox from "../../../components/InputBox/InputBox";
import FormButton from "../../../components/button/FormButton";
import RegCancelButton from "../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../components/button/regBackBtn/RegBackButton";
import FileUploadBoxRegistration from "../../../components/fileUploadBox/FileUploadBoxRegistration";
import { memberWorkDetailsSchema } from "../../../Validators/register.validators";

import "./style.css";

import RegistrationLogo from "../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../assets/icons/errorWarning.svg";
import {
  useAdvisoryBoardBasicInfoMutation,
  useAdvisoryBoardPreviewInfoMutation,
  useAdvisoryBoardWorkDetailsMutation,
} from "../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import { getUserFromLocal } from "../../../utils/storage";
import { setUserHandler } from "../../../features/user/userAction";
import { userActions } from "../../../features/user/userSlice";
import { showError } from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../../components/Loader/PageLoader";

const orgCategoryList = [
  { name: "Category 1", value: "category1" },
  { name: "Category 2", value: "category2" },
  { name: "Category 3", value: "category3" },
  { name: "Category 4", value: "category4" },
];

const countryStateMasterData = {
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};

const employeeList = [
  { name: "10-100", value: "10-100" },
  { name: "101-500", value: "101-500" },
  { name: "501-1000", value: "501-1000" },
  { name: "1001-5000", value: "1001-5000" },
  { name: "5001-10000", value: "5001-10000" },
  { name: "10000+", value: "10000+" },
];
const sectorList = [
  { name: "Sector 1", value: "sector1" },
  { name: "Sector 2", value: "sector2" },
  { name: "Sector 3", value: "sector3" },
  { name: "sector 4", value: "sector4" },
];

const subCategoryList = [
  { name: "Sub-category 1", value: "sub-category1" },
  { name: "Sub-category 2", value: "sub-category2" },
  { name: "Sub-category 3", value: "sub-category3" },
  { name: "Sub-category 4", value: "sub-category4" },
];

function MemberWorkDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("advisoryBoard-Work-details");
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { workDetails } = response || {};

  const [
    advisoryBoardPreviewInfo,
    {
      isLoading: advisoryBoardPreviewIsLoading,
      isSuccess: advisoryBoardPreviewIsSuccess,
      error: advisoryBoardPreviewIsError,
    },
  ] = useAdvisoryBoardPreviewInfoMutation();

  const [
    workDetailsAdvisoryBoard,
    {
      isLoading: advisoryBoardIsLoading,
      isSuccess: advisoryBoardIsSuccess,
      error: advisoryBoardIsError,
    },
  ] = useAdvisoryBoardWorkDetailsMutation();

  useEffect(() => {
    if (userDetails?.userType === "advisoryBoard") {
      advisoryBoardPreviewInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
  }, [userDetails?.userId, userDetails?.trackingCode, userDetails?.userType]);

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };

  const emptyValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    advisoryBoardType: {
      boardType: "Non-Government Organization/Non-Profit Organization",
      other: "",
    },
    orgName: "",
    orgWebsiteLink: "",
    positionInOrg: "",
    orgHeadquarter: {
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
    },
    orgBranch: [
      {
        fullAddress: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
      },
    ],
    noOfEmployees: "",
    typeOfExpertise: [],
  };

  const formik = useFormik({
    initialValues: {
      _id: userDetails?.userId,
      trackingCode: userReduxDetails?.trackingCode,
      advisoryBoardType: {
        boardType:
          workDetails?.advisoryBoardType?.boardType ||
          "Non-Government Organization/Non-Profit Organization",
        other: workDetails?.advisoryBoardType?.other || "",
      },
      orgName: workDetails?.orgName ?? "",
      orgWebsiteLink: workDetails?.orgWebsiteLink ?? "",
      positionInOrg: workDetails?.positionInOrg ?? "",
      orgHeadquarter: {
        fullAddress: workDetails?.orgHeadquarter?.fullAddress ?? "",
        city: workDetails?.orgHeadquarter?.city ?? "",
        country: workDetails?.orgHeadquarter?.country ?? "",
        state: workDetails?.orgHeadquarter?.state ?? "",
        zipCode: workDetails?.orgHeadquarter?.zipCode ?? "",
      },
      orgBranch: workDetails?.orgBranch?.map((branch) => ({
        fullAddress: branch?.fullAddress ?? "",
        city: branch?.city ?? "",
        country: branch?.country ?? "",
        state: branch?.state ?? "",
        zipCode: branch?.zipCode ?? "",
      })) ?? [
        {
          fullAddress: "",
          city: "",
          country: "",
          state: "",
          zipCode: "",
        },
      ],
      noOfEmployees: workDetails?.noOfEmployees ?? "",
      typeOfExpertise: workDetails?.typeOfExpertise ?? [
        "GHG Emissions Accounting",
      ],
    },
    enableReinitialize: true,
    validationSchema: memberWorkDetailsSchema,

    onSubmit: (values) => {
      const memberWorkDetailValues = structuredClone(values);
      cleanObject(memberWorkDetailValues);
      workDetailsAdvisoryBoard(memberWorkDetailValues)
        .unwrap()
        .then((res) => {
          // setFormNumber(4);
          navigate("/auth/registration/loginCredentials");
          dispatch(
            setUserHandler({
              email: res?.data?.user?.email,
              userType: res?.data?.user?.userType,
              userId: res?.data?.user?._id,
            })
          );
          dispatch(
            userActions.setTrackingCode({
              trackingCode: res?.data?.trackingCode,
            })
          );
        })
        .catch((e) => {
          dispatch(showError({ message: e?.data?.message }));
        });
    },
  });

  const addBranch = () => {
    formik.setFieldValue("orgBranch", [
      ...formik.values.orgBranch,
      {
        fullAddress: "",
        city: "",
        country: "",
        state: "",
        zipCode: "",
      },
    ]);
  };

  const onRemoveBranch = ({ deleteIndex }) => {
    const updatedBranch = formik?.values?.orgBranch?.filter(
      (_, index) => index !== deleteIndex
    );
    formik.setFieldValue("orgBranch", updatedBranch);
  };

  const handleStatusCheckboxChange = (event) => {
    const selectedValue = event.target.value;
    const currentValues = formik?.values?.typeOfExpertise || [];

    const isValueSelected = currentValues.includes(selectedValue);

    if (isValueSelected) {
      formik.setFieldValue(
        "typeOfExpertise",
        currentValues.filter((value) => value !== selectedValue)
      );
    } else {
      formik.setFieldValue("typeOfExpertise", [
        ...currentValues,
        selectedValue,
      ]);
    }
  };

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  console.log("MemberWorkDetailFormikValues", formik?.values);
  console.log("MemberWorkDetailFormikErrors", formik?.errors);

  return (
    <div className="container workDetailsSection">
      {(advisoryBoardPreviewIsLoading || advisoryBoardIsLoading) && (
        <PageLoader />
      )}
      <div className="workDelCol1">
        <img className="workDetails_topBg" src={SignUpTopBg} />
        <img className="workDetails_bottomBg" src={SignUpBottomBg} />
        <img className="workDetails_leftBg" src={SignUpLeftBg} />

        <div className="workDetails_timeLineContents">
          <div className="workDetails_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>

      <div className="workDelCol2">
        <h1>Work Details</h1>
        {/* <p className="mb-30">
          Lorem ipsum dolor sit amet consectetur. Dictum eget viverra at
          pellentesque consectetur fames ullamcorper. Aliquet volutpat cras
          cursus at morbi consectetur. Gravida nunc in sit porta.
        </p> */}
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="formInnerDiv">
            <div>
              <p className="workDHeadings">
                Choose the option that best describes your organization
              </p>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="wd-100"
                value={formik?.values?.advisoryBoardType?.boardType}
                onChange={(e) => {
                  formik?.handleChange(e);
                  formik?.setFieldValue(
                    "advisoryBoardType.boardType",
                    e.target.value
                  );
                }}
              >
                <FormControlLabel
                  value="Non-Government Organization/Non-Profit Organization"
                  control={<Radio size="small" />}
                  label="Non-Government Organization/Non-Profit Organization"
                  defaultChecked
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                      fontFamily: "Uniform",
                      fontWeight: 400,
                    },
                  }}
                />
                <FormControlLabel
                  value="University/Academia"
                  control={<Radio size="small" />}
                  label="University/Academia"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                      fontFamily: "Uniform",
                      fontWeight: 400,
                    },
                  }}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio size="small" />}
                  label="Other"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                      fontFamily: "Uniform",
                      fontWeight: 400,
                    },
                  }}
                />
              </RadioGroup>
              <div className="wd-100">
                <InputBox
                  headName={"If Other, please specify"}
                  placeholder=" Enter a name"
                  name="advisoryBoardType.other"
                  value={formik?.values?.advisoryBoardType?.other}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  required={
                    formik?.values?.advisoryBoardType?.boardType === "Other"
                      ? true
                      : false
                  }
                  error={
                    !!formik.touched.advisoryBoardType?.other &&
                    formik.errors.advisoryBoardType?.other
                  }
                />
              </div>
            </div>

            <div className="wd-100">
              <InputBox
                headName={"Name of your Organization"}
                placeholder=" Enter a name"
                name="orgName"
                value={formik?.values?.orgName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.orgName && formik.errors.orgName}
                required
              />
            </div>
            <div className="wd-100 titleRow">
              <InputBox
                headName={"Your Website Link:"}
                placeholder="www.website.com"
                name="orgWebsiteLink"
                value={formik?.values?.orgWebsiteLink}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.orgWebsiteLink &&
                  formik.errors.orgWebsiteLink
                }
                required
              />
            </div>

            <div className="wd-50 titleRow">
              <InputBox
                headName={"Designation / Position In The Organization"}
                placeholder="Enter your position in the organization"
                name="positionInOrg"
                value={formik?.values?.positionInOrg}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.positionInOrg && formik.errors.positionInOrg
                }
                required
              />
            </div>

            <div>
              <p className="workDHeadings mb-5">
                ggg Please tell us the location of your organisation’s
                headquarter and the number and location of the branches that you
                have (in each country of operation).
              </p>

              <div className="headQuarter_section headQuarterBg">
                <h3 className="branchSecHeading">Headquarters</h3>
                <div className="headSections">
                  <div className="wd-70">
                    <InputBox
                      headName={"Your Address"}
                      placeholder="Enter the address here"
                      name="orgHeadquarter.fullAddress"
                      value={formik?.values?.orgHeadquarter?.fullAddress}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errorIcon={WarningIcon}
                      error={
                        !!formik.touched.orgHeadquarter?.fullAddress &&
                        formik.errors.orgHeadquarter?.fullAddress
                      }
                      required
                    />
                  </div>
                  <div className="orCityDiv">
                    <InputBox
                      headName={"City / Town / Suburb"}
                      placeholder="Enter city / town / suburb"
                      name="orgHeadquarter.city"
                      value={formik?.values?.orgHeadquarter?.city}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errorIcon={WarningIcon}
                      error={
                        !!formik.touched.orgHeadquarter?.city &&
                        formik.errors.orgHeadquarter?.city
                      }
                      required
                    />
                  </div>
                </div>

                <div className="headSections">
                  <div className="wd-33">
                    <SelectCountryInputBox
                      data={countryStateMasterData}
                      headName={"Country"}
                      selectPlaceholder={"Enter Country"}
                      name="orgHeadquarter.country"
                      value={formik?.values?.orgHeadquarter?.country}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      formik={formik}
                      errorIcon={WarningIcon}
                      error={
                        !!formik.touched.orgHeadquarter?.country &&
                        formik.errors.orgHeadquarter?.country
                      }
                      required
                    />
                  </div>
                  <div className="wd-33">
                    <SelectStateInputBox
                      headName={"State / Region"}
                      selectPlaceholder={" Enter state / region"}
                      data={countryStateMasterData}
                      selectedData={formik?.values?.orgHeadquarter?.country}
                      name="orgHeadquarter.state"
                      value={formik?.values?.orgHeadquarter?.state}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      formik={formik}
                      errorIcon={WarningIcon}
                      error={
                        !!formik.touched.orgHeadquarter?.state &&
                        formik.errors.orgHeadquarter?.state
                      }
                      required
                    />
                  </div>
                  <div className="wd-33">
                    <InputBox
                      headName={"Zipcode / Postal Code"}
                      placeholder="Phone Number here"
                      name="orgHeadquarter.zipCode"
                      value={formik?.values?.orgHeadquarter?.zipCode}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      errorIcon={WarningIcon}
                      required
                    />
                  </div>
                </div>
              </div>

              {formik?.values?.orgBranch?.map((_, index) => (
                <div
                  className="headQuarter_section"
                  key={index}
                  style={{ marginTop: "10px" }}
                >
                  <div className="remove_div">
                    <h3 className="branchSecHeading">Branch</h3>

                    <p
                      className="remove cursorPointer"
                      onClick={onRemoveBranch.bind(null, {
                        deleteIndex: index,
                      })}
                    >
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        className="fontAwesomeIcon_img"
                        style={{ color: "#008BB2" }}
                      />
                      Remove
                    </p>
                  </div>

                  <div className="headSections">
                    <div className="wd-70">
                      <InputBox
                        headName={"Your Address"}
                        placeholder="Enter the address here"
                        name={`orgBranch[${index}].fullAddress`}
                        value={formik?.values?.orgBranch?.[index]?.fullAddress}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={
                          !!formik.touched.orgBranch?.[index]?.fullAddress &&
                          formik.errors.orgBranch?.[index]?.fullAddress
                        }
                        required
                      />
                    </div>
                    <div className="orCityDiv">
                      <InputBox
                        headName={"City / Town / Suburb"}
                        placeholder="Enter city / town / suburb"
                        name={`orgBranch[${index}].city`}
                        value={formik?.values?.orgBranch?.[index]?.city}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={
                          !!formik.touched.orgBranch?.[index]?.city &&
                          formik.errors.orgBranch?.[index]?.city
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="headSections">
                    <div className="wd-33">
                      <SelectCountryInputBox
                        data={countryStateMasterData}
                        headName={"Country"}
                        selectPlaceholder={"Enter country"}
                        name={`orgBranch[${index}].country`}
                        value={formik?.values?.orgBranch?.[index]?.country}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        formik={formik}
                        errorIcon={WarningIcon}
                        error={
                          !!formik.touched.orgBranch?.[index]?.country &&
                          formik.errors.orgBranch?.[index]?.country
                        }
                        required
                      />
                    </div>
                    <div className="wd-33">
                      <SelectStateInputBox
                        headName={"State / Region"}
                        selectPlaceholder={"Enter state / region"}
                        data={countryStateMasterData}
                        selectedData={
                          formik?.values?.orgBranch?.[index]?.country
                        }
                        name={`orgBranch[${index}].state`}
                        value={formik?.values?.orgBranch?.[index]?.state}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        formik={formik}
                        errorIcon={WarningIcon}
                        error={
                          !!formik.touched.orgBranch?.[index]?.state &&
                          formik.errors.orgBranch?.[index]?.state
                        }
                        required
                      />
                    </div>
                    <div className="wd-33">
                      <InputBox
                        headName={"Zipcode / Postal Code"}
                        placeholder="Phone Number here"
                        name={`orgBranch[${index}].zipCode`}
                        value={formik?.values?.orgBranch?.[index]?.zipCode}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errorIcon={WarningIcon}
                        error={
                          !!formik.touched.orgBranch?.[index]?.zipCode &&
                          formik.errors.orgBranch?.[index]?.zipCode
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              ))}

              <div className="addBranch_div cursorPointer">
                <p className="addBranch" onClick={addBranch}>
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fontAwesomeIcon_img"
                    style={{ color: "#008BB2" }}
                  />
                  Add a branch
                </p>
              </div>
            </div>
            <div>
              <div className="wd-50 titleRow mt-28">
                <SelectInputBox
                  headName={"Number of Employees"}
                  selectPlaceholder={"Select"}
                  data={employeeList}
                  name="noOfEmployees"
                  value={formik?.values?.noOfEmployees}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.noOfEmployees &&
                    formik.errors.noOfEmployees
                  }
                  required
                />
              </div>
              <div className="expertiseSec">
                <p className="workDHeadings">
                  Please confirm the type of expertise you bring to the SEE
                  Rating Advisory Board (tick all that apply)
                </p>
                <FormGroup onChange={handleStatusCheckboxChange}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="GHG Emissions Accounting"
                    value="GHG Emissions Accounting"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "GHG Emissions Accounting"
                    )}
                  />
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="Net Zero Target Setting & Action Planning"
                    value="Net Zero Target Setting & Action Planning"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "Net Zero Target Setting & Action Planning"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="Nature-based Solutions"
                    value="Nature-based Solutions"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "Nature-based Solutions"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="Industrial Technology such as Carbon Capture and Storage and Direct Air Capture"
                    value="Industrial Technology such as Carbon Capture and Storage and Direct Air Capture"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "Industrial Technology such as Carbon Capture and Storage and Direct Air Capture"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="Carbon Offsetting"
                    value="Carbon Offsetting"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "Carbon Offsetting"
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                      />
                    }
                    label="Physical Impacts of Climate Change"
                    value="Physical Impacts of Climate Change"
                    checked={formik?.values?.typeOfExpertise.includes(
                      "Physical Impacts of Climate Change"
                    )}
                  />
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-row wd_BtnRow">
            <div
              className="cursorPointer"
              onClick={() => {
                navigate("/auth/registration/basicDetails");
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div className=" cursorPointer cancelBtn_Row">
              <div
                onClick={() => {
                  formik.resetForm();
                  formik.setValues(emptyValues);
                }}
              >
                <RegCancelButton buttonName={"Cancel"} />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Next"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px"}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MemberWorkDetails;
