import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  useBasicInfoMutation,
  usePreviewInfoMutation,
} from "../../../../features/organization/organizationApiSlice";
import {
  useBasicAudInfoMutation,
  usePreviewAudInfoMutation,
} from "../../../../features/auditor/auditorApiSlice";
import {
  useBasicConsInfoMutation,
  usePreviewConsInfoMutation,
} from "../../../../features/consultant/consultantApiSlice";

import { basicInfoSchema } from "../../../../Validators/register.validators";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import SelectInputBox, {
  SelecSubCatBox,
  SelectCountryInputBox,
  SelectStateInputBox,
} from "../../../../components/InputBox/selectBox/SelectInputBox";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import PhoneInputBox from "../../../../components/InputBox/phoneInputBox/PhoneInputBox";

import "./style.css";

import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import { getUserFromLocal } from "../../../../utils/storage";
import {
  useOrgBasicInfoMutation,
  useOrgPreviewInfoMutation,
} from "../../../../features/registration/organization/organizatonApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUserHandler } from "../../../../features/user/userAction";
import { userActions } from "../../../../features/user/userSlice";
import {
  useAdvisoryBoardBasicInfoMutation,
  useAdvisoryBoardPreviewInfoMutation,
} from "../../../../features/registration/AdvisoryBoardMember/AdvisoryBoardMemberApiSlice";
import OrganizationTimeline from "../../../../components/timeLine/OrganizationTimeline";
import AuditorTimeline from "../../../../components/timeLine/AuditorTimeline";
import ConsultantTimeline from "../../../../components/timeLine/ConsultantTimeline";
import AdvisoryBoardTimeline from "../../../../components/timeLine/AdvisoryBoardTimeline";
import {
  useBasicConsultantInfoMutation,
  usePreviewConsultantMutation,
} from "../../../../features/registration/consultant/consultantApiSlice";
import {
  useAuditorBasicInfoMutation,
  usePreviewAuditorMutation,
} from "../../../../features/registration/auditor/auditorApiSlice";
import { cleanObject } from "../../../../utils/helper";
import { showError } from "../../../../features/snackbar/snackbarAction";
import PageLoader from "../../../../components/Loader/PageLoader";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import AppMobileCodeSelect from "./BasicDetails copy";

const countryList = [
  { value: "saudi arabia", name: "Saudi Arabia" },
  { value: "uae", name: "UAE" },
  { value: "india", name: "India" },
  { value: "america", name: "America" },
];

const countryStateMasterData = {
  India: [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ],
  UAE: [
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Ras Al Khaimah",
    "Sharjah",
    "Umm Al Quwain",
  ],
  "Saudi Arabia": [
    "'Asir",
    "Al Bahah",
    "Al Jawf",
    "Al Madinah",
    "Al Qasim",
    "Eastern Province",
    "Ha'il",
    "Jazan",
    "Mecca",
    "Najran",
    "Northern Borders",
    "Riyadh",
    "Tabuk",
  ],
};

const state = [{ value: "up", name: "Uttar Pradesh" }];

const titleList = [
  { value: "Mr", name: "Mr." },
  { value: "Mrs", name: "Mrs." },
  { value: "Ms", name: "Ms." },
];

function BasicDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = getUserFromLocal();
  const userReduxDetails = useSelector((state) => state.user);
  const [resposnse, setResoponse] = useState("");
  const [currentStep, setCurrentStep] = useState("basic-details");

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { basicInfo } = response || {};

  const [
    previewInfo,
    {
      isLoading: previewIsLoading,
      isSuccess: previewIsSuccess,
      error: previewIsError,
    },
  ] = useOrgPreviewInfoMutation();

  const [
    advisoryBoardPreviewInfo,
    {
      isLoading: advisoryBoardPreviewIsLoading,
      isSuccess: advisoryBoardPreviewIsSuccess,
      error: advisoryBoardPreviewIsError,
    },
  ] = useAdvisoryBoardPreviewInfoMutation();

  const [
    previewConsInfo,
    {
      isLoading: previewConsIsLoading,
      isSuccess: previewConsIsSuccess,
      error: previewConsIsError,
    },
  ] = usePreviewConsultantMutation();

  const [
    previewAudInfo,
    {
      isLoading: previewAudIsLoading,
      isSuccess: previewAudIsSuccess,
      error: previewAudIsError,
    },
  ] = usePreviewAuditorMutation();

  const [
    basicInfoOrg,
    {
      isLoading: organisationIsLoading,
      isSuccess: organisationIsSuccess,
      error: organisationIsError,
    },
  ] = useOrgBasicInfoMutation();

  const [
    basicInfoAdvisoryBoard,
    {
      isLoading: advisoryBoardIsLoading,
      isSuccess: advisoryBoardIsSuccess,
      error: advisoryBoardIsError,
    },
  ] = useAdvisoryBoardBasicInfoMutation();

  const [
    basicAudInfo,
    {
      isLoading: auditorIsLoading,
      isSuccess: auditorIsSuccess,
      error: auditorIsError,
    },
  ] = useAuditorBasicInfoMutation();

  const [
    basicConsInfo,
    {
      isLoading: consultantIsLoading,
      isSuccess: consultantIsSuccess,
      error: consultantIsError,
    },
  ] = useBasicConsultantInfoMutation();

  useEffect(() => {
    if (userDetails?.userType === "organization") {
      previewInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "auditor") {
      previewAudInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "consultant") {
      previewConsInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
    if (userDetails?.userType === "advisoryBoard") {
      advisoryBoardPreviewInfo(basicValues).then((res) => {
        setResoponse(res);
      });
    }
  }, [userDetails?.userId, userDetails?.trackingCode, userDetails?.userType]);

  const basicValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
  };

  const emptyValues = {
    _id: userDetails?.userId,
    trackingCode: userReduxDetails?.trackingCode,
    email: basicInfo?.email ?? userDetails?.email,
    title: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    mobileNo: "",
    address: {
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
    },
  };

  const formik = useFormik({
    initialValues: {
      _id: userDetails?.userId,
      trackingCode: userReduxDetails?.trackingCode,
      title: basicInfo?.title ?? "",
      firstName: basicInfo?.firstName ?? "",
      lastName: basicInfo?.lastName ?? "",
      email: basicInfo?.email ?? userDetails?.email ?? "",
      phoneNoCountryCode: basicInfo?.phoneNoCountryCode ?? "+971",
      phoneNo: basicInfo?.phoneNo ?? "",
      mobileNoCountryCode: basicInfo?.mobileNoCountryCode ?? "+971",
      mobileNo: basicInfo?.mobileNo ?? "",
      address: {
        fullAddress: basicInfo?.address?.fullAddress ?? "",
        city: basicInfo?.address?.city ?? "",
        country: basicInfo?.address?.country ?? "",
        state: basicInfo?.address?.state ?? "",
        zipCode: basicInfo?.address?.zipCode ?? "",
      },
    },
    enableReinitialize: true,
    validationSchema: basicInfoSchema,

    onSubmit: (values) => {
      const basicDetailValues = structuredClone(values);
      cleanObject(basicDetailValues);
      if (userDetails?.userType === "organization") {
        basicInfoOrg(basicDetailValues)
          .unwrap()
          .then((res) => {
            // setFormNumber(2);
            navigate("/auth/registration/organizationDetails");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(showError({ message: e?.data?.message }));
          });
      } else if (userDetails?.userType === "advisoryBoard") {
        basicInfoAdvisoryBoard(basicDetailValues)
          .unwrap()
          .then((res) => {
            // setFormNumber(2);
            navigate("/auth/registration/memberWorkDetails");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(showError({ message: e?.data?.message }));
          });
      } else if (userDetails?.userType === "consultant") {
        basicConsInfo(basicDetailValues)
          .unwrap()
          .then((res) => {
            // setFormNumber(2);
            console.log("trackingCode", res?.data?.trackingCode);
            navigate("/auth/registration/workDetails");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
                // organizationName:
                //   res?.data?.user?.associatedOrg[0]?.organizationName,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(
              showError({ message: e?.data?.message ?? "Something went wrong" })
            );
          });
      } else if (userDetails?.userType === "auditor") {
        basicAudInfo(basicDetailValues)
          .unwrap()
          .then((res) => {
            navigate("/auth/registration/workDetails");
            dispatch(
              setUserHandler({
                email: res?.data?.user?.email,
                userType: res?.data?.user?.userType,
                userId: res?.data?.user?._id,
                // organizationName:
                //   res?.data?.user?.associatedOrg[0]?.organizationName,
              })
            );
            dispatch(
              userActions.setTrackingCode({
                trackingCode: res?.data?.trackingCode,
              })
            );
          })
          .catch((e) => {
            dispatch(showError({ message: e?.data?.message }));
          });
      }
    },
  });

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  const handleCancel = () => {
    formik.resetForm();
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  // window.history.pushState(null, document.title, window.location.href);
  // window.addEventListener("popstate", function (event) {
  //   this.window.history.pushState(null, document.title, window.location.href);
  // });

  console.log(userDetails, "userDetails");
  console.log(userReduxDetails, "userReduxDetails");

  return (
    <div className="container basicDetails_section">
      {(previewIsLoading ||
        advisoryBoardPreviewIsLoading ||
        previewConsIsLoading ||
        previewAudIsLoading ||
        organisationIsLoading ||
        advisoryBoardIsLoading ||
        auditorIsLoading ||
        consultantIsLoading) && <PageLoader />}
      <div className="basicDetailsSec_Col1">
        <img className="basicDetails_topBg" src={SignUpTopBg} />
        <img className="basicDetails_bottomBg" src={SignUpBottomBg} />
        <img className="basicDetails_leftBg" src={SignUpLeftBg} />

        <div className="basicDetails_timeLineContents">
          <div className="basicDetails_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>
      <div className="basicDetailsSec_Col2">
        <div className="innerContainer">
          <h1>Basic Details</h1>
          <form
            className="basicDetails_form"
            noValidate
            onSubmit={formik.handleSubmit}
            onKeyDown={onKeyDown}
          >
            <div className="bsInputRow">
              <div className="wd-20 titleRow">
                <SelectInputBox
                  data={titleList}
                  headName={"Title"}
                  selectPlaceholder={"Select"}
                  name="title"
                  value={formik?.values?.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.title && formik.errors.title}
                  required
                />
              </div>
              <div className="wd-38">
                <InputBox
                  headName={"First Name"}
                  placeholder="Please Enter your first name"
                  name="firstName"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.firstName && formik.errors.firstName}
                  required
                />
              </div>
              <div className="wd-38">
                <InputBox
                  headName={"Last Name"}
                  placeholder="Please Enter your last name"
                  name="lastName"
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.lastName && formik.errors.lastName}
                  required
                />
              </div>
            </div>

            <div className="bsInputRow">
              <div className="bsEmailInput titleRow">
                <InputBox
                  headName={"Email ID"}
                  placeholder="Enter your email here"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.email && formik.errors.email}
                  required
                  readOnly
                />
              </div>
              {/* <div className="wd-50 bs_emailId">
                <p>Please enter your work email ID</p>
              </div> */}
            </div>

            <div className="bsInputRow" style={{ marginBottom: "10px" }}>
              <div className="wd-50">
                <PhoneInputBox
                  mobileNo={true}
                  headName={"Office Number"}
                  placeholder="Office Number here"
                  name="phoneNo"
                  value={formik.values.phoneNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.phoneNo && formik.errors.phoneNo}
                  countryCodeName={"phoneNoCountryCode"}
                  countryCodeValue={formik?.values?.phoneNoCountryCode}
                  defaultCountry="AE"
                  formik={formik}
                />
              </div>
              <div className="wd-50">
                <PhoneInputBox
                  mobileNo={true}
                  headName={"Mobile Number"}
                  placeholder="Mobile Number here"
                  name="mobileNo"
                  value={formik.values.mobileNo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={!!formik.touched.mobileNo && formik.errors.mobileNo}
                  countryCodeName={"mobileNoCountryCode"}
                  countryCodeValue={formik?.values?.mobileNoCountryCode}
                  countryCodeError={
                    !!formik.touched.mobileNoCountryCode &&
                    formik.errors.mobileNoCountryCode
                  }
                  defaultCountry="AE"
                  formik={formik}
                  required
                />
              </div>
            </div>

            <div className="bsInputRow">
              <div className=" bsAddressField titleRow">
                <InputBox
                  headName={"Your Address"}
                  placeholder="Enter your address here"
                  name="address.fullAddress"
                  value={formik.values?.address?.fullAddress}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.fullAddress &&
                    formik.errors.address?.fullAddress
                  }
                  required
                />
              </div>
              <div className="bsCityDiv">
                <InputBox
                  headName={"City / Town / Suburb"}
                  placeholder="Enter City / Town / Suburb"
                  name="address.city"
                  value={formik.values?.address?.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.city &&
                    formik.errors.address?.city
                  }
                  required
                />
              </div>
            </div>

            <div className="bsInputRow titleRow">
              <div className="wd-33">
                <SelectCountryInputBox
                  headName={"Country"}
                  selectPlaceholder={"Country"}
                  data={countryStateMasterData}
                  name="address.country"
                  value={formik?.values?.address?.country}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik?.handleChange(e);
                    formik.setFieldValue("address.state", "");
                    formik.setFieldValue("address.zipCode", "");
                  }}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.country &&
                    formik.errors.address?.country
                  }
                  required
                />
              </div>
              <div className="wd-33">
                <SelectStateInputBox
                  headName={"State / Region"}
                  selectPlaceholder={"Select"}
                  data={countryStateMasterData}
                  selectedData={formik?.values?.address.country}
                  name="address.state"
                  value={formik?.values?.address?.state}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  formik={formik}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.state &&
                    formik.errors.address?.state
                  }
                  required
                />
              </div>
              <div className="wd-33 mb-30">
                <InputBox
                  headName={"Zipcode / Postal Code"}
                  placeholder=" Enter zipcode / postal code"
                  name="address.zipCode"
                  value={formik.values.address.zipCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  errorIcon={WarningIcon}
                  error={
                    !!formik.touched.address?.zipCode &&
                    formik.errors.address?.zipCode
                  }
                  required
                />
              </div>
            </div>

            <div>
              {/* <div
                className="cursor-pointer"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <RegBackButton buttonName={"Back"} />
              </div> */}
              <div className=" cancelBtn_Row">
                <div
                  onClick={() => {
                    formik.resetForm();
                    formik.setValues(emptyValues);
                  }}
                >
                  <RegCancelButton buttonName={"Cancel"} />
                </div>
                <div className="NextBtn cursorPointer">
                  <FormButton
                    buttonName={"Next"}
                    backgroundColor={"#008BB2"}
                    buttonText={"#ffffff"}
                    btnFont={"14px"}
                    btnPdd={"2px 12px"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BasicDetails;
