import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import InputBox from "../../../InputBox/InputBox";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";

function LeadAndSupport({ formik, value, index, initiativeIndex }) {
  return (
    console.log(
      "LeadAndSupport -> contactType",
      formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
        initiativeIndex
      ]?.leadAndSupport?.leadContactType
    ),
    (
      <div className="leadAndSupportSec">
        <p className="leadSupportText">Lead & Support (Internal)</p>
        <div className="leadSupportRow">
          <div className="wd-50">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
                  initiativeIndex
                ]?.leadAndSupport?.leadContactType
              }
              onChange={(e) => {
                formik?.setFieldValue(
                  `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.leadContactType`,
                  e.target.value
                );
              }}
            >
              <FormControlLabel
                value="pointOfContact"
                control={<Radio size="small" />}
                label="Lead POC"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="department"
                control={<Radio size="small" />}
                label="Lead Department"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>

            <div className="leadRow">
              <div className="leadCol1">
                <InputBox
                  type="text"
                  headName={"Lead Name"}
                  placeholder="Enter a name"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.leadName`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives[initiativeIndex]?.leadAndSupport?.leadName
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.leadName &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport?.leadName
                  }
                  errorIcon={WarningIcon}
                />
              </div>
              <div className="leadCol2">
                <InputBox
                  type="text"
                  headName={"Lead Email ID"}
                  placeholder="example@mail.com"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.leadEmail`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives[initiativeIndex]?.leadAndSupport?.leadEmail
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.leadEmail &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.leadEmail
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            </div>
          </div>

          <div className="wd-50">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="wd-100"
              value={
                formik?.values?.actionPlan?.priorityPillars[index]?.initiatives[
                  initiativeIndex
                ]?.leadAndSupport?.supportContactType
              }
              onChange={(e) => {
                formik?.setFieldValue(
                  `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.supportContactType`,
                  e.target.value
                );
              }}
            >
              <FormControlLabel
                value="pointOfContact"
                control={<Radio size="small" />}
                label="Support POC"
                defaultChecked
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
              <FormControlLabel
                value="department"
                control={<Radio size="small" />}
                label="Support Department"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                    fontFamily: "Uniform",
                    fontWeight: 400,
                  },
                }}
              />
            </RadioGroup>

            <div className="leadRow">
              <div className="leadCol1">
                <InputBox
                  type="text"
                  headName={"Support Name"}
                  placeholder="Enter a name"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.supportName`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives[initiativeIndex]?.leadAndSupport
                      ?.supportName
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.supportName &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.supportName
                  }
                  errorIcon={WarningIcon}
                />
              </div>
              <div className="leadCol2">
                <InputBox
                  type="text"
                  headName={"Support Email ID"}
                  placeholder="example@mail.com"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].leadAndSupport.supportEmail`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives[initiativeIndex]?.leadAndSupport
                      ?.supportEmail
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.supportEmail &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.leadAndSupport
                      ?.supportEmail
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default LeadAndSupport;
