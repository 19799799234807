import * as React from "react";
import { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

export default function AuditorTimeline({ currentStep, setCurrentStep, userType }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(1);
  // const [isAuditor, setIsAuditor] = useState(false);


  useEffect(() => {
    switch (currentStep) {
      case "basic-details":
        setSelectedOptionIndex(1);
        break;
      case "work-details":
        setSelectedOptionIndex(2);
        break;
      case "login-credentials":
        setSelectedOptionIndex(3);
        break;
      case "finishing-up":
        setSelectedOptionIndex(4);
        break;
      
      default:
        setSelectedOptionIndex(1);
    }
  }, [currentStep]);

  const handleRadioChange = (step) => {
    setCurrentStep(step);
  };


  return (
    <>
      <div className="form-timeline">
        <ul>
          <li>
            <div
              className={`timeline ${
                selectedOptionIndex > 1 ? "complete" : "ongoing"
              }`}
              id="timeline-line"
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                background: "white",
                borderRadius: "100%",
                border: "2px solid #008BB2",
              }}
            >
              {selectedOptionIndex > 1 ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className=" green_CheckBox"
                />
              ) : selectedOptionIndex === 1 ? (
                <div
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "#008BB2",
                    borderRadius: "100%",
                    top: "25%",
                    left: "25%",
                  }}
                />
              ) : null}
            </div>
            <p className="basic-details timLineText" style={{ color: selectedOptionIndex === 4 ? '#2BA84A' :  selectedOptionIndex === 3 ? '#2BA84A' : selectedOptionIndex === 2 ? '#2BA84A' : '#fff' }}>Basic Details</p>
           </li>
           <li>
            <div
              className={`timeline ${
                selectedOptionIndex > 2 ? "complete" : "ongoing"
              }`}
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                background: "white",
                borderRadius: "100%",
                border: "2px solid #008BB2",
              }}
            >
              {selectedOptionIndex > 2 ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="green_CheckBox"
                />
              ) : selectedOptionIndex === 2 ? (
                <div
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "#008BB2",
                    borderRadius: "100%",
                    top: "25%",
                    left: "25%",
                  }}
                />
              ) : null}
            </div>
            <p className="basic-details timLineText" style={{ color: selectedOptionIndex === 4 ? '#2BA84A' : selectedOptionIndex === 3 ? '#2BA84A' :   selectedOptionIndex === 2 ? '#fff'  : '#70858b' }}>Work Details</p>
          </li>
         
          <li>
            <div
              className={`timeline ${
                selectedOptionIndex > 3 ? "complete" : "ongoing"
              }`}
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                background: "white",
                borderRadius: "100%",
                border: "2px solid #008BB2",
              }}
            >
              {selectedOptionIndex > 3 ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="green_CheckBox"
                />
              ) : selectedOptionIndex === 3 ? (
                <div
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "#008BB2",
                    borderRadius: "100%",
                    top: "25%",
                    left: "25%",
                  }}
                />
              ) : null}
            </div>
            <p className="basic-details timLineText" style={{ color: selectedOptionIndex === 4 ? '#2BA84A' :  selectedOptionIndex === 3 ? '#fff' : '#70858b' }}>Login Credentials</p>
          </li>
          <li>
            <div
              style={{
                position: "relative",
                width: "20px",
                height: "20px",
                background: "white",
                borderRadius: "100%",
                border: "2px solid #008BB2",
              }}
            >
              {selectedOptionIndex > 4 ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="green_CheckBox"
                />
              ) : selectedOptionIndex === 4 ? (
                <div
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    background: "#008BB2",
                    borderRadius: "100%",
                    top: "25%",
                    left: "25%",
                  }}
                />
              ) : null}
            </div>
            <p className="basic-details timLineText" style={{ color:  selectedOptionIndex === 5 ? '#2BA84A' : selectedOptionIndex === 4 ? '#fff' : '#70858b' }} >Finishing Up!</p>
          </li>
          {/* Add more radio button options as needed */}
        </ul>
        <div className="timeline-vertical-line"></div>
      </div>

    </>
  );
}
