import React, { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import InputBox from "../../../InputBox/InputBox";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import InfoEmissionScopes from "../infoEmissionScopes/InfoEmissionScopes";
import IndicationMessage from "../../../indicationMessage/IndicationMessage";

function InfoOnEmission({ formik, activeSection, setActiveSection }) {
  useEffect(() => {
    const { co2, ch4, n2o, hfcs, pfcs, sf6 } =
      formik.values?.infoOnEmission1?.scope1Emissions;

    const total =
      parseInt(co2?.value || 0) +
      parseInt(ch4?.value || 0) +
      parseInt(n2o?.value || 0) +
      parseInt(hfcs?.value || 0) +
      parseInt(pfcs?.value || 0) +
      parseInt(sf6?.value || 0);

    formik.setFieldValue("infoOnEmission1.scope1Emissions.total", total);
  }, [
    formik.values?.infoOnEmission1?.scope1Emissions?.co2?.value,
    formik.values?.infoOnEmission1?.scope1Emissions?.ch4?.value,
    formik.values?.infoOnEmission1?.scope1Emissions?.n2o?.value,
    formik.values?.infoOnEmission1?.scope1Emissions?.hfcs?.value,
    formik.values?.infoOnEmission1?.scope1Emissions?.pfcs?.value,
    formik.values?.infoOnEmission1?.scope1Emissions?.sf6?.value,
  ]);

  useEffect(() => {
    const { co2, ch4, n2o, hfcs, pfcs, sf6 } =
      formik.values?.infoOnEmission1?.scope2Emissions;

    const total =
      parseInt(co2?.value || 0) +
      parseInt(ch4?.value || 0) +
      parseInt(n2o?.value || 0) +
      parseInt(hfcs?.value || 0) +
      parseInt(pfcs?.value || 0) +
      parseInt(sf6?.value || 0);

    formik.setFieldValue("infoOnEmission1.scope2Emissions.total", total);
  }, [
    formik.values?.infoOnEmission1?.scope2Emissions?.co2?.value,
    formik.values?.infoOnEmission1?.scope2Emissions?.ch4?.value,
    formik.values?.infoOnEmission1?.scope2Emissions?.n2o?.value,
    formik.values?.infoOnEmission1?.scope2Emissions?.hfcs?.value,
    formik.values?.infoOnEmission1?.scope2Emissions?.pfcs?.value,
    formik.values?.infoOnEmission1?.scope2Emissions?.sf6?.value,
  ]);

  useEffect(() => {
    const { co2, ch4, n2o, hfcs, pfcs, sf6 } =
      formik.values?.infoOnEmission1?.scope3Emissions;

    const total =
      parseInt(co2?.value || 0) +
      parseInt(ch4?.value || 0) +
      parseInt(n2o?.value || 0) +
      parseInt(hfcs?.value || 0) +
      parseInt(pfcs?.value || 0) +
      parseInt(sf6?.value || 0);

    formik.setFieldValue("infoOnEmission1.scope3Emissions.total", total);
  }, [
    formik.values?.infoOnEmission1?.scope3Emissions?.co2?.value,
    formik.values?.infoOnEmission1?.scope3Emissions?.ch4?.value,
    formik.values?.infoOnEmission1?.scope3Emissions?.n2o?.value,
    formik.values?.infoOnEmission1?.scope3Emissions?.hfcs?.value,
    formik.values?.infoOnEmission1?.scope3Emissions?.pfcs?.value,
    formik.values?.infoOnEmission1?.scope3Emissions?.sf6?.value,
  ]);

  const handleChange = (event, isExpanded) => {
    setActiveSection(isExpanded ? "infoEmission1" : false);
  };

  return (
    <div className="emisSectionGap" id="infoEmission1">
      <Accordion
        expanded={activeSection === "infoEmission1"}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            <div className="GenrlInfo">
              <h3>INFORMATION ON EMISSIONS 1</h3>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="emissOneSec">
            <div className="emissOneInnerSec">
              <h1>
                The table below refers to emissions independent of any GHG
                trades such as sales, purchases, transfers, or banking of
                allowances
              </h1>
              <div className="scopeSection">
                <p>Scope 1 Emissions</p>
                <InfoEmissionScopes
                  headName={"CO2 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.co2.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.co2
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.co2.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.co2?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.co2
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.co2?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.co2.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.co2
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.co2
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.co2
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"CH4 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.ch4.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.ch4
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.ch4.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.ch4?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.ch4
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.ch4?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.ch4.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.ch4
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.ch4
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.ch4
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"N2O(mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.n2o.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.n2o
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.n2o.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.n2o?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.n2o
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.n2o?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.n2o.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.n2o
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.n2o
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.n2o
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"HFCs (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.hfcs.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.hfcs.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.hfcs?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.hfcs.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.hfcs
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"PFCs (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.pfcs.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.pfcs.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.pfcs?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.pfcs.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.pfcs
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"SF6 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope1Emissions.sf6.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.sf6
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope1Emissions.sf6.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.sf6?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.sf6
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.sf6?.value
                  }
                  name3="infoOnEmission1.scope1Emissions.sf6.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope1Emissions?.sf6
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope1Emissions?.sf6
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope1Emissions?.sf6
                      ?.specify
                  }
                />
                <div className="infoEmisTotal">
                  <InputBox
                    headName={"Total (mtCO2e)"}
                    placeholder="Enter the value"
                    name="infoOnEmission1.scope1Emissions.total"
                    value={
                      formik.values.infoOnEmission1?.scope1Emissions?.total
                    }
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.infoOnEmission1?.scope1Emissions
                        ?.total &&
                      formik.errors.infoOnEmission1?.scope1Emissions?.total
                    }
                    errorIcon={WarningIcon}
                  />
                  <IndicationMessage />
                </div>
              </div>

              <div className="scopeSection">
                <p>Scope 2 Emissions</p>
                <InfoEmissionScopes
                  headName={"CO2 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.co2.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.co2
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.co2.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.co2?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.co2
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.co2?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.co2.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.co2
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.co2
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.co2
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"CH4 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.ch4.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.ch4
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.ch4.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.ch4?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.ch4
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.ch4?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.ch4.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.ch4
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.ch4
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.ch4
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"N2O(mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.n2o.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.n2o
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.n2o.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.n2o?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.n2o
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.n2o?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.n2o.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.n2o
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.n2o
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.n2o
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"HFCs (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.hfcs.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.hfcs.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.hfcs?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.hfcs.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.hfcs
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"PFCs (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.pfcs.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.pfcs.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.pfcs?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.pfcs.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.pfcs
                      ?.specify
                  }
                />
                <InfoEmissionScopes
                  headName={"SF6 (mt)"}
                  formik={formik}
                  name1="infoOnEmission1.scope2Emissions.sf6.notRecorded"
                  value1={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.sf6
                      ?.notRecorded
                  }
                  name2="infoOnEmission1.scope2Emissions.sf6.value"
                  value2={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.sf6?.value
                  }
                  error2={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.sf6
                      ?.value &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.sf6?.value
                  }
                  name3="infoOnEmission1.scope2Emissions.sf6.specify"
                  value3={
                    formik?.values?.infoOnEmission1?.scope2Emissions?.sf6
                      ?.specify
                  }
                  error3={
                    !!formik.touched?.infoOnEmission1?.scope2Emissions?.sf6
                      ?.specify &&
                    formik.errors?.infoOnEmission1?.scope2Emissions?.sf6
                      ?.specify
                  }
                />
                <div className="infoEmisTotal">
                  <InputBox
                    type="number"
                    headName={"Total (mtCO2e)"}
                    placeholder="Enter the value"
                    name="infoOnEmission1.scope2Emissions.total"
                    value={
                      formik?.values?.infoOnEmission1?.scope2Emissions?.total
                    }
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.infoOnEmission1?.scope2Emissions
                        ?.total &&
                      formik.errors.infoOnEmission1?.scope2Emissions?.total
                    }
                    errorIcon={WarningIcon}
                  />
                  <IndicationMessage />
                </div>
              </div>

              {formik?.values?.operationalBoundaries?.includeScope3 && (
                <div className="scopeSection">
                  <p>
                    Scope 3 Emissions (Applicable only if Scope 3 is included)
                  </p>
                  <InfoEmissionScopes
                    headName={"CO2 (mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.co2.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.co2
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.co2.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.co2
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.co2
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.co2
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.co2.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.co2
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.co2
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.co2
                        ?.specify
                    }
                  />
                  <InfoEmissionScopes
                    headName={"CH4 (mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.ch4.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.ch4.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.ch4.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.ch4
                        ?.specify
                    }
                  />
                  <InfoEmissionScopes
                    headName={"N2O(mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.n2o.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.n2o.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.n2o.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.n2o
                        ?.specify
                    }
                  />
                  <InfoEmissionScopes
                    headName={"HFCs (mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.hfcs.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.hfcs.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.hfcs.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.hfcs
                        ?.specify
                    }
                  />
                  <InfoEmissionScopes
                    headName={"PFCs (mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.pfcs.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.pfcs.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.pfcs.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.pfcs
                        ?.specify
                    }
                  />
                  <InfoEmissionScopes
                    headName={"SF6 (mt)"}
                    formik={formik}
                    name1="infoOnEmission1.scope3Emissions.sf6.notRecorded"
                    value1={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.notRecorded
                    }
                    name2="infoOnEmission1.scope3Emissions.sf6.value"
                    value2={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.value
                    }
                    error2={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.value &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.value
                    }
                    name3="infoOnEmission1.scope3Emissions.sf6.specify"
                    value3={
                      formik?.values?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.specify
                    }
                    error3={
                      !!formik.touched?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.specify &&
                      formik.errors?.infoOnEmission1?.scope3Emissions?.sf6
                        ?.specify
                    }
                  />
                  <div className="infoEmisTotal">
                    <InputBox
                      headName={"Total (mtCO2e)"}
                      placeholder="Enter the value"
                      name="infoOnEmission1.scope3Emissions.total"
                      value={
                        formik?.values?.infoOnEmission1?.scope3Emissions?.total
                      }
                      onChange={formik.handleChange}
                      error={
                        !!formik.touched.infoOnEmission1?.scope3Emissions
                          ?.total &&
                        formik.errors.infoOnEmission1?.scope3Emissions?.total
                      }
                      errorIcon={WarningIcon}
                    />
                    <IndicationMessage />
                  </div>
                </div>
              )}
            </div>

            <div className="directCo2Row">
              <p>Direct CO2 emissions from Biogenic combustion (mtCO2)</p>
              <InfoEmissionScopes
                headName={"Direct Emissions from Mobile Combustion"}
                formik={formik}
                name1="infoOnEmission1.directCo2Emission.notRecorded"
                value1={
                  formik?.values?.infoOnEmission1?.directCo2Emission
                    ?.notRecorded
                }
                name2="infoOnEmission1.directCo2Emission.value"
                value2={
                  formik?.values?.infoOnEmission1?.directCo2Emission?.value
                }
                error2={
                  !!formik.touched?.infoOnEmission1?.directCo2Emission?.value &&
                  formik.errors?.infoOnEmission1?.directCo2Emission?.value
                }
                name3="infoOnEmission1.directCo2Emission.specify"
                value3={
                  formik?.values?.infoOnEmission1?.directCo2Emission?.specify
                }
                error3={
                  !!formik.touched?.infoOnEmission1?.directCo2Emission
                    ?.specify &&
                  formik.errors?.infoOnEmission1?.directCo2Emission?.specify
                }
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default InfoOnEmission;
