import apiSlice from "../../app/api/apiSlice";

export const dashStatusApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashBoardStatus: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/app/v1/auth/user/get-status${queryString}`,
        };
      },
      providesTags: ["dashBoardStatus"],
    }),

    getStage: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/rating/v1/declaration/get-stage${queryString}`,
        };
      },
      providesTags: ["stageInfo"],
    }),

    getFeedback: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${
              queries[key]
            }`;
          }
        }
        return {
          url: `/app/v1/auth/user/get-feedbacks${queryString}`,
        };
      },
      providesTags: ["Feedback"],
    }),

    addFeedback: builder.mutation({
      query: (feedback) => ({
        url: "/app/v1/auth/user/create-feedback",
        method: "POST",
        body: feedback,
      }),
      invalidatesTags: ["Feedback"],
    }),
  }),
});

export const {
  useGetDashBoardStatusQuery,
  useGetStageQuery,
  useGetFeedbackQuery,
  useAddFeedbackMutation,
} = dashStatusApiSlice;
