import React from 'react';

import ProfileEditBtn from '../../button/profileEditBtn/ProfileEditBtn';

import "./style.css";

// import UserProfileImg from "../../../assets/images/userImg.png";
import UserProfileImg from "../../../assets/icons/profileImg.svg";
import { useNavigate } from 'react-router-dom';
import SaveProfileBtn from '../../button/profileEditBtn/SaveProfileBtn';

function MyProfileHeader({editProfile}) {
  const navigate = useNavigate();

  const editProfileFun = () =>{
   navigate("/myProfileEdit")
  } 

  return (
    <div className="profileInfoHeader">
    <div className="profileRateImgDiv">
        <div className='profileImgSection'>
            <img src={UserProfileImg} />
            <div className="OgProfileName">
                <h1>Mr. Mohamed Irfan</h1>
                <p>Project Manager</p>
            </div>
        </div>
        
        {editProfile? (
          <div>
            <SaveProfileBtn />
          </div>
        ) : (
          <div onClick={editProfileFun}>
            <ProfileEditBtn />
          </div>
        )}
    </div>
  </div>
  )
}

export default MyProfileHeader