import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import InputBox from "../../../InputBox/InputBox";
import LeadAndSupport from "../leadAndSupport/LeadAndSupport";
import ExternalPartners from "../externalPartners/ExternalPartners";
import TimelineExecution from "../timelineExecution/TimelineExecution";
import TimelineMonitoring from "../timelineMonitoring/TimelineMonitoring";

import "./style.css";

import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import RemoveButton from "../../../button/removeButton/RemoveButton";
import AddTimeLine from "../../../button/addTimeLine/AddTimeLine";
import Timeline from "../timelinePlanning/Timeline";

function Initiative1({
  formik,
  removeInitiative,
  initiativeIndex,
  headName,
  index,
  value,
}) {
  const addTimelinePlanning = () => {
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data`,
      [
        ...formik.values.actionPlan.priorityPillars[index].initiatives[
          initiativeIndex
        ].timelines.planning.data,
        {
          fromYear: "",
          fromQuater: "",
          toYear: "",
          toQuater: "",
        },
      ]
    );
  };

  const removeTimelinePlanning = ({ deleteIndex }) => {
    const updatedTimelinePlanning =
      formik?.values?.actionPlan?.priorityPillars?.[index]?.initiatives[
        initiativeIndex
      ]?.timelines?.planning?.data?.filter((_, index) => index !== deleteIndex);
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data`,
      updatedTimelinePlanning
    );
  };

  const addTimelineExecution = () => {
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data`,
      [
        ...formik.values.actionPlan.priorityPillars[index].initiatives[
          initiativeIndex
        ].timelines.execution.data,
        {
          fromYear: "",
          fromQuater: "",
          toYear: "",
          toQuater: "",
        },
      ]
    );
  };

  const removeTimelineExecution = ({ deleteIndex }) => {
    const updatedTimelineExecution =
      formik?.values?.actionPlan?.priorityPillars?.[index]?.initiatives[
        initiativeIndex
      ]?.timelines?.execution?.data?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data`,
      updatedTimelineExecution
    );
  };

  const addTimelineMonitoring = () => {
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data`,
      [
        ...formik.values.actionPlan.priorityPillars[index].initiatives[
          initiativeIndex
        ].timelines.monitoring.data,
        {
          fromYear: "",
          fromQuater: "",
          toYear: "",
          toQuater: "",
        },
      ]
    );
  };

  const removeTimelineMonitoring = ({ deleteIndex }) => {
    const updatedTimelineMonitoring =
      formik?.values?.actionPlan?.priorityPillars?.[index]?.initiatives[
        initiativeIndex
      ]?.timelines?.monitoring?.data?.filter(
        (_, index) => index !== deleteIndex
      );
    formik.setFieldValue(
      `actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data`,
      updatedTimelineMonitoring
    );
  };

  return (
    <div className="initiativeSections">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="typographyRow">
            <Typography>
              <div className="GenrlInfo">
                <h3>Initiative {headName}</h3>
              </div>
            </Typography>
            {formik.values.actionPlan?.priorityPillars[index]?.initiatives
              ?.length > 1 && (
              <RemoveButton
                btnTitle={"Remove"}
                remove={removeInitiative}
                index={initiativeIndex}
              />
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="initiative1Con">
            <div className="initiativeDesc">
              <InputBox
                headName={"Initiative Description"}
                placeholder="Enter the value"
                name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].description.data`}
                value={value?.description?.data}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.actionPlan?.priorityPillars?.[index]
                    ?.initiatives?.[initiativeIndex]?.description?.data &&
                  formik.errors.actionPlan?.priorityPillars?.[index]
                    ?.initiatives?.[initiativeIndex]?.description?.data
                }
                errorIcon={WarningIcon}
              />
            </div>
            <div className="horizontalLine"></div>
            <div className="locationCon">
              <div className="locationCol1">
                <h1>Locations</h1>
              </div>
              <div className="locationCol2">
                <InputBox
                  headName={"Location 1"}
                  placeholder="Enter a location"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].location.location1`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location1
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location1 &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location1
                  }
                  errorIcon={WarningIcon}
                />
              </div>
              <div className="locationCol3">
                <InputBox
                  type="text"
                  headName={"Location 2"}
                  placeholder="Enter a location"
                  name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].location.location2`}
                  value={
                    formik?.values?.actionPlan?.priorityPillars[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location2
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik?.touched?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location2 &&
                    formik?.errors?.actionPlan?.priorityPillars?.[index]
                      ?.initiatives?.[initiativeIndex]?.location?.location2
                  }
                  errorIcon={WarningIcon}
                />
              </div>
            </div>
            <div className="horizontalLine"></div>
            <LeadAndSupport
              formik={formik}
              value={value}
              index={index}
              initiativeIndex={initiativeIndex}
            />
            <div className="horizontalLine"></div>
            <ExternalPartners
              formik={formik}
              value={value}
              index={index}
              initiativeIndex={initiativeIndex}
            />
            <div className="horizontalLine"></div>
            <div style={{ display: "flex", gap: "12px" }}>
              <div className="timelinePlanCol1">
                <h1>Timeline - Planning</h1>
              </div>
              <div className="timlineRightCol">
                {formik?.values?.actionPlan?.priorityPillars[
                  index
                ]?.initiatives[initiativeIndex]?.timelines?.planning?.data?.map(
                  (_, timelinePlanningIndex) => (
                    <Timeline
                      key={timelinePlanningIndex}
                      formik={formik}
                      index={index}
                      initiativeIndex={initiativeIndex}
                      timelinePlanningIndex={timelinePlanningIndex}
                      removeTimelinePlanning={removeTimelinePlanning}
                      name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data[${timelinePlanningIndex}]`}
                      value={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data
                      }
                      error={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex] &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]
                      }
                      startDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data[${timelinePlanningIndex}].fromYear`}
                      endDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data[${timelinePlanningIndex}].toYear`}
                      startDateValue={
                        formik.values.actionPlan.priorityPillars[index]
                          ?.initiatives[initiativeIndex].timelines.planning
                          .data[timelinePlanningIndex].fromYear
                      }
                      endDateValue={
                        formik.values.actionPlan.priorityPillars[index]
                          ?.initiatives[initiativeIndex].timelines.planning
                          .data[timelinePlanningIndex].toYear
                      }
                      startDateTouch={
                        formik?.touched?.actionPlan?.priorityPillars &&
                        formik?.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik?.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik?.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromYear
                      }
                      startDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromYear
                      }
                      endDateTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toYear
                      }
                      endDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toYear
                      }
                      fromQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data[${timelinePlanningIndex}].fromQuater`}
                      fromQuaterValue={
                        formik.values.actionPlan.priorityPillars[index]
                          ?.initiatives[initiativeIndex].timelines.planning
                          .data[timelinePlanningIndex].fromQuater
                      }
                      fromQuaterTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromQuater
                      }
                      fromQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.fromQuater
                      }
                      toQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.planning.data[${timelinePlanningIndex}].toQuater`}
                      toQuaterValue={
                        formik.values.actionPlan.priorityPillars[index]
                          ?.initiatives[initiativeIndex].timelines.planning
                          .data[timelinePlanningIndex].toQuater
                      }
                      toQuaterTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toQuater
                      }
                      toQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.planning
                          ?.data[timelinePlanningIndex]?.toQuater
                      }
                      errorIcon={WarningIcon}
                    />
                  )
                )}
              </div>
            </div>
            <AddTimeLine addTimeline={addTimelinePlanning} />
            <div className="horizontalLine"></div>
            <div style={{ display: "flex", gap: "12px" }}>
              <div className="timelineExeCol1">
                <h1>Timeline - Execution</h1>
              </div>
              <div className="timlineRightCol">
                {formik?.values?.actionPlan?.priorityPillars[
                  index
                ]?.initiatives[
                  initiativeIndex
                ]?.timelines?.execution?.data?.map(
                  (_, timelineExecutionIndex) => (
                    <Timeline
                      key={timelineExecutionIndex}
                      formik={formik}
                      index={index}
                      initiativeIndex={initiativeIndex}
                      timelinePlanningIndex={timelineExecutionIndex}
                      removeTimelinePlanning={removeTimelineExecution}
                      name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data[${timelineExecutionIndex}]`}
                      value={
                        formik.values.actionPlan.priorityPillars[index]
                          ?.initiatives[initiativeIndex].timelines.execution
                          .data
                      }
                      error={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex] &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]
                      }
                      startDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data[${timelineExecutionIndex}].fromYear`}
                      endDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data[${timelineExecutionIndex}].toYear`}
                      startDateValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromYear
                      }
                      endDateValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toYear
                      }
                      startDateTouch={
                        formik?.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromYear
                      }
                      startDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromYear
                      }
                      endDateTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toYear
                      }
                      endDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toYear
                      }
                      fromQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data[${timelineExecutionIndex}].fromQuater`}
                      fromQuaterValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromQuater
                      }
                      fromQuaterTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromQuater
                      }
                      fromQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.fromQuater
                      }
                      toQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.execution.data[${timelineExecutionIndex}].toQuater`}
                      toQuaterValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toQuater
                      }
                      toQuaterTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toQuater
                      }
                      toQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.execution
                          ?.data[timelineExecutionIndex]?.toQuater
                      }
                      errorIcon={WarningIcon}
                    />
                  )
                )}
              </div>
            </div>
            <AddTimeLine addTimeline={addTimelineExecution} />
            <div className="horizontalLine"></div>
            <div style={{ display: "flex", gap: "12px" }}>
              <div className="timeMonitorCol1">
                <h1>Timeline - Monitoring</h1>
              </div>
              <div className="timlineRightCol">
                {formik?.values?.actionPlan?.priorityPillars[
                  index
                ]?.initiatives[
                  initiativeIndex
                ]?.timelines?.monitoring?.data?.map(
                  (_, timelineMonitoringIndex) => (
                    <Timeline
                      key={timelineMonitoringIndex}
                      formik={formik}
                      index={index}
                      initiativeIndex={initiativeIndex}
                      timelinePlanningIndex={timelineMonitoringIndex}
                      removeTimelinePlanning={removeTimelineMonitoring}
                      name={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data[${timelineMonitoringIndex}]`}
                      value={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data
                      }
                      error={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex] &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]
                      }
                      startDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data[${timelineMonitoringIndex}].fromYear`}
                      endDateName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data[${timelineMonitoringIndex}].toYear`}
                      startDateValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromYear
                      }
                      endDateValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toYear
                      }
                      startDateTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromYear
                      }
                      startDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromYear
                      }
                      endDateTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toYear
                      }
                      endDateError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toYear &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toYear
                      }
                      fromQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data[${timelineMonitoringIndex}].fromQuater`}
                      fromQuaterValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromQuater
                      }
                      fromQuaterTouch={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromQuater
                      }
                      fromQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.fromQuater
                      }
                      toQuaterName={`actionPlan.priorityPillars[${index}].initiatives[${initiativeIndex}].timelines.monitoring.data[${timelineMonitoringIndex}].toQuater`}
                      toQuaterValue={
                        formik?.values?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toQuater
                      }
                      toQuaterTouch={
                        formik?.touched?.actionPlan?.priorityPillars &&
                        formik?.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toQuater
                      }
                      toQuaterError={
                        formik.touched?.actionPlan?.priorityPillars &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data &&
                        formik.touched?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toQuater &&
                        formik.errors?.actionPlan?.priorityPillars[index]
                          ?.initiatives[initiativeIndex]?.timelines?.monitoring
                          ?.data[timelineMonitoringIndex]?.toQuater
                      }
                      errorIcon={WarningIcon}
                    />
                  )
                )}
              </div>
            </div>
            <AddTimeLine addTimeline={addTimelineMonitoring} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Initiative1;
