import React, { useState, useEffect } from "react";
import "./style.css";

export default function DeclerationTimeline({ status }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);

  useEffect(() => {
    switch (status) {
      case "Initial Declaration":
        setSelectedOptionIndex(0);
        break;
      case "Emission Declaration":
      case "Emission Resubmit":
        setSelectedOptionIndex(1);
        break;
      case "Emission Audit":
        setSelectedOptionIndex(2);
        break;
      case "Roadmap Submission":
      case "Roadmap Resubmit":
        setSelectedOptionIndex(3);
        break;
      case "Roadmap Audit":
        setSelectedOptionIndex(4);
        break;
      case "Grant Rating":
        setSelectedOptionIndex(5);
        break;
      case "Monitor & Report":
        setSelectedOptionIndex(6);
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <>
      <div className="dash-timeline">
        <div className="rating-timeline">
          <ul className="dashTime">
            <li>
              <input
                type="radio"
                disabled
                className={
                  selectedOptionIndex === 1
                    ? "active"
                    : selectedOptionIndex > 1
                      ? "completed"
                      : null
                }
                onclick="clickhandler()"
                id="timeline"
                value="basic-details"
              />
              <label for="basic">Emissions Declaration</label>
            </li>
            <li>
              <input
                type="radio"
                className={
                  selectedOptionIndex === 2
                    ? "active"
                    : selectedOptionIndex > 2
                      ? "completed"
                      : null
                }
                disabled
                id="timeline"
                value="organization-details"
              />
              <label for="basic">Assure/Audit Emissions</label>
            </li>
            <li>
              <input
                type="radio"
                className={
                  selectedOptionIndex === 3
                    ? "active"
                    : selectedOptionIndex > 3
                      ? "completed"
                      : null
                }
                disabled
                id="timeline"
                value="organization-details"
              />
              <label for="basic">Roadmap To Net Zero</label>
            </li>
            <li>
              <input
                type="radio"
                className={
                  selectedOptionIndex === 4
                    ? "active"
                    : selectedOptionIndex > 4
                      ? "completed"
                      : null
                }
                disabled
                id="timeline"
                value="organization-details"
              />
              <label for="basic">Assure/Audit Roadmap</label>
            </li>
            <li>
              <input
                type="radio"
                className={
                  selectedOptionIndex === 5
                    ? "active"
                    : selectedOptionIndex > 5
                      ? "completed"
                      : null
                }
                disabled
                id="timeline"
                value="organization-details"
              />
              <label for="basic">Granting A Rating</label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
