import React from "react";

import "./style.css";

import PaperPlan from "../../../assets/icons/Paper_Plane.svg";

function ChatBoxSubmitBtn({ handleCreateIssueAndChats }) {
  return (
    <button
      className="chatBoxSubmitBtnCon cursorPointer"
      onClick={() => {
        handleCreateIssueAndChats();
      }}
    >
      <img src={PaperPlan} alt="PaperPlan" />
      <h1>Submit</h1>
    </button>
  );
}

export default ChatBoxSubmitBtn;
