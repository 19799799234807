import React from "react";

import "./style.css";

function AuditScopeTable({ gasType, gasValue, reason }) {
  const scopeGreenBg = "scopeGreenBg";
  const auditGasBg = "auditGasBg";
  return (
    <div className="auditGasRow auditCamical_list">
      <div className={gasType === "Total (mtCO2e)" ? scopeGreenBg : auditGasBg}>
        <div>
          <h1>{gasType}</h1>
          <p>{gasValue}</p>
        </div>
        {reason ? (
          <div>
            <h1>Reason</h1>
            <p>{reason}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AuditScopeTable;
