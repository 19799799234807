import React from 'react';
import Chart from "react-apexcharts"; 

function AreaChart() {

    const options = {
        series: [{
            name: 'series1',
            data: [52, 51, 51.3, 50.4, 51.5,]
        }],
        chart: {
            height: 350,
            type: 'area',
            margin: {
                left: 0,
                right: 0
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            colors: ['#A20074'] // Set wave line color
        },
        xaxis: {
            labels: {
                show: false
            },
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z"],
            axisBorder: {
                show: false // Hide bottom label lines
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            enabled : false,
            x: {
                format: 'dd'
            },
        },
        grid: {
            show: false // Hide background lines
        },
         // Hide menu options
       chart: {
        toolbar: {
            show: false // Hide the toolbar
        },
        zoom: {
            enabled: false // Disable zooming
        },
        contextMenu: {
            menu: [] // Hide all context menu options
        }
      },
      fill: {
        type: 'gradient',
        colors: ['#A20074'] // Set fill color directly in series options
      },
    };

    const responsive = [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
    }]
    


  return (
    <Chart
        type='area'
        // width={500}
        height={280}
        options={options}
        series={options.series}
        responsive={responsive}
        style={{minWidth:"50px", width:"28.1vw", marginLeft: "-22px"}}
    />
  )
}

export default AreaChart;