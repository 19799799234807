import React from 'react';

import "./style.css";

function ReportedProgressCard({
    reportCardIcon,
    reportCardTitle,
    reportValue,
    reportGrowIcon,
    reportPercentage
}) {
  return (
    <div className="reprtedCardCon">
        <img src={reportCardIcon} alt='BIRegisteredIcon' className='reportingCardIcon' />
        <div className='userProgressCardCol'>
            <span>{reportCardTitle}</span>
            <div className='usersValueRow'>
                <h1 className='reportingValue'>{reportValue}</h1>
                <div className='progressUpDownRow'>
                    <img src={reportGrowIcon} alt='ProgressUp' className=''/>
                    <h3>{reportPercentage}</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReportedProgressCard