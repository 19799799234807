import React from "react";

import "./style.css";

import UpArraow from "../../../assets/icons/Caret_Circle_Up.svg";
import Circle from "../../../assets/icons/stepOneCircle.svg";

const documentTitleData = [
  {
    index: "1",
    documentTitle: "Organizational Diagram",
  },
  {
    index: "2",
    documentTitle: "Relevant ratio performance indicators",
  },
  // {
  //   index: "3",
  //   documentTitle: "Document 1",
  // },
  // {
  //   index: "4",
  //   documentTitle: "Document 2",
  // },
];

function EmisChecklistSection() {
  return (
    <div className="checkList_SectionDiv">
      <div className="leftCheckDiv">
        <p className="secTitle_style">Checklist</p>
        <img src={UpArraow} alt="" />
      </div>
      <p className="leftCheckPara">
        Check off all the requirements from this list to complete your Rating
        Application process smoothly.
      </p>
      <div className="documentLists">
        {documentTitleData?.map((item, index) => (
          <div className="documentRow">
            <img src={Circle} alt="" />
            <p>{item.documentTitle}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmisChecklistSection;
