import React from "react";

const Branch = ({ item, index }) => {
  return (
    <div className="preview_headQuarter  mb_1r" key={index}>
      <p className="Headquarters_title">Branch {index + 1} </p>
      <div className="headqDetails">
        <div className="wd-30">
          <h4 className="preTitle">Address</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item?.fullAddress}</h4>
        </div>
      </div>
      <div className="headqDetails">
        <div className="wd-30">
          <h4 className="preTitle">City / Town / Suburb</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item?.city}</h4>
        </div>
      </div>
      <div className="headqDetails">
        <div className="wd-30">
          <h4 className="preTitle">Country</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item.country}</h4>
        </div>
      </div>
      <div className="headqDetails">
        <div className="wd-30">
          <h4 className="preTitle">State / Region</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item.state}</h4>
        </div>
      </div>
      {item.zipCode && <div className="headqDetails">
        <div className="wd-30">
          <h4 className="preTitle">Zipcode / Postal Code</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item.zipCode}</h4>
        </div>
      </div>}
    </div>
  );
};

export default Branch;
