
import React from 'react';
import Uncompromizing from '../../../../assets/homeImages/Uncompromizing.svg';
import Straight  from '../../../../assets/homeImages/StraightForward.svg';
import UncompromizingImg  from '../../../../assets/homeImages/UncompromizingImg.svg';
import Common from '../../../../assets/homeImages/Common.svg';
import Measurable from '../../../../assets/homeImages/Measurable.svg';
import Verifiable from '../../../../assets/homeImages/Verifiable.svg';
import Transparent from '../../../../assets/homeImages/Transparent.svg';
import Economically from '../../../../assets/homeImages/EconomicallyViable.svg';
import Impactful from '../../../../assets/homeImages/Impactful.svg';
import FrameRightwatermarkBg from '../../../../assets/frameRightwatermarkBg.png';
import './style.css';


function FrameWork() {
  
   const frameworkData = [
      {
        index: "1",
        img: Common,
        heading: "Industry Agnostic",
        discription: "Assessing the net zero achievements of any organization regardless of industry or product" ,
        // bgColorClass: "marginBt_1",
      },
      {
        index: "2",
        img: UncompromizingImg,
        heading: "Uncompromizing",
        discription:"We have one clear goal: Reducing emissions and accelerating the path to net zero globally",
        // bgColorClass: "marginBt_2",
      },
      {
        index: "3",
        img: Straight,
        heading: "Straight Forward",
        discription:"We are focused on offsetting emissions and not responsibility",
        // bgColorClass: "marginBt_3",
       },
       {
         index: "4",
         img: Measurable,
         heading: "Measurable",
         discription:"Continuous monitoring and reporting is required of all participants",
        //  bgColorClass: "marginBt_4",  
      },

        {
         index: "5",
         img: Verifiable,
         heading: "Verifiable",
         discription:"Our methodology is continuously audited and verified by third parties",
        //  bgColorClass: "marginBt_5",
        },
        {
         index: "6",
         img: Transparent,
         heading: "Transparent",
         discription:"We upload all data to a blockchain ledger system that is immutable and transparent and use AI to allocate ratings, ensuring increased objectivity",
        //  bgColorClass: "marginBt_6",
        },
        {
         index: "7",
         img: Economically,
         heading: "Economically Viable",
         discription:"Allowing for offsetting and in-setting strategies that are revenue generating.",
        //  bgColorClass: "marginBt_7",
        },
        {
         index: "8",
         img: Impactful,
         heading: "Impactful",
         discription:"Making an impact not only on companies but on industries, countries and the world.",
        //  bgColorClass: "marginBt_8",   
      },

     
    ];




  return (
    <div className='container frameWork_section'>
      <div className='framework_headSection'>
        <div className='frameWorkSec_Col1'>
            <h1>Our Framework</h1>
            <p className='frameWorkSec_Col1_p'>SEE Rating has several unique features, which are summarised here</p>
        </div>
        <div className='frameWorkSec_Col2'>
        </div>
      </div>
    <div className='frameworkCard_section'>
   <div className='frameWork_Row'>
   {frameworkData?.map((item, index) => (
      <div className={`frameWork_Cols  ${item.bgColorClass} `}   >
        <h2><img src={item.img} alt="" /> {item.heading} </h2>
        <p>{item.discription}</p>
     </div>
    ))}
   </div>
    </div>
    <div className='frameworkBackground-text'>Framework </div>
    <img className='frameRight_watermark' src={FrameRightwatermarkBg} alt="" />
   </div> 
  );
}

export default FrameWork;