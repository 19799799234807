import { useEffect, useState } from "react";
import FormButton from "../button/FormButton";

import "./style.css";

import TimerIcon from "../../assets/icons/Timer.svg";
import RightCheck from "../../assets/icons/Check.svg";
import AddIcon from "../../assets/icons/AddPlusBtn.svg";
import AddConsultantPopUp from "../PopUp/consAuditorPopUp.js/AddConsultantAndAuditorPopUp";
import { useNavigate } from "react-router-dom";
import StatusTab from "../tabs/StatusTab";
import ContainedButton from "../button/ContainedButton";
import RightArrow from "../../assets/icons/continueRightArrow.svg";
import OutlinedButton from "../button/OutlinedButton";
import DeclerationTimeline from "../dashTimeline/DeclerationTimline";

function ConsultantStatus({ status, stageData }) {
  const [addConsultantPopUpOpen, setAddConsultantPopUpOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Add Consultant");
  const navigate = useNavigate();

  const handleAddConsultantPopUpOpen = () => {
    setPopupTitle("Add Consultant");
    setAddConsultantPopUpOpen(true);
  };

  const handleAddAuditorPopUpOpen = () => {
    setPopupTitle("Add Auditor");
    setAddConsultantPopUpOpen(true);
  };

  const handleAddConsultantPopUpClose = () => {
    setAddConsultantPopUpOpen(false);
  };

  const handleOpenEmissionDeclerationForm1 = () => {
    navigate("/emissionsDeclaration");
  };
  const handleOpenEmissionDeclerationForm2 = () => {
    navigate("/emissionStep2");
  };

  return (
    <div className="orgDashTimeContainer">
      <div className="orgRatingStatus">
        <div className="orgRatingCo1">
          <div className="updateBtnSec">
            <p>Account Verification Status</p>

            {status?.data?.auditor?.auditorStatus === "registered" ? (
              <StatusTab text="Pending" type="pending" />
            ) : status?.data?.auditor?.auditorStatus === "rejected" ? (
              <StatusTab text="Rejected" type="rejected" />
            ) : status?.data?.auditor?.auditorStatus === "active" ? (
              <StatusTab text="Active" type="active" />
            ) : null}
          </div>
          <p className="orgDeclarText">
            {status?.data?.auditor?.auditorStatus === "registered"
              ? "Your profile is yet to be verified by the admin. Please check back in some time."
              : status?.data?.auditor?.auditorStatus === "rejected"
                ? "Uh oh! You’re verification was rejected by the admin. Please try uploading your details again."
                : status?.data?.auditor?.auditorStatus === "active" &&
                    status?.data?.consultant?.consultantStatus === "registered"
                  ? "Your profile is verified and active but consultant approval is pending. Please check back in some time."
                  : status?.data?.auditor?.auditorStatus === "active" &&
                      status?.data?.consultant?.consultantStatus === "rejected"
                    ? "Your profile is verified and active but consultant approval was rejected. Please check back in some time."
                    : ""}
          </p>
        </div>

        {status?.data?.consultant?.consultantStatus === "rejected" && (
          <div className="orgRatingCo2">
            <ContainedButton
              text="Register Again"
              bgColor="bgBlue"
              type="button"
            />
          </div>
        )}

        {/* Temporary button for testing */}
        {/* <div className="orgRatingCo2">
          <ContainedButton
            text="Declear Emission"
            bgColor="bgBlue"
            type="button"
            onClick={handleOpenEmissionDeclerationForm1}
          />
        </div>
        <div className="orgRatingCo2">
          <ContainedButton
            text="Review Comments"
            bgColor="bgBlue"
            type="button"
            onClick={handleOpenEmissionDeclerationForm2}
          />
        </div>
        <div className="orgRatingCo2">
          <OutlinedButton
            text="View All Details"
            bgColor="bgWhite"
            type="button"
            // onClick={handleOpenEmissionDeclerationForm2}
          />
        </div> */}
        {/* Temporary button for testing */}
      </div>

      {/* {status?.data?.organization?.organizationStatus === "active" &&
        status?.data?.consultant?.consultantStatus === "active" &&
        status?.data?.auditor?.auditorStatus === "active" && (
          <>
            <p className="orgDeclarText declarePadd">
              Your consultant has not yet declared your emissions. Please ask
              them to declare the emissions to see some progress
            </p>
            <DeclerationTimeline status={"Emission Audit"} />
          </>
        )} */}
    </div>
  );
}

export default ConsultantStatus;
