import React from "react";
import Chart from "react-apexcharts";

import "./style.css";

function StackedBarChart({ sectorwiseOrganization }) {
  if (!sectorwiseOrganization) {
    return null;
  }

  const options = {
    // series: sectorwiseOrganization?.map((item) => ({
    //   name: item?._id,
    //   data: [item?.count],
    // })),
    series: [
      {
        name: "Energy",
        data: [44],
      },
      {
        name: "Materials",
        data: [53],
      },
      {
        name: "Industrials",
        data: [12],
      },
      {
        name: "Consumer Discretionary",
        data: [20],
      },
      {
        name: "Consumer Staples",
        data: [25],
      },
      {
        name: "Health Care",
        data: [18],
      },
      {
        name: "Financials",
        data: [25],
      },
      {
        name: "Information Technology",
        data: [24],
      },
      {
        name: "Communication Services",
        data: [22],
      },
      {
        name: "Utilities",
        data: [20],
      },
      {
        name: "Real Estate",
        data: [25],
      },
    ],
    colors: [
      "#0E3818",
      "#12471F",
      "#185C29",
      "#1F7735",
      "#279943",
      "#2BA84A",
      "#55B96E",
      "#71C586",
      "#9DD7AC",
      "#BDE4C7",
      "#EAF6ED",
    ],
    chart: {
      stacked: true,
      menubar: false,
      contextMenu: {
        menu: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    title: {
      // text: 'Fiction Books Sales'
    },
    xaxis: {
      categories: [2008],
      labels: {
        show: false,
      },
      show: false,
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide top and bottom stroke lines
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
      show: false,
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide top and bottom stroke lines
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "K";
        },
      },
    },
    fill: {
      opacity: 1,
      type: "gradient",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  const responsive = [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ];
  console.log("Series", options?.series);
  console.log("sectorwiseOrganization", sectorwiseOrganization);

  return (
    <Chart
      type="bar"
      height={300}
      series={options?.series}
      options={options}
      responsive={responsive}
      style={{ minWidth: "200px", width: "100%" }}
    />
  );
}

export default StackedBarChart;
