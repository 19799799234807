import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import {
  useOtherInfoMutation,
  usePreviewInfoMutation,
} from "../../../../features/organization/organizationApiSlice";
import {
  useOtherAudInfoMutation,
  usePreviewAudInfoMutation,
} from "../../../../features/auditor/auditorApiSlice";
import {
  useOtherConsInfoMutation,
  usePreviewConsInfoMutation,
} from "../../../../features/consultant/consultantApiSlice";

import { otherDetailsSchema } from "../../../../Validators/register.validators";

import BasicTimeline from "../../../../components/timeLine/BasicTimeline";
import InputBox from "../../../../components/InputBox/InputBox";
import FormButton from "../../../../components/button/FormButton";
import FileUploadBox from "../../../../components/fileUploadBox/FileUploadBox";
import PdfUpload from "../../../../components/pdfUpload/PdfUpload";
import FileUploadBoxRegistration from "../../../../components/fileUploadBox/FileUploadBoxRegistration";
import RegCancelButton from "../../../../components/button/regCancelBtn/RegCancelButton";
import RegBackButton from "../../../../components/button/regBackBtn/RegBackButton";
import { getUserFromLocal } from "../../../../utils/storage";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../../features/user/userSlice";
import { setUserHandler } from "../../../../features/user/userAction";

import "./style.css";

import RegistrationLogo from "../../../../assets/logo/registerLogo.svg";
import SignUpTopBg from "../../../../assets/bgImages/signUpTopBg.svg";
import SignUpBottomBg from "../../../../assets/bgImages/signUpBottomBg.svg";
import SignUpLeftBg from "../../../../assets/bgImages/signUpLeftBg.svg";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";
import WavyCheck from "../../../../assets/icons/WavyCheck.svg";

const passCriRul = [
  { rule: "The password should be at least 8 characters or more." },
  {
    rule: "Password must contain at least one uppercase and one lowercase alphabet.",
  },
  { rule: "Password must contain at least one numeric character." },
  { rule: "Password must contain at least one special character." },
];

const loginCredentials = Yup.object({
  email: Yup.string()
    .required("Required")
    .trim()
    .lowercase()
    .min(5, "Enter valid email id")
    .max(50, "Name must be at most 50 characters")
    .test(
      "valid-email",
      "Incorrect Email ID. Please check your email ID",
      (value) => {
        if (!value) return false;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(value);
      }
    ),
  password: Yup.string()
    .min(8, "Atleast 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/^\S*$/, "Password cannot contain whitespace")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
  retypePassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(8, "Atleast 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/^\S*$/, "Password cannot contain whitespace")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
});

function LoginCredentials({ setFormNumber }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = getUserFromLocal();
  const [resposnse, setResoponse] = useState("");

  const [currentStep, setCurrentStep] = useState("login-credentials");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const userReduxDetails = useSelector((state) => state.user);

  const { data } = resposnse || {};
  const response = data?.data?.user;
  const { otherDetails } = response || {};

  const emptyValues = {
    email: userDetails?.email,
    password: "",
    retypePassword: "",
  };

  const formik = useFormik({
    initialValues: {
      email: userDetails?.email ?? "",
      password: userReduxDetails?.loginPassword ?? "",
      retypePassword: userReduxDetails?.loginPassword ?? "",
    },
    enableReinitialize: true,
    validationSchema: loginCredentials,
    onSubmit: (values) => {
      dispatch(
        userActions.setLoginPassword({ loginPassword: values.password })
      );
      // setFormNumber(5);
      navigate("/auth/registration/previewForm");
    },
  });

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const confirmPassVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleCopy = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!userReduxDetails?.trackingCode) {
      navigate("/auth/signup");
    }
  }, []);

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  console.log("loginCredentialsValues", formik?.values);
  console.log("loginDetails", userReduxDetails);
  console.log("loginErrors", formik?.errors);

  return (
    <div className="container logCredsSection">
      <div className="logCredsCol1">
        <img className="logCreds_TopBg" src={SignUpTopBg} />
        <img className="logCreds_bottomBg" src={SignUpBottomBg} />
        <img className="logCreds_leftBg" src={SignUpLeftBg} />

        <div className="logCreds_timeLineContents">
          <div className="logCreds_logoDiv">
            <img src={RegistrationLogo} />
          </div>
          <BasicTimeline
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            userType={userDetails?.userType}
          />
        </div>
      </div>
      <form
        className="logCredsCol2"
        noValidate
        onSubmit={formik.handleSubmit}
        onKeyDown={onKeyDown}
      >
        <h1>Login Credentials</h1>
        <p className="createPassText">
          Please create your password for your SeeRating account
        </p>
        <div className="logCredsForm">
          <div className="logCreds_fullname">
            <InputBox
              headName={"Email"}
              placeholder="Enter your email here"
              name="email"
              value={formik?.values?.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              errorIcon={WarningIcon}
              error={!!formik.touched.email && formik.errors.email}
              required
              readOnly
            />
          </div>
          <div className="logCreds_password">
            <div className="relativePosition passCol1">
              <InputBox
                headName={"Password"}
                placeholder="Password"
                type={isPasswordVisible ? "text" : "password"}
                name="password"
                value={formik?.values?.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={!!formik.touched.password && formik.errors.password}
                required
                onCopy={handleCopy}
              />
              <div className="login_Eyeicon_position">
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                  onClick={() => togglePasswordVisibility()}
                  className="login_Eye_icon eye_color"
                />
              </div>
            </div>
            <div className="relativePosition passCol2">
              <InputBox
                headName={"Retype Password"}
                placeholder="Please retype your password"
                type={isConfirmPasswordVisible ? "text" : "password"}
                name="retypePassword"
                value={formik?.values?.retypePassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                errorIcon={WarningIcon}
                error={
                  !!formik.touched.retypePassword &&
                  formik.errors.retypePassword
                }
                required
              />
              <div className="login_Eyeicon_position">
                <FontAwesomeIcon
                  icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                  onClick={() => confirmPassVisibility()}
                  className="login_Eye_icon eye_color"
                />
              </div>
            </div>
          </div>

          <div className="passCriteriaSec">
            <p className="criteriaHeading">Password Criteria:</p>
            {passCriRul?.map((item, index) => (
              <div className="passCriteriaRuls">
                <img src={WavyCheck} />
                <p>{item.rule}</p>
              </div>
            ))}
          </div>

          <div className="form-row logCred_BtnRow">
            <div
              className=" cursorPointer"
              onClick={() => {
                if (userDetails?.userType === "organization") {
                  navigate("/auth/registration/additionalDetails");
                } else if (userDetails?.userType === "advisoryBoard") {
                  navigate("/auth/registration/memberWorkDetails");
                } else if (
                  userDetails?.userType === "consultant" ||
                  userDetails?.userType === "auditor"
                ) {
                  navigate("/auth/registration/workDetails");
                }
              }}
            >
              <RegBackButton buttonName={"Back"} />
            </div>
            <div className=" cursorPointer cancelBtn_Row">
              <div
                onClick={() => {
                  formik.resetForm();
                  dispatch(userActions.setLoginPassword({ loginPassword: "" }));
                  formik.setValues(emptyValues);
                }}
              >
                <RegCancelButton buttonName={"Cancel"} />
              </div>
              <div className=" NextBtn cursorPointer">
                <FormButton
                  buttonName={"Next"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"3px 12px"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LoginCredentials;
