import React, {useEffect} from "react";
import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import './style.css';

import OurProcessRectangle from "../../../../assets/OurProcessRectangle.png";
import CardOneRectangle from "../../../../assets/icons/cardOneRectangle.svg";
import CardTwoRectangle from "../../../../assets/icons/cardTwoRectangle.svg";
import CardThreeRectangle from "../../../../assets/icons/cardThreeRectangle.svg";
import CardFourRectangle from "../../../../assets/icons/cardFourRectangle.svg";
import CardFiveRectangle from "../../../../assets/icons/cardFiveRectangle.svg";
import CardSixRectangle from "../../../../assets/icons/cardSixRectangle.svg";

function OurProcess({ourProcessSectionRef}) {
  const processData = [
    {
      index: "1",
      heading: "Emissions Declaration",
      discription:[
        "Done by consultants or in-house", "Develop a baseline year for all operational emissions (2021)",
        "Applies to existing businesses - existing real data; new based on robust projections", "Align with key global best practice standards",
        "Calculation of historic emissions and all scope 3 is optional but not a requirement"
      ],
      rectangleIcon: CardOneRectangle,
      digit: "1",
      bgColorClass: "processBgColor1",
    },
    {
      index: "2",
      heading: "Assure/Audit Emissions",
      discription:[
        "By assurance / auditing firms", "Check data and claims made in emissions declarations, provide feedback",
        "Go back to step 1 to make changes until assurance can be confirmed", "Provided limited or full assurance",
        "Move to the next step once confirmed"
      ],
      digit: "2",
      rectangleIcon: CardTwoRectangle,
      bgColorClass: "processBgColor2",
    },
    {
      index: "3",
      heading: " Action Plan To Net Zero",
      discription:[
       "Done by consultants or in house", "Action plan develop that outlines targets and how they will achieve them",
       "Offsetting needs identified including credible partners that provide carbon credits",
       "Key focus is on future emissions and how they can be addressedAlignment with key global best practice standards"
      ],
      digit: "3",
      rectangleIcon: CardThreeRectangle,
      bgColorClass: "processBgColor3",
    },
    {
      index: "4",
      heading: "Assure/Audit Action Plan",
      discription:[
       "By auditing firms", "Targets and action plan are checked for credibility and gaps including whether they will help achieve the goals",
       "Go back to step 3 to make changes until assurance can be confirmed", "Provide limited or full assurance"
      ],
      digit: "4",
      rectangleIcon: CardFourRectangle,
      cardWhiteText: "processCardWhiteText",
      bgColorClass: "processBgColor4",
      textColor: "listColor",
    },
    {
      index: "5",
      heading: "Granting Rating",
      discription:[
        "All data templates on emissions declarations net zero targets and action plans are reviewed on blockchain",
        "Reports from auditors resulting changes are reviewed on blockchain", "All registration information check and validated",
        "AI assess and allocates the rating based on SEE rating bands"
      ],
      digit: "5",
      rectangleIcon: CardFiveRectangle,
      cardWhiteText: "processCardWhiteText",
      bgColorClass: "processBgColor5",
      textColor: "listColor",
    },
    {
      index: "6",
      heading: "Monitor & Reporting",
      discription:[
          "Quarterly emissions progress reports required for companies' compliance assessment.", "Reports must explain changes and circumstances.",
          "Auditing and verification processes are conducted, accompanied by advisory support",
          "Performance changes impact annual ratings, allowing upgrades, downgrades, or placement on a corrective action watch list."
      ],
      digit: "6",
      rectangleIcon: CardSixRectangle,
      cardWhiteText: "processCardWhiteText",
      bgColorClass: "processBgColor6",
      textColor: "listColor",
    },
    
  ];

  const repeatedprocessData = [...processData ];

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, 
    cssEase: "linear",
  };


  return (
    <div className="container ourProcess_section" ref={ourProcessSectionRef}>
      <h1 className="ourProcessMain_heading">Our Process</h1>
      <p className="ourProcess_section_p">
        SEE rating follows a six step and iterative process, 
        all with different stakeholders engaged during the process. 
        The methodology is also overseen by an independent advisory 
        board comprised of experts from NGOs, academia and standards bodies.
      </p>
      <div className="ourProcessGridContainer">
      {repeatedprocessData?.map((item, index) => (
        <div className={ `ourProCard ${item.bgColorClass} `}>
          <div className="processCardInner">
          <div className="processInner_Row">
            <div className="processInner_Col1">
            <h1>{item.heading}</h1>{" "}
            </div>
            <div className="processInner_Col2">
              <img src={item.rectangleIcon} />
              <p>{item.digit}</p>
            </div>
            </div>
            <div>
              <ul className="processCardUl">
               {item.discription?.map((i,index)=>
                <li>{i}</li>
               )}
              </ul>
            </div>

          </div>

        </div>
      ))}

      </div>








      <div className='ourProcessBackground-text'>Our <br/> Process</div>
 </div>
  );
}

export default OurProcess;
