import { useState } from "react";

import "./style.css";

import CurrentStatusImg from "../../../assets/images/currentStatusImg.svg"

function CurrentRating() {

  return (
    <div className="currentratSection">
        <div className="curRatInner">
         <p className="curRatHeading">Current Rating</p>
         <h3>EEE+</h3>
         <p className="validDate">Valid until Aug 2024</p>
        </div>
        <div className="curRatImg">
            <img src={CurrentStatusImg} />
        </div>
    </div>
  );
}

export default CurrentRating;
