import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Switch } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import AdminPagination from "../pagination/AdminPagination";
import { useUserStatusChangeMutation } from "../../../features/admin/adminApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import PageLoader from "../../Loader/PageLoader";

import "./style.css";

import AppleIcon from "../../../assets/icons/apple.svg";
import FormButton from "../../button/FormButton";
import ViewFeedback from "../../drawer/registrationLog/ViewFeedback";

const feedbackList = [
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Emissions Declaration",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "B&I Organization",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Emissions Declaration",
    userName: "Divyashree",
    emailId: "divya@codewave.com",
    role: "Consultant",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Emissions Declaration",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "Auditor",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Monitoring & Reporting",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "Auditor",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "Changing Consultant",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "B&I Organization",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "Admin",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "Advisory Board",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "B&I Organization",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
  {
    feedback: "Lorem ipsum dolor sit amet consectetur. Mus commodo...",
    subject: "General",
    userName: "Karthik Ramesh",
    emailId: "karthik@codewave.com",
    role: "B&I Organization",
    sendDate: "5/12/2023",
    answeredOn: "5/12/2023",
    action: "View Feedback",
  },
];

const FeedbackDetails = ({
  isLoading,
  isFetching,
  changeRowsPerPage,
  rowsPerPage,
  changePage,
  page,
  totalCount,
  feedbackData,
  feedbackIsLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackSubject, setFeedbackSubject] = useState("");
  const [feedbackDate, setFeedbackDate] = useState("");
  const [user, setUser] = useState("");

  const feedbackOpen = (item) => {
    setViewFeedback(true);
    setFeedbackMessage(item?.message);
    setFeedbackSubject(item?.subject);
    setFeedbackDate(moment(item?.createdAt).format("DD/MM/YYYY"));
    setUser(`by ${item?.user?.fullName} (${item?.userType})`);
  };
  const feedbackClose = () => {
    setViewFeedback(false);
  };

  return (
    <div className="userDetailsContainer">
      {feedbackIsLoading && <PageLoader />}
      <table className="orgRatingTable">
        <tr className="userDetailTitle">
          <th className="heading">Feedback</th>
          <th className="heading">Subject</th>
          <th className="heading">Name & Email</th>
          <th className="heading">Role</th>
          <th className="heading">Sent On</th>
          <th className="heading">Answered On</th>
          <th className="heading">Actions</th>
        </tr>
        {feedbackData?.data?.[0]?.data?.map((item, index) => (
          <tr className="adminUserList" key={index}>
            <td>
              <div className="listedUser cursorPointer">
                <FormButton
                  buttonName={"New"}
                  backgroundColor={"#2BA84A"}
                  buttonText={"#ffffff"}
                />
                <div className="admFeedbackTitle">
                  <p>{item.message}</p>
                </div>
              </div>
            </td>
            <td className="">
              <p>{item.subject}</p>
            </td>
            <td className="orgProManager cursorPointer">
              <h1>{item?.userName}</h1>
              <h3>{item?.emailId}</h3>
            </td>
            <td>
              <p>{item.userType}</p>
            </td>
            <td>
              <p>{moment(item?.createdAt).format("DD/MM/YYYY")}</p>
            </td>
            <td>
              <p>{moment(item?.updatedAt).format("DD/MM/YYYY")}</p>
            </td>
            <td
              className="feedbackAction cursorPointer"
              onClick={() => {
                feedbackOpen(item);
              }}
            >
              <p>View Feedback</p>
            </td>
          </tr>
        ))}
      </table>
      {/* <AdminPagination
        changeRowsPerPage={changeRowsPerPage}
        rowsPerPage={rowsPerPage}
        changePage={changePage}
        page={page}
        totalCount={totalCount}
      /> */}

      <ViewFeedback
        isOpen={viewFeedback}
        onClose={feedbackClose}
        feedbackMessage={feedbackMessage}
        feedbackSubject={feedbackSubject}
        feedbackDate={feedbackDate}
        user={user}
      />
    </div>
  );
};

export default FeedbackDetails;
