import React from "react";
import Chart from "react-apexcharts";

function LineChart() {
  const options = {
    series: [
      {
        name: "Page Views",
        // data: [35, 50, 70, 50, 35, 20, 4],
        data: [7658],
      },
    ],
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5],
      curve: "smooth",
      colors: ["#008BB2"], // Set wave line color
      dashArray: [8],
    },

    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      // categories: ['0', '2010', '2020', '2023', '2030', '2035', '2040'],
      categories: ["2024"],
      tickAmount: 8,
    },
    yaxis: {
      title: {
        text: "Global GHG Emissions, mtCO2e",
        align: "left",
      },
      min: 0,
      labels: {
        formatter: function (value) {
          return (value * 2) / 10; // Multiply each value by 2 to display the 2's table
        },
      },
    },
    //   tooltip: {
    //     y: [
    //       {
    //         title: {
    //           formatter: function (val) {
    //             return val + " page views"
    //           }
    //         }
    //       }
    //     ]
    //   },
    grid: {
      borderColor: "#f1f1f1",
    },
    // Hide menu options
    chart: {
      toolbar: {
        show: false, // Hide the toolbar
      },
    },
  };

  const responsive = [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ];

  return (
    <Chart
      height={260}
      options={options}
      series={options.series}
      type="line"
      style={{ minWidth: "150px", width: "40.5vw", padding: "20px" }}
    />
  );
}

export default LineChart;
