import react from "react";

import "./style.css";
import ProfileHeadquarters from "../profileHeadquarters/ProfileHeadquarters";

function AdminWorkDetails({ userData }) {
  return (
    <div className="viewOrgDetContainer">
      <div className="userProDetailsRow">
        <div className="userOrgDetails">
          <p>Name of Organization</p>
          <h1>SEE Rating</h1>
        </div>
        <div className="userOrgDetails">
          <p>Position in the organization</p>
          <h1>Director Of Operations</h1>
        </div>
      </div>
      <div className="userOrgDetails">
        <p>Employee ID</p>
        <h1>SR1234567890</h1>
      </div>

      {/* Headquarter Section */}
      <ProfileHeadquarters
        HeadquartersTitle={"Work Location"}
        address={userData?.data?.orgDetails?.orgHeadquarter}
      />

    </div>
  );
}

export default AdminWorkDetails;
