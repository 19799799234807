import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import "./style.css";
import { FormHelperText } from "@mui/material";
import WarningIcon from "../../../assets/icons/errorWarning.svg";

function EmisCalendar({
  startDateLabel,
  endDateLabel,
  startDateValue,
  startDateName,
  startDateError,
  endDateValue,
  endDateName,
  endDateError,
  formik,
  errorIcon,
}) {
  const [cleared, setCleared] = useState(false);
  const [datePopUp, setDatePopUp] = useState({
    startDate: false,
    endDate: false,
  });

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  return (
    <div className="calenderContainer">
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="calenderInnerContainer">
            <label className="calendarLabel">{startDateLabel}</label>
            <DatePicker
              views={["year", "month", "day"]}
              value={dayjs(startDateValue)}
              onChange={(newValue) => {
                formik.setFieldValue(
                  `${startDateName}`,
                  newValue.format("YYYY-MM-DD")
                );
              }}
              className={`dateField ${startDateError ? "error" : ""}`}
              onBlur={formik?.handleBlur}
              slotProps={{
                toolbar: {
                  hidden: true,
                },
                textField: {
                  InputProps: {
                    className: "dateField",
                  },
                  role: "button",
                  onClick: () =>
                    setDatePopUp({
                      startDate: true,
                      endDate: false,
                    }),
                  onKeyDown: (e) => {
                    e.preventDefault();
                    setDatePopUp({
                      startDate: false,
                      endDate: true,
                    });
                  },
                },
              }}
              open={datePopUp.startDate}
              onOpen={() =>
                setDatePopUp({
                  startDate: true,
                  endDate: false,
                })
              }
              onClose={() =>
                setDatePopUp({
                  startDate: false,
                  endDate: false,
                })
              }
              format="YYYY/MM/DD"
              // minDate={dayjs()}
              maxDate={dayjs(endDateValue)}
            />
          </div>
        </LocalizationProvider>
        {startDateError && (
          <p error className="inputError">
            {errorIcon && (
              <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
            )}
            {startDateError}
          </p>
        )}
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="calenderInnerContainer">
            <label className="calendarLabel">{endDateLabel}</label>
            <DatePicker
              views={["year", "month", "day"]}
              value={dayjs(endDateValue)}
              onChange={(newValue) => {
                formik.setFieldValue(
                  `${endDateName}`,
                  newValue.format("YYYY-MM-DD")
                );
              }}
              className={`dateField ${endDateError ? "error" : ""}`}
              slotProps={{
                toolbar: {
                  hidden: true,
                },
                textField: {
                  size: "small",
                  InputProps: {
                    className: "dateField",
                  },
                  role: "button",
                  onClick: () =>
                    setDatePopUp({
                      startDate: false,
                      endDate: true,
                    }),
                  onKeyDown: (e) => {
                    e.preventDefault();
                    setDatePopUp({
                      startDate: false,
                      endDate: true,
                    });
                  },
                },
              }}
              open={datePopUp.endDate}
              onOpen={() =>
                setDatePopUp({
                  startDate: true,
                  endDate: false,
                })
              }
              onClose={() =>
                setDatePopUp({
                  startDate: false,
                  endDate: false,
                })
              }
              format="YYYY/MM/DD"
              minDate={startDateValue ? dayjs(startDateValue) : null}
              disabled={startDateValue ? false : true}
            />
          </div>
        </LocalizationProvider>
        {endDateError && (
          <p error className="inputError">
            {errorIcon && (
              <img src={WarningIcon} alt="Error Icon" className="errorIcon" />
            )}
            {endDateError}
          </p>
        )}
      </div>
    </div>
  );
}

export default EmisCalendar;
