import React from "react";

import "./style.css";

function ViewDeclarationBtn({ onClick, text }) {
  return (
    <div className="viewDeclarationCon cursorPointer" onClick={onClick}>
      <p>{text}</p>
    </div>
  );
}

export default ViewDeclarationBtn;
