import react, { useState } from "react";
import FormButton from "../../../components/button/FormButton";
import PdfUpload from "../../../components/pdfUpload/PdfUpload";
import FileUploadBox from "../../../components/fileUploadBox/FileUploadBox";
import CommentBox from "../../../components/commentBox/CommentBox";
import EmisGoodPractice from "../../../components/emissionComponents/emisGoodPractice/EmisGoodPractice";
import SelectNetYear from "../../../components/emissionComponents/step3/selectNetYear/SelectNetYear";
import EmisChecklistSection from "../../../components/emissionComponents/emisChecklistSection/EmisChecklistSection";
import ActionPlanTemplate from "../../../components/emissionComponents/step3/actionPlanTemplate/ActionPlanTemplate";
import Acknowledgement from "../../../components/emissionComponents/step1/acknowledgement/Acknowledgement";

import "./style.css";

import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import UpArraow from "../../../assets/icons/Caret_Circle_Up.svg";
import Circle from "../../../assets/icons/stepOneCircle.svg";
import Triangle from "../../../assets/icons/Triangle.svg";
import CodeLogo from "../../../assets/logo/CodeWaveLogo.svg";
import PriorityPillar from "../../../components/emissionComponents/step3/priorityPillar/PriorityPillar";
import { useFormik } from "formik";
import AddPriorityPillar from "../../../components/emissionComponents/step3/addPriorityPillar/AddPriorityPillar";
import PriorityPillarPreview from "../../../components/emissionComponents/step4/priorityPillarPreview/PriorityPillarPreview";
import ApproveDecButton from "../../../components/button/approveButton/ApproveDecButton";
import { useGetEmissionDataQuery } from "../../../features/steps/declerationApiSlice";
import {
  useRoadmapApproveMutation,
  useRoadmapRejectMutation,
  useRoadmapRevertMutation,
} from "../../../features/steps/roadmapApiSlice";
import { useNavigate } from "react-router-dom";
import { getUserFromLocal } from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { showSuccess } from "../../../features/snackbar/snackbarAction";

function EmissionStep4() {
  const navigate = useNavigate();
  const userDetails = getUserFromLocal();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");

  const {
    data: findOneData,
    isLoading: findOneIsLoading,
    isSuccess: userListIsSuccess,
    error: userListError,
    isError: findOneIsError,
    isFetching: findOneIsFetching,
  } = useGetEmissionDataQuery({}, { refetchOnMountOrArgChange: true });

  const declerationId = findOneData?.data?.declaration?._id;

  const [
    roadmapApprove,
    {
      isLoading: roadmapApproveIsLoading,
      isSuccess: roadmapApproveIsSuccess,
      error: roadmapApproveIsError,
    },
  ] = useRoadmapApproveMutation();

  const [
    roadmapRevert,
    {
      isLoading: roadmapRevertIsLoading,
      isSuccess: roadmapRevertIsSuccess,
      error: roadmapRevertIsError,
    },
  ] = useRoadmapRevertMutation();

  const [
    roadmapReject,
    {
      isLoading: roadmapRejectIsLoading,
      isSuccess: roadmapRejectIsSuccess,
      error: roadmapRejectIsError,
    },
  ] = useRoadmapRejectMutation();

  const handleRoadmapSubmission = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "Approve Declaration") {
      roadmapApprove({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Approved Successfully` }));
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log("error", err.data.message);
        });
    } else if (event.target.value === "Revert with Comments") {
      roadmapRevert({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Reverted Successfully` }));
          console.log(res, "res");
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log("error", err.data.message);
        });
    } else if (event.target.value === "Reject Roadmap") {
      roadmapReject({
        declarationId: declerationId,
      })
        .unwrap()
        .then((res) => {
          dispatch(showSuccess({ message: `Rejected Successfully` }));
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log("error", err.data.message);
        });
    }
  };

  const handleRoadmapEdit = () => {
    navigate("/emissionStep3");
  };

  const handleRoadmapResubmit = () => {};

  const handleCancelAndExit = () => {
    navigate("/dashboard");
  };

  return (
    <form className="emisPreviewSection">
      <div className="emisPreTitleHeader">
        <div className="">
          <img src={EmissionDecLogo} alt="EmissionDecLogo" />
        </div>
        {userDetails?.userType === "auditor" && (
          <div className="emisPreLeftDiv">
            <div className="emisPreCancelBtn" onClick={handleCancelAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
                type="button"
              />
            </div>
            <div className="emis2SubmitBtn">
              <ApproveDecButton
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleChange={handleRoadmapSubmission}
                declerationId={declerationId}
              />
            </div>
            {/* <div className='emisPreSubmitBtn'>
            <FormButton
              buttonName={"Submit Declaration"}
              backgroundColor={"#00637E"}
              buttonText={"#ffffff"}
              btnFont={"14px"}
              btnPdd={"12px 20px"}
            />
          </div> */}
          </div>
        )}

        {userDetails?.userType === "consultant" && (
          <div className="emis2ButtonDiv">
            <div className="emisCancelBtn" onClick={handleCancelAndExit}>
              <FormButton
                buttonName={"Cancel & Exit"}
                buttonText={"#ffffff"}
                backgroundColor={"transparent"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="emisSaveBtn">
              <div
                className="form_SubmitBtn"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #ffffff",
                  color: "#ffffff",
                  fontSize: "1rem",
                  padding: "10px 20px",
                  cursor: "pointer",
                }}
                onClick={handleRoadmapEdit}
              >
                Edit Roadmap
              </div>
            </div>
            <div className="emisSubmitBtn" onClick={handleRoadmapResubmit}>
              <FormButton
                buttonName={"Resubmit Roadmap"}
                backgroundColor={"#00637E"}
                buttonText={"#ffffff"}
                btnFont={"1rem"}
                btnPdd={"12px 20px"}
              />
            </div>
          </div>
        )}
      </div>
      <div className="emisPreInnerCon">
        <div className="emisPreSecCol1">
          <div className="roadmapTitle">
            <h1>Roadmap To Net Zero</h1>
            <div className="stepsNext">
              <p> Next Step: </p>
              <span> Granting A Rating </span>
            </div>
          </div>
          {/* <EmisChecklistSection /> */}
          <EmisGoodPractice />
        </div>
        <div className="emisPreSecCol2">
          <div className="net4YearRow">
            <div className="stepsCodewaveLogo">
              <img src={CodeLogo} />
              <h3>Codewave’s Roadmap To NetZero Emissions</h3>
            </div>
            <div className="preNetZeroValue">
              <h1>Net Zero Target: 2030</h1>
            </div>
          </div>

          {findOneData?.data?.declaration?.actionPlan?.priorityPillars?.map(
            (item, priorityPillarIndex) => (
              <PriorityPillarPreview
                key={priorityPillarIndex}
                priorityPillarIndex={priorityPillarIndex}
                headName={`Priority Pillar ${priorityPillarIndex + 1}`}
                value={item}
                declarationId={findOneData?.data?.declaration?._id}
              />
            )
          )}

          <div className="horizontalLine"></div>

          {/* <div className=" bottomSubmitRow">
            <div className="cursorPointer">
              <FormButton
                buttonName={"Cancel & Exit"}
                border={"1px solid #008BB2"}
                buttonText={"#008BB2"}
                backgroundColor={"#ffffff"}
                btnFont={"14px"}
                btnPdd={"12px 20px"}
              />
            </div>
            <div className="cancelBtn_Row">
              <ApproveDecButton
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleChange={handleRoadmapSubmission}
                declerationId={declerationId}
              />
              <div className="NextBtn cursorPointer">
                <FormButton
                  buttonName={"Submit Action Plan"}
                  backgroundColor={"#008BB2"}
                  buttonText={"#ffffff"}
                  btnFont={"14px"}
                  btnPdd={"12px 20px"}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="emissionCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>
    </form>
  );
}

export default EmissionStep4;
