import { Backdrop, Box, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader = () => {
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "none",
          outline: "none",
        }}
      >
        <div>
          <CircularProgress />
        </div>
      </div>
    </Modal>
  );
};

export default PageLoader;
