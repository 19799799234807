import React from "react";

import "./style.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function RecommendedOptions({ type, setType }) {
  return (
    <div className="recommendedOptCon">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        className="wd-100"
        defaultValue={"car"}
        value={type}
        onChange={(e) => {
          setType(e.target.value);
          console.log("d3knbdbhqj3bdv3", e.target.value);
        }}
      >
        <FormControlLabel
          value={"Corrective Action Required"}
          control={<Radio size="small" />}
          label="CAR - Corrective Action Required"
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
              fontFamily: "Uniform",
              fontWeight: 400,
            },
          }}
        />
        <FormControlLabel
          value={"Clarification"}
          control={<Radio size="small" />}
          label="CL - Clarification"
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
              fontFamily: "Uniform",
              fontWeight: 400,
            },
          }}
        />
        <FormControlLabel
          value={"Recommendation"}
          control={<Radio size="small" />}
          label="FAR - Recommendation"
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
              fontFamily: "Uniform",
              fontWeight: 400,
            },
          }}
        />
      </RadioGroup>
    </div>
  );
}

export default RecommendedOptions;
