import _ from "lodash";
import { showError } from "../features/snackbar/snackbarAction";
import store from "../app/store";
import { UNITS } from "./defaults";

const isHttpValid = (str) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
};

const omitEmptyKeys = (obj, excludes = []) =>
  _.omitBy(obj, (value, key) => {
    return (
      !excludes.includes(key) &&
      (value === "" ||
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0))
    );
  });
// const omitEmptyKeys = (obj, excludes = []) =>
//   _.omitBy(obj, (value, key) => {
//     return (
//       !excludes.includes(key) &&
//       (value === "" || value === undefined || value === null)
//     );
//   });

const omitNullishKeys = (obj, excludes = []) =>
  _.omitBy(obj, (value, key) => {
    return !excludes.includes(key) && !value;
  });

const pickExactObjKeys = (obj, pickObj) => _.pick(pickObj, Object.keys(obj));

// function removeEmptyKeys(obj) {
//   for (const key in obj) {
//     if (typeof obj[key] === "object") {
//       obj[key] = removeEmptyKeys(obj[key]);

//       if (Object.keys(obj[key]).length === 0 ) {
//         delete obj[key];
//       }
//     } else if (obj[key] === "" || obj[key] === null) {
//       delete obj[key];
//     }
//   }
//   return obj;
// }

function removeEmptyKeys(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeEmptyKeys);
  }

  const result = {};

  for (const key in obj) {
    const value = removeEmptyKeys(obj[key]);

    if (
      (typeof value === "object" && Object.keys(value).length !== 0) ||
      (value !== "" && value !== null)
    ) {
      result[key] = value;
    }
  }

  return result;
}

function cleanObject(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      cleanObject(obj[key]);
    }
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      (Array.isArray(obj[key]) && obj[key].length === 0) ||
      (obj[key] instanceof Object && Object.keys(obj[key]).length === 0)
    ) {
      delete obj[key];
    }
  });
}
function cleanObject2(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (item && typeof item === "object") {
            cleanObject2(item);
          }
        });
        obj[key] = obj[key].filter(
          (item) =>
            item !== null && item !== undefined && Object.keys(item).length > 0
        );
      } else {
        cleanObject2(obj[key]);
      }
    }
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      (Array.isArray(obj[key]) && obj[key].length === 0) ||
      (obj[key] instanceof Object && Object.keys(obj[key]).length === 0)
    ) {
      delete obj[key];
    }
  });
}
function cleanObjectExceptKeys(obj, ignoreKeys = []) {
  Object.keys(obj).forEach((key) => {
    // Skip cleaning for the keys that should be ignored
    if (ignoreKeys.includes(key)) return;

    // Recursively clean objects
    if (
      obj[key] &&
      typeof obj[key] === "object" &&
      !(obj[key] instanceof Date)
    ) {
      cleanObject(obj[key], ignoreKeys);
    }

    // Delete null, undefined, empty strings, and empty arrays or objects
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      (Array.isArray(obj[key]) && obj[key].length === 0) ||
      (obj[key] instanceof Object && Object.keys(obj[key]).length === 0)
    ) {
      delete obj[key];
    }
  });
}

function bytesFormat(x) {
  let index = 0;
  let value = parseInt(x, 10) || 0;

  while (value >= 1024 && ++index) {
    value = value / 1024;
  }

  return value.toFixed(value < 10 && index > 0 ? 1 : 0) + " " + UNITS[index];
}

const validateDropzoneSingleFile = (rejectedFiles, maxSize) => {
  const rejectedFile = rejectedFiles[0];
  if (rejectedFile) {
    const {
      errors: [{ code }],
      file: { name },
    } = rejectedFile;
    switch (code) {
      case "file-too-large": {
        store.dispatch(
          showError({
            message: `${name} is larger than ${bytesFormat(maxSize)}`,
          })
        );
        break;
      }
      default:
        break;
    }
  }
};

const validateDropzoneMultipleFiles = (rejectedFiles, maxSize) => {
  rejectedFiles.forEach((file) => {
    const {
      errors: [{ code }],
      file: { name },
    } = file;
    switch (code) {
      case "file-too-large": {
        store.dispatch(
          showError({
            message: `${name} is larger than ${bytesFormat(maxSize)}`,
          })
        );
        break;
      }
      default:
        break;
    }
  });
};

export {
  isHttpValid,
  omitEmptyKeys,
  omitNullishKeys,
  pickExactObjKeys,
  removeEmptyKeys,
  validateDropzoneSingleFile,
  validateDropzoneMultipleFiles,
  cleanObject,
  cleanObject2,
  cleanObjectExceptKeys,
};
