import React from 'react'
import EditPencil from "../../assets/icons/Edit_Pencil.svg";
import Headquarters from './Headquarters';
import PdfUpload from '../pdfUpload/PdfUpload';
import { useNavigate } from 'react-router-dom';

const AuditorWorkDetails = ({workDetails}) => {
  const navigate = useNavigate();
    const auditorDetails = [
        {
          title: "Representing Organization",
          value: workDetails?.associatedOrg,
        },
        {
          title: "Name of Your Organization",
          value: workDetails?.orgName,
        },
        {
          title: "Website",
          value: workDetails?.websiteLink,
        },
      ];
  return (
    <div className="orgDetailsEdit_section">
    <div className="edit_div">
      <div className="wd-50">
        <h3>Work Details</h3>
      </div>
      <div className="wd-50 editContainer" onClick={()=>{navigate(`/auth/registration/workDetails`);}}>
        <p className="edit cursorPointer">
          <img className="editIcon" src={EditPencil} alt="EditPencil" />
          Edit
        </p>
      </div>
    </div>
    {auditorDetails.map((item) => (
      <div className="detailsRowDiv" key={item.index}>
        <div className="wd-30">
          <h4 className="preTitle">{item.title}</h4>
        </div>
        <div className="wd-40 mL-2r">
          <h4 className="preValue">{item.value}</h4>
        </div>
      </div>
    ))}

    <Headquarters workDetails={workDetails} />

    <div className="detailsRowDiv">
      <div className="wd-33">
        <h4 className="preTitle">Documents Of Proofs</h4>
      </div>
      <div className="wd-65 preview_pdfRow">
        {workDetails?.proofOfAuditorshipFiles?.map((item) => (
          <div className="wd-35 mb_1r">
            <PdfUpload pdfType={item} faXmarkDisplay={"none"} />
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

export default AuditorWorkDetails