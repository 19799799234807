import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetDashBoardStatusQuery } from "../../../features/status/statusApiSlice";
import { getUserFromLocal, removeAuthFromLocal } from "../../../utils/storage";
import { useDispatch } from "react-redux";
import { authActions } from "../../../features/auth/authSlice";
import SendReminder from "../../../components/dashboardComponents/SendReminder";
import CurrentRating from "../../../components/ratingStatusBoard/currentRating/CurrentRating";
import EmisDeclarationStatus from "../../../components/ratingStatusBoard/emisDeclarationStatus/EmisDeclarationStatus";
import GlobalGHGRating from "../../../components/globalGHG/GlobalGHGRating";

import "./style.css";

import RightArrow from "../../../assets/icons/ArrowRight.svg";
import TopBg from "../../../assets/icons/dashTopIcon.svg";
import RightBg from "../../../assets/icons/dashRightIcon.svg";
import EmissionDecLogo from "../../../assets/logo/EmissionDeclLogo.svg";
import Location from "../../../assets/icons/location.svg";
import BellIcon from "../../../assets/icons/bell.svg";
import ProfileIcon from "../../../assets/icons/profileImg.svg";
import AddIcon from "../../../assets/icons/AddPlusBtn.svg";
import AddConsultantAndAuditor from "../../../components/dashboardComponents/AddConsultantAndAuditor";

function ConsAuditDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);

  const [logOutPopUp, setLogOutPopUp] = useState(false);
  const userDetails = getUserFromLocal();

  const toggleLogOut = () => {
    setLogOutPopUp(!logOutPopUp);
  };

  const {
    data: dashBoardStatusData,
    isLoading: dashBoardStatusIsLoading,
    isSuccess: dashBoardStatusIsSuccess,
    error: dashBoardStatusError,
    isError: dashBoardStatusIsError,
  } = useGetDashBoardStatusQuery({});

  useEffect(() => {
    if (dashBoardStatusIsSuccess) {
      setStatus(dashBoardStatusData?.data?.stageInfo?.currentStage);
    }
  }, [dashBoardStatusIsSuccess]);

  return (
    <div className="container dashboardSection">
      <div className="dashtitleDiv">
        <div className=" logoSec wd-50">
          <img src={EmissionDecLogo} alt="" />
          <ul className="dashUl">
            <li>Dashboard</li>
            <li>My Rating</li>
            <li>Help & Support</li>
          </ul>
        </div>
        <div className="dashProfil wd-50">
          <div className="city">
            <p>Dubai</p>
            <img src={Location} />
          </div>
          <div className="rightBorder"></div>
          <div className="city">
            <img src={BellIcon} />
          </div>
          <div className="rightBorder"></div>
          <div className="profileImg" onClick={toggleLogOut}>
            <img src={ProfileIcon} />
          </div>
        </div>
      </div>
      <div className="dashboardInner">
        <div className="welcomeDiv">
          <p className="welcome">Welcome,</p>
          <p className="userName">{userDetails?.userName}</p>
        </div>
        <img src={TopBg} className="topbg" />
        <img src={RightBg} className="rightbg" />
        <div className="addConSection">
          <AddConsultantAndAuditor />
        </div>

        <dv className="dashCurrentStatus">
          <CurrentRating />
          <EmisDeclarationStatus />
          <GlobalGHGRating />
        </dv>
      </div>
      <div className="dashboardCopy">
        <p>© Copyright | All Rights Reserved by SeeInstitute</p>
        <p>Made with love by Codewave Technologies</p>
      </div>

      {logOutPopUp && (
        <div className="LogOutContainer cursor-pointer">
          <div
            className="cursor-pointer"
            onClick={() => {
              removeAuthFromLocal();
              dispatch(authActions.logout());
            }}
          >
            <h4 className="cursor-pointer colorWhite"> Log Out </h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsAuditDashboard;
