import React from "react";
import "./style.css";

const ContainedButton = ({
  text,
  bgColor,
  rightIcon,
  leftIcon,
  onClick,
  type,
  fontSize,
}) => {
  return (
    <button
      type={type ? type : "button"}
      className={`contained-button ${bgColor}`}
      onClick={onClick}
    >
      {leftIcon && <img src={leftIcon} alt="leftIcon" />}
      <span
        style={{
          paddingTop: "2px",
          textWrap: "nowrap",
          fontSize: fontSize ? fontSize : "12px",
        }}
      >
        {text}
      </span>
      {rightIcon && <img src={rightIcon} alt="RightIcon" />}
    </button>
  );
};

export default ContainedButton;
