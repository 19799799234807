import React from "react";
import InputBox from "../../../InputBox/InputBox";
import WarningIcon from "../../../../assets/icons/errorWarning.svg";

import "./style.css";
import TextArea from "../../../InputBox/textAreaBox/TextArea";

function GHGEmission({
  formik,
  resultHeaderName,
  resultTitle1,
  resultTitle2,
  resultTitle3,
  name1,
  value1,
  error1,
  touched1,
  name2,
  value2,
  error2,
  touched2,
  name3,
  value3,
  error3,
  touched3,
}) {
  return (
    <div className="ghgEmissionCon">
      <h1 className="ghgEmissTitle">{resultHeaderName}</h1>
      <div className="ghgEmissScopeRow">
        <div className="ghgEmissScope">
          <div className="ghgEmissCol">
            <h1>{resultTitle1}</h1>
            <div className="resultSec">
              <InputBox
                type="number"
                headName={"Q1 Result GHG (tCO2e)"}
                placeholder="Enter the value"
                name={`${name1}.value`}
                value={value1?.value}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched1?.value && error1?.value}
                errorIcon={WarningIcon}
              />
              <TextArea
                type="text"
                headName={"Explanatory Notes"}
                placeholder="Tell us more here..."
                name={`${name1}.explanatoryNotes`}
                value={value1?.explanatoryNotes}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched1?.explanatoryNotes && error1?.explanatoryNotes}
                errorIcon={WarningIcon}
              />
            </div>
          </div>
        </div>
        <div className="ghgEmissScope">
          <div className="ghgEmissCol">
            <h1>{resultTitle2}</h1>
            <div className="resultSec">
              <InputBox
                type="number"
                headName={"Q1 Result GHG (tCO2e)"}
                placeholder="Enter the value"
                name={`${name2}.value`}
                value={value2?.value}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched2?.value && error2?.value}
                errorIcon={WarningIcon}
              />
              <TextArea
                type="text"
                headName={"Explanatory Notes"}
                placeholder="Tell us more here..."
                name={`${name2}.explanatoryNotes`}
                value={value2?.explanatoryNotes}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched2?.explanatoryNotes && error2?.explanatoryNotes}
                errorIcon={WarningIcon}
              />
            </div>
          </div>
        </div>
        <div className="ghgEmissScope">
          <div className="ghgEmissCol">
            <h1>{resultTitle3}</h1>
            <div className="resultSec">
              <InputBox
                type="number"
                headName={"Q1 Result GHG (tCO2e)"}
                placeholder="Enter the value"
                name={`${name3}.value`}
                value={value3?.value}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched3?.value && error3?.value}
                errorIcon={WarningIcon}
              />
              <TextArea
                type="text"
                headName={"Explanatory Notes"}
                placeholder="Tell us more here..."
                name={`${name3}.explanatoryNotes`}
                value={value3?.explanatoryNotes}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={touched3?.explanatoryNotes && error3?.explanatoryNotes}
                errorIcon={WarningIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GHGEmission;
